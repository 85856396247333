<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="height: 70px">
          <p style="font-size: 22px; text-align: left">Stock</p>
        </div>
      </div>
    </div>
    <div class="columns" style="margin-left: 20px">
      <div class="column is-2"></div>
      <div class="column is-8">
        <div style="float: left">
          <div class="field has-addons" style="margin-left: 0">
            <p class="control">
              <select v-model="filterSelection" style="height: 40px; width: 120px; border: 1px solid #0c9eda">
                <option value="" hidden>Room No.</option>
                <option v-for="filter in filterTypes" :key="filter.id">
                  {{ filter }}
                </option>
              </select>
            </p>
            <p class="control">
              <input class="input" style="width: 140px; border: 1px solid #0c9eda" type="date" v-model="date" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2"></div>
      <div class="column is-8">
        <div class="box" style="height: 60vh">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <!-- <th>Room</th> -->
                <!-- <th>Date</th> -->
                <th>Item Name</th>
                <th>D. Quantity</th>
                <th>U. Quantity</th>
                <th>Cost</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="d in data" v-bind:key="d.id">
                <template v-if="d.room_no == filterSelection">
                  <template v-if="d.date == date">
                    <!-- <td>{{ d.room_no }}</td> -->
                    <!-- <td>{{ d.date }}</td> -->
                    <td>{{ d.item_name }}</td>
                    <td>{{ d.defaultQuantity }}</td>
                    <td>{{ d.item_used }}</td>
                    <td>{{ d.item_bill }}</td>
                  </template>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-2"></div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
// import { toast } from 'bulma-toast'

export default {
  data() {
    return {
      data: [],
      dataR: [],
      dataS: [],
      filterSelection: '',
      filterTypes: [],
      dftQ: '',
      dftN: '',
      date: new Date().toISOString().substr(0, 10),
      pic: null,
    };
  },
  created() {
    this.getDataS();
    this.getDataR();
    this.getDataST();
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back05;
        })
        .catch((err) => console.log(err));
    },

    async getDataS() {
      await api
        .get('api/stockjoint/')
        .then((response) => {
          this.data = response.data;
          console.log(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataST() {
      await api
        .get('api/item/')
        .then((response) => {
          this.dataS = response.data;
          console.log(this.dataS);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataR() {
      await api
        .get('api/room/')
        .then((response) => {
          this.dataR = response.data;
          console.log(this.dataR);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataR.length; i++) {
        this.filterTypes.push(this.dataR[i].room_no);
      }
      console.log(this.filterTypes);
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>