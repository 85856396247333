<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Reports</p></div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3">
      <div class="col-span-3 mx-10 justify-center">
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_guest')">Guest Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_reservation')">Reservation Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_advance')">Advance Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_current_bill')">Current Bill Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_pre_bill')">Previous Bill Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_cash')">Cash Transaction Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_daily')">Daily Sales Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_Monthly')">Monthly Sales Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_extraa')">Laundry Sales</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_room_t')">Room Transfer Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_checkin')">Checked In Report</button>
        <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/report_checkout')">Checked Out Report</button>
        <!-- <button class="button is-big" style="float:left; margin-right:10px; background-color:#26ADE4; color:white;" @click.prevent="$router.push('/report_demo')">Demo</button> -->
        <!-- <button class="button is-big" style="float:left; margin-right:10px; background-color:#26ADE4; color:white;" @click.prevent="$router.push('/report_night')">Night Audit Report</button> -->
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios';
export default {
  data() {
    return {
      pic: null,
      data: [],
    };
  },
  created() {
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back05;
        })
        .catch((err) => console.log(err));
    },

    // async getPic() {
    //   await api
    //     .get('users/')
    //     .then((res) => {
    //       this.data = res.data;
    //     })
    //     .catch((err) => console.log(err));
    // },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>