<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="height: 80px">
          <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/register')">Register User</button>
          <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/settings_room')">Room Settings</button>
          <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/settings_item')">Item Settings</button>
          <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/settings_laundry')">Laundry Settings</button>
          <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/settings_food')">Food Settings</button>
          <button class="button is-big" style="float: left; margin-right: 10px; background-color: #26ade4; color: white" @click.prevent="$router.push('/settings_pic')">Picture Settings</button>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column is-6">
        <div class="box">
          <div class="columns">
            <div class="column is-12">
              <div class="box" style="display: inline-block; width: 100%">
                <div style="float: left"><p style="font-size: 22px">Hotel Details</p></div>
              </div>
            </div>
          </div>

          <div style="margin-top: 10px">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Hotel Name:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="hotel name" v-model="data.company_name" />
                  </p>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Tax</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="vat" v-model="data.tax" />
                  </p>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Service Charge:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="service charge" v-model="data.service_charge" />
                  </p>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Laundry Tax:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="service charge" v-model="data.laundry_tax" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Laundry Service Charge:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="service charge" v-model="data.laundry_sc" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Room Service Tax:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="service charge" v-model="data.room_service_tax" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Room Service Service Charge:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="service charge" v-model="data.room_service_sc" />
                  </p>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Zip Code:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="zip code" v-model="data.zip_code" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Branch:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="branch" v-model="data.branch" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Hotel Address:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="address" v-model="data.company_address" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">City:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="city" v-model="data.city" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">BAT:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="bat no." v-model="data.bat" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">BIN:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="bin no." v-model="data.bin" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Contact Person:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="person" v-model="data.contact_person_name" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Phone:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input class="input" type="text" placeholder="phone" v-model="data.contact_person_phone" />
                  </p>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label" style="font-size: 20px; width: 200px">Select logo:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 300px">
                    <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload" />
                  </p>
                </div>
              </div>
            </div>

            <button class="button" style="margin-top: 5px; background-color: #26ade4; color: white" @click.prevent="Submit()">Save</button>
          </div>
        </div>
      </div>
      <div class="column is-3"></div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';
export default {
  data() {
    return {
      data: [],
      logo_array: [],
    };
  },
  created() {
    this.getData();
    this.getLogo();
  },
  methods: {
    async getData() {
      await api
        .get('auth/company/1/')
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLogo() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo_array = res.data;
        })
        .catch((err) => console.log(err));
    },
    async Submit() {
      await api
        .patch('/auth/company/1/', this.data)
        .then((response) => {
          toast({
            message: 'Saved',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      window.location.reload();
      await this.uploadImageHandler();
    },
    OnFileUpload(event) {
      this.pot = event.target.files[0];
      console.log(this.pot);
    },
    async uploadImageHandler() {
      const fd = new FormData();
      fd.append('pic', this.pot);
      fd.append('hotel_id', this.data.id);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      await api.put(`auth/hotel_logo/1/`, fd, config).then((res) => {
        console.log('pic upload: ', res.data);
      });
    },
  },
};
</script>

<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>