<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Bell Boy</p></div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-1"></div>
      <div class="column is-10">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Token no.</th>
              <th>Room No.</th>
              <th>Secondary Room(s)</th>
              <th>Check In/Check Out</th>
              <th>Specail Ins.</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in data" v-bind:key="d.id">
              <td>{{ d.token }}</td>
              <td>{{ d.room_no }}</td>
              <td>{{ d.room_secondary }}</td>
              <td>
                <p v-if="d.status == true">Check Out</p>
                <p v-else>Check In</p>
              </td>
              <td>{{ d.special_ins }}</td>
              <td>
                <button class="button is-small" style="background-color: #76dbab; color: white" @click.prevent="(statusB = true), (id = d.id)" :disabled="d.status == true">
                  <p v-if="d.status == true">Done</p>
                  <p v-else>Ongoing</p>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-1"></div>
    </div>

    <!-- ModaL: Delivery status update -->
    <div v-if="statusB == true">
      <div class="modal is-active">
        <div class="modal-background" @click.prevent="statusB = false">
          <div class="modal-content" style="height: 30vh; background: white; margin-top: 25vh">
            <div style="margin-top: 15%">
              <p class="subtitle is-4">Luggage has been Delivered</p>
              <div style="margin-top: 25px">
                <button class="button" style="background-color: #76dbab; color: white" @click.prevent="changeStatus">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
// import { toast } from 'bulma-toast'

export default {
  data() {
    return {
      data: [],
      id: '',
      statusB: false,
      pic: null,
    };
  },
  created() {
    this.getData();
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back08;
        })
        .catch((err) => console.log(err));
    },
    async getData() {
      await api
        .get('api/bellboy/')
        .then((response) => {
          this.data = response.data;
          console.table(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async changeStatus() {
      await api.put(`api/bellboy/${this.id}/`, { status: true }).catch((error) => {
        console.log(error);
      });
      this.getData();

      this.statusB = false;
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>