<template>
  <div class="min-h-screen h-full bg-gray-200">
    <div class="grid grid-cols-4 gap-x-2">
      <div class="h-14 col-span-4 border-2 bg-accentM">
        <ol class="list-reset flex mt-3 ml-5">
          <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li><span @click="this.$router.push('/purchase')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Purchase</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li class="text-white text-xl">Purchase Request Quotation</li>
        </ol>
      </div>

      <div class="col-span-4 mt-4 ml-10 mb-2">
        <!-- <button @click="true" class="bg-green-300 hover:bg-green-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Draft</button> -->
        <button @click="Save()" class="bg-button shadow-lg hover:bg-button2 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Submit</button>
        <button @click="this.$router.push('/purchase')" class="bg-red-400 shadow-lg hover:bg-red-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Back</button>
      </div>

      <div v-for="(d, j) in dataPurchaseItem" :key="j" class="col-span-4 my-2 mx-14 rounded shadow-lg bg-bg">
        <div class="grid grid-cols-6">
          <div class="col-span-1 bg-gray-200 border-2 border-black">
            <p class="text-xl mt-10 ud font-semibold">Item Name: {{ d.item }}</p>
            <p class="text-lg mt-2 ud">Quantity: {{ d.quantity }}</p>
            <p class="text-lg mt-2 ud">Unit price : {{ d.unitPrice }}</p>
            <div class="border-b will-change-transform border-gray-700 mx-10"></div>
            <p class="text-lg mt-2 ud">Total price : {{ d.unitPrice * d.quantity }}</p>
            <p class="text-lg mt-2 mb-10 ud">Price Limit : {{ d.supplierLimit }}</p>
          </div>
          <div class="col-span-5">
            <div class="grid grid-cols-2 my-2">
              <div class="col-span-2">
                <div class="flex items-center my-2">
                  <div class="w-3/12 underline"><p class="text-center">Supplier Name</p></div>
                  <div class="w-3/12 underline"><p class="text-center">Value</p></div>
                  <div class="w-3/12 underline"><p class="text-center">Tax</p></div>
                  <div class="w-3/12 underline"><p class="text-center">Other Amount</p></div>
                </div>
              </div>
              <div v-for="(b, i) in dataSupplier[j]" :key="i" class="col-span-2 my-2">
                <div class="flex items-center my-2">
                  <div class="w-3/12 mx-3">
                    <p class="text-lg">{{ b.name }}</p>
                  </div>
                  <div class="w-3/12 mx-3">
                    <input class="w-5/6 text-right border border-black py-1 px-2 rounded-sm focus:border-button" type="number" placeholder="quote" v-model="b.amount" />
                  </div>
                  <div class="w-3/12 mx-3">
                    <input class="w-5/6 text-right border border-black py-1 px-2 rounded-sm focus:border-button" type="number" placeholder="Tax" v-model="b.tax" />
                  </div>
                  <div class="w-3/12 mx-3">
                    <input class="w-5/6 text-right border border-black py-1 px-2 rounded-sm focus:border-button" type="number" placeholder="Other" v-model="b.other" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';
// import html2pdf from 'html2pdf-jspdf2';

export default {
  components: {},

  data() {
    return {
      date: new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 8),
      id: this.$route.params.id,
      dataS: [],
      dataStatus: [],
      dataStatus2: [],
      dataPurchase: [],
      dataPurchaseItem: [],
      dataSupplier: [],
    };
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      await api
        .get(`api/supplier/`)
        .then((res) => {
          this.dataS = res.data;
          //   console.table(this.dataS);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=rfq&statusCode=&statusName=pending`)
        .then((res) => {
          this.dataStatus = res.data;
          console.table(this.dataStatus);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=com&statusCode=&statusName=entry`)
        .then((res) => {
          this.dataStatus2 = res.data;
          if (this.dataStatus2 == '') {
            alert('Status not Set');
          }
          console.table(this.dataStatus2);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/pruchase/${this.id}/`)
        .then((res) => {
          this.dataPurchase = res.data;
          console.table(this.dataPurchase);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/requested_item/?orderId=${this.id}&status=${this.dataStatus[0].id}`)
        .then((res) => {
          this.dataPurchaseItem = res.data;
          console.table(this.dataPurchaseItem);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        this.dataSupplier[i] = [];
        let a = this.dataPurchaseItem[i].supplier.split('|');
        for (let j = 0; j < a.length; j++) {
          for (let k = 0; k < this.dataS.length; k++) {
            if (this.dataS[k].id == a[j]) {
              let a = {
                id: this.dataS[k].id,
                name: this.dataS[k].name,
                amount: '',
                tax: '',
                other: '',
                flat: false,
                includes: false,
              };
              this.dataSupplier[i].push(a);
            }
          }
        }
      }
      // console.table(this.dataSupplier);
    },
    async Save() {
      // console.table(this.dataSupplier);
      let d = this.dataStatus2[0].id;
      let e = this.dataStatus2[0].statusCode;
      let f = this.dataStatus2[0].statusName;
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        let s = '';
        let t = '';
        let o = '';
        for (let j = 0; j < this.dataSupplier[i].length; j++) {
          if (s == '') {
            s = this.dataSupplier[i][j].amount + '';
            t = this.dataSupplier[i][j].tax + '';
            o = this.dataSupplier[i][j].other + '';
          } else {
            s = s + '|' + this.dataSupplier[i][j].amount;
            t = t + '|' + this.dataSupplier[i][j].tax;
            o = o + '|' + this.dataSupplier[i][j].other;
          }
        }

        this.dataPurchaseItem[i].supplierValue = s;
        this.dataPurchaseItem[i].supplierValueTax = t;
        this.dataPurchaseItem[i].supplierValueOther = o;

        console.log(this.dataPurchaseItem[i].status);

        this.dataPurchaseItem[i].status = d;
        this.dataPurchaseItem[i].statusC = e;
        this.dataPurchaseItem[i].statusName = f;
        console.log(this.dataPurchaseItem[i].status);
      }
      this.dataPurchase.status = d;
      this.dataPurchase.statusC = e;
      this.dataPurchase.statusName = f;
      await api
        .put(`api/pruchase/${this.id}/`, this.dataPurchase)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        console.log(this.dataPurchaseItem[i].supplierValue);
        await api
          .put(`api/requested_item/${this.dataPurchaseItem[i].id}/`, this.dataPurchaseItem[i])
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.$router.push('/purchase_table');
    },
  },
};
</script>