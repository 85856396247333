<template>
  <div class="">
    <div style="height: 8vh" class="bg-csNav flex justify-end items-center">
      <button @click="OrderHistory()" class="text-white bg-accentM hover:bg-accent2 px-3 rounded-lg text-lg mr-2" style="height: 40px">Order(s)</button>
      <select v-model="card" class="bg-accentM hover:bg-accent2 text-white px-4 rounded-lg text-lg mr-2" style="height: 40px">
        <option value="" hidden>Select Room</option>
        <option v-for="d in dataCard" :key="d" :value="d.card_no">{{ d.room_no }}</option></select
      ><select v-model="table" @click="getTable()" class="bg-accentM hover:bg-accent2 text-white px-4 rounded-lg text-lg mr-2" style="height: 40px">
        <option value="" hidden>Select Table</option>
        <option v-for="d in dataTable" :key="d" :value="d.id">{{ d.num }}</option>
      </select>
      <button @click="OrderPlace()" class="text-white bg-accentM hover:bg-accent2 px-3 rounded-lg text-lg mr-5" style="height: 40px">Place Order</button>
    </div>
    <div style="height: 92vh; max-height: 92vh" class="grid grid-cols-5">
      <div class="col-span-3">
        <div class="grid grid-rows-6 h-full">
          <div class="row-span-1 m-2">
            <div class="flex flex-wrap">
              <div class="" v-for="d in dataCat" :key="d">
                <p @click="getMenu(d.id)" class="bg-gray-700 m-1 p-3 w-36 text-gray-200 text-xl rounded-lg cursor-pointer hover:text-white hover:bg-gray-900">{{ d.name }}</p>
              </div>
            </div>
          </div>
          <div class="row-span-5 border bg-scafold m-4">
            <div class="flex flex-wrap">
              <div v-for="d in data" :key="d">
                <div @click="AddItem(d)" style="position: relative; height: 150px; width: 150px; background-repeat: no-repeat; background-size: cover" v-bind:style="{ 'background-image': 'url(' + d.pic + ')' }" class="bg-gray-200 m-2 border rounded-md border-black cursor-pointer">
                  <div style="content: ''; display: block; position: absolute; bottom: 0; left: 0; right: 0; height: 35%; background-color: rgba(0, 0, 0, 0.5)">
                    <p class="text-white text-lg overflow-auto">{{ d.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2">
        <div class="grid grid-rows-4 h-full max-h-full">
          <div class="row-span-3 m-2 block">
            <div class="overflow-y-scroll" style="max-height: 66vh">
              <div class="grid grid-cols-5 bg-white text-xl font-semibold border-b border-black">
                <div class="col-span-2">Item</div>
                <div class="col-span-1">Qty</div>
                <div class="col-span-1">Unit Price</div>
                <div class="col-span-1">Amount</div>
              </div>
              <div class="">
                <div v-for="(d, i) in dataCart" :key="i" class="grid grid-cols-5 bg-gray-10 text-xl bg-scafold border-b border">
                  <div class="col-span-2">{{ d.name }}</div>
                  <div class="col-span-1">
                    <span @click="Minus(i)"><i class="fas fa-solid fa-minus text-red-500 cursor-pointer"></i></span> {{ d.quantity }} <span @click="Plus(i)"><i class="fas fa-solid fa-plus text-red-500 cursor-pointer"></i></span>
                  </div>
                  <div class="col-span-1">{{ d.price }} ৳</div>
                  <div class="col-span-1">{{ d.price * d.quantity }} ৳</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-span-1 bg-scafold2 border mb-4 mr-2">
            <div class="grid grid-cols-2 gap mx-10">
              <div class="col-span-1 text-left text-xl mt-2">
                <p>Sub Total</p>
              </div>
              <div class="col-span-1 text-right text-xl mt-2">{{ subtotal }}</div>

              <div class="col-span-1 text-left text-xl mt-1">
                <p>Tax</p>
              </div>
              <div class="col-span-1 text-right text-xl mt-1">{{ tax }}</div>

              <div class="col-span-1 text-left text-2xl mt-2">
                <p>Total</p>
              </div>
              <div class="col-span-1 text-right text-2xl mt-2">৳ {{ subtotal + subtotal * (tax / 100) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="orderModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="orderModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 900px; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div style="float: left; width: 850px; height: 90vh">
              <div class="flex rounded shadow-sm mb-1 ml-2" role="group">
                <button
                  @click="
                    bPlaced = true;
                    bkitchen = false;
                    bServed = false;
                    modalSerched = 'Placed';
                    OrderHistory();
                  "
                  type="button"
                  :class="{ activeRed: bPlaced }"
                  class="py-1 px-4 text-lg font-medium text-gray-900 bg-white rounded-l border border-gray-300 hover:bg-color1 hover:text-black"
                >
                  Placed
                </button>
                <button
                  @click="
                    bPlaced = false;
                    bkitchen = true;
                    bServed = false;
                    modalSerched = 'In Kitchen';
                    OrderHistory();
                  "
                  type="button"
                  :class="{ activeRed: bkitchen }"
                  class="py-1 px-4 text-lg font-medium text-gray-900 bg-white border border-gray-300 hover:bg-color1 hover:text-black"
                >
                  In Kitchen
                </button>
                <button
                  @click="
                    bPlaced = false;
                    bkitchen = false;
                    bServed = true;
                    modalSerched = 'Served';
                    OrderHistory();
                  "
                  type="button"
                  :class="{ activeRed: bServed }"
                  class="py-1 px-4 text-lg font-medium text-gray-900 bg-white rounded-r border border-gray-300 hover:bg-color1 hover:text-black"
                >
                  Served
                </button>
              </div>

              <div class="grid grid-cols-2">
                <div @click="OrderDetails(d)" class="col-span-1 m-1 border bg-scafold2 rounded hover:bg-blue-200 cursor-pointer" v-for="d in dataOrder" :key="d">
                  <div class="grid grid-cols-2 mx-4">
                    <div class="col-span-1 text-left text-xl">Order Number</div>
                    <div class="col-span-1 text-right text-xl">{{ d.idd }}</div>

                    <div class="col-span-1 text-left text-lg">Table number</div>
                    <div class="col-span-1 text-right text-lg">{{ d.table }}</div>

                    <div class="col-span-1 text-left text-lg">Amount</div>
                    <div class="col-span-1 text-right text-lg font-semibold">৳ {{ d.total }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 5px">
              <button class="button is-danger is-light" @click.prevent="orderModal = false" style="margin-right: 5px">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="detailsModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="detailsModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 900px; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div style="float: left; width: 850px; height: 70vh">
              <p class="text-3xl font-semibold underline">Order No. {{ dataDetails.idd }}</p>
              <div class="grid grid-cols-2 mx-32 mt-10 bg-scafold2 p-4 border rounded">
                <div class="col-span-1 text-left text-2xl">Status</div>
                <div v-if="dataDetails.status == 'Placed'" class="col-span-1 text-right text-2xl text-blue-700">{{ dataDetails.status }}</div>
                <div v-else-if="dataDetails.status == 'In Kitchen'" class="col-span-1 text-right text-2xl text-orange-700">{{ dataDetails.status }}</div>
                <div v-else-if="dataDetails.status == 'Served'" class="col-span-1 text-right text-2xl text-green-700">{{ dataDetails.status }}</div>

                <div class="col-span-1 text-left text-xl mt-1">Table No</div>
                <div class="col-span-1 text-right text-xl mt-1">{{ dataDetails.table }}</div>

                <div v-if="dataDetails.card_no != null" class="col-span-1 text-left text-xl mt-1">Card Number</div>
                <div v-if="dataDetails.card_no != null" class="col-span-1 text-right text-xl mt-1">{{ dataDetails.card_no }}</div>
              </div>

              <div class="grid grid-cols-2 mx-32 mt-4 px-2 bg-scafold p-4 border rounded">
                <div class="col-span-1 text-left text-lg">Sub Total</div>
                <div class="col-span-1 text-right text-lg">{{ dataDetails.subTotal }}</div>
                <div class="col-span-1 text-left text-lg mt-1">Tax</div>
                <div class="col-span-1 text-right text-lg mt-1">{{ dataDetails.tax }}</div>
                <div class="col-span-1 text-left text-xl mt-1">Total</div>
                <div class="col-span-1 text-right text-xl font-semibold mt-1">{{ dataDetails.total }}</div>
                <div class="col-span-2 mx-10 mt-2 rounded bg-gray-600 text-white">
                  <div class="grid grid-cols-2">
                    <div class="text-lg">Item</div>
                    <div class="text-lg">Qty</div>
                    <div class="col-span-2 border border-white"></div>
                    <div class="col-span-2" v-for="p in dataDetails.order" :key="p">
                      <div class="grid grid-cols-2">
                        <div class="text">{{ p.item }}</div>
                        <div class="text">{{ p.quantity }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 5px">
              <div v-if="dataDetails.status == 'Served'" class="col-span-2 mt-10">
                <button @click="jeAdd()" v-if="dataDetails.card_no != null" class="button is-info is-light mx-1">Add to Account</button>
                <button @click="CheckOut()" v-if="dataDetails.card_no != null" class="button is-info is-light mx-1">Pay Now</button>
                <button @click="CheckOut()" v-if="dataDetails.card_no == null" class="button is-info is-light mx-1">Payment</button>
                <button @click="detailsClose()" class="button is-danger is-light mx-1">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
// import { toast } from 'bulma-toast'\
export default {
  data() {
    return {
      tax: 0,
      subtotal: 0,
      dataTable: [],
      dataCard: [],
      table: '',
      card: '',
      dataCat: [],
      data: [],
      dataCart: [],
      dataOrder: [],
      orderModal: false,
      bPlaced: true,
      bkitchen: false,
      bServed: false,
      detailsModal: false,
      modalSerched: 'Placed',

      dataDetails: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getTable() {
      await api
        .get('api/table/?status=Available')
        .then((response) => {
          this.dataTable = response.data;
          console.table(this.dataTable);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getData() {
      await api
        .get('api/food_cat/')
        .then((response) => {
          this.dataCat = response.data;
          // console.table(this.dataCat);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('auth/company/1/')
        .then((response) => {
          this.tax = response.data.tax;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/card/')
        .then((response) => {
          this.dataCard = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      let varr = [];
      for (let i = 0; i < this.dataCard.length; i++) {
        if (this.dataCard[i].checked_in == true) {
          const a = {
            card_no: this.dataCard[i].card_no,
            room_no: parseInt(this.dataCard[i].room_no),
            rev_id: this.dataCard[i].rev_id,
          };
          varr.push(a);
          if (this.dataCard[i].secondary_room != '') {
            let b = this.dataCard[i].secondary_room.split(',');
            for (let j = 0; j < b.length; j++) {
              const c = {
                card_no: this.dataCard[i].card_no,
                room_no: parseInt(b[j]),
                rev_id: this.dataCard[i].rev_id,
              };
              varr.push(c);
            }
          }
        }
      }
      this.dataCard = varr;
      console.table(this.dataCard);
    },
    async getMenu(i) {
      await api
        .post('api/food_c/', { a: i })
        .then((response) => {
          this.data = response.data;
          console.table(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    AddItem(d) {
      let check = true;
      for (let i = 0; i < this.dataCart.length; i++) {
        if (d.id == this.dataCart[i].id) {
          this.dataCart[i].quantity++;
          this.subtotal += d.cost;
          check = false;
          break;
        }
      }
      if (check == true) {
        let a = {
          id: d.id,
          name: d.name,
          quantity: 1,
          price: d.cost,
        };
        this.subtotal += d.cost;
        this.dataCart.push(a);
      }
    },
    Minus(i) {
      console.log(true);
      if (this.dataCart[i].quantity > 1) {
        this.dataCart[i].quantity--;
        this.subtotal -= this.dataCart[i].price;
      } else {
        this.subtotal -= this.dataCart[i].price;
        this.dataCart.splice(i, 1);
      }
    },
    Plus(i) {
      this.dataCart[i].quantity++;
      this.subtotal += this.dataCart[i].price;
    },
    async OrderPlace() {
      if (this.table == '' || this.dataCart == '') {
        return;
      }

      let item = '',
        quantity = '',
        itemName = '';
      for (let i = 0; i < this.dataCart.length; i++) {
        item = item + this.dataCart[i].id + '|';
        itemName = itemName + this.dataCart[i].name + '|';
        quantity = quantity + this.dataCart[i].quantity + '|';
      }
      item = item.slice(0, -1);
      itemName = itemName.slice(0, -1);
      quantity = quantity.slice(0, -1);
      this.total = this.subtotal + this.subtotal * (this.tax / 100);
      const a = {
        subTotal: this.subtotal,
        tax: this.tax,
        total: this.total,
        item: item,
        itemQuantity: quantity,
        itemName: itemName,
        table: this.table,
        card_no: this.card,
        status: 'Placed',
      };
      await api
        .post('api/food_pos/', a)
        .then((res) => {
          {
            console.log(res);
            api
              .put(`api/table/${this.table}/`, { status: 'Occupied' })
              .then((res) => {
                {
                  console.log(res);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.getData();
      this.dataCart = [];
      this.subtotal = 0;
      this.table = '';
      this.card = '';
      this.data = [];
    },
    async OrderHistory() {
      let table = [];
      await api
        .get('api/table/')
        .then((response) => {
          table = response.data;
          console.log(table);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/food_pos/?status=${this.modalSerched}`)
        .then((response) => {
          this.dataOrder = response.data;
          console.table(this.dataOrder);
          for (let i = 0; i < this.dataOrder.length; i++) {
            this.dataOrder[i].idd = this.dataOrder[i].id.toString().padStart(4, '0');
            for (let j = 0; j < table.length; j++) {
              if (table[j].id == this.dataOrder[i].table) {
                this.dataOrder[i].table = table[j].num;
                this.dataOrder[i].tableId = table[j].id;
                break;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.orderModal = true;
    },
    detailsClose() {
      this.detailsModal = false;
      this.orderModal = true;
    },
    async jeAdd() {
      let dataTT = [];
      let dataCAO = [];
      // let user = [];
      // await api
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'pos' && dataCAO[i].type === 'Debit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'POS',
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'demo',
        reference: this.dataDetails.card_no,
        credit: 0,
        debit: this.dataDetails.total,
        memo: this.dataDetails.id,
        journal_id: c,
        auto_journal: true,
      };
      console.table(a);
      await api.post('/api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
      this.CheckOut();
    },
    async CheckOut() {
      await api
        .put(`api/food_pos/${this.dataDetails.id}/`, { status: 'Completed' })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .put(`api/table/${this.dataDetails.tableId}/`, { status: 'Available' })
        .then((res) => {
          {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.detailsModal = false;
      this.orderModal = true;
      this.OrderHistory();
    },
    OrderDetails(i) {
      console.log(i);
      let a = i.itemName.split('|');
      let b = i.itemQuantity.split('|');
      let c = [];

      for (let i = 0; i < a.length; i++) {
        let d = { item: a[i], quantity: b[i] };
        c.push(d);
      }
      console.table(c);
      i.order = c;
      this.dataDetails = i;
      this.orderModal = false;
      this.detailsModal = true;
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
.activeRed {
  background-color: #2e4150 !important;
  color: white !important;
}
</style>