<template>
  <div style="height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Laundry Sales Report</p></div>
          <div style="float: right"><button style="background-color: #26ade4; color: white" class="button" @click.prevent="$router.push('/report')">Back</button></div>
        </div>
      </div>
    </div>
    <div class="columns" style="margin-top: 0px">
      <div class="column is-1"></div>
      <div class="column is-8" style="diplay: flex">
        <div style="float: left">
          <div class="field has-addons" style="margin-left: 0">
            <p class="control">
              <input class="input" style="width: 150px; border: 1px solid #0c9eda" type="date" @change="Calculate()" v-model="date1" />
            </p>
            <p class="control">
              <input class="input" style="width: 150px; border: 1px solid #0c9eda" type="date" @change="Calculate()" v-model="date2" />
            </p>
            <p class="control">
              <button class="button" style="color: white; border: 1px solid #0c9eda; background-color: #0c9eda" @click.prevent="Calculate()">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div class="column is-2"><button class="button" @click="printPDF()">print</button></div>
    </div>
    <div class="columns" style="margin-top: 20px">
      <div class="column is-12">
        <div class="box" style="height: 60vh; min-height: 600px; overflow-y: scroll">
          <table class="table is-fullwidth" style="overflow-y: auto">
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Bill type</th>
                <th>Amount</th>
                <th>Transaction Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in data" v-bind:key="d.id">
                <template v-if="d.date >= date1">
                  <template v-if="d.date <= date2">
                    <td>{{ d.date }}</td>
                    <td>{{ d.time }}</td>
                    <td>{{ d.type_bill }}</td>
                    <td>
                      {{
                        d.cost
                          .toString('0.00')
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }}
                    </td>
                    <td>{{ d.trx_type }}</td>
                  </template>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-9"></div>
      <div class="column is-3" style="display: flex">
        <p style="font-size: 22px">
          Total :
          {{
            total
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }}
        </p>
      </div>
    </div>

    <div v-show="modalShow == true">
      <div id="pdfportion">
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <img :src="logo" style="max-width: 50px; left: 0" />
        </div>
        <div style="display: flex; justify-content: center; font-size: 36px; margin-top: 5px">{{ company.company_name }} Hotel</div>
        <div style="display: flex; justify-content: center; font-size: 18px; margin-top: 5px">
          {{ company.company_address }}
        </div>
        <div style="display: flex; justify-content: center; font-size: 15px; margin-top: 5px">Hotline: {{ company.contact_person_phone }}</div>
        <div style="display: flex; justify-content: right; font-size: 14px; margin-top: 5px">Date: {{ date }}</div>

        <table class="table is-fullwidth" style="font-size: 12px; margin-top: 20px">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Bill type</th>
              <th>Amount</th>
              <th>Transaction Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in data" v-bind:key="d.id">
              <template v-if="d.date >= date1">
                <template v-if="d.date <= date2">
                  <td>{{ d.date }}</td>
                  <td>{{ d.time }}</td>
                  <td>{{ d.type_bill }}</td>
                  <td>
                    {{
                      d.cost
                        .toString('0.00')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                  </td>
                  <td>{{ d.trx_type }}</td>
                </template>
              </template>
            </tr>
          </tbody>
        </table>
        <div style="display: flex; justify-content: right; font-size: 14px; margin-top: 10px">
          <p style="font-size: 18px">
            Total:
            {{
              total
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import html2pdf from 'html2pdf-jspdf2';

export default {
  data() {
    return {
      data: [],
      date1: '',
      date2: '',
      total: 0,
      modalShow: false,
      logo: null,
      company: [],
      date: '',
    };
  },
  created() {
    this.getData();
    this.getLogo();
    this.getCompany();
  },
  methods: {
    async getLogo() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },

    async getCompany() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.company = res.data;
          console.log(this.company);
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/bills_extra/')
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    printPDF() {
      this.modalShow = true;
      this.date = new Date().toISOString().substr(0, 10);

      var element = document.getElementById('pdfportion');

      var opt = {
        margin: 0.2,
        filename: 'Laundry_Report_' + this.date + '.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();

      this.modalShow = false;
    },

    Calculate() {
      this.total = 0;
      console.log(this.total);
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].trx_type == 'cash' && this.data[i].date >= this.date1 && this.data[i].date <= this.date2) {
          this.total += parseInt(this.data[i].cost);
        }
      }
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>