<template>
  <!-- <div class="back" v-bind:style="{ 'background-image': 'url(' + require('../assets/background2.webp') + ')' }" style="background-repeat: no-repeat; background-position: bottom left"> -->
  <div class="back" style="background-repeat: no-repeat; background-position: bottom left">
    <!-- Title -->
    <!-- <div class="back" style="height:100vh;"> -->

    <div class="columns" style="height: 100px">
      <div class="column is-12" style="height: 70px">
        <div class="box" style="font-size: 22px; font-weight: bold; height: 70px; width: 100%">
          <p style="float: left">Concierge</p>
          <button class="button" style="float: right; background-color: #099dd9; color: white; margin-top: -5px" @click.prevent="roomTM = true">Add New</button>
        </div>
      </div>
    </div>

    <div class="columns" style="height: 88vh">
      <div class="column is-1"></div>
      <div class="column is-10">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Card No</th>
              <th>Pickup Date</th>
              <th>Pickup Time</th>
              <th>Driver Assigned</th>
              <th>Rent Type</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in data" v-bind:key="d.id">
              <td>{{ d.name }}</td>
              <td>{{ d.phone }}</td>
              <td>
                <template v-if="d.card_no == ''"> NA </template>
                <template v-else>
                  {{ d.card_no }}
                </template>
              </td>
              <td>{{ d.pickup_date }}</td>
              <td>{{ d.pickup_time }}</td>
              <td>{{ d.driver_assigned }}</td>
              <td>{{ d.rent_type }}</td>
              <td>
                <button class="button is-small" style="background-color: #26ade4; color: white" @click.prevent="(statusB = true), (id = d.id)" :disabled="d.status == true">
                  <p v-if="d.status == true">Done</p>
                  <p v-else>Not Completed</p>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-1"></div>
    </div>

    <!-- ModaL: Delivery status update -->
    <div v-if="statusB == true">
      <div class="modal is-active">
        <div class="modal-background" @click.prevent="statusB = false">
          <div class="modal-content" style="height: 30vh; background: white; margin-top: 25vh">
            <div style="margin-top: 15%">
              <p class="subtitle is-4">Completed?</p>
              <div style="margin-top: 25px">
                <button class="button" style="background-color: #26ade4; color: white" @click.prevent="changeStatus">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: room type add -->
    <div v-if="roomTM == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="roomTM = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 800px; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div class="columns" style="height: 10vh">
              <div class="column is-12">
                <div class="box" style="height: 7vh">
                  <p style="font-size: 22px; font-weight: bold">Add Concierge</p>
                </div>
              </div>
            </div>

            <div class="columns" style="height: 70vh; margin-top: 15px">
              <div class="column is-12">
                <div class="box" style="height: 70vh">
                  <div style="margin-left: 8vw">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Guests Name:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="name" v-model="name" />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Phone:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="phone" v-model="phone" />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Card No:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="card no." v-model="card" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Date:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="date" v-model="date" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Time:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="time" v-model="time" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Driver:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="driver name" v-model="driver" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px; width: 100px">Rent Type:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <select class="select" v-model="rent_type" style="width: 100%">
                              <option value="" hidden>Rent Type Select</option>
                              <option>Whole Day</option>
                              <option>Pick Up</option>
                              <option>Drop Off</option>
                            </select>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 25px">
              <button class="button is-danger is-light" @click.prevent="roomTM = false" style="margin-right: 5px; background-color: #e67c6c; color: white">Cancel</button>
              <button class="button is-success is-light" style="background-color: #099dd9; color: white" @click.prevent="AddConsierg()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      data: [],
      id: '',
      statusB: false,
      roomTM: false,
      name: '',
      phone: '',
      card: '',
      date: '',
      time: '',
      driver: '',
      rent_type: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await api
        .get('api/consierge/')
        .then((response) => {
          this.data = response.data;
          console.table(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async changeStatus() {
      await api.put(`api/consierge/${this.id}/`, { status: true }).catch((error) => {
        console.log(error);
      });
      this.getData();

      this.statusB = false;
    },

    async AddConsierg() {
      const a = {
        name: this.name,
        phone: this.phone,
        card_no: this.card,
        driver_assigned: this.driver,
        pickup_date: this.date,
        pickup_time: this.time,
        rent_type: this.rent_type,
        status: false,
      };
      await api
        .post('/api/consierge/', a)
        .then(() => {
          toast({
            message: 'Consierge has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          });
          this.getData();
          this.name = '';
          this.phone = '';
          this.card = '';
          this.date = '';
          this.time = '';
          this.driver = '';
          this.rent_type = '';
          this.roomTM = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
</style>