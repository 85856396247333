<template>
  <div style="height: 100vh">
    <div class="columns" style="height: 70px">
      <div class="column is-12" style="height: 70px">
        <div class="box" style="font-size: 22px; font-weight: bold; height: 70px; width: 100%">
          <p style="float: left">Online Booking</p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div class="tabs is-medium is-centered">
          <ul>
            <li v-bind:class="{ 'is-active': isActive == 'room' }"><a v-on:click="isActive = 'room'">Room</a></li>
            <li v-bind:class="{ 'is-active': isActive == 'details' }"><a v-on:click="isActive = 'details'">Details</a></li>
            <li v-bind:class="{ 'is-active': isActive == 'confirm' }"><a v-on:click="isActive = 'confirm'">Confirm</a></li>
          </ul>
        </div>

        <div class="tab-contents">
          <div class="content" v-bind:class="{ 'is-active': isActive == 'room' }">
            <!-- Room Selection -->
            <div class="columns">
              <div class="column is-4" style="margin-left: 20px">
                <div class="field has-addons" style="margin-top: -15px; margin-bottom: -15px">
                  <p class="control" data-tooltip="Check In Date">
                    <input class="input" style="width: 150px; border: 1px solid #0c9eda" type="date" placeholder="From" v-model="co" />
                  </p>
                  <p class="control" data-tooltip="Check Out Date">
                    <input class="input" style="width: 150px; border: 1px solid #0c9eda" type="date" placeholder="To" v-model="cd" />
                  </p>
                  <p class="control" data-tooltip="Room(s)">
                    <select v-model="roomCount" style="height: 40px; width: 90px; border: 1px solid #0c9eda">
                      <option value="0" hidden>Room</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                  </p>
                  <p class="control" data-tooltip="Adult(s)">
                    <select v-model="adult" style="height: 40px; width: 90px; border: 1px solid #0c9eda">
                      <option value="0" hidden>Adult</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                  </p>
                  <p class="control" data-tooltip="Child(ren)">
                    <select v-model="child" style="height: 40px; width: 90px; border: 1px solid #0c9eda">
                      <option value="0" hidden>Child</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                  </p>
                  <p class="control">
                    <button class="button" @click="SearchDate()" style="color: white; border: 1px solid #0c9eda; background-color: #0c9eda">Search</button>
                  </p>
                </div>
              </div>

              <div class="column is-2"></div>

              <div class="column is-6">
                <p style="font-size: 18px">
                  Booking Details: <template v-for="d in selection">{{ d.type_name }}: {{ d.booked }}</template>
                </p>
              </div>
            </div>

            <div class="columns">
              <div class="column is-12" style="height: 100%">
                <div class="box" style="height: 100%; width: 70%; border: 1px solid #cfd8e8; margin: 0 30px 0 14%">
                  <div v-for="(d, i) in roomV" :key="d.id">
                    <div class="columns">
                      <div class="column is-5">
                        <img alt="Hotel" style="width: 400px; height: 270px" />
                      </div>
                      <div class="column is-5" style="text-align: left">
                        <p style="font-size: 30px">{{ d.type_name }} Room</p>
                        <p style="font-size: 22px">Price: {{ d.cost }}</p>
                        <p style="font-size: 18px">Description: {{ d.description }}</p>
                        <p style="font-size: 16px">Adult: {{ d.adults }}</p>
                        <p style="font-size: 16px">Child: {{ d.child }}</p>
                      </div>
                      <div class="column is-2">
                        <div class="field has-addons" style="margin-top: 15px; margin-bottom: 15px">
                          <p class="control">
                            <input class="input" style="width: 50px; border: 1px solid #51627e" min="0" :max="number2[i]" type="number" v-model="number[i]" />
                          </p>
                          <p class="control">
                            <button @click.prevent="Add(i)" class="button" style="color: white; border: 1px solid #51627e; background-color: #51627e">Add</button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content" v-bind:class="{ 'is-active': isActive == 'details' }">
            <div class="columns" style="height: 100%">
              <div class="column is-6"></div>
              <div class="column is-6">
                <p style="font-size: 18px">Booking Details: {{ roomSelection }} room(s). {{ adult }} adult(s). {{ child }} child(ren)</p>
              </div>
            </div>

            <div class="columns" style="margin-top: 40px">
              <div class="column is-2"></div>
              <div class="column is-4">
                <div class="field" style="text-align: left">
                  <label class="label" style="font-size: 24px">First Name:</label>
                  <div class="control" style="width: 400px; height: 50px">
                    <input class="input" style="height: 50px" type="text" placeholder="first name" v-model="name1" />
                  </div>
                </div>

                <div class="field" style="text-align: left">
                  <label class="label" style="font-size: 24px">Phone:</label>
                  <div class="control" style="width: 400px; height: 50px">
                    <input class="input" style="height: 50px" type="text" placeholder="phone number" v-model="phone" />
                  </div>
                </div>

                <div class="field" style="text-align: left">
                  <label class="label" style="font-size: 24px">Passport:</label>
                  <div class="control" style="width: 400px; height: 50px">
                    <input class="input" style="height: 50px" type="text" placeholder="passport" v-model="passport" />
                  </div>
                </div>
              </div>

              <div class="column is-4">
                <div class="field" style="text-align: left">
                  <label class="label" style="font-size: 24px">Last Name:</label>
                  <div class="control" style="width: 400px; height: 50px">
                    <input class="input" style="height: 50px" type="text" placeholder="last name" v-model="name2" />
                  </div>
                </div>

                <div class="field" style="text-align: left">
                  <label class="label" style="font-size: 24px">Email:</label>
                  <div class="control" style="width: 400px; height: 50px">
                    <input class="input" style="height: 50px" type="text" placeholder="email" v-model="email" />
                  </div>
                </div>

                <div class="field" style="text-align: left">
                  <label class="label" style="font-size: 24px">Country:</label>
                  <div class="control" style="width: 400px; height: 50px">
                    <input class="input" style="height: 50px" type="text" placeholder="country" v-model="country" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content" v-bind:class="{ 'is-active': isActive == 'confirm' }">
            <div v-if="name1 == '' || name2 == '' || phone == '' || email == '' || country == '' || selection == []">Not Enough Detail</div>
            <div v-else class="columns" style="margin-top: 30px">
              <div class="column is-1"></div>
              <div class="column is-5" style="text-align: left; font-size: 23px">
                <p>
                  Name: <em>{{ name1 }} {{ name2 }}</em>
                </p>
                <p>
                  Phone: <em>{{ phone }}</em>
                </p>
                <p>
                  Email: <em>{{ email }}</em>
                </p>
                <p>
                  Passport: <em>{{ passport }}</em>
                </p>
                <p>
                  Country: <em>{{ country }}</em>
                </p>
              </div>
              <div class="column is-5">
                <!-- <p style="text-align:center; font-size:20px;">Days Count: {{dayCount}}</p> -->
                <table class="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Room</th>
                      <th>Price</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="d in selection" v-bind:key="d.id">
                      <td>{{ d.type_name }}</td>
                      <td>{{ d.cost }}</td>
                      <td>{{ d.booked }}</td>
                    </tr>
                  </tbody>
                </table>
                <p style="text-align: right; font-size: 20px">
                  Sub Price:
                  {{
                    cost
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }}
                </p>
                <p style="text-align: right; font-size: 20px">
                  Vat({{ company.tax }}%):
                  {{
                    costV
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }}
                </p>
                <p style="text-align: right; font-size: 20px">
                  SC({{ company.service_charge }}%):
                  {{
                    costC
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }}
                </p>
                <p style="text-align: right; font-size: 20px">
                  Grand Total:
                  {{
                    costT
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }}
                </p>
              </div>
            </div>
            <div>
              <button class="button" style="background-color: #6388a6; color: white">Book for Now</button>
              <button class="button" style="background-color: #26ade4; color: white; margin-left: 5px">Payment</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      isActive: 'room',
      roomType: [],
      roomV: [],
      roomBooked: [],
      roomData: [],
      resData: [],
      dataR: [],
      b1: false,
      b2: false,
      b3: false,
      b4: false,
      b5: false,
      cd: '',
      co: '',
      child: 0,
      adult: 0,
      roomCount: 0,
      number: [],
      number2: [],
      roomSelection: 0,
      selection: [],
      name1: '',
      name2: '',
      phone: '',
      email: '',
      passport: '',
      country: '',
      cost: 0,
      costV: 0,
      costC: 0,
      costT: 0,
      company: [],
      dayCount: 0,
    };
  },
  mounted() {
    this.getData();
    this.getCompany();
  },
  methods: {
    async getCompany() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.company = res.data;
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/room_type/')
        .then((res) => {
          this.roomType = res.data;
        })
        .catch((err) => console.log(err));
      for (let i = 0; i < this.roomType.length; i++) {
        this.number[i] = 0;
        this.number2[i] = 0;
      }
    },

    async SearchDate() {
      if (this.co == '' || this.cd == '') {
        toast({
          message: 'Date empty',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }

      if (this.roomCount == 0) {
        toast({
          message: 'Room number empty',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }

      if (this.child == '' || this.adult == '') {
        toast({
          message: 'human empty',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }

      this.cost = 0;
      this.costC = 0;
      this.costS = 0;
      this.costT = 0;
      this.selection = [];
      this.roomSelection = 0;
      for (let i = 0; i < this.number.length; i++) {
        this.number[i] = 0;
      }

      this.roomV = [];
      this.roomBooked = [];
      this.roomData = [];
      this.resData = [];

      await api
        .get('api/rmrt/')
        .then((res) => {
          this.roomData = res.data;
        })
        .catch((err) => console.log(err));

      await api
        .get('api/reservation/')
        .then((res) => {
          this.dataR = res.data;
        })
        .catch((err) => console.log(err));

      let varr = [];
      for (let i = 0; i < this.dataR.length; i++) {
        const a = {
          room: parseInt(this.dataR[i].room),
          check_in_date: this.dataR[i].check_in_date,
          check_out_date: this.dataR[i].check_out_date,
          status: this.dataR[i].status,
        };
        varr.push(a);
        if (this.dataR[i].secondary_room != '' && this.dataR[i].secondary_room != null) {
          let b = this.dataR[i].secondary_room.split(',');
          for (let j = 0; j < b.length; j++) {
            const c = {
              room: parseInt(b[j]),
              check_in_date: this.dataR[i].check_in_date,
              check_out_date: this.dataR[i].check_out_date,
              status: this.dataR[i].status,
            };
            varr.push(c);
          }
        }
      }
      this.dataR = varr;

      if (this.cd != '' && this.co != '') {
        for (let i = 0; i < this.roomData.length; i++) {
          this.b1 = false;
          this.b2 = false;
          this.b3 = false;
          this.b4 = false;
          this.b5 = false;
          for (let a = 0; a < this.dataR.length; a++) {
            if (this.roomData[i].room_no == this.dataR[a].room && this.dataR[a].status != 'booked') {
              if (this.dataR[a].status == 'OnHold') {
                this.b5 = true;
                break;
              }
              if (this.cd > this.dataR[a].check_in_date && this.cd < this.dataR[a].check_out_date) {
                // console.log(true)
                this.b1 = true;
                break;
              }
              if (this.co > this.dataR[a].check_in_date && this.co < this.dataR[a].check_out_date) {
                // console.log(true)
                this.b2 = true;
                break;
              }
              if (this.dataR[a].check_in_date > this.co && this.dataR[a].check_in_date < this.cd) {
                // console.log(true)
                this.b3 = true;
                break;
              }
              if (this.dataR[a].check_out_date > this.co && this.dataR[a].check_out_date < this.cd) {
                // console.log(true)
                this.b4 = true;
                break;
              }
              if (this.dataR[a].check_in_date == this.co && this.dataR[a].check_out_date == this.cd) {
                this.b1 = true;
                break;
              }
            }
          }
          if (this.b1 == false && this.b2 == false && this.b3 == false && this.b4 == false && this.b5 == false) {
            this.roomV.push(this.roomData[i]);
          }
        }
      }

      let asd = [];
      for (let i = 0; i < this.roomType.length; i++) {
        let a = 0;
        for (let j = 0; j < this.roomV.length; j++) {
          if (this.roomType[i].type_name == this.roomV[j].type_name) {
            a++;
          }
        }
        if (a > 0) {
          for (let j = 0; j < this.roomV.length; j++) {
            if (this.roomType[i].type_name == this.roomV[j].type_name) {
              if (this.roomV[j].adults >= this.adult && this.roomV[j].child >= this.child && a >= this.roomCount) {
                const q = {
                  type_name: this.roomV[j].type_name,
                  adults: this.roomV[j].adults,
                  child: this.roomV[j].child,
                  cost: this.roomV[j].cost,
                  quantity: a,
                  description: this.roomType[i].description,
                  booked: 0,
                };
                asd.push(q);
                this.number2[i] = a;
              }
              break;
            }
          }
        }
      }

      this.roomV = asd;
      this.dayCount = this.cd - this.co;

      console.log(this.cd);
      console.log(this.co);
      console.log(this.cd - this.co);
    },

    Add(i) {
      const q = {
        type_name: this.roomV[i].type_name,
        adults: this.roomV[i].adults,
        child: this.roomV[i].child,
        cost: this.roomV[i].cost,
        quantity: this.roomV[i].quantity,
        description: this.roomType[i].description,
        booked: this.number[i],
      };
      this.roomSelection += this.number[i];
      this.cost += this.number[i] * this.roomV[i].cost;
      this.costV = this.cost * (this.company.tax / 100);
      this.costC = this.cost * (this.company.service_charge / 100);
      this.costT = this.cost + this.costV + this.costC;
      this.selection.push(q);
      this.roomV.splice(i, 1);
      this.number.splice(i, 1);
      this.number2.splice(i, 1);
    },
  },
};
</script>

<style>
ul li {
  float: left;
}
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}

.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>

