<template>
  <div class="min-h-screen h-full bg-gray-200">
    <div class="col-span-3 w-[700px]" v-show="printDiv == true">
      <div id="pdfPackage" class="bg-white text-black">
        <div v-for="d in dataPrint" :key="d">
          <div class="grid grid-cols-5">
            <div class="col-span-5 m-1">
              <h1 class="text-2xl text-center">Purchase Order</h1>
            </div>
            <div class="col-span-2 text-left px-5">
              <p class="text-base">TTZ Sollution</p>
              <p class="text-base">Bashandhara, Dhaka</p>
              <p class="text-base">0721 770024</p>
            </div>
            <div class="col-span-1"></div>
            <div class="col-span-2 text-right px-5">
              <p class="text-base">
                PO Number: <i>{{ dataPurchase.orderNumber }}</i>
              </p>
              <p class="text-base">
                Date: <i>{{ date }}</i>
              </p>
              <p class="text-base">
                Vendor Id: <i>{{ d.s.id }}</i>
              </p>
            </div>
            <div class="col-span-5 border-b-2 my-5"></div>
            <div class="col-span-2 text-left px-10">
              <p class="text-sm">
                Vendor Name: <i>{{ d.s.name }}</i>
              </p>
              <p class="text-sm">
                Vendor Adress: <i>{{ d.s.address }}</i>
              </p>
              <p class="text-sm">
                Vendor Phone: <i>{{ d.s.phone }}</i>
              </p>
            </div>
            <div class="col-span-1"></div>
            <div class="col-span-2 text-left px-10">
              <p class="text-sm">
                Delivery Address: <i>{{ dataPurchase.address_delivery }}</i>
              </p>
            </div>
            <div class="col-span-5 my-10 mx-5">
              <table class="text-center w-full">
                <thead class="" style="border-bottom: thin solid black; border: 1px solid black">
                  <tr>
                    <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Name</th>
                    <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Quantity</th>
                    <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Unit Price</th>
                    <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="p in d.i" :key="p">
                    <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black; border-left: 1px solid black">{{ p.itemName }}</td>
                    <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">{{ p.quantity }}</td>
                    <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">{{ p.unitPrice }}</td>
                    <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">{{ p.supplierSelectedQuaote }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-span-2 mx-10 pt-12">
              <hr />
              <p>Aprroved By</p>
            </div>
            <div class="col-span-1 mx-10"></div>
            <div class="col-span-2 text-right mx-10">
              <p class="">Subtotal : <i>00.0</i></p>
              <p class="">Tax : <i>00.0</i></p>
              <p class="font-bold">Total : <i>00.0</i></p>
            </div>
          </div>
          <div class="html2pdf__page-break"></div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-x-2">
      <div class="h-screen3 col-span-4 border-2 bg-accentM">
        <ol class="list-reset flex mt-3 ml-5">
          <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li><span @click="this.$router.push('/purchase')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Purchase</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li class="text-white text-xl">Purchase Order</li>
        </ol>
      </div>

      <div class="col-span-4 mt-4 ml-8">
        <!-- <button @click="true" class="bg-green-300 hover:bg-green-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Draft</button> -->
        <button @click="Save()" class="bg-button shadow-lg hover:bg-button2 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Genarete PO</button>
        <button @click="this.$router.push('/purchase')" class="bg-red-400 shadow-lg hover:bg-red-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Back</button>
      </div>

      <div class="col-span-2 my-4 ml-10 mr-1 rounded py-5 bg-bg shadow-lg">
        <p class="font-bold ml-4 text-2xl text-left">Order Details</p>
        <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Order Number:</p>
          </div>
          <div class="md:w-4/6">
            <input v-model="puchaseId" disabled class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="order number" />
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Order By:</p>
          </div>
          <div class="md:w-4/6">
            <input disabled v-model="dataPurchase.orderBy" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="order By" />
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Requested By:</p>
          </div>
          <div class="md:w-4/6">
            <select disabled class="border-2 border-gray-400 rounded w-3/5 float-right mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="dataPurchase.requestedBy" required>
              <option value="" hidden>Requested By</option>
              <option v-for="c in dataE" :key="c" :value="c.id">{{ c.name }}</option>
            </select>
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Requested Date:</p>
          </div>
          <div class="md:w-4/6">
            <input disabled v-model="dataPurchase.date_requested" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="date" />
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Expected Date:</p>
          </div>
          <div class="md:w-4/6">
            <input disabled v-model="dataPurchase.date_requested" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="date" />
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Ungency:</p>
          </div>
          <div class="md:w-4/6">
            <select disabled class="border-2 border-gray-400 rounded w-3/5 float-right mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="dataPurchase.urgency" required>
              <option value="" hidden>Ungency</option>
              <option v-for="c in dataU" :key="c" :value="c">{{ c }}</option>
            </select>
          </div>
        </div>

        <div v-if="dataPurchase.urgency == 'Urgent'" class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Remarks:</p>
          </div>
          <div class="md:w-4/6">
            <input disabled v-model="dataPurchase.remarks" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="Add Remarks" />
          </div>
        </div>
      </div>

      <div class="col-span-2 my-4 mr-10 py-5 ml-1 rounded bg-bg shadow-lg">
        <p class="font-bold ml-4 text-2xl text-left">Address</p>
        <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>

        <div class="md:flex md:items-center my-6 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Delivery Address:</p>
          </div>
          <div class="md:w-4/6">
            <textarea disabled v-model="dataPurchase.address_delivery" class="float-right md:flex w-4/5 h-24 appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button disabled:bg-slate-200 disabled:border-gray-400" type="text" placeholder="address" />
          </div>
        </div>

        <div class="md:flex md:items-center my-6 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Billing Address:</p>
          </div>
          <div class="md:w-4/6">
            <textarea disabled v-model="dataPurchase.address_billing" class="float-right md:flex w-4/5 h-24 appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button disabled:bg-slate-200 disabled:border-gray-400" type="text" placeholder="address" />
          </div>
        </div>
      </div>

      <div class="col-span-4 mx-10 py-3 rounded bg-bg shadow-lg mb-5">
        <p class="font-bold ml-4 text-2xl text-left">Requested Items</p>
        <!-- <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div> -->
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 mt-2 min-h-4/5">
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs uppercase bg-accent2">
              <tr>
                <th scope="col" class="px-6 py-3 text-white">Name</th>
                <th scope="col" class="px-4 py-3 text-white">Total Unit</th>
                <th scope="col" class="px-4 py-3 text-white">Total Amount</th>
                <th scope="col" class="px-4 py-3 text-white">Payment Terms</th>
                <th scope="col" class="px-4 py-3 text-white">Advance System</th>
                <th scope="col" class="px-4 py-3 text-white">Advance</th>
                <th scope="col" class="px-4 py-3 text-white">Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in dataPurchaseItem" :key="index" class="border-b odd:bg-white even:bg-gray-50">
                <td class="px-6 py-4 md:pl-10">{{ p.item }}</td>
                <td class="px-6 py-4 md:pl-10">{{ p.quantity }}</td>
                <td class="px-6 py-4 md:pl-10">{{ parseInt(p.supplierSelectedOther) + parseInt(p.supplierSelectedtax) + parseInt(p.supplierSelectedOther) }}</td>
                <td class="py-4 md:pl-10">
                  <div class="md:w-2/10 tooltip tooltip-accent" data-tip="Payment Terms">
                    <select v-model="p.paymentTerms" class="ml-4 border-2 py-1 px-2 border-gray-600 rounded text-gray-900 focus:ring-blue-500 focus:border-blue-500 block">
                      <option value="" hidden>Payment Terms:</option>
                      <option v-for="c in dataPayment" :key="c.id" :value="c.name">{{ c.name }}</option>
                    </select>
                  </div>
                </td>
                <td class="py-4">
                  <div v-if="p.paymentTerms == 'Advance'" class="md:w-8/10">
                    <select v-model="p.paymentAdvanceSytem" class="border-2 py-1 px-2 border-gray-600 rounded text-gray-900 focus:ring-blue-500 focus:border-blue-500 block">
                      <option value="" hidden>Payment Terms:</option>
                      <option v-for="c in dataPaymentWay" :key="c.id" :value="c.name">{{ c.name }}</option>
                    </select>
                  </div>
                  <div v-else>NA</div>
                </td>
                <td class="px-6 py-4">
                  <div class="py-1 px-2 w-full border border-black rounded" v-if="p.paymentAdvanceSytem == 'Percentage'"><input type="number" v-model="p.paymentAdvanceAmount" placeholder="Percentage" max="100" min="0" /></div>
                  <div class="py-1 px-2 w-full border border-black rounded" v-else-if="p.paymentAdvanceSytem == 'Amount'"><input type="number" v-model="p.paymentAdvanceAmount" placeholder="Amount" min="0" /></div>
                  <div v-else>NA</div>
                </td>
                <td class="px-6 py-4 md:pl-10">
                  <input class="py-1 px-2 border border-black rounded" placeholder="Remarks" type="text" v-model="p.paymentRemarks" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';
import html2pdf from 'html2pdf-jspdf2';

export default {
  components: {},

  data() {
    return {
      date: new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 8),
      id: this.$route.params.id,
      puchaseId: '',
      dataE: [],
      dataS: [],
      dataStatus: [],
      dataStatus2: [],
      dataI: [],
      dataU: ['Urgent', 'Low'],
      dataTable: [],
      dataPurchase: [],
      dataPurchaseItem: [],
      dataCost: [],
      dataCOA: [],
      dataLineType: [],
      dataProject: [],
      maxV: 100,

      nameL: [],
      varr2: '',
      nameArr: [],
      dataPayment: [
        { id: 1, name: 'Cash' },
        { id: 2, name: 'Online' },
        { id: 3, name: 'Advance' },
      ],
      dataPaymentWay: [
        { id: 1, name: 'Percentage' },
        { id: 2, name: 'Amount' },
      ],

      creator: '',

      dataPrint: [],
      printDiv: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      // console.log(this.id);
      await api
        .get(`api/employee/`)
        .then((res) => {
          this.dataE = res.data;
          //   console.table(this.dataE);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/cost_center/`)
        .then((res) => {
          this.dataCost = res.data;
          //   console.table(this.dataCost);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/coa/`)
        .then((res) => {
          this.dataCOA = res.data;
          //   console.table(this.dataCOA);
        })
        .catch((error) => {
          console.log(error);
        });
      await api.get(`api/line_type/`).then((res) => {
        this.dataLineType = res.data;
        // console.table(this.dataLineType);
      });
      await api
        .get(`api/project/`)
        .then((res) => {
          this.dataProject = res.data;
          //   console.table(this.dataProject);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/supplier/`)
        .then((res) => {
          this.dataS = res.data;
          //   console.table(this.dataS);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=po&statusCode=&statusName=pending`)
        .then((res) => {
          this.dataStatus = res.data;
          if (this.dataStatus == '') {
            alert('Status not Set');
          }
          // console.table(this.dataStatus);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=po&statusCode=&statusName=approved`)
        .then((res) => {
          this.dataStatus2 = res.data;
          if (this.dataStatus2 == '') {
            alert('Status not Set');
          }
          // console.table(this.dataStatus2);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/pruchase/${this.id}/`)
        .then((res) => {
          this.dataPurchase = res.data;
          // console.log(this.dataPurchase);
        })
        .catch((error) => {
          console.log(error);
        });
      this.puchaseId = 'OP' + this.dataPurchase.orderNumber;
      await api
        .get(`api/requested_item/?orderId=${this.id}&status=${this.dataStatus[0].id}`)
        .then((res) => {
          this.dataPurchaseItem = res.data;
          // console.table(this.dataPurchaseItem);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        this.dataPurchaseItem[i].paymentTerms = '';
        this.dataPurchaseItem[i].paymentAdvanceSytem = '';
        this.dataPurchaseItem[i].paymentRemarks = '';
      }
      console.table(this.dataPurchaseItem);
    },
    async Save() {
      let dd = this.dataStatus2[0].id;
      let ee = this.dataStatus2[0].statusCode;
      let ff = this.dataStatus2[0].statusName;
      this.dataPurchase.status = dd;
      this.dataPurchase.statusC = ee;
      this.dataPurchase.statusName = ff;
      await api
        .put(`api/pruchase/${this.id}/`, this.dataPurchase)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        this.dataPurchaseItem[i].status = dd;
        this.dataPurchaseItem[i].statusC = ee;
        this.dataPurchaseItem[i].statusName = ff;
        this.dataPurchaseItem[i].quantityOpen = 'NA';
        await api
          .put(`api/requested_item/${this.dataPurchaseItem[i].id}/`, this.dataPurchaseItem[i])
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      return;
      // let a = [],
      //   b = [];
      // for (let i = 0; i < this.dataPurchaseItem.length; i++) {
      //   a.push(this.dataPurchaseItem[i].supplierSelected);
      // }
      // a.forEach((e) => {
      //   if (!b.includes(e)) {
      //     b.push(e);
      //   }
      // });
      // for (let i = 0; i < b.length; i++) {
      //   let c = { s: [], i: [] };
      //   for (let j = 0; j < this.dataS.length; j++) {
      //     if (this.dataS[j].id == b[i]) {
      //       c.s = this.dataS[j];
      //       break;
      //     }
      //   }
      //   for (let j = 0; j < this.dataPurchaseItem.length; j++) {
      //     if (this.dataPurchaseItem[j].supplierSelected == b[i]) {
      //       c.i.push(this.dataPurchaseItem[j]);
      //     }
      //   }
      //   this.dataPrint.push(c);
      // }
      // // console.log(this.dataPrint);
      // this.printPackage();
    },
    printPackage() {
      this.printDiv = true;
      var element = document.getElementById('pdfPackage');

      var opt = {
        margin: 0.2,
        filename: 'po.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        // pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();

      this.printDiv = false;
    },
  },
};
</script>
<style >
input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1) hue-rotate(180deg);
}
input:hover {
  cursor: pointer;
}
select:hover {
  cursor: pointer;
}
textarea:hover {
  cursor: pointer;
}
</style>