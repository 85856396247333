<template>
  <div style="height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Night Audit Report</p></div>
          <div style="float: right"><button style="background-color: #26ade4; color: white" class="button" @click.prevent="$router.push('/report')">Back</button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import html2pdf from 'html2pdf-jspdf2';

export default {
  data() {
    return {
      dataBill: [],
      dataCard: [],
      logo: null,
      company: [],
      date: '',
    };
  },
  created() {
    this.getData();
    this.getLogo();
    this.getCompany();
  },
  methods: {
    async getLogo() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },

    async getCompany() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.company = res.data;
          console.log(this.company);
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/bills/')
        .then((response) => {
          this.dataBill = response.data;
          console.table(this.dataBill);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/res_cus_card/')
        .then((response) => {
          this.dataCard = response.data;
          console.table(this.dataCard);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    printPDF() {
      this.modalShow = true;

      this.date = new Date().toISOString().substr(0, 10);

      var element = document.getElementById('pdfportion');

      var opt = {
        margin: 0.2,
        filename: 'Cash_Transaction_Report_' + this.date + '.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();

      this.modalShow = false;
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>