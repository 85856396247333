<template>
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation" style="background-color: #51627e">
      <div class="navbar-brand">
        <a class="navbar-item" href="">
          <div class="logo">
            <router-link to="/" class="d-flex" title="Quickai - HTML Template"><img src="@/assets/ttz_white.png" alt="Quickai" style="width: 40px" /></router-link>
          </div>
          <p style="color: white; font-weight: 700; margin-left: 10px; font-size: 20px">TechTrioz Hotel</p>
        </a>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start"></div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <!-- <a class="button" style="background-color: #26ADE4; border:1px solid #26ADE4;">
             <router-link to="/login" class="btn btn-info" style="color: White; font-weight:700;">Login</router-link>
          </a> -->
              <!-- <a class="button is-light" style="border:1px solid gray;">
            <router-link to="/register" class="btn btn-info" style="color: inherit; font-weight:700;">Register</router-link>
          </a> -->
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- <nav class="navbar navbar-expand-lg navbar-light border border-1">
        <div class="container " style="margin-left: 100px;margin-right: 100px;">
            <div class="header-column justify-content-start" >  
               
                <div class="logo"> <router-link to="/" class="d-flex" title="Quickai - HTML Template"><img src="@/assets/ttzlogo.webp" alt="Quickai" style="width:80px;"/></router-link> </div>
                
            </div>
            
            

           
            <div class="h-right d-flex align-items-center mr-5 mr-lg-0 order-1" >
                

                <div class="p-2 m-1">   
                    <router-link to="/login" class="btn btn-info" style="color: blue;">Login</router-link>
                </div>
                <div class="p-2 m-1">
                    <router-link to="/register" class="btn btn-info" style="color: blue;">Register</router-link>
                </div>
                
            </div>


        </div>
    </nav> -->
  </div>
</template>

<script>
import api from '../boot/axios';
export default {
  name: 'NavbarComponent',
  data() {
    return {
      message: null,
      useremail: '',
      indx: 0,
      currentuser: [],
    };
  },
  props: ['routepath'],

  async created() {
    let usr = localStorage.getItem('username');
    if (usr) {
      this.message = usr;
      let url = '/auth/user';
      await api.get(url).then((res) => {
        this.currentuser = res.data;
      });
    }
    document.addEventListener('DOMContentLoaded', function () {
      // make it as accordion for smaller screens
      if (window.innerWidth < 992) {
        // close all inner dropdowns when parent is closed
        document.querySelectorAll('.navbar .dropdown').forEach(function (everydropdown) {
          everydropdown.addEventListener('hidden.bs.dropdown', function () {
            // after dropdown is hidden, then find all submenus
            this.querySelectorAll('.submenu').forEach(function (everysubmenu) {
              // hide every submenu as well
              everysubmenu.style.display = 'none';
            });
          });
        });

        document.querySelectorAll('.dropdown-menu a').forEach(function (element) {
          element.addEventListener('click', function (e) {
            let nextEl = this.nextElementSibling;
            if (nextEl && nextEl.classList.contains('submenu')) {
              // prevent opening link if link needs to open dropdown
              e.preventDefault();
              if (nextEl.style.display == 'block') {
                nextEl.style.display = 'none';
              } else {
                nextEl.style.display = 'block';
              }
            }
          });
        });
      }
      // end if innerWidth
    });
  },
  methods: {
    async getCurrentUser() {
      let url = '/auth/user';
      await api.get(url).then((res) => {
        this.currentuser = res.data;
      });
      console.log('this is user->', this.currentuser);
    },
  },
};
</script>

<style scoped>
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
  }
}
/* ============ small devices .end// ============ */
</style>