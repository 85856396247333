<template>
  <div>
    <div class="min-h-screen h-full bg-gray-200">
      <div class="grid grid-cols-4 gap-x-2">
        <div class="h-14 col-span-4 border-2 bg-accentM">
          <ol class="list-reset flex mt-3 ml-5">
            <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li><span @click="this.$router.push('/purchase')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Purchase</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li class="text-white text-xl">Purchase Requisition</li>
          </ol>
        </div>

        <div class="col-span-4 mt-4 ml-8">
          <!-- <button @click="true" class="bg-green-300 hover:bg-green-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded shadow-lg">Draft</button> -->
          <button @click="Save()" class="bg-button hover:bg-button2 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded shadow-lg">Submit</button>
          <button @click="Clear()" class="bg-red-400 hover:bg-red-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded shadow-lg">Clear</button>
          <!-- <button @click="showModal = true" class="bg-cyan-400 hover:bg-cyan-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded shadow-lg">Template</button> -->
        </div>

        <div class="col-span-2 my-4 ml-10 mr-1 rounded py-5 bg-bg shadow-lg">
          <p class="font-bold ml-4 text-2xl text-left">Order Details</p>
          <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Order Type:</p>
            </div>
            <div class="md:w-4/6">
              <select class="border-2 border-gray-400 rounded w-3/5 float-right mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="orderType" required>
                <option value="" hidden>Order Type</option>
                <option value="OR">OR</option>
              </select>
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Order Number:</p>
            </div>
            <div class="md:w-4/6">
              <input v-model="orderNumber" disabled class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="order number" />
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Order By:</p>
            </div>
            <div class="md:w-4/6">
              <input disabled v-model="orderBy" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="order By" />
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Requested By:</p>
            </div>
            <div class="md:w-4/6">
              <select class="border-2 border-gray-400 rounded w-3/5 float-right mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="requested" required>
                <option value="" hidden>Requested By</option>
                <option v-for="c in dataE" :key="c" :value="c.id">{{ c.name }}</option>
              </select>
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Requested Date:</p>
            </div>
            <div class="md:w-4/6">
              <input v-model="date1" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="date" />
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Expected Date:</p>
            </div>
            <div class="md:w-4/6">
              <input v-model="date2" :min="date1" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="date" />
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Ungency:</p>
            </div>
            <div class="md:w-4/6">
              <select class="border-2 border-gray-400 rounded w-3/5 float-right mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="urgent" required>
                <option value="" hidden>Ungency</option>
                <option v-for="c in dataU" :key="c" :value="c">{{ c }}</option>
              </select>
            </div>
          </div>

          <div v-if="urgent == 'Urgent'" class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Remarks:</p>
            </div>
            <div class="md:w-4/6">
              <input v-model="note" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="Add Remarks" />
            </div>
          </div>
        </div>

        <div class="col-span-2 my-4 mr-10 py-5 ml-1 rounded bg-bg shadow-lg">
          <p class="font-bold ml-4 text-2xl text-left">Address</p>
          <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>

          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Delivery Address:</p>
            </div>
            <div class="md:w-4/6">
              <textarea v-model="address1" class="float-right md:flex w-4/5 h-24 appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="Delivery Address" />
            </div>
          </div>

          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Billing Address:</p>
            </div>
            <div class="md:w-4/6">
              <textarea :disabled="addressBoolean" v-model="address2" class="float-right md:flex w-4/5 h-24 appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button disabled:bg-slate-200 disabled:border-gray-400" type="text" placeholder="Billing Address" />
            </div>
          </div>

          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Same As Delivery Address:</p>
            </div>
            <div class="md:w-4/6">
              <input v-model="addressBoolean" @click="address2 = ''" class="float-left md:flex appearance-none border-2 border-gray-400 rounded p-2 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100" type="checkbox" />
            </div>
          </div>
        </div>

        <!-- item add -->
        <div class="col-span-4 mx-10 mb-5 py-5 rounded bg-bg shadow-lg">
          <p class="font-bold ml-4 text-2xl text-left">Requested Items</p>
          <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>
          <div class="md:flex md:items-center mx-4 my-2 auto-rows-1fr">
            <div class="md:w-2/15 tooltip">
              <span class="tooltiptext">Item Select</span>
              <select class="border-2 border-gray-400 rounded mx-2 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block" v-model="item" required>
                <option value="" hidden>Item</option>
                <option v-for="c in dataI" :key="c.name" :value="c.name">{{ c.name }}</option>
              </select>
            </div>
            <div class="md:w-2/15 tooltip">
              <span class="tooltiptext">Quantity</span>
              <input v-model="quantity" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-2 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="number" placeholder="Quantity" />
            </div>
            <div class="md:w-2/15 tooltip">
              <span class="tooltiptext">Unit Price</span>
              <input v-model="price" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-2 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="number" placeholder="Unit Price" />
            </div>
            <div class="md:w-2/15 tooltip tooltip-accent" data-tip="Remarks">
              <span class="tooltiptext">Remarks</span>
              <input v-model="remarks" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-2 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="Remarks" />
            </div>
            <div class="md:w-2/15 tooltip tooltip-accent" data-tip="Cost Center">
              <span class="tooltiptext">Cost Center</span>
              <select class="border-2 border-gray-400 rounded mx-2 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="cc" required>
                <option value="" hidden>Cost Center</option>
                <option v-for="c in dataCost" :key="c.name" :value="c.name">{{ c.name }}</option>
              </select>
            </div>
            <div class="md:w-2/15 tooltip tooltip-accent" data-tip="Project">
              <span class="tooltiptext">Project</span>
              <select class="border-2 border-gray-400 rounded mx-2 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="project" required>
                <option value="" hidden>Project</option>
                <option v-for="c in dataProject" :key="c.id" :value="c.id">{{ c.name }}</option>
              </select>
            </div>
            <div class="md:w-2/15 tooltip tooltip-accent" data-tip="Line">
              <span class="tooltiptext">Line Type</span>
              <select class="border-2 border-gray-400 rounded mx-2 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="line" required>
                <option value="" hidden>Line Type</option>
                <option v-for="c in dataLineType" :key="c.id" :value="c.id">{{ c.name }}</option>
              </select>
            </div>
            <div class="md:w-1/15">
              <button @click="Add()" class="bg-gray-700 hover:bg-gray-500 hover:border-2 hover:border-black text-white hover:text-black rounded-lg py-1 px-4">Add</button>
            </div>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 mt-2 min-h-4/5">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs uppercase bg-accent2">
                <tr>
                  <th scope="col" class="px-6 py-3 text-white">Name</th>
                  <!-- <th scope="col" class="px-4 py-3">GL Code</th> -->
                  <th scope="col" class="px-4 py-3 text-white">Quantity</th>
                  <th scope="col" class="px-4 py-3 text-white">Unit Price</th>
                  <th scope="col" class="px-4 py-3 text-white">Line Type</th>
                  <th scope="col" class="px-4 py-3 text-white">Cost Center</th>
                  <th scope="col" class="px-4 py-3 text-white">Project</th>
                  <th scope="col" class="px-4 py-3 text-white">Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in dataTable" :key="p" class="border-b odd:bg-white even:bg-gray-50">
                  <td class="px-6 py-4 md:pl-20">{{ p.item }}</td>
                  <!-- <td class="px-6 py-4">{{ p.glCode }}</td> -->
                  <td class="px-6 py-4">{{ p.quantity }}</td>
                  <td class="px-6 py-4">{{ p.price }}</td>
                  <td class="px-6 py-4">{{ p.lineName }}</td>
                  <td class="px-6 py-4">{{ p.cc }}</td>
                  <td class="px-6 py-4">{{ p.projectName }}</td>
                  <td class="px-6 py-4">{{ p.remarks }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-[800px] h-[700px]">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex justify-center px-5 py-2 border-b border-solid border-slate-200 rounded-t">
            <h3 class="text-2xl font-semibold">Template</h3>
          </div>
          <!--body-->
          <form>
            <div class="w-[700px]">
              <div class="md:flex md:items-center my-4 auto-rows-1fr">
                <div class="md:w-2/6">
                  <p class="block text-gray-900 font-bold pr-4 text-right ml-10" for="inline-full-name">Template Name:</p>
                </div>
                <div class="md:w-4/6">
                  <input v-model="draftName" class="float-left md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="template Name" />
                </div>
              </div>
              <div class="md:flex md:items-center my-4 auto-rows-1fr">
                <div class="md:w-2/6">
                  <p class="block text-gray-900 font-bold pr-4 text-right ml-10" for="inline-full-name">Template:</p>
                </div>
                <div class="md:w-4/6">
                  <select class="border-2 border-gray-400 rounded w-3/5 float-left mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="draft" required>
                    <option value="" hidden>Saved Template</option>
                    <option v-for="c in dataPurchase" :key="c" :value="c.id">{{ c.stateName }}</option>
                  </select>
                </div>
              </div>
            </div>
            <!--footer-->
            <div class="flex items-center justify-end px-6 py-3 border-t border-solid border-slate-200 rounded-b">
              <button class="text-cyan-500 bg-transparent border border-solid border-cyan-500 hover:bg-cyan-700 hover:text-white active:bg-button2 font-bold uppercase text-sm px-4 h-[40px] rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" v-on:click="this.showModal = false">Close</button>
              <button type="submit" class="bg-cyan-500 hover:bg-cyan-700 text-white font-bold text-lg px-4 rounded h-[40px] mr-1" @click.prevent="TemplateLoad()">Load</button>
              <button type="submit" class="bg-cyan-500 hover:bg-cyan-700 text-white font-bold text-lg px-4 rounded h-[40px]" @click.prevent="Template()">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>
<script>
import api from '../boot/axios';

export default {
  components: {},

  data() {
    return {
      orderBy: localStorage.getItem('username'),
      dataE: [],
      dataS: [],
      dataStatus: [],
      dataI: [],
      dataU: ['Urgent', 'Low'],
      dataTable: [],
      dataPurchase: [],
      dataCost: [],
      dataCOA: [],
      dataLineType: [],
      dataProject: [],

      showModal: '',

      orderNumber: '',
      orderType: '',
      requested: '',
      supplier: '',
      date1: '',
      date2: '',
      urgent: '',
      note: '',
      address1: '',
      address2: '',
      glCode: '',
      addressBoolean: false,

      item: '',
      quantity: '',
      price: '',
      remarks: '',
      line: '',
      project: '',
      cc: '',

      draft: '',
      draftName: '',
    };
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    Clear() {
      this.requested = '';
      this.supplier = '';
      this.date1 = '';
      this.date2 = '';
      this.urgent = '';
      this.address1 = '';
      this.address2 = '';
      this.item = '';
      this.quantity = '';
      this.price = '';
      this.remarks = '';
      this.dataTable = [];
    },
    async getData() {
      await api
        .get(`api/employee/`)
        .then((res) => {
          this.dataE = res.data;
          // console.table(this.dataE);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/cost_center/`)
        .then((res) => {
          this.dataCost = res.data;
          console.table(this.dataCost);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/coa/`)
        .then((res) => {
          this.dataCOA = res.data;
          // console.table(this.dataCOA);
        })
        .catch((error) => {
          console.log(error);
        });
      await api.get(`api/line_type/`).then((res) => {
        this.dataLineType = res.data;
        // console.table(this.dataLineType);
      });
      await api
        .get(`api/project/`)
        .then((res) => {
          this.dataProject = res.data;
          // console.table(this.dataProject);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/supplier/`)
        .then((res) => {
          this.dataS = res.data;
          // console.table(this.dataS);
        })
        .catch((error) => {
          console.log(error);
        });

      await api
        .get(`api/item/`)
        .then((res) => {
          this.dataI = res.data;
          console.table(this.dataI);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=or&statusCode=&statusName=pending`)
        .then((res) => {
          this.dataStatus = res.data;
          if (this.dataStatus == '') {
            alert('Status not Set');
          }
          console.table(this.dataStatus);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/pruchase/`)
        .then((res) => {
          console.log(res.data);
          let a = res.data;
          if (a.length == 0) {
            this.orderNumber = '000001';
          } else {
            this.orderNumber = ('000000' + (a[a.length - 1].id + 1)).slice(-6);
          }
          // console.log(this.orderNumber);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/pruchase/?status=5`) // ? template
        .then((res) => {
          this.dataPurchase = res.data;
          // console.table(this.dataPurchase);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async TemplateLoad() {
      let a = [];
      await api
        .get(`api/pruchase/${this.draft}/`)
        .then((res) => {
          a = res.data;
          console.log(a);
          this.orderNumber = a.orderNumber;
          this.orderBy = a.orderBy;
          this.requested = a.requestedBy;
          this.supplier = a.supplier;
          this.date1 = a.date_requested;
          this.date2 = a.date_expected;
          this.urgent = a.urgency;
          this.address1 = a.address_delivery;
          this.address2 = a.address_billing;
          this.note = a.remarks;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/requested_item/?orderId=${this.draft}`)
        .then((res) => {
          a = res.data;
          for (let i = 0; i < a.length; i++) {
            let b = {
              item: a[i].itemId,
              name: a[i].itemName,
              glCode: a[i].itemGL,
              accountId: a[i].accountId,
              quantity: a[i].quantity,
              price: a[i].unitPrice,
              remarks: a[i].remarks,
            };
            this.dataTable.push(b);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.showModal = false;
    },
    Add() {
      if (this.item == '' || this.quantity == '' || this.price == '') {
        return;
      }
      let q, w;
      for (let i = 0; i < this.dataLineType.length; i++) {
        if (this.dataLineType[i].id == this.line) {
          q = this.dataLineType[i].name;
        }
      }
      for (let i = 0; i < this.dataProject.length; i++) {
        if (this.dataProject[i].id == this.project) {
          w = this.dataProject[i].name;
        }
      }

      for (let i = 0; i < this.dataI.length; i++) {
        if (this.dataI[i].name == this.item) {
          let a = {
            item: this.item,
            quantity: this.quantity,
            price: this.price,
            remarks: this.remarks,
            line: this.line,
            lineName: q,
            project: this.project,
            projectName: w,
            cc: this.cc,
          };
          this.dataTable.push(a);
          this.item = '';
          this.cc = '';
          this.quantity = '';
          this.price = '';
          this.remarks = '';
          this.line = '';
          this.project = '';
        }
      }
      console.table(this.dataTable);
    },
    async Save() {
      if (this.orderNumber == '' || this.requested == '') {
        return;
      }

      let d = this.dataStatus[0].id;
      let e = this.dataStatus[0].statusName;
      if (this.addressBoolean) {
        this.address2 = this.address1;
      }
      let a = {
        orderNumber: this.orderNumber,
        orderBy: this.orderBy,
        requestedBy: this.requested,
        date_requested: this.date1,
        date_expected: this.date2,
        urgency: this.urgent,
        address_delivery: this.address1,
        address_billing: this.address2,
        status: d,
        statusC: 'OR',
        statusName: e,
        remarks: this.note,
      };
      let c = '';
      await api
        .post(`api/pruchase/`, a)
        .then((res) => {
          c = res.data.id;
          console.log(c);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataTable.length; i++) {
        const b = {
          orderId: c,
          cc: this.dataTable[i].cc,
          ccName: this.dataTable[i].ccName,
          line: this.dataTable[i].line,
          lineName: this.dataTable[i].lineName,
          project: this.dataTable[i].project,
          projectName: this.dataTable[i].projectName,
          item: this.dataTable[i].item,
          itemName: this.dataTable[i].name,
          itemGL: this.dataTable[i].glCode,
          accountId: this.dataTable[i].accountId,
          quantity: this.dataTable[i].quantity,
          unitPrice: this.dataTable[i].price,
          remarks: this.dataTable[i].remarks,
          status: d,
          statusC: 'OR',
          statusName: 'pending',
        };
        console.table(b);
        await api
          .post(`api/requested_item/`, b)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.Clear();
    },
    async Template() {
      if (this.orderNumber == '' || this.requested == '') {
        return;
      }
      let d = '';
      let e = '';
      for (let i = 0; i < this.dataStatus.length; i++) {
        if (this.dataStatus[i].orderType == 'OR' && this.dataStatus[i].statusName == 'template') {
          d = this.dataStatus[i].statusCode;
          e = this.dataStatus[i].statusName;
        }
      }
      let a = {
        orderNumber: this.orderNumber,
        orderBy: this.orderBy,
        requestedBy: this.requested,
        supplier: this.supplier,
        date_requested: this.date1,
        date_expected: this.date2,
        urgency: this.urgent,
        address_delivery: this.address1,
        address_billing: this.address2,
        status: d,
        statusName: e,
        stateName: this.draftName,
      };
      let c = '';
      await api
        .post(`api/pruchase/`, a)
        .then((res) => {
          c = res.data.id;
          console.log(c);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataTable.length; i++) {
        const b = {
          orderId: c,
          itemId: this.dataTable[i].item,
          itemName: this.dataTable[i].name,
          itemGL: this.dataTable[i].glCode,
          accountId: this.dataTable[i].accountId,
          quantity: this.dataTable[i].quantity,
          unitPrice: this.dataTable[i].price,
          remarks: this.dataTable[i].remarks,
          status: d,
          statusName: e,
        };
        await api
          .post(`api/requested_item/`, b)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.Clear();
    },
  },
};
</script>
<style >
input:hover {
  cursor: pointer;
}
select:hover {
  cursor: pointer;
}
textarea:hover {
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>