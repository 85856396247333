<template>
  <div style="height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Previous Bill Report</p></div>
          <div style="float: right"><button style="background-color: #26ade4; color: white" class="button" @click.prevent="$router.push('/report')">Back</button></div>
        </div>
      </div>
    </div>
    <div class="columns" style="margin-top: 0px">
      <div class="column is-10" style="margin-left: 20px">
        <div style="float: left">
          <div class="field has-addons" style="margin-left: 0">
            <p class="control">
              <select v-model="card_no" style="height: 40px; width: 150px; border: 1px solid #0c9eda">
                <option value="" hidden>Select Card</option>
                <option v-for="r in dataC" :key="r.card_no">
                  {{ r.card_no }}
                </option>
              </select>
            </p>
            <p class="control">
              <button class="button" style="color: white; border: 1px solid #0c9eda; background-color: #0c9eda" @click.prevent="Calculate()">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div class="column is-2"><button class="button" @click="printPDF()">print</button></div>
    </div>
    <div class="columns" style="margin-top: 20px">
      <div class="column is-12">
        <div class="box" style="height: 60vh; min-height: 600px; overflow-y: scroll">
          <table class="table is-fullwidth" style="overflow-y: auto">
            <thead>
              <tr>
                <th>Bills</th>
                <th>Date</th>
                <th>Time</th>
                <th>Amount</th>
                <th>Vat</th>
                <th>Service Charge</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in dataA" v-bind:key="d.id">
                <td>{{ d.bill_item }}</td>
                <td>{{ d.date }}</td>
                <td>{{ d.time }}</td>
                <td>
                  {{
                    d.cost
                      .toString('0.00')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }}
                </td>
                <td>{{ d.vat_amount }}</td>
                <td>{{ d.sc_amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-8"></div>
      <div class="column is-4" style="display: flex">
        <p style="font-size: 22px">
          Total:
          {{
            total
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }}
        </p>
        <p style="font-size: 22px; margin-left: 20px">
          Vat:
          {{
            vt
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }}
        </p>
        <p style="font-size: 22px; margin-left: 20px">
          S.C:
          {{
            sc
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }}
        </p>
      </div>
    </div>

    <div v-show="modalShow == true">
      <div id="pdfportion">
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <img :src="logo" style="max-width: 50px; left: 0" />
        </div>
        <div style="display: flex; justify-content: center; font-size: 36px; margin-top: 5px">{{ company.company_name }} Hotel</div>
        <div style="display: flex; justify-content: center; font-size: 18px; margin-top: 5px">
          {{ company.company_address }}
        </div>
        <div style="display: flex; justify-content: center; font-size: 15px; margin-top: 5px">Hotline: {{ company.contact_person_phone }}</div>
        <div class="columns">
          <div class="column is-12">
            <div style="float: left; margin-left: 10px; font-size: 18px">
              <p>Date: {{ date }}</p>
            </div>
            <div style="float: right; margin-right: 10px; font-size: 18px">
              <p>Time: {{ time }}</p>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: left; font-size: 18px; margin-top: 10px">Card No: {{ card_no }}</div>

        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Bills</th>
              <th>Date</th>
              <th>Time</th>
              <th>Amount</th>
              <th>Vat</th>
              <th>Service Charge</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in dataA" v-bind:key="d.id">
              <td>{{ d.bill_item }}</td>
              <td>{{ d.date }}</td>
              <td>{{ d.time }}</td>
              <td>
                {{
                  d.cost
                    .toString('0.00')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
              </td>
              <td>{{ d.vat_amount }}</td>
              <td>{{ d.sc_amount }}</td>
            </tr>
          </tbody>
        </table>

        <div style="display: flex; justify-content: right; font-size: 14px; margin-top: 10px">
          <p style="font-size: 18px">
            Total:
            {{
              total
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
          </p>
        </div>
        <div style="display: flex; justify-content: right; font-size: 14px; margin-top: 10px">
          <p style="font-size: 18px; margin-left: 20px">
            Vat:
            {{
              vt
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
          </p>
        </div>
        <div style="display: flex; justify-content: right; font-size: 14px; margin-top: 10px">
          <p style="font-size: 18px; margin-left: 20px">
            S.C:
            {{
              sc
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import html2pdf from 'html2pdf-jspdf2';

export default {
  data() {
    return {
      data: [],
      dataC: [],
      dataA: [],
      card_no: '',
      cards: [],
      total: 0,
      vt: 0,
      sc: 0,
      logo: null,
      modalShow: false,
      company: [],
      date: '',
      time: '',
    };
  },
  created() {
    this.getData();
    this.getDataC();
    this.getLogo();
    this.getCompany();
  },
  methods: {
    async getLogo() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },

    async getCompany() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.company = res.data;
          console.log(this.company);
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/bills/')
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataC() {
      await api
        .get('api/res_cus_card/')
        .then((response) => {
          this.dataC = response.data;
          console.table(this.dataC);
          let a = [];
          for (let i = 0; i < this.dataC.length; i++) {
            if (this.dataC[i].status == 'Checked Out') {
              console.log(this.dataC[i]);
              a.push(this.dataC[i]);
            }
          }
          this.dataC = a;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Calculate() {
      this.total = 0;
      this.vt = 0;
      this.sc = 0;
      this.dataA = [];
      console.log(this.card_no);
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].card_no != null) {
          if (this.data[i].card_no == this.card_no && this.data[i].bill_category == 'debit') {
            this.dataA.push(this.data[i]);
            this.total += parseInt(this.data[i].cost);
            if (this.data[i].type_bill == 'laundry' || this.data[i].type_bill == 'room_sevice') {
              this.vt += parseInt(this.data[i].cost) * (parseInt(this.data[i].vat_amount) / 100);
              this.sc += parseInt(this.data[i].cost) * (parseInt(this.data[i].sc_amount) / 100);
            } else {
              this.vt += parseInt(this.data[i].vat_amount);
              this.sc += parseInt(this.data[i].sc_amount);
            }
          }
        }
      }
    },

    printPDF() {
      this.modalShow = true;
      this.date = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 8);
      this.time = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(10, 11);

      var element = document.getElementById('pdfportion');

      var opt = {
        margin: 0.2,
        filename: 'Previous_Bill_Report_' + this.card_no + '|' + this.date + '|' + this.time + '.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();

      this.modalShow = false;
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>