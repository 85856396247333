<template>
  <div>
    <div style="height: 100vh">
      <div class="columns" style="height: 100px">
        <div class="column is-12" style="height: 70px">
          <div class="box" style="font-size: 22px; font-weight: bold; height: 70px; width: 100%">
            <p style="float: left">Complain</p>
            <button class="button" style="float: right; background-color: #099dd9; color: white; margin-top: -5px" @click.prevent="rejectModal = true">Add New</button>
          </div>
        </div>
      </div>
      <div v-if="rejectModal == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="rejectModal = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 40vw; margin-right: 0; overflow-x: hidden">
            <div style="width: 40vw; background-color: #51627e; color: white; font-size: 20px; margin-bottom: 10px">Add Complain</div>
            <div style="height: 85vh">
              <div class="field is-horizontal" style="">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 18px; width: 180px">Room No:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input class="input" type="text" placeholder="Room No" v-model="room_no" style="width: 300px; float: left" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 18px; width: 180px">Card No:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input class="input" type="text" placeholder="card number" v-model="card" style="width: 300px; float: left" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 18px; width: 180px">Issue Date:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input class="input" type="date" v-model="issue_date" style="width: 300px; float: left" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 18px; width: 180px">Particulars:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input class="input" type="text" placeholder="Particular Responsible" v-model="part" style="width: 300px; float: left" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 18px; width: 180px">Subject:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input class="input" type="text" placeholder="Subject" v-model="subject" style="width: 300px; float: left" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 18px; width: 180px">Issues:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <textarea class="textarea" placeholder="Issues" v-model="issues" style="max-width: 100px; float: left"></textarea>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 10px">
              <button class="button" style="background-color: #e67c6c; color: white" @click.prevent="rejectModal = false">Back</button>
              <button style="margin-left: 10px; background-color: #26ade4; color: white" class="button" @click.prevent="submitComplain()">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Room</th>
            <th>Card No</th>
            <th>Issue Date</th>
            <th>Particulars</th>
            <th>Subject</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="d in tData" v-bind:key="d.id">
            <td>{{ d.room_no }}</td>
            <td>{{ d.card_no }}</td>
            <td>{{ d.issueDate }}</td>
            <td>{{ d.particular }}</td>
            <td>{{ d.subject }}</td>
            <td><button class="button">Show Info</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
export default {
  data() {
    return {
      rejectModal: false,
      issues: '',
      subject: '',
      part: '',
      issue_date: '',
      card: '',
      room_no: '',
      tData: [],
    };
  },
  async mounted() {
    await this.getTableData();
  },
  methods: {
    async submitComplain() {
      await api
        .post(`api/complain/`, {
          card_no: this.card,
          room_no: this.room_no,
          issues: this.issues,
          subject: this.subject,
          particular: this.part,
          issueDate: this.issue_date,
        })
        .catch((err) => console.log(err));
    },
    async getTableData() {
      await api
        .get('api/complain/')
        .then((res) => {
          this.tData = res.data;
        })
        .catch((err) => console.log(err));
      console.log(this.tData);
    },
  },
};
</script>

<style>
</style>