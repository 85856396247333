<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%">
          <div style="float: left"><p style="font-size: 24px">VAT Ledger</p></div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="columns" style="min-height: 600px; min-width: 600px">
      <div class="column is-1"></div>
      <div class="column is-10">
        <div class="box" style="height: 100%; min-height: 600px; min-width: 1300px">
          <table class="table is-fullwidth is-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Journal Id</th>
                <th>Account ID</th>
                <th>Description</th>
                <th>Date</th>
                <th>Credit</th>
                <th>Debit</th>
                <th>Memo</th>
                <th>Reference</th>
                <th>By</th>
                <th>Auto Journal</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="d in dataTT" v-bind:key="d.id">
                <td>{{ d.id }}</td>
                <td>{{ d.journal_id }}</td>
                <td>{{ d.account_id }}</td>
                <td>{{ d.description }}</td>
                <td>{{ d.date }}</td>
                <td>{{ d.credit }}</td>
                <td>{{ d.debit }}</td>
                <td>{{ d.memo }}</td>
                <td>{{ d.reference }}</td>
                <td>{{ d.credited_by }}</td>
                <td>{{ d.auto_journal }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-1"></div>
    </div>

    <!-- Table -->
    <div class="columns" v-show="false">
      <div class="column is-1"></div>
      <div class="column is-10">
        <div class="box" style="height: 100%">
          <table class="table is-fullwidth is-striped" id="printit">
            <thead>
              <tr>
                <th>ID</th>
                <th>Journal Id</th>
                <th>Account ID</th>
                <th>Description</th>
                <th>Date</th>
                <th>Credit</th>
                <th>Debit</th>
                <th>Memo</th>
                <th>Reference</th>
                <th>By</th>
                <th>Auto Journal</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="d in dataTT2" v-bind:key="d.id">
                <td>{{ d.id }}</td>
                <td>{{ d.journal_id }}</td>
                <td>{{ d.account_id }}</td>
                <td>{{ d.description }}</td>
                <td>{{ d.date }}</td>
                <td>{{ d.credit }}</td>
                <td>{{ d.debit }}</td>
                <td>{{ d.memo }}</td>
                <td>{{ d.reference }}</td>
                <td>{{ d.credited_by }}</td>
                <td>{{ d.auto_journal }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-1"></div>
    </div>

    <div class="columns">
      <div class="column is-12">
        <span v-if="showPrevButton">
          <button class="button" @click.prevent="showPrev()" style="background-color: #26ade4; color: white">Prev</button>
        </span>
        <span v-if="showNextButton">
          <button class="button" @click.prevent="showNext()" style="background-color: #26ade4; color: white; margin-left: 10px">Next</button>
        </span>
      </div>
    </div>
    <!-- Modal: COA add -->
    <div v-if="caoModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="caoModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 800px; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div class="columns" style="height: 10vh">
              <div class="column is-12">
                <div class="box" style="height: 7vh">
                  <p style="font-size: 20px">Create Chart of Accounts</p>
                </div>
              </div>
            </div>

            <div class="columns" style="height: 70vh; margin-top: 15px">
              <div class="column is-12">
                <div class="box" style="height: 70vh">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 200px">ID:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="id" v-model="coaId" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 200px">Cost Center:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <select v-model="cost_center" style="width: 300px; height: 40px">
                            <option value="" hidden>Cost Center</option>
                            <option v-for="r in dataCC" :key="r.name">
                              <span style="text-transform: uppercase"> {{ r.name }}</span>
                            </option>
                          </select>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 200px">Responsible Center:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="responsible center" v-model="responsible_center" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 200px">Type:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <select v-model="type" style="width: 300px; height: 40px">
                            <option value="" hidden>Select Type</option>
                            <option>Credit</option>
                            <option>Debit</option>
                          </select>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 200px">Statement:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="statement" v-model="statement" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 200px">Description:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="description" v-model="description" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 200px">Tax:</label>
                    </div>
                    <div class="field-body">
                      <input type="checkbox" v-model="tax" style="margin-top: 15px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 25px">
              <button class="button" @click.prevent="caoModal = false" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
              <button class="button" @click.prevent="coaAdd()" style="background-color: #26ade4; color: white">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: JE add -->
    <div v-if="jeModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="jeModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 80vw; margin-right: 0">
          <div class="" style="height: 100vh; min-height: 100%; width: 99%">
            <div class="columns" style="height: 60px; min-height: 60px">
              <div class="column is-12">
                <div class="box" style="height: 60px; min-height: 60px">
                  <p style="font-size: 20px">Add Journal Entry</p>
                </div>
              </div>
            </div>

            <div class="columns" style="min-height: 200px; margin-top: 15px">
              <div class="column is-12">
                <div class="box" style="height: 100%">
                  <div class="columns">
                    <div class="column is-4">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 17px; font-weight: normal; width: 120px">Date: </label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input type="date" class="input" v-model="date" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 17px; font-weight: normal; width: 120px">Reference: </label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input type="text" placeholder="reference" class="input" v-model="reference" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 17px; font-weight: normal; width: 120px">Credited By: </label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input type="text" readonly class="input" v-model="user.username" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="columns" style="margin-top: 20px">
                    <div class="column is-4">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 17px; font-weight: normal; width: 120px">Account Id:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <select v-model="coaId" @change="SelectCOA()" style="height: 40px; width: 100%">
                                <option value="" hidden>Account Id</option>
                                <option v-for="r in dataCOA" :key="r.id">{{ r.id }}</option>
                              </select>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 17px; font-weight: normal; width: 120px">Credit:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="number" :disabled="varChange == 'd'" min="0" v-model="credit" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 17px; font-weight: normal; width: 120px">Debit:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="number" :disabled="varChange == 'c'" min="0" v-model="debit" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column is-4">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 17px; font-weight: normal; width: 120px">Description:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="text" placeholder="description" v-model="description" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 17px; font-weight: normal; width: 120px">Memo:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="text" placeholder="memo" v-model="memo" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="column is-3">
                      <button class="button" style="float: right; width: 200px; background-color: #26ade4; color: white" @click.prevent="AddJE()">Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns" style="height: 400px; min-height: 400px; margin-top: 5px">
              <div class="column is-12">
                <div class="box" style="height: 100%; overflow-y: auto">
                  <table class="table is-fullwidth is-striped">
                    <thead>
                      <tr>
                        <th>Account ID</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Description</th>
                        <th>Memo</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="d in varArr" v-bind:key="d.index">
                        <td>{{ d.id }}</td>
                        <td>{{ d.credit }}</td>
                        <td>{{ d.debit }}</td>
                        <td>{{ d.description }}</td>
                        <td>{{ d.memo }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-9"></div>
              <div class="column is-3">
                <p style="font-size: 20px">Total credit: {{ creditT }} Total debit: {{ debitT }}</p>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 25px">
              <button class="button" @click.prevent="(jeModal = false), (varArr = []), (creditT = 0), (debitT = 0)" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
              <button class="button" style="background-color: #26ade4; color: white" :disabled="creditT != debitT" @click.prevent="jeAdd()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      dataCOA: [],
      dataTT: [],
      dataTT2: [],
      dataCC: [],
      varArr: [],
      total: 0,
      credit: 0,
      creditT: 0,
      debit: 0,
      debitT: 0,
      caoModal: false,
      jeModal: false,
      coaId: '',
      cost_center: '',
      responsible_center: '',
      type: '',
      tax: false,
      description: '',
      statement: '',
      credited_by: '',
      reference: '',
      memo: '',
      date: new Date().toISOString().substr(0, 10),
      amount: 0,
      varChange: '',
      hotel: [],
      user: [],
      showNextButton: false,
      showPrevButton: false,
      pageNum: 1,
      pic: null,
    };
  },
  created() {
    this.getData();
    this.getHotel();
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back05;
        })
        .catch((err) => console.log(err));
    },

    exportTableToExcel(tableID, filename = '') {
      var downloadLink;
      var dataType = 'application/vnd.ms-excel';
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

      // Specify file name
      filename = filename ? filename + '.xls' : this.selected_rule.rule_name + '.xls';

      // Create download link element
      downloadLink = document.createElement('a');

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(['\ufeff', tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },

    async getHotel() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.hotel = res.data;
        })
        .catch((err) => console.log(err));
      // await api
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         this.user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
    },

    async getData() {
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          this.dataTT2 = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      await api
        .get(`api/Transaction_table3/?page=${this.pageNum}`)
        .then((res) => {
          this.showNextButton = false;
          this.showPrevButton = false;

          if (res.data.next) {
            this.showNextButton = true;
          }

          if (res.data.previous) {
            this.showPrevButton = true;
          }
          this.dataTT = res.data.results;
          console.log(this.dataTT);
        })
        .catch((err) => console.log(err));

      await api
        .get('api/coa/')
        .then((response) => {
          this.dataCOA = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/cost_center/')
        .then((response) => {
          this.dataCC = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async coaAdd() {
      const a = {
        id: this.coaId,
        description: this.description,
        cost_center: this.cost_center,
        responsible_center: this.responsible_center,
        type: this.type,
        tax: this.tax,
        statement: this.statement,
      };
      await api
        .post('/api/coa/', a)
        .then(() => {
          toast({
            message: 'COA has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          });
          (this.coaId = ''), (this.description = '');
          this.cost_center = '';
          this.responsible_center = '';
          this.type = '';
          this.tax = false;
          this.statement = '';

          this.getData();

          this.caoModal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async jeAdd() {
      await this.getData();
      let c = 1;
      if (!this.dataTT2.length == 0) {
        c = parseInt(this.dataTT2[this.dataTT2.length - 1].journal_id) + 1;
      }

      for (let i = 0; i < this.varArr.length; i++) {
        const a = {
          description: this.varArr[i].description,
          date: this.date,
          account_id: this.varArr[i].id,
          credited_by: this.credited_by,
          reference: this.reference,
          credit: this.varArr[i].credit,
          debit: this.varArr[i].debit,
          memo: this.varArr[i].memo,
          journal_id: c,
          auto_journal: false,
        };
        await api.post('/api/Transaction_table/', a).catch((err) => {
          console.log(err);
        });
      }
      toast({
        message: 'Journal entry has added',
        type: 'is-success',
        dismissible: true,
        pauseOnHover: true,
        duration: 1000,
        position: 'bottom-right',
      });
      this.date = new Date().toISOString().substr(0, 10);
      this.credited_by = '';
      this.reference = '';
      this.creditT = 0;
      this.debitT = 0;
      this.varArr = [];

      this.getData();

      this.jeModal = false;
    },

    SelectCOA() {
      for (let i = 0; i < this.dataCOA.length; i++) {
        if (this.dataCOA[i].id == this.coaId) {
          if (this.dataCOA[i].type == 'Credit') {
            this.varChange = 'c';
          }
          if (this.dataCOA[i].type == 'Debit') {
            this.varChange = 'd';
          }
          console.log(this.varChange);
          break;
        }
      }
    },

    AddJE() {
      const a = {
        id: this.coaId,
        credit: this.credit,
        debit: this.debit,
        description: this.description,
        memo: this.memo,
      };
      this.creditT += this.credit;
      this.debitT += this.debit;
      (this.coaId = ''), (this.credit = 0), (this.debit = 0), (this.description = ''), (this.memo = '');
      this.varArr.push(a);
      console.log(this.varArr);
    },

    showNext() {
      this.pageNum += 1;
      this.getData();
    },
    showPrev() {
      this.pageNum -= 1;
      this.getData();
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>