<template>
  <div class="min-h-screen h-full bg-gray-200">
    <div class="grid grid-cols-4 gap-x-2">
      <div class="h-14] col-span-4 border-2 bg-accentM">
        <ol class="list-reset flex mt-3 ml-5">
          <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li><span @click="this.$router.push('/purchase')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Purchase</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li class="text-white text-xl">Purchase Request Quotation</li>
        </ol>
      </div>

      <div class="col-span-4 mt-4 ml-8 mb-2">
        <!-- <button @click="true" class="bg-green-300 hover:bg-green-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Draft</button> -->
        <button @click="Save()" class="bg-button shadow-lg hover:bg-button2 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Save</button>
        <button @click="this.$router.push('/purchase')" class="bg-red-400 shadow-lg hover:bg-red-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Back</button>
      </div>
      <div v-for="(d, index) in dataPurchaseItem" :key="index" class="col-span-4 my-1 mx-10 rounded">
        <div class="grid grid-cols-6">
          <div class="col-span-1 bg-white rounded-xl my-2 shadow-lg">
            <div class="my-auto">
              <p class="text-xl mt-5 font-semibold">Item Name: {{ d.item }}</p>
              <p class="text-lg mt-2">Quantity: {{ d.quantity }}</p>
              <p class="text-lg mt-2">Unit price : {{ d.unitPrice }}</p>
              <div class="border-b border-gray-700 mx-10"></div>
              <p class="text-lg mt-2">Total price : {{ d.unitPrice * d.quantity }}</p>
              <p class="text-lg mt-2 mb-5">Price Limit : {{ d.supplierLimit }}</p>
            </div>
          </div>
          <div class="col-span-5">
            <div class="grid grid-cols-2 my-2">
              <div class="col-span-2 relative overflow-x-auto shadow-md sm:rounded-lg mx-5 min-h-4/5">
                <table class="w-full text-sm text-left text-gray-800">
                  <thead class="text-xs uppercase bg-gray-700">
                    <tr>
                      <th scope="col" class="md:pl-20 px-6 py-3 text-white">Supplier Name</th>
                      <th scope="col" class="px-6 py-3 text-white">Quated Price</th>
                      <th scope="col" class="px-6 py-3 text-white">Other Amount</th>
                      <th scope="col" class="px-6 py-3 text-white">Tax</th>
                      <th scope="col" class="px-6 py-3 text-white">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(b, i) in dataSupplier[index]" :key="i" class="border-b bg-white">
                      <td class="px-6 py-4 md:pl-20">{{ b.name }}</td>
                      <td class="px-6 py-4">{{ b.value }}</td>
                      <td class="px-6 py-4">{{ b.other }}</td>
                      <td class="px-6 py-4">{{ b.tax }}</td>
                      <td @change="Change(index, i)" class="px-6 py-4"><input type="checkbox" v-model="select[index][i]" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';
// import html2pdf from 'html2pdf-jspdf2';

export default {
  components: {},

  data() {
    return {
      date: new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 8),
      id: this.$route.params.id,
      dataS: [],
      dataStatus: [],
      dataStatus2: [],
      dataPurchase: [],
      dataPurchaseItem: [],
      dataSupplier: [],
      select: [],
    };
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      await api
        .get(`api/supplier/`)
        .then((res) => {
          this.dataS = res.data;
          //   console.table(this.dataS);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=com&statusCode=&statusName=entry`)
        .then((res) => {
          this.dataStatus = res.data;
          if (this.dataStatus == '') {
            alert('Status not Set');
          }
          // console.table(this.dataStatus);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=po&statusCode=&statusName=pending`)
        .then((res) => {
          this.dataStatus2 = res.data;
          if (this.dataStatus2 == '') {
            alert('Status not Set');
          }
          // console.table(this.dataStatus2);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/pruchase/${this.id}/`)
        .then((res) => {
          this.dataPurchase = res.data;
          //   console.table(this.dataPurchase);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/requested_item/?orderId=${this.id}&status=${this.dataStatus[0].id}`)
        .then((res) => {
          this.dataPurchaseItem = res.data;
          //   console.table(this.dataPurchaseItem);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        this.select[i] = [];
        this.dataSupplier[i] = [];
        // console.log(this.dataPurchaseItem[i].supplier);
        let a = this.dataPurchaseItem[i].supplier.split('|');
        let b = this.dataPurchaseItem[i].supplierValue.split('|');
        let c = this.dataPurchaseItem[i].supplierValueTax.split('|');
        let d = this.dataPurchaseItem[i].supplierValueOther.split('|');
        for (let j = 0; j < a.length; j++) {
          for (let k = 0; k < this.dataS.length; k++) {
            if (this.dataS[k].id == a[j]) {
              let t = {
                id: this.dataS[k].id,
                name: this.dataS[k].name,
              };
              this.dataSupplier[i].push(t);
            }
          }
        }

        for (let j = 0; j < this.dataSupplier[i].length; j++) {
          this.dataSupplier[i][j].value = b[j];
          this.dataSupplier[i][j].tax = c[j];
          this.dataSupplier[i][j].other = d[j];
          this.select[i][j] = false;
        }
      }
    },
    Print() {
      console.table(this.select);
    },
    Change(a, b) {
      for (let i = 0; i < this.select[a].length; i++) {
        if (b == i) {
          continue;
        }
        this.select[a][i] = false;
      }
    },
    async Save() {
      let dd = this.dataStatus2[0].id;
      let ee = this.dataStatus2[0].statusCode;
      let ff = this.dataStatus2[0].statusName;
      this.dataPurchase.status = dd;
      this.dataPurchase.statusC = ee;
      this.dataPurchase.statusName = ff;
      await api
        .put(`api/pruchase/${this.id}/`, this.dataPurchase)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        for (let j = 0; j < this.dataSupplier.length; j++) {
          if (this.select[i][j] == true) {
            this.dataPurchaseItem[i].supplierSelected = this.dataSupplier[i][j].id;
            this.dataPurchaseItem[i].supplierSelectedQuaote = this.dataSupplier[i][j].value;
            this.dataPurchaseItem[i].supplierSelectedtax = this.dataSupplier[i][j].tax;
            this.dataPurchaseItem[i].supplierSelectedOther = this.dataSupplier[i][j].other;
            this.dataPurchaseItem[i].status = dd;
            this.dataPurchaseItem[i].statusC = ee;
            this.dataPurchaseItem[i].statusName = ff;
            break;
          }
        }
        await api
          .put(`api/requested_item/${this.dataPurchaseItem[i].id}/`, this.dataPurchaseItem[i])
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>