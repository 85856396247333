<template>
  <div id="content" style="height: 100vh">
    <NavbarNotLoggedIn />
    <div class="container">
      <div class="row">
        <div class="card bg-light" style="width: 500px; height: 300px">
          <h1 class="" style="font-size: 20px; font-weight: 700"><p style="border-bottom: 1px solid #dbdbdb">Sign in</p></h1>
          <br />

          <input v-model="username" type="text" class="input mb-3 mt-4 mr-2" style="width: 200px" placeholder="username" required />

          <input v-model="password" type="password" class="input mt-4 mb-3" style="width: 200px" placeholder="Password" required />
          <br />

          <button class="button btn-lg btn-primary mt-4 mb-4" type="submit" @click="submit" style="background-color: #26ade4; color: white">Sign in</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import NavbarNotLoggedIn from '@/components/NavbarNotLoggedIn.vue';
import { toast } from 'bulma-toast';

export default {
  name: 'login',
  components: { NavbarNotLoggedIn },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async submit() {
      var loginForm = {
        username: this.username,
        password: this.password,
      };
      var jwtToken = '';
      await api
        .post('/auth/logins', loginForm, {
          withCredentials: true,
        })
        .then((res) => {
          jwtToken = res.data;

          if (res.status != 200) {
            toast({
              message: `User Credentials do not match`,
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            });
            this.username = '';
            this.password = '';
          }
        });
      console.log(jwtToken);

      api.defaults.headers.common['Authorization'] = `Bearer ${jwtToken.token}`;
      localStorage.setItem('jwt', jwtToken.token);
      localStorage.setItem('r_token', jwtToken.r_token);
      localStorage.setItem('username', jwtToken.username);

      //  alert(jwtToken.username)

      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style scoped>
.card {
  margin: 60px auto; /* Added */
  float: none; /* Added */

  padding: 40px;
}
</style>