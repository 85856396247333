<template>
  <!-- <div style="height:100vh" > -->
  <div class="back" v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover">
    <div class="" style="">
      <div class="columns" style="height: 100px">
        <div class="column is-12" style="height: 70px">
          <div class="box" style="font-size: 22px; font-weight: bold; height: 70px; width: 100%">
            <p style="float: left">Reservation</p>
            <button class="button" style="float: right; background-color: #099dd9; color: white; margin-top: -5px" @click.prevent="addReserve()">Add New</button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12" style="min-height: 80vh; max-height: 80vh">
          <div class="" style="height: 60vh; width: 80vw; margin: auto">
            <div class="columns">
              <div class="column is-12" style="border-bottom: 1px solid #e4e8ee">
                <div style="float: left">
                  <div class="field has-addons" style="margin-left: 0">
                    <p class="control">
                      <input class="input" style="width: 120px" type="text" placeholder="Last Name" @keypress.enter="getResTable()" v-model="searchName" />
                    </p>
                    <p class="control">
                      <input class="input" style="width: 145px" type="date" placeholder="Date" @keypress.enter="getResTable()" v-model="searchDateField" />
                    </p>
                    <p class="control">
                      <button class="button" style="color: white; border: 1px solid #0c9eda; background-color: #0c9eda" @click.prevent="getResTable()">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </p>
                    <template v-if="iconCancel == true">
                      <div class="cancelIcon" data-tooltip="Clear Search">
                        <i class="fa-solid fa-xmark" style="font-size: 30px; margin-left: 20px; margin-top: 0.5vh" @click.prevent="cancelSearch()"></i>
                        <!-- <img src="../assets/modify.png" alt="human" style="width:25px;" @click.prevent="iconModal = 'modInfo', rejectModal = true, modalClose= true"> -->
                      </div>
                    </template>
                  </div>
                </div>
                <div style="float: right">
                  <div class="field has-addons" style="margin-left: 0">
                    <p class="control">
                      <button
                        class="button"
                        @click.prevent="
                          dataStatus = 'Checked In';
                          getResTable();
                        "
                      >
                        Checked In
                      </button>
                    </p>
                    <p class="control">
                      <button
                        class="button"
                        @click.prevent="
                          dataStatus = 'Checked Out';
                          getResTable();
                        "
                      >
                        Checked Out
                      </button>
                    </p>
                    <p class="control">
                      <button
                        class="button"
                        @click.prevent="
                          dataStatus = 'Reserved';
                          getResTable();
                        "
                      >
                        Reserved
                      </button>
                    </p>
                    <p class="control">
                      <button
                        class="button"
                        @click.prevent="
                          dataStatus = 'Booked';
                          getResTable();
                        "
                      >
                        Booked
                      </button>
                    </p>
                    <p class="control">
                      <button
                        class="button"
                        @click.prevent="
                          dataStatus = '';
                          getResTable();
                        "
                      >
                        All
                      </button>
                    </p>
                  </div>
                </div>
                <!-- <button class="button is-info is-light" style="float:right; margin-left:0px; border-radius:0px; border:1px solid #0C9EDA;">Check In</button>
                  <button class="button is-info is-light" style="float:right; border-radius:0px; border: 1px solid #0C9EDA;">Check Out</button>
                  <div class="select" style="float:right;"><select v-model="statusVal" @change="filterRes()" style="border: 1px solid #0C9EDA;"><option value="" hidden>Status Type:</option><option v-for="filter in statusTypes" :key="filter.id">{{filter}}</option></select></div> -->
              </div>
            </div>
            <div>
              <table class="table is-fullwidth" style="max-width: 80vw">
                <thead>
                  <tr>
                    <th>Card No</th>
                    <th>Revs Id</th>
                    <th>Room</th>
                    <th>Name</th>
                    <th>CheckIn Date</th>
                    <th>CheckOut Date</th>
                    <th>Early CIn/COut Time</th>
                    <th>Secondary Rooms</th>

                    <th>Status</th>
                    <th>Details</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="d in tableData" v-bind:key="d.id">
                    <template v-if="d.is_primary_room == true">
                      <template v-if="d.status == 'Checked In' || d.status == 'reserved'"
                        ><td>{{ d.room_id }}{{ d.res_id.toString().padStart(5, '0') }}</td></template
                      >
                      <!-- <template v-if="d.status == 'Checked In' || d.status == 'reserved'"><td>{{d.room_id}}0000{{d.res_id}}</td></template> -->
                      <template v-else><td>N.A</td></template>
                      <td>{{ d.res_id }}</td>
                      <td>{{ d.room_id }}</td>
                      <td>{{ d.first_name }} {{ d.last_name }}</td>
                      <td>{{ d.check_in_date }}</td>
                      <td>{{ d.check_out_date }}</td>

                      <template v-if="d.check_in_time != null">
                        <td>{{ d.check_in_time }} / {{ d.check_out_time }}</td>
                      </template>
                      <template v-else>
                        <td>N.A</td>
                      </template>
                      <template v-if="d.secondary_room != '' && d.secondary_room != null">
                        <td>{{ d.secondary_room }}</td>
                      </template>
                      <template v-else>
                        <td>N.A</td>
                      </template>
                      <td>{{ d.status }}</td>
                      <td style="">
                        <router-link class="button" :to="{ name: 'ReservationEdit', params: { id: d.res_id } }" style="color: white; background-color: #099dd9; height: 30px">Show</router-link>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4"></div>
        <div class="column is-4" style="margin-left: 5vw">
          <div style="margin-left: 10px">
            <div v-if="showPrevButton">
              <button class="button" @click.prevent="showPrev()" style="float: left">Prev</button>
              <button class="button" @click.prevent="showNext()" style="float: left; margin-left: 10px" disabled>Next</button>
            </div>
            <div v-if="showNextButton" style="">
              <button class="button" @click.prevent="showPrev()" style="float: left" disabled>Prev</button>
              <button class="button" @click.prevent="showNext()" style="float: left; margin-left: 10px">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="rejectModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 85vw; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div>
              <div style="width: 83vw; height: 97vh; margin-top: -20px">
                <div class="tabs is-centered is-medium">
                  <ul>
                    <li v-bind:class="{ 'is-active': isActive == 'book' }"><a v-on:click="isActive = 'book'">Book Room</a></li>
                    <li v-bind:class="{ 'is-active': isActive == 'info' }"><a v-on:click="isActive = 'info'">Add Info</a></li>
                    <li v-bind:class="{ 'is-active': isActive == 'confirm' }"><a v-on:click="isActive = 'confirm'">Confirm</a></li>
                  </ul>
                </div>
                <div class="tab-contents">
                  <div class="content" v-bind:class="{ 'is-active': isActive == 'book' }">
                    <div style="width: 81vw; height: 75vh">
                      <div class="columns">
                        <div class="column is-12">
                          <div style="float: right; border: 1px solid #26ade4; margin-top: -10px">
                            <p style="margin: 5px 5px; color: #0c66a1; font-size: 18px">Booking Details: {{ roomCount }} room(s) | {{ adultsNum }} adult(s), {{ childNum }} child(s) | BDT {{ roomCost }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="columns">
                        <div class="column is-12">
                          <div class="field has-addons" style="margin-top: -15px; margin-bottom: -15px">
                            <p class="control">
                              <input class="input" style="width: 165px" type="date" placeholder="From" :min="checkMinDate()" v-model="co" />
                            </p>
                            <p class="control">
                              <input class="input" style="width: 165px" type="date" placeholder="To" :min="checkFromDate()" v-model="cd" />
                            </p>
                            <p class="control">
                              <button class="button" style="color: white; border: 1px solid #0c9eda; background-color: #0c9eda" @click.prevent="searchDate()">Search</button>
                            </p>
                          </div>
                          <div style="float: right; display: flex; margin-top: -35px">
                            <div class="box" style="height: 20px; background-color: #f6f7fb; border: 1px solid #d3d8ed">
                              <div style="margin-top: -10px; display: flex">
                                <p style="font-weight: bold">Rooms Added:</p>
                                <ul v-for="(room, index) in addRoomCount" :key="room.id">
                                  <li class="button" style="width: 20px; height: 20px; margin-top: -15px; color: white; background-color: #0c9eda" @click.prevent="cancelAdd(room, index)">{{ room.room_no }}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="background-color: #f6f7fb">
                        <div class="columns">
                          <div class="column is-12" style="background-color: #f6f7fb">
                            <div class="columns">
                              <div class="column is-3">
                                <div class="select is-fullwidth" style="">
                                  <select v-model="filterAdultSelection" @change="filterRooms()">
                                    <option value="" hidden><b>Adults :</b></option>
                                    <option v-for="filter in filterAdult" :key="filter.id">{{ filter }}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="column is-3" style="margin-left: -10px">
                                <div class="select is-fullwidth" style="">
                                  <select v-model="filterRoomTypeSelection" @change="filterRooms()">
                                    <option value="" hidden><b>Room Type:</b></option>
                                    <option v-for="filter in filterRoom" :key="filter.id">{{ filter }}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="column is-3" style="margin-left: -10px">
                                <div class="select is-fullwidth" style="">
                                  <select v-model="filterRoomRateSelection" @change="filterRooms()">
                                    <option value="" hidden><b>Room Rate:</b></option>
                                    <option v-for="filter in filterRoomRate" :key="filter.id">{{ filter }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns" style="background-color: #f6f7fb">
                          <div class="column is-12" style="overflow-y: scroll; height: 400px">
                            <template v-if="cd != '' && co != ''">
                              <table class="table is-fullwidth" style="">
                                <thead>
                                  <tr>
                                    <th>Room Type</th>
                                    <th>Room No.</th>
                                    <th>Room Rate</th>

                                    <th>Add</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr v-for="(d, index) in roomVacantDataTemp" v-bind:key="d.id" style="padding-bottom: 30px">
                                    <td>{{ d.type_name }}</td>

                                    <td v-for="b in roomBooked" :key="b.id">
                                      <template v-if="b.room != d.room_no">{{ d.room_no }}</template>
                                      <template v-else
                                        ><span style="color: red">B{{ d.room_no }}</span></template
                                      >
                                    </td>
                                    <template v-if="roomBooked.length == 0"
                                      ><td>{{ d.room_no }}</td></template
                                    >
                                    <td>{{ d.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                                    <template v-if="roomBooked.length == 0"
                                      ><td><button class="button" style="background-color: #26ade4; color: white" @click.prevent="addRoomNo(d, index)">Add</button></td></template
                                    >

                                    <td v-for="b in roomBooked" :key="b.id"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="addRoomNo(d, index)" v-if="b.room != d.room_no">Add</button><button class="button" v-else @click.prevent="cancelBooked(d, index)" style="background-color: #26ade4; color: white">Cancel Booked</button></td>

                                    <!-- <template v-if="d.status == 'Checked In'"><td>{{d.room_id}}{{d.res_id}}</td></template>
                                              <template v-else><td>N.A</td></template> -->
                                    <!-- <td style="">
                                                  <router-link class="button" :to="{ name: 'ReservationEdit', params: { id: d.res_id }}" style=" color:white; background-color: #099DD9; height:30px;">Show Details</router-link>
                                              </td>
                                          -->
                                  </tr>
                                </tbody>
                              </table>
                            </template>
                            <template v-else>
                              <p style="font-size: 20px; font-weight: bold">Enter Check In and Check Out Date to See Results</p>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-bind:class="{ 'is-active': isActive == 'info' }" style="padding-right: 5px">
                    <div style="">
                      <div class="">
                        <div class="columns" style="padding-right: 10px; height: 83vh">
                          <div class="column is-6">
                            <div class="box" style="height: 80vh; overflow-y: scroll">
                              <div class="field is-horizontal" style="margin-top: -20px">
                                <div class="field-label is-normal">
                                  <label class="label" style="float: left; color: #c2c2c2">Primary Guest</label>
                                  <button class="button" style="background-color: #26ade4; color: white; float: right; height: 30px; margin-right: -20px">Scan Documents</button>
                                </div>
                              </div>

                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">Phone:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control" style="">
                                      <!-- <input class="input" type="text" placeholder="Number" v-model="pHone" style="width:250px;float:left;" @keyup.prevent="showBox=true" @keyup.enter="showBox= !showBox"> -->
                                      <vue-tel-input
                                        defaultCountry="BD"
                                        v-model="phone"
                                        mode="international"
                                        @keyup.prevent="
                                          showBox = true;
                                          checkForNew();
                                        "
                                        @keyup.enter="showBox = !showBox"
                                        style="float: left"
                                      ></vue-tel-input>
                                    </p>
                                    <div v-if="showBox == true" class="box" style="width: 260px; height: 100px; overflow-y: scroll">
                                      <div v-for="r of resultQuery" :key="r.id" @click.prevent="fillInputs(r.id)">
                                        <div>{{ r.phone }} {{ r.first_name }} {{ r.last_name }}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">Name:</label>
                                </div>
                                <div class="select is-fullwidth" style="width: 110px; margin-right: 10px">
                                  <select v-model="genderSelection">
                                    <option value="" hidden><b>Gender</b></option>
                                    <option v-for="filter in genderTypes" :key="filter.id">{{ filter }}</option>
                                  </select>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control">
                                      <input class="input" type="text" placeholder="First Name" v-model="firstName" />
                                    </p>
                                  </div>
                                  <div class="field">
                                    <p class="control">
                                      <input class="input" type="text" placeholder="Last Name" v-model="lastName" />
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">Email:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control">
                                      <input class="input" type="text" placeholder="Email" v-model="email" style="width: 210px; float: left" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">Address:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control" style="width: 410px">
                                      <textarea class="textarea" placeholder="Address" v-model="address" style="width: 200px"></textarea>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">NID/DL:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control" style="">
                                      <input class="input" type="int" placeholder="Number" v-model="nidDl" style="width: 200px; float: left" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">Country:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control" style="">
                                      <input class="input" type="text" placeholder="Country Name" v-model="country" style="width: 200px; float: left" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">Nationality:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control" style="">
                                      <input class="input" type="text" placeholder="Nationality" v-model="nationality" style="width: 200px; float: left" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">Occupation:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control" style="">
                                      <input class="input" type="int" placeholder="Info" v-model="occupation" style="width: 200px; float: left" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label has-text-left" style="width: 110px; font-size: 18px">Passport No:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control" style="">
                                      <input class="input" type="int" placeholder="Number" v-model="passport" style="width: 200px; float: left" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left">Passport:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control">
                                      <input class="input" type="date" placeholder="Date Of Issue" v-model="doi" />
                                    </p>
                                  </div>
                                  <div class="field">
                                    <p style="font-size: 30px; color: #c2c2c2">/</p>
                                  </div>
                                  <div class="field">
                                    <p class="control">
                                      <input class="input" type="date" placeholder="Date Of Expiry" v-model="dof" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="field is-horizontal" style="">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; width: 85px; text-align: left">Visa Type:</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <p class="control" style="">
                                      <input class="input" type="int" placeholder="Type" v-model="visa" style="width: 200px; float: left" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="field is-horizontal">
                                <div class="field-label is-normal" style="">
                                  <label class="label" style="font-size: 18px; text-align: left; width: 50px">Visa:</label>
                                </div>
                                <div class="field-body" style="margin-left: -50px">
                                  <div class="field">
                                    <p class="control">
                                      <input class="input" type="date" placeholder="Date Of Issue" v-model="vdoi" />
                                    </p>
                                  </div>
                                  <div class="field">
                                    <p style="font-size: 30px; color: #c2c2c2">/</p>
                                  </div>
                                  <div class="field">
                                    <p class="control">
                                      <input class="input" type="date" placeholder="Date Of Expiry" v-model="vdof" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload" />
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="column is-12" style="margin-top: -13px">
                              <div class="box" style="height: 39vh">
                                <div></div>

                                <div class="field is-horizontal">
                                  <div class="field-label is-normal">
                                    <label class="label" style="font-size: 18px; width: 95px; text-align: left">No. Guest:</label>
                                  </div>
                                  <div class="field-body">
                                    <div class="field">
                                      <p class="control">
                                        <input class="input" type="text" placeholder="Number" v-model="guestNum" style="width: 200px; float: left" />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="field is-horizontal">
                                  <div class="field-label is-normal">
                                    <label class="label" style="font-size: 18px; width: 120px; text-align: left">Adults/Childs:</label>
                                  </div>
                                  <div class="field-body">
                                    <div class="field">
                                      <p class="control">
                                        <input class="input" type="text" placeholder="Adults" v-model="adultsNum" style="width: 180px; float: left" />
                                      </p>
                                    </div>
                                    <div class="field">
                                      <p class="control">
                                        <input class="input" type="text" placeholder="Child" v-model="childNum" style="width: 180px; float: left" />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="field is-horizontal">
                                  <div class="field-label is-normal">
                                    <label class="label" style="font-size: 18px; width: 125px; text-align: left">Early Check In:</label>
                                  </div>
                                  <div class="field-body">
                                    <div class="field">
                                      <p class="control">
                                        <input class="input" type="time" placeholder="Time" step="3600000" v-model="checkInTime" style="width: 200px; float: left" />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="field is-horizontal">
                                  <div class="field-label is-normal">
                                    <label class="label" style="font-size: 18px; width: 145px; text-align: left">Early Check Out:</label>
                                  </div>
                                  <div class="field-body">
                                    <div class="field">
                                      <p class="control">
                                        <input class="input" type="time" placeholder="Time" step="3600000" v-model="checkOutTime" style="width: 200px; float: left" />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="field is-horizontal">
                                  <div class="field-label is-normal">
                                    <label class="label" style="font-size: 18px; width: 135px; text-align: left">Extra Breakfast:</label>
                                  </div>
                                  <div class="field-body">
                                    <div class="field">
                                      <p class="control">
                                        <input class="input" type="text" placeholder="Number of extra breakfast" v-model="extraB" style="width: 230px; float: left" />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="column is-12">
                              <div class="box" style="height: 38vh">
                                <div id="form">
                                  <div class="columns">
                                    <div class="column is-6">
                                      <div class="field-label is-normal" style="float: left; margin-left: -20px">
                                        <label class="label" style="font-size: 20px; width: 160px">Add Guest Info:</label>
                                      </div>
                                    </div>
                                    <div class="column is-6" style="">
                                      <button class="button" style="width: 100px; height: 35px; float: right; background-color: #26ade4; color: white" @click="addItem">Add</button>
                                    </div>
                                  </div>
                                  <div style="height: 200px; overflow-y: scroll">
                                    <label class="label"></label>
                                    <input class="input" v-for="item in items" v-model="item.value" placeholder="Guest Name" :key="item.id" style="margin-top: 5px; width: 300px" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-bind:class="{ 'is-active': isActive == 'confirm' }">
                    <div style="width: 81vw; height: 80vh">
                      <div class="columns">
                        <div class="column is-12">
                          <div style="float: right; border: 1px solid #26ade4; margin-top: -10px">
                            <p style="margin: 5px 5px; color: #26ade4">Booking Details: {{ roomCount }} room(s) | {{ adultsNum }} adult(s), {{ childNum }} child(s) | BDT {{ roomCost }}</p>
                          </div>
                          <div style="float: left; display: flex; margin-top: -11px">
                            <div class="" style="height: 35px; border: 1px solid #d3d8ed">
                              <div style="display: flex; margin: 5px 15px 0 10px">
                                <p style="font-weight: bold">Rooms Selected:</p>
                                <ul v-for="room in addRoomCount" :key="room.id">
                                  <li class="button" style="width: 20px; height: 20px; margin-top: -15px; color: white; background-color: #0c9eda">{{ room.room_no }}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="columns" style="height: 67vh">
                        <div class="column is-6">
                          <div class="box" style="height: 67vh">
                            <div class="columns">
                              <div class="column is-8">
                                <p style="float: left; font-size: 19px">From: {{ co }}</p>
                                <p style="float: left; margin-left: 40px; font-size: 19px">To: {{ cd }}</p>
                              </div>
                              <div class="column is-2">
                                <p style="font-size: 19px">Days:{{ dayCount }}</p>
                              </div>
                            </div>

                            <div style="text-align: left; margin-top: -20px">
                              <p style="font-size: 17px">No. of Guests: {{ guestNum }}</p>
                              <hr />
                              <p style="font-size: 20px; font-weight: bold">Primary Guest: {{ genderSelection }} {{ firstName }} {{ lastName }}</p>

                              <p style="font-size: 17px">Phone: {{ phone }}</p>
                              <p style="font-size: 17px">Address: {{ address }}</p>
                              <p style="font-size: 17px">Country: {{ country }}</p>
                              <p style="font-size: 17px">Additonal Guests Info:</p>
                            </div>
                            <div class="field is-horizontal" style="margin-top: 10px">
                              <div class="field-label is-normal" style="">
                                <label class="label" style="font-size: 18px; text-align: left; font-weight: normal">Special Instruction:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control" style="width: 410px">
                                    <textarea class="textarea" placeholder="Instruction" v-model="specialIns" style="width: 200px"></textarea>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div class="box" style="height: 67vh">
                            <div style="height: 50vh">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Rooms</th>
                                    <th>Type</th>
                                    <th>Qnty</th>
                                    <th>Cost</th>
                                    <th>Discount</th>
                                    <th>Total Cost</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="cost in addRoomCount" :key="cost.id">
                                    <th>{{ cost.room_no }}</th>
                                    <th>{{ cost.type_name }}</th>
                                    <th>{{ dayCount }} day(s)</th>
                                    <th>{{ cost.cost }}</th>
                                    <th>0(%)</th>
                                    <th>{{ cost.cost * dayCount }}</th>
                                  </tr>
                                </tbody>
                              </table>
                              <hr style="margin-top: -10px" />
                              <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Sub Total: {{ roomCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                              <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">VAT:{{ this.companyInfo.tax }}%): {{ (roomCost * (this.companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                              <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Service Charge:{{ this.companyInfo.service_charge }}%): {{ (roomCost * (this.companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                              <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Special Discount: {{ amountDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                              <hr />
                              <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-weight: bold; font-size: 18px">Grand Total: {{ (totalCalculated - amountDiscount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                            </div>

                            <template v-if="addRoomCount.length != 0 && phone != '' && firstName != '' && lastName != '' && genderSelection != '' && email != '' && address != '' && guestNum != ''">
                              <button class="button" style="margin-top: 15px; width: 250px; background-color: #26ade4; color: white" @click.prevent="payAdvance()">Pay Advance</button>
                            </template>
                            <template v-else>
                              <button disabled class="button" style="margin-top: 15px; width: 250px; background-color: #26ade4; color: white" @click.prevent="payAdvance()">Pay Advance</button>
                            </template>

                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 10px">
                  <button
                    class="button"
                    @click.prevent="
                      rejectModal = false;
                      co = '';
                      cd = '';
                      firstName = '';
                      lastName = '';
                      email = '';
                      address = '';
                      nidDl = '';
                      passport = '';
                      doi = '';
                      dof = '';
                      occupation = '';
                      adultsNum = '';
                      childNum = '';
                      guestNum = '';
                    "
                    style="margin-right: 5px"
                  >
                    Cancel
                  </button>

                  <!-- working here -->
                  <template v-if="addRoomCount.length != 0 && phone != '' && firstName != '' && lastName != '' && genderSelection != '' && email != '' && address != '' && guestNum != ''">
                    <button class="button" @click.prevent="submitGuestInfo()" style="background-color: #26ade4; color: white">Submit</button>
                  </template>
                  <template v-else>
                    <button disabled class="button" @click.prevent="submitGuestInfo()" style="background-color: #26ade4; color: white">Submit</button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div v-if="advanceScreen == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="advanceScreen = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
            <div class="tabs is-centered is-medium">
              <ul>
                <li v-bind:class="{ 'is-active': isA == 'cash' }"><a v-on:click="(isA = 'cash'), (trxType = 'cash')">Cash</a></li>
                <li v-bind:class="{ 'is-active': isA == 'card' }"><a v-on:click="(isA = 'card'), (trxType = 'card')">Card</a></li>
                <li v-bind:class="{ 'is-active': isA == 'bkash' }"><a v-on:click="(isA = 'bkash'), (trxType = 'bkash')">bkash</a></li>
              </ul>
            </div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'cash' }" style="height: 80vh">
                <div style="overflow-y: auto; max-height: 20vh">
                  <table style="overflow-y: scroll">
                    <thead>
                      <tr>
                        <th>Rooms</th>
                        <th>Type</th>
                        <th>Qnty</th>
                        <th>Cost</th>
                        <th>Discount</th>
                        <th>Total Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cost in addRoomCount" :key="cost.id">
                        <th>{{ cost.room_no }}</th>
                        <th>{{ cost.type_name }}</th>
                        <th>{{ dayCount }} day(s)</th>
                        <th>{{ cost.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                        <th>0(%)</th>
                        <th>{{ (cost.cost * dayCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Sub Total: {{ roomCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">VAT({{ this.companyInfo.tax }}%): {{ (roomCost * (this.companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Service Charge({{ this.companyInfo.service_charge }}%): {{ (roomCost * (this.companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Special Discount: {{ amountDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-weight: bold; font-size: 18px">Grand Total: {{ (totalCalculated - amountDiscount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <div style="margin-left: 8vw">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'card' }" style="height: 80vh">
                <div style="overflow-y: auto; max-height: 20vh">
                  <table>
                    <thead>
                      <tr>
                        <th>Rooms</th>
                        <th>Type</th>
                        <th>Qnty</th>
                        <th>Cost</th>
                        <th>Discount</th>
                        <th>Total Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cost in addRoomCount" :key="cost.id">
                        <th>{{ cost.room_no }}</th>
                        <th>{{ cost.type_name }}</th>
                        <th>{{ dayCount }} day(s)</th>
                        <th>{{ cost.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                        <th>0(%)</th>
                        <th>{{ (cost.cost * dayCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Sub Total: {{ roomCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">VAT({{ this.companyInfo.tax }}%): {{ (roomCost * (this.companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Service Charge({{ this.companyInfo.service_charge }}%): {{ (roomCost * (this.companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Special Discount: {{ amountDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-weight: bold; font-size: 18px">Grand Total: {{ (totalCalculated - amountDiscount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <div style="margin-left: 6vw">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Card no:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Discount amount" v-model="trans_id" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Date of expiry:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="Discount amount" v-model="doe_card" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'bkash' }" style="height: 80vh">
                <div style="overflow-y: auto; max-height: 20vh">
                  <table>
                    <thead>
                      <tr>
                        <th>Rooms</th>
                        <th>Type</th>
                        <th>Qnty</th>
                        <th>Cost</th>
                        <th>Discount</th>
                        <th>Total Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cost in addRoomCount" :key="cost.id">
                        <th>{{ cost.room_no }}</th>
                        <th>{{ cost.type_name }}</th>
                        <th>{{ dayCount }} day(s)</th>
                        <th>{{ cost.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                        <th>0(%)</th>
                        <th>{{ (cost.cost * dayCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Sub Total: {{ roomCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">VAT({{ this.companyInfo.tax }}%): {{ (roomCost * (this.companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Service Charge({{ this.companyInfo.service_charge }}%): {{ (roomCost * (this.companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Special Discount: {{ amountDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-weight: bold; font-size: 18px">Grand Total: {{ (totalCalculated - amountDiscount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <div style="margin-left: 8vw">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Transaction id:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Discount amount" v-model="trans_id" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button class="button" @click.prevent="advanceScreen = false" style="margin-right: 5px">Cancel</button>
            <button class="button" @click.prevent="submitPay()" style="background-color: #26ade4; color: white">Pay & Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from 'bulma-toast';
import api from '../boot/axios';
import axios from '../boot/axios';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default {
  components: {
    VueTelInput,
  },
  data() {
    return {
      searchName: '',
      searchDateField: '',
      tableData: [],
      statusTypes: ['Booked', 'Reserved', 'Checked In', 'Checked Out'],
      statusVal: '',
      rejectModal: false,
      isActive: 'book',
      isA: 'cash',
      roomData: [],
      resData: [],
      b1: false,
      b2: false,
      b3: false,
      b4: false,
      b5: false,
      roomVacantData: [],
      filterRoomTypeSelection: '',
      filterMealSelection: '',
      filterRoomRateSelection: '',
      filterAdultSelection: '',
      filterAdult: ['All', 1, 2, 3, 4, 5],
      filterRoom: ['All', 'Single', 'Double', 'Delux'],
      filterRoomRate: ['All', '5000.00', '10000.00', '12000.00'],
      filterMeal: ['All', 'Breakfast'],
      cd: '',
      co: '',
      addRoomCount: [],
      roomCount: 0,
      roomCost: 0,
      roomBooked: [],
      roomVacantDataTemp: [],
      extraGuest: [],
      items: [
        {
          value: '',
        },
      ],
      fieldid: 1,
      arrayCount: 0,
      genderTypes: ['Mr.', 'Ms.', 'Mrs.'],
      genderSelection: '',
      phone: '',
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      nidDl: '',
      passport: '',
      doi: '',
      dof: '',
      occupation: '',
      guestNum: '',
      checkInTime: null,
      checkOutTime: null,
      extraB: null,
      country: '',
      nationality: '',
      visa: '',
      vdoi: null,
      vdof: null,
      conDateS: '',
      conDateE: '',
      dayCount: '',
      custId: '',
      specialIns: '',
      adultsNum: 0,
      childNum: 0,
      pageNum: 1,
      showNextButton: false,
      showPrevButton: false,
      customerData: [],
      showBox: false,
      customerFillData: '',
      lastResData: '',
      roomsConcated: '',
      advanceScreen: false,
      amount_credited: '',
      amountDiscount: 0,
      payNow: false,
      discountD: 0,
      trans_id: '',
      doe_card: null,
      trxType: 'cash',
      totalAmount: 0,
      due_amount: 0,
      lastCardData: '',
      cardString: '',
      specialDiscountReason: '',
      companyInfo: '',
      userInfo: '',
      vatCalculated: 0,
      scCalculated: 0,
      totalCalculated: 0,
      allCustData: [],
      compareCustCount: 0,
      compareCustData: '',
      minDate: '',
      minFromDate: '',
      iconCancel: false,
      dataR: [],
      dataStatus: 'Checked In',
      cancelBookedArray: [],
      pic: null,
    };
  },
  watch: {
    roomVacantData: function () {
      console.log(this.roomVacantData);
    },
  },
  async created() {
    await this.getUserInfo();
    await this.getResTable();
    await this.getCustomerData();
    await this.getPic();
  },
  computed: {
    resultQuery() {
      if (this.phone) {
        return this.customerData.filter((item) => {
          console.log(item.phone);
          return this.phone
            .toString()
            .split(' ')
            .every((v) => item.phone.toString().includes(v));
        });
      } else {
        return this.customerData.last_name + this.customerData.first_name;
      }
    },
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back01;
        })
        .catch((err) => console.log(err));
    },
    cancelSearch() {
      this.iconCancel = false;
      this.searchName = '';
      this.searchDateField = '';
      this.getResTable();
    },
    checkForNew() {
      if (this.resultQuery.length == 0) {
        this.showBox = false;
      }
    },
    async filterReservation() {
      await this.getResTable();
    },
    OnFileUpload(event) {
      this.pot = event.target.files[0];
      console.log(event);
    },
    async uploadImageHandler() {
      const fd = new FormData();

      //call cuspic here with cus id

      fd.append('pic', this.pot);
      fd.append('cust_id', this.custId);
      console.log(this.pot);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (this.pot != undefined) {
        await api.post(`api/cuspic/`, fd, config).then((res) => {
          console.log('pic upload: ', res.data);
        });
      }
    },
    checkMinDate() {
      this.minDate = new Date().toISOString().split('T')[0];
      return this.minDate;
    },
    checkFromDate() {
      this.minFromDate = this.co;
      return this.minFromDate;
    },
    async getUserInfo() {
      // await axios.get('/auth/currentuser/'
      //     ).then(res =>{
      //         console.log(res.data.role)
      //         this.userInfo = res.data.company
      //     }).catch(err => console.log(err))
      console.log(this.userInfo);

      await axios
        .get(`/auth/company/1/`)
        .then((res) => {
          this.companyInfo = res.data;
        })
        .catch((err) => console.log(err));
      console.log(this.companyInfo);
    },
    async getResTable() {
      if (this.searchName == '' && this.searchDateField == '' && this.dataStatus != '') {
        await api
          .get(`api/res_cus_status/?status=${this.dataStatus}`)
          .then((res) => {
            this.showNextButton = false;
            this.showPrevButton = false;

            if (res.data.next) {
              this.showNextButton = true;
            }

            if (res.data.previous) {
              this.showPrevButton = true;
            }
            this.tableData = res.data.results;
          })
          .catch((err) => console.log(err));
      }

      if (this.searchName == '' && this.searchDateField == '' && this.dataStatus == '') {
        console.log('search empty');
        await api
          .get(`api/res_cus/?page=${this.pageNum}`)
          .then((res) => {
            this.showNextButton = false;
            this.showPrevButton = false;

            if (res.data.next) {
              this.showNextButton = true;
            }

            if (res.data.previous) {
              this.showPrevButton = true;
            }
            this.tableData = res.data.results;
          })
          .catch((err) => console.log(err));
      }
      if (this.searchName != '' && this.searchDateField == '') {
        this.iconCancel = true;
        await api
          .get(`api/res_cus/?search=${this.searchName}&?page=${this.pageNum}`)
          .then((res) => {
            this.showNextButton = false;
            this.showPrevButton = false;

            if (res.data.next) {
              this.showNextButton = true;
            }

            if (res.data.previous) {
              this.showPrevButton = true;
            }
            this.tableData = res.data.results;
            console.log(res.data.results);
          })
          .catch((err) => console.log(err));
      }
      if (this.searchName == '' && this.searchDateField != '') {
        this.iconCancel = true;
        await api
          .get(`api/res_cus/?search=${this.searchDateField}&?page=${this.pageNum}`)
          .then((res) => {
            this.showNextButton = false;
            this.showPrevButton = false;

            if (res.data.next) {
              this.showNextButton = true;
            }

            if (res.data.previous) {
              this.showPrevButton = true;
            }
            this.tableData = res.data.results;
            console.log(res.data.results);
          })
          .catch((err) => console.log(err));
      }

      await api
        .get('api/rmrt/')
        .then((res) => {
          this.roomData = res.data;
          //console.log("room",this.roomData)
        })
        .catch((err) => console.log(err));

      await api
        .get('api/reservation/')
        .then((res) => {
          this.resData = res.data;
          //console.log("res",this.resData)
        })
        .catch((err) => console.log(err));
    },
    showNext() {
      this.pageNum += 1;
      this.getResTable();
    },
    showPrev() {
      this.pageNum -= 1;
      this.getResTable();
    },
    async getCustomerData() {
      await api
        .get('api/customer/')
        .then((res) => {
          this.customerData = res.data;
        })
        .catch((err) => console.log(err));
      console.log('customer data', this.customerData);
    },
    async fillInputs(id) {
      console.log('hello');
      this.showBox = false;
      await api
        .get(`api/customer/${id}/`)
        .then((res) => {
          this.customerFillData = res.data;
        })
        .catch((err) => console.log(err));
      console.log('customer data', this.customerFillData);

      this.firstName = this.customerFillData.first_name;
      this.lastName = this.customerFillData.last_name;
      this.email = this.customerFillData.email;
      this.address = this.customerFillData.address;
      this.phone = this.customerFillData.phone;
      this.genderSelection = this.customerFillData.gender;
      this.nidDl = this.customerFillData.nid;
      this.country = this.customerFillData.country;
      this.nationality = this.customerFillData.nationality;
      this.occupation = this.customerFillData.occupation;
      this.passport = this.customerFillData.passport;
      this.dof = this.customerFillData.passport_exp;
      this.visa = this.customerFillData.visa_type;
      this.vdoi = this.customerFillData.visa_issue;
      this.vdof = this.customerFillData.visa_expiry;
    },
    async addReserve() {
      this.rejectModal = true;
      //console.log("helo")

      // this.roomData.forEach(element => {
      //   this.resData.forEach(item =>{
      //     if(this.resData[item.room] == this.roomData[element.room_no]){
      //       console.log("true")
      //     }
      //   })
      // });

      //console.log("room ",this.roomVacantData)
    },
    async searchDate() {
      this.roomVacantData = [];
      this.roomVacantDataTemp = [];
      this.roomBooked = [];
      this.roomData = [];
      this.resData = [];

      await api
        .get('api/rmrt/')
        .then((res) => {
          this.roomData = res.data;
          //console.log("room",this.roomData)
        })
        .catch((err) => console.log(err));

      await api
        .get('api/reservation/')
        .then((res) => {
          this.resData = res.data;
          //console.log("res",this.resData)
        })
        .catch((err) => console.log(err));

      let varr = [];
      for (let i = 0; i < this.resData.length; i++) {
        const a = {
          room: parseInt(this.resData[i].room),
          check_in_date: this.resData[i].check_in_date,
          check_out_date: this.resData[i].check_out_date,
          status: this.resData[i].status,
        };
        varr.push(a);
        if (this.resData[i].secondary_room != '' && this.resData[i].secondary_room != null) {
          let b = this.resData[i].secondary_room.split(',');
          for (let j = 0; j < b.length; j++) {
            const c = {
              room: parseInt(b[j]),
              check_in_date: this.resData[i].check_in_date,
              check_out_date: this.resData[i].check_out_date,
              status: this.resData[i].status,
            };
            varr.push(c);
          }
        }
      }
      this.resData = varr;

      if (this.cd != '' && this.co != '') {
        for (let i = 0; i < this.roomData.length; i++) {
          this.b1 = false;
          this.b2 = false;
          this.b3 = false;
          this.b4 = false;
          this.b5 = false;

          let b11 = false;
          let b22 = false;
          let b33 = false;

          for (let a = 0; a < this.resData.length; a++) {
            if (this.resData[a].status == 'booked') {
              if (this.co >= this.resData[a].check_in_date && this.co < this.resData[a].check_out_date) {
                b22 = true;
              }
              if (this.cd > this.resData[a].check_in_date && this.cd <= this.resData[a].check_out_date) {
                b11 = true;
              }
              if (this.co < this.resData[a].check_in_date && this.cd > this.resData[a].check_out_date) {
                b33 = true;
              }
            }
            if (b11 == true || b22 == true || b33 == true) {
              if (!this.roomBooked.includes(this.resData[a])) {
                this.roomBooked.push(this.resData[a]);
              }
            }
            b11 = false;
            b22 = false;
            b33 = false;

            if (this.roomData[i].room_no == this.resData[a].room && this.resData[a].status != 'booked') {
              if (this.resData[a].status == 'OnHold') {
                this.b5 = true;
                break;
              }
              if (this.cd > this.resData[a].check_in_date && this.cd < this.resData[a].check_out_date) {
                // console.log(true)
                this.b1 = true;
                break;
              }
              if (this.co > this.resData[a].check_in_date && this.co < this.resData[a].check_out_date) {
                //console.log(true)
                this.b2 = true;
                break;
              }
              if (this.resData[a].check_in_date > this.co && this.resData[a].check_in_date < this.cd) {
                // console.log(true)
                this.b3 = true;
                break;
              }
              if (this.resData[a].check_out_date > this.co && this.resData[a].check_out_date < this.cd) {
                //console.log(true)
                this.b4 = true;
                break;
              }
              if (this.resData[a].check_in_date == this.co && this.resData[a].check_out_date == this.cd) {
                this.b1 = true;
                break;
              }
            }
          }
          if (this.b1 == false && this.b2 == false && this.b3 == false && this.b4 == false && this.b5 == false) {
            this.roomVacantData.push(this.roomData[i]);
          }
        }
        this.roomVacantDataTemp = this.roomVacantData;
        console.log('temp', this.roomVacantDataTemp);
      }

      // console.log("presort",this.roomVacantData)
      // this.roomVacantData.sort()
      // console.log("postsort",this.roomVacantData)
      this.conDateS = new Date(this.co);
      this.conDateE = new Date(this.cd);
      this.dayCount = (this.conDateE - this.conDateS) / (1000 * 3600 * 24);
      console.log('yolo', this.roomBooked);
    },
    async addRoomNo(room, id) {
      this.discountD += parseInt(room.discount);
      this.roomCost += parseInt(room.cost * this.dayCount);
      this.vatCalculated = parseInt(this.roomCost * (this.companyInfo.tax / 100));
      console.log('vat', this.vatCalculated);
      this.scCalculated = parseInt(this.roomCost * (this.companyInfo.service_charge / 100));
      console.log('sc', this.scCalculated);
      this.totalCalculated = this.roomCost + this.vatCalculated + this.scCalculated;
      console.log(this.totalCalculated);
      this.addRoomCount.push(room);
      //this.roomVacantData.pop(room)
      //console.log("pre",this.roomVacantData)
      //console.log(id)
      this.roomVacantData.splice(id, 1);

      console.log(room);

      await api
        .post(`api/customer/`, {
          phone: '1111',
        })
        .catch((err) => console.log(err));

      await api
        .get('api/lastcustomerinfo/')
        .then((res) => {
          this.custId = res.data.id;
        })
        .catch((err) => console.log(err));

      await api
        .post(`api/reservation/`, {
          check_in_date: this.co,
          cust_id: this.custId,
          room: room.room_no,
          source: 'FrontDesk',
          status: 'OnHold',
          check_out_date: this.cd,
          no_guest: '1',
        })
        .catch((err) => console.log(err));

      console.log(this.addRoomCount);
      //console.log(this.roomVacantData)
      this.roomCount++;
    },
    async cancelAdd(room, id) {
      let deleteInfo = [];
      this.addRoomCount.splice(id, 1);
      this.roomVacantData.push(room);
      this.roomCount--;
      this.roomCost -= parseInt(room.cost * this.dayCount);
      this.discountD -= parseInt(room.discount);
      this.vatCalculated -= parseInt(this.roomCost * (this.companyInfo.tax / 100));
      this.scCalculated -= parseInt(this.roomCost * (this.companyInfo.service_charge / 100));
      this.totalCalculated -= this.roomCost + this.vatCalculated + this.scCalculated;

      await api
        .get(`api/reservation/?=${room.room_no}&status=OnHold`)
        .then((res) => {
          deleteInfo = res.data;
        })
        .catch((err) => console.log(err));

      console.log(deleteInfo);
      for (let i = 0; i < deleteInfo.length; i++) {
        if (deleteInfo[i].room == room.room_no) {
          console.log('deleinfo', deleteInfo[i].room);

          await api.delete(`api/reservation/${deleteInfo[i].id}/`).catch((err) => console.log(err));
        }
      }

      await this.searchDate();
    },
    async cancelBooked(room, id) {
      console.log(room, id);
      console.log(this.roomBooked);
      await api.get(`api/reservation/?room=${room.room_no}&primary_room_no=&status=booked&bill_cleared=`).then((res) => {
        this.cancelBookedArray = res.data;
      });
      console.log(this.cancelBookedArray);

      await api.delete(`api/reservation/${this.cancelBookedArray[0].id}/`).catch((err) => console.log(err));
      this.roomBooked = this.roomBooked.filter((a) => a.room != room.room_no);
      console.log(this.roomBooked);
      this.roomBooked = ['1'];

      /* post data to be done */
    },
    addItem() {
      if (this.arrayCount < 3) {
        this.items.push({
          fieldid: this.fieldid++,
          value: '',
        });
      }
      this.arrayCount++;
    },
    filterRooms() {
      /* Search Filter For Rooms */
      let roomTempData = this.roomVacantData;
      this.roomVacantDataTemp = this.roomVacantData;
      console.log('Room Data', this.roomVacantDataTemp);
      console.log(this.filterRoomTypeSelection);
      console.log(this.filterMealSelection);
      console.log(this.filterRoomRateSelection);
      console.log(this.filterAdultSelection);
      //this.roomVacantDataTemp = this.roomVacantDataTemp.filter(room=> room.type_name == this.filterRoomTypeSelection)
      //this.roomVacantDataTemp = this.roomVacantDataTemp.filter(room=> room.cost == this.filterRoomRateSelection)

      if (this.filterRoomTypeSelection != 'All' && this.filterRoomRateSelection != 'All' && this.filterAdultSelection != 'All' && this.filterMealSelection != 'All') {
        if (this.filterRoomTypeSelection != '' && this.filterRoomRateSelection == '' && this.filterAdultSelection == '' && this.filterMealSelection == '') {
          this.roomVacantDataTemp = this.roomVacantDataTemp.filter((room) => room.type_name == this.filterRoomTypeSelection);
        } else if (this.filterRoomRateSelection != '' && this.filterRoomTypeSelection == '' && this.filterAdultSelection == '' && this.filterMealSelection == '') {
          this.roomVacantDataTemp = this.roomVacantDataTemp.filter((room) => room.cost == this.filterRoomRateSelection);
        } else if (this.filterAdultSelection != '' && this.filterRoomRateSelection == '' && this.filterRoomTypeSelection == '' && this.filterMealSelection == '') {
          this.roomVacantDataTemp = this.roomVacantDataTemp.filter((room) => room.adults == this.filterAdultSelection);
        } else if (this.filterMealSelection != '' && this.filterAdultSelection == '' && this.filterRoomRateSelection == '' && this.filterRoomTypeSelection == '') {
          this.roomVacantDataTemp = this.roomVacantDataTemp.filter((room) => room.breakfast == true);
          /* need to implement rest of Meal filter*/
        } else if (this.filterAdultSelection == '' && this.filterRoomRateSelection != '' && this.filterRoomTypeSelection != '') {
          this.roomVacantDataTemp = roomTempData.filter((room) => room.type_name == this.filterRoomTypeSelection && room.cost == this.filterRoomRateSelection);
        } else if (this.filterAdultSelection != '' && this.filterRoomRateSelection != '' && this.filterRoomTypeSelection == '') {
          this.roomVacantDataTemp = roomTempData.filter((room) => room.adults == this.filterAdultSelection && room.cost == this.filterRoomRateSelection);
        } else if (this.filterAdultSelection != '' && this.filterRoomRateSelection == '' && this.filterRoomTypeSelection != '') {
          this.roomVacantDataTemp = roomTempData.filter((room) => room.adults == this.filterAdultSelection && room.type_name == this.filterRoomTypeSelection);
        } else {
          this.roomVacantDataTemp = roomTempData.filter((room) => room.adults == this.filterAdultSelection && room.type_name == this.filterRoomTypeSelection && room.cost == this.filterRoomRateSelection);
        }
      } else {
        this.roomVacantDataTemp = this.roomVacantData;
        this.filterRoomTypeSelection = '';
        this.filterRoomRateSelection = '';
        this.filterAdultSelection = '';
        this.filterMealSelection = '';
      }

      // if(this.filterSelection != 'all' && this.filterRoomSelection != 'all'){

      //   if(this.filterRoomTypeSelection != '' && this.filterMealSelection == ''){

      //     this.roomView = this.roomData.filter(room=> room.room_type == this.filterRoomSelection)
      //   } else if(this.filterSelection != '' && this.filterRoomSelection == ''){

      //     this.roomView = this.roomData.filter(room=> room.status_fd == this.filterSelection)
      //   }else{

      //     this.roomView = this.roomData.filter(room => room.status_fd == this.filterSelection && room.room_type == this.filterRoomSelection)
      //   }

      // }else{
      //     this.roomView = this.roomData
      //     this.filterSelection = ''
      //     this.filterRoomSelection = ''
      // }
    },

    async submitGuestInfo() {
      await api
        .get('api/customer/')
        .then((res) => {
          this.allCustData = res.data;
        })
        .catch((err) => console.log(err));

      for (let i = 0; i < this.allCustData.length; i++) {
        if (this.allCustData[i].phone == this.phone) {
          this.compareCustCount++;
          this.compareCustData = this.allCustData[i];
        }
      }
      console.log(this.compareCustCount);
      console.log(this.compareCustData);
      console.log('hello');

      if (this.compareCustCount > 0) {
        this.custId = this.compareCustData.id;
        console.log('hi');
      } else {
        console.log('low');
        await api
          .post('api/customer/', {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            address: this.address,
            phone: this.phone,
            gender: this.genderSelection,
            nid: this.nidDl,
            country: this.country,
            nationality: this.nationality,
            occupation: this.occupation,
            passport: this.passport,
            passport_exp: this.dof,
            visa_type: this.visa,
            visa_issue: this.vdoi,
            visa_expiry: this.vdof,
          })
          .catch((err) => {
            console.log(err);
          });

        await api
          .get('api/lastcustomerinfo/')
          .then((res) => {
            this.custId = res.data.id;
          })
          .catch((err) => console.log(err));

        await this.uploadImageHandler();
      }

      //    await api.post('customer/',{
      //       first_name: this.firstName,
      //       last_name: this.lastName,
      //       email: this.email,
      //       address: this.address,
      //       phone: this.phone,
      //       gender: this.genderSelection,
      //       nid: this.nidDl,
      //       country: this.country,
      //       nationality: this.nationality,
      //       occupation: this.occupation,
      //       passport: this.passport,
      //       passport_exp: this.dof,
      //       visa_type: this.visa,
      //       visa_issue: this.vdoi,
      //       visa_expiry: this.vdof

      //       }).catch(err => {
      //         console.log(err)
      //         })
      // // for(let i=0; i<this.addRoomCount.length; i++){
      // //     console.log("room yo",this.addRoomCount[i])
      // //   }
      // await api.get('lastcustomerinfo/',
      //     ).then(res =>{
      //       this.custId = res.data.id
      //     }).catch(err =>console.log(err))

      console.log('hi d', this.custId);
      await this.postRes();
    },
    async postRes() {
      console.log('roomcount', this.addRoomCount);

      let roomDeleteArray = [];

      for (let i = 0; i < this.addRoomCount.length; i++) {
        await api
          .get(`api/reservation/?=${this.addRoomCount[i].room_no}&status=OnHold`)
          .then((res) => {
            roomDeleteArray.push(res.data[i]);
          })
          .catch((err) => console.log(err));
      }
      console.log(roomDeleteArray);

      if (roomDeleteArray.length != 0) {
        for (let i = 0; i < roomDeleteArray.length; i++) {
          await api.delete(`api/reservation/${roomDeleteArray[i].id}/`).catch((err) => console.log(err));
        }
      }

      let tempSplit = [];
      console.log(this.addRoomCount.length);
      if (this.payNow == false) {
        if (this.addRoomCount.length > 1) {
          console.log(this.addRoomCount);
          console.log('hello');
          for (let i = 1; i < this.addRoomCount.length; i++) {
            this.roomsConcated += this.addRoomCount[i].room_no + ',';
          }
          console.log(this.roomsConcated);
          //this.roomsConcated = this.roomsConcated.slice(0,-1)
          console.log(this.roomsConcated);
          tempSplit = this.roomsConcated.split(',');
          tempSplit.pop();

          console.log(tempSplit);
          //this.roomsConcated = this.roomsConcated.slice(0,-1)

          await await api
            .post('api/reservation/', {
              check_in_date: this.co,
              check_in_time: this.checkInTime,
              cust_id: this.custId,
              room: this.addRoomCount[0].room_no,
              source: 'FrontDesk',
              status: 'booked',
              check_out_date: this.cd,
              check_out_time: this.checkOutTime,
              no_guest: this.guestNum,
              special_ins: this.specialIns,
              extra_breakfast: this.extraB,
              No_days: this.dayCount,
              adults: this.adultsNum,
              childs: this.childNum,
              secondary_room: this.roomsConcated.slice(0, -1),
              is_primary_room: true,
              previous_discounts: 0,
            })
            .then(
              toast({
                message: 'Reservation has been added',
                type: 'is-info',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })
            )
            .catch((err) => console.log(err));

          // for(let i=1; i < this.addRoomCount.length; i++){
          //   await api.post("reservation/",{
          //   check_in_date: this.co,
          //   check_in_time: this.checkInTime,
          //   cust_id: this.custId,
          //   room: this.addRoomCount[i].room_no,
          //   source: "FrontDesk",
          //   status: "booked",
          //   check_out_date: this.cd,
          //   check_out_time: this.checkOutTime,
          //   no_guest: this.guestNum,
          //   special_ins: this.specialIns,
          //   extra_breakfast: this.extraB,
          //   No_days: this.dayCount,
          //   adults: this.adultsNum,
          //   childs: this.childNum,
          //   is_primary_room: false,
          //   primary_room_no: this.addRoomCount[0].room_no
          //   }).catch(err => console.log(err))

          // }
        } else {
          await api
            .post('api/reservation/', {
              check_in_date: this.co,
              check_in_time: this.checkInTime,
              cust_id: this.custId,
              room: this.addRoomCount[0].room_no,
              source: 'FrontDesk',
              status: 'booked',
              check_out_date: this.cd,
              check_out_time: this.checkOutTime,
              no_guest: this.guestNum,
              special_ins: this.specialIns,
              extra_breakfast: this.extraB,
              No_days: this.dayCount,
              adults: this.adultsNum,
              childs: this.childNum,
              is_primary_room: true,
              previous_discounts: 0,
            })
            .then(
              toast({
                message: 'Reservation has been added',
                type: 'is-info',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })
            )
            .catch((err) => console.log(err));
        }
      } else {
        if (this.addRoomCount.length > 1) {
          console.log(this.addRoomCount);
          console.log('hello');
          for (let i = 1; i < this.addRoomCount.length; i++) {
            this.roomsConcated += this.addRoomCount[i].room_no + ',';
          }
          console.log(this.roomsConcated);
          tempSplit = this.roomsConcated.split(',');
          tempSplit.pop();

          console.log(tempSplit);

          await api
            .post('api/reservation/', {
              check_in_date: this.co,
              check_in_time: this.checkInTime,
              cust_id: this.custId,
              room: this.addRoomCount[0].room_no,
              source: 'FrontDesk',
              status: 'reserved',
              check_out_date: this.cd,
              check_out_time: this.checkOutTime,
              no_guest: this.guestNum,
              special_ins: this.specialIns,
              extra_breakfast: this.extraB,
              No_days: this.dayCount,
              adults: this.adultsNum,
              childs: this.childNum,
              secondary_room: this.roomsConcated.slice(0, -1),
              is_primary_room: true,
              total_amount: this.totalAmount,
              total_due: this.due_amount,
              total_credit: this.amount_credited,
              previous_discounts: this.amountDiscount,
            })
            .then(
              toast({
                message: 'Reservation has been added',
                type: 'is-info',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })
            )
            .catch((err) => console.log(err));

          await api
            .get('api/lastres/')
            .then((res) => {
              this.lastResData = res.data;
            })
            .catch((err) => console.log(err));
          console.log(this.lastResData);
          this.cardString = this.lastResData.room + this.lastResData.id.toString().padStart(5, '0');

          await api
            .post(`api/card/`, {
              card_no: this.cardString,
              room_no: this.lastResData.room,
              cust_id: this.lastResData.cust_id,
              rev_id: this.lastResData.id,
              secondary_room: this.roomsConcated.slice(0, -1),
            })
            .catch((err) => {
              console.log(err);
            });
          for (let i = 0; i < this.addRoomCount.length; i++) {
            console.log('room count', this.addRoomCount[i]);

            await api
              .post(`api/bills/`, {
                type_bill: 'room_rent',
                cost: this.addRoomCount[i].cost * this.dayCount,
                vat_amount: this.addRoomCount[i].cost * this.dayCount * (this.companyInfo.tax / 100),
                sc_amount: this.addRoomCount[i].cost * this.dayCount * (this.companyInfo.service_charge / 100),
                discount: this.addRoomCount[i].discount,
                is_paid: false,
                bill_item: `room ${this.addRoomCount[i].room_no}`,
                card_no: this.cardString,
                item_quantity: this.dayCount,
                bill_category: 'debit',
                special_discount: 0,
              })
              .catch((err) => console.log(err));
          }

          if (this.trxType == 'cash') {
            await api
              .post(`api/bills/`, {
                cost: this.amount_credited,
                trx_no: this.trans_id,
                special_discount: this.amountDiscount,
                special_discount_reason: this.specialDiscountReason,
                card_validity: this.doe_card,
                trx_type: 'cash',
                //
                type_bill: 'room',
                is_paid: false,
                bill_item: `Advance`,
                card_no: this.cardString,
                bill_category: 'credit',
              })
              .catch((err) => console.log(err));
          }
          if (this.trxType == 'card') {
            await api
              .post(`api/bills/`, {
                cost: this.amount_credited,
                trx_no: this.trans_id,
                special_discount: this.amountDiscount,
                special_discount_reason: this.specialDiscountReason,
                card_validity: this.doe_card,
                trx_type: 'card',
                //
                type_bill: 'room',
                is_paid: false,
                bill_item: `Advance`,
                card_no: this.cardString,
                bill_category: 'credit',
              })
              .catch((err) => console.log(err));
          }
          if (this.trxType == 'bkash') {
            await api
              .post(`api/bills/`, {
                cost: this.amount_credited,
                trx_no: this.trans_id,
                special_discount: this.amountDiscount,
                special_discount_reason: this.specialDiscountReason,
                card_validity: this.doe_card,
                trx_type: 'bkash',
                //
                type_bill: 'room',
                is_paid: false,
                bill_item: `Advance`,
                card_no: this.cardString,
                bill_category: 'credit',
              })
              .catch((err) => console.log(err));
          }

          // for(let i=1; i < this.addRoomCount.length; i++){
          //   await api.post("reservation/",{
          //   check_in_date: this.co,
          //   check_in_time: this.checkInTime,
          //   cust_id: this.custId,
          //   room: this.addRoomCount[i].room_no,
          //   source: "FrontDesk",
          //   status: "reserved",
          //   check_out_date: this.cd,
          //   check_out_time: this.checkOutTime,
          //   no_guest: this.guestNum,
          //   special_ins: this.specialIns,
          //   extra_breakfast: this.extraB,
          //   No_days: this.dayCount,
          //   adults: this.adultsNum,
          //   childs: this.childNum,
          //   is_primary_room: false,
          //   primary_room_no: this.addRoomCount[0].room_no
          //   }).catch(err => console.log(err))

          // }
          await this.jeAddForMultipleCredit();
          await this.jeAddForMultipleDebit();
        } else {
          await api
            .post('api/reservation/', {
              check_in_date: this.co,
              check_in_time: this.checkInTime,
              cust_id: this.custId,
              room: this.addRoomCount[0].room_no,
              source: 'FrontDesk',
              status: 'reserved',
              check_out_date: this.cd,
              check_out_time: this.checkOutTime,
              no_guest: this.guestNum,
              special_ins: this.specialIns,
              extra_breakfast: this.extraB,
              No_days: this.dayCount,
              adults: this.adultsNum,
              childs: this.childNum,
              is_primary_room: true,
              total_amount: this.totalAmount,
              total_due: this.due_amount,
              total_credit: this.amount_credited,
              previous_discounts: this.amountDiscount,
            })
            .then(
              toast({
                message: 'Reservation has been added',
                type: 'is-info',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })
            )
            .catch((err) => console.log(err));

          await api
            .get('api/lastres/')
            .then((res) => {
              this.lastResData = res.data;
            })
            .catch((err) => console.log(err));
          console.log(this.lastResData);
          this.cardString = this.lastResData.room + this.lastResData.id.toString().padStart(5, '0');
          await api
            .post(`api/card/`, {
              card_no: this.cardString,
              room_no: this.lastResData.room,
              cust_id: this.lastResData.cust_id,
              rev_id: this.lastResData.id,
              secondary_room: '',
            })
            .catch((err) => {
              console.log(err);
            });

          // await api.get('lastcard/'
          // ).then(res=>{
          //   this.lastCardData = res.data
          // }).catch(err=>console.log(err))

          await api
            .post(`api/bills/`, {
              type_bill: 'room_rent',
              cost: this.addRoomCount[0].cost * this.dayCount,
              vat_amount: this.addRoomCount[0].cost * this.dayCount * (this.companyInfo.tax / 100),
              sc_amount: this.addRoomCount[0].cost * this.dayCount * (this.companyInfo.service_charge / 100),
              discount: this.addRoomCount[0].discount,
              is_paid: false,
              bill_item: `room ${this.addRoomCount[0].room_no}`,
              card_no: this.cardString,
              item_quantity: this.dayCount,
              bill_category: 'debit',
              special_discount: 0,
            })
            .catch((err) => console.log(err));

          await this.jeAddForOneDebit();
          if (this.trxType == 'cash') {
            await api
              .post(`api/bills/`, {
                cost: this.amount_credited,
                trx_no: this.trans_id,
                special_discount: this.amountDiscount,
                special_discount_reason: this.specialDiscountReason,
                card_validity: this.doe_card,
                trx_type: 'cash',
                //
                type_bill: 'room',
                is_paid: false,
                bill_item: `Advance`,
                card_no: this.cardString,
                bill_category: 'credit',
              })
              .catch((err) => console.log(err));
          }
          if (this.trxType == 'card') {
            await api
              .post(`api/bills/`, {
                cost: this.amount_credited,
                trx_no: this.trans_id,
                special_discount: this.amountDiscount,
                special_discount_reason: this.specialDiscountReason,
                card_validity: this.doe_card,
                trx_type: 'card',
                //
                type_bill: 'room',
                is_paid: false,
                bill_item: `Advance`,
                card_no: this.cardString,
                bill_category: 'credit',
              })
              .catch((err) => console.log(err));
          }
          if (this.trxType == 'bkash') {
            await api
              .post(`api/bills/`, {
                cost: this.amount_credited,
                trx_no: this.trans_id,
                special_discount: this.amountDiscount,
                special_discount_reason: this.specialDiscountReason,
                card_validity: this.doe_card,
                trx_type: 'bkash',
                //
                type_bill: 'room',
                is_paid: false,
                bill_item: `Advance`,
                card_no: this.cardString,
                bill_category: 'credit',
              })
              .catch((err) => console.log(err));
          }
          await this.jeAddForCredit();
        }
      }

      this.rejectModal = false;
      this.advanceScreen = false;
      this.getResTable();
    },
    async jeAddForMultipleDebit() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      console.log(user);
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'room_rent' && dataCAO[i].type === 'Debit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      for (let i = 0; i < this.addRoomCount.length; i++) {
        const a = {
          description: 'Room Rent', //change
          date: new Date().toISOString().substr(0, 10),
          account_id: q,
          credited_by: 'Frontdesk',
          reference: this.cardString, //change
          credit: 0, //change
          debit: parseInt(parseInt(this.addRoomCount[i].cost) * parseInt(this.dayCount) + parseInt(this.addRoomCount[i].cost) * parseInt(this.dayCount) * (this.companyInfo.tax / 100) + parseInt(this.addRoomCount[i].cost) * parseInt(this.dayCount) * (this.companyInfo.service_charge / 100)), //change
          memo: 'R' + this.addRoomCount[0].room_no, //change
          journal_id: c,
          auto_journal: true,
        };
        await api.post('api/Transaction_table/', a).catch((err) => {
          console.log(err);
        });
      }
    },
    async jeAddForMultipleCredit() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      console.log(user);
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'advance' && dataCAO[i].type === 'Credit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Advance', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'Frontdesk',
        reference: this.cardString, //change
        credit: this.amount_credited, //change
        debit: 0, //change
        memo: 'R' + this.addRoomCount[0].room_no, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },

    async jeAddForCredit() {
      let dataTT = [];
      let dataCAO = [];
      // let user = [];
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'advance' && dataCAO[i].type === 'Credit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Advance', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'Frontdesk',
        reference: this.cardString, //change
        credit: this.amount_credited, //change
        debit: 0, //change
        memo: 'R' + this.addRoomCount[0].room_no, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
    async jeAddForOneDebit() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'room_rent' && dataCAO[i].type === 'Debit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Room Rent', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: user.username,
        reference: this.cardString, //change
        credit: 0, //change
        debit: parseInt(parseInt(this.addRoomCount[0].cost) * parseInt(this.dayCount) + parseInt(this.addRoomCount[0].cost) * parseInt(this.dayCount) * (this.companyInfo.tax / 100) + parseInt(this.addRoomCount[0].cost) * parseInt(this.dayCount) * (this.companyInfo.service_charge / 100)), //change
        memo: 'R' + this.addRoomCount[0].room_no, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
    async payAdvance() {
      this.advanceScreen = true;
      console.log(this.payNow);
    },
    async submitPay() {
      this.payNow = true;
      console.log(this.payNow);
      console.log(this.trxType);
      this.totalAmount = this.totalCalculated - this.amountDiscount;
      console.log(this.totalAmount);
      this.due_amount = this.totalAmount - this.amount_credited;
      console.log(this.totalAmount);
      await this.submitGuestInfo();
    },
  },
};
</script>

<style>
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}
ul li {
  float: left;
}
.cancelIcon {
  color: #d3d3d3;
}
.button:active,
.button:focus,
.button:after {
  background-color: #099dd9 !important;
  color: white !important;
}
.button:hover {
  background-color: #099dd9 !important;
  color: white !important;
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}

.cancelIcon:hover {
  color: #ff5349;
}
</style>