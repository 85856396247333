<template>
  <div>
    <div class="h-screen bg-gray-200">
      <div class="grid grid-cols-3 gap-x-2">
        <div class="h-[55px] col-span-3 border-2 border-gray-600 bg-accentM">
          <ol class="list-reset flex mt-3 ml-5">
            <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li><span @click="this.$router.push('/inventory')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Inventory</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li class="text-white text-xl">Issue Table</li>
          </ol>
        </div>

        <div class="col-span-2 mt-2 px-7 mb-1">
          <div class="inline-flex rounded-md shadow-sm float-left" role="group">
            <button
              type="button"
              @click="
                ii = true;
                ir = false;
              "
              :class="{ activeRed: ii }"
              class="py-1 px-4 text-base font-medium text-gray-900 bg-white rounded-l-sm border border-gray-400 hover:bg-button2 hover:text-white"
            >
              Issue
            </button>
            <button
              type="button"
              @click="
                ii = false;
                ir = true;
              "
              :class="{ activeRed: ir }"
              class="py-1 px-4 text-base font-medium text-gray-900 bg-white border border-gray-400 hover:bg-button2 hover:text-white"
            >
              Returns
            </button>
          </div>
        </div>

        <div class="col-span-3" style="height: 78vh">
          <div v-if="ii == true" class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 min-h-4/5">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs uppercase bg-accentM">
                <tr>
                  <th scope="col" class="md:pl-20 px-6 py-3 text-white">Order By</th>
                  <th scope="col" class="px-6 py-3 text-white">Issue Number</th>
                  <th scope="col" class="px-6 py-3 text-white">Item Name</th>
                  <th scope="col" class="px-6 py-3 text-white">Issue To</th>
                  <th scope="col" class="px-6 py-3 text-white">Project</th>
                  <th scope="col" class="px-6 py-3 text-white">Avaialble</th>
                  <th scope="col" class="px-6 py-3 text-white">Requested</th>
                  <th scope="col" class="px-6 py-3 text-white">Issue</th>
                  <th scope="col" class="px-6 py-3 text-white">Pending</th>
                  <th scope="col" class="px-6 py-3 text-white">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in dataIssue" :key="index" class="border-b odd:bg-white even:bg-gray-50">
                  <td class="px-6 py-4 md:pl-20">{{ p.orderBy }}</td>
                  <td class="px-6 py-4 md:pl-20">{{ p.docNumber }}</td>
                  <td class="px-6 py-4">{{ p.itemName }}</td>
                  <td class="px-6 py-4">{{ p.issueTo }}</td>
                  <td class="px-6 py-4">{{ p.projectName }}</td>
                  <td class="px-6 py-4">{{ p.stock }}</td>
                  <td class="px-6 py-4">{{ p.requestedItem }}</td>
                  <td class="px-6 py-4">{{ p.amount }}</td>
                  <td class="px-6 py-4">{{ p.pendingItem }}</td>
                  <td v-if="p.statusS == 'rejected'" class="px-6 py-4"><p class="text-red-600 font-bold">Rejected</p></td>
                  <td v-else-if="p.amount == 0" class="px-6 py-4">
                    <button @click="Accept2(p.id)" class="bg-green-500 hover:bg-green-400 text-white hover:text-black text-sm py-1 px-2 mx-1 rounded">Issue Item</button>
                    <button @click="Reject(p.id)" class="bg-red-600 hover:bg-red-400 text-white hover:text-black text-sm py-1 px-2 mx-1 rounded">Reject</button>
                  </td>
                  <td v-else-if="p.pendingItem == 0" class="px-6 py-4 text-green-600 font-bold">
                    Approved
                    <button @click="Return(p.id)" class="bg-blue-500 hover:bg-blue-400 text-white hover:text-black text-sm py-1 px-2 mx-1 rounded">Return</button>
                  </td>
                  <td v-else-if="p.pendingItem != 0" class="px-6 py-4 text-green-600 font-bold">
                    Approved
                    <button @click="Accept2(p.id)" class="bg-green-500 hover:bg-green-400 text-white hover:text-black text-sm py-1 px-2 mx-1 rounded">Issue</button>
                    <button @click="Reject(p.id)" class="bg-red-600 hover:bg-red-400 text-white hover:text-black text-sm py-1 px-2 mx-1 rounded">Reject</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 min-h-4/5">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-white uppercase bg-accentM">
                <tr>
                  <th scope="col" class="px-6 py-3 text-white">Issue Number</th>
                  <th scope="col" class="px-6 py-3 text-white">Item Name</th>
                  <th scope="col" class="px-6 py-3 text-white">Project</th>
                  <th scope="col" class="px-6 py-3 text-white">Issue</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in dataIssueR" :key="index" class="border-b odd:bg-white even:bg-gray-50">
                  <td class="px-6 py-4 md:pl-20">{{ p.docNumber }}</td>
                  <td class="px-6 py-4">{{ p.itemName }}</td>
                  <td class="px-6 py-4">{{ p.projectName }}</td>
                  <td class="px-6 py-4">{{ p.amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="h-[6vh] col-span-3">
          <button class="bg-button hover:bg-button2 text-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 text-base py-2 px-4 mx-1 mt-6 rounded">Prev</button>
          <button class="bg-button hover:bg-button2 text-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 text-base py-2 px-4 mx-1 mt-6 rounded">Next</button>
        </div>
      </div>
    </div>

    <div v-if="modalR" class="opacity-80 fixed inset-0 z-40 bg-black"></div>
    <div v-if="modalR">
      <div class="w-3/6 h-1/2 fixed block top-40 left-[25vw] bg-white z-50">
        <div class="h-[6vh] bg-slate-200 rounded-lg">
          <p class="text-2xl font-bold pt-3 font-mono">Issue Item Return</p>
        </div>

        <div class="h-[150px] mx-32 my-20">
          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-3/6">
              <p class="block text-gray-900 text-xl pr-4 text-left ml-10" for="inline-full-name">Return Quanity:</p>
            </div>
            <div class="md:w-3/6">
              <input v-model="mQ" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="number" placeholder="Quantity" />
            </div>
          </div>
          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-3/6">
              <p class="block text-gray-900 text-xl pr-4 text-left ml-10" for="inline-full-name">Remarks:</p>
            </div>
            <div class="md:w-3/6">
              <input v-model="mR" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="remarks" />
            </div>
          </div>
        </div>
        <div>
          <button class="bg-green-500 hover:bg-green-400 hover:text-black text-white font-bold text-base py-2 px-5 mx-1 mt-2 rounded" @click.prevent="AcceptR()">Return</button>
          <button class="bg-red-500 hover:bg-red-400 hover:text-black text-white font-bold text-base py-2 px-4 mx-1 mt-2 rounded" @click.prevent="modalR = false">Close</button>
        </div>
      </div>
    </div>

    <div v-if="modal" class="opacity-80 fixed inset-0 z-40 bg-black"></div>
    <div v-if="modal">
      <div class="w-3/6 h-1/2 fixed block top-40 left-[25vw] bg-white z-50">
        <div class="h-[6vh] bg-slate-200 rounded-lg">
          <p class="text-2xl font-bold pt-3 font-mono">Issue Item</p>
        </div>

        <div class="h-[150px] mx-32 my-20">
          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-3/6">
              <p class="block text-gray-900 text-xl pr-4 text-left ml-10" for="inline-full-name">Requested/Pending Quanity:</p>
            </div>
            <div class="md:w-3/6">
              <input v-model="mQ" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="number" placeholder="Quantity" />
            </div>
          </div>
          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-3/6">
              <p class="block text-gray-900 text-xl pr-4 text-left ml-10" for="inline-full-name">Remarks:</p>
            </div>
            <div class="md:w-3/6">
              <input v-model="mR" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="remarks" />
            </div>
          </div>
        </div>
        <div>
          <button class="bg-green-500 hover:bg-green-400 hover:text-black text-white font-bold text-base py-2 px-5 mx-1 mt-2 rounded" @click.prevent="Accept()">Save</button>
          <button class="bg-red-500 hover:bg-red-400 hover:text-black text-white font-bold text-base py-2 px-4 mx-1 mt-2 rounded" @click.prevent="modal = false">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';

export default {
  components: {},

  data() {
    return {
      searchName: '',
      dataIssue: [],
      dataIssueR: [],
      dataItem: [],
      dataProject: [],
      daraR: [],

      modal: false,
      idM: '',
      mQ: '',
      mI: '',
      mR: '',
      mID: '',
      mP: '',

      modalR: false,
      dataV: [],

      ir: false,
      ii: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await api
        .get(`api/issue_item/`)
        .then((res) => {
          this.dataIssue = res.data;
          // console.table(this.dataIssue);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/issue_item_r/`)
        .then((res) => {
          this.dataIssueR = res.data;
          // console.table(this.dataIssue);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/project/`)
        .then((res) => {
          this.dataProject = res.data;
          // console.table(this.dataProject);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/prod_det/`)
        .then((res) => {
          this.dataR = res.data;
          // console.table(this.dataR);
        })
        .catch((error) => {
          console.log(error);
        });

      for (let i = 0; i < this.dataIssue.length; i++) {
        this.dataIssue[i].stock = 0;
        for (let j = 0; j < this.dataR.length; j++) {
          if (this.dataIssue[i].item == this.dataR[j].prod && this.dataIssue[i].project == this.dataR[j].project) {
            this.dataIssue[i].stock += parseInt(this.dataR[j].stock);
          }
        }
      }
    },
    Return(a) {
      this.idM = a;
      for (let i = 0; i < this.dataIssue.length; i++) {
        if (a == this.dataIssue[i].id) {
          this.dataV = this.dataIssue[i];
        }
      }
      this.mQ = this.dataV.amount;
      // console.table(this.dataV);
      this.modalR = true;
    },
    async Accept2(a) {
      this.idM = a;
      for (let i = 0; i < this.dataIssue.length; i++) {
        if (a == this.dataIssue[i].id) {
          this.mQ = this.dataIssue[i].pendingItem;
          this.mI = this.dataIssue[i].pendingItem;
          this.mID = this.dataIssue[i].item;
          this.mP = this.dataIssue[i].project;
        }
      }
      this.modal = true;
    },
    async AcceptR() {
      let a = {
        stockI: this.mQ,
        stock: this.mQ,
        price: '',
        date: new Date().toISOString().substr(0, 10),
        op: 'return',
        batch: '',
        projectName: this.dataV.projectName,
        prod: this.dataV.item,
        project: this.dataV.project,
      };
      await api
        .post(`api/prod_det/`, a)
        .then((res) => {
          console.table(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      let b = {
        orderType: 'IR',
        docNumber: 'IR' + this.dataV.docNumber,
        date: new Date().toISOString().substr(0, 10),
        amount: this.mQ,
        reamrk: this.mR,
        ledger: true,
        projectName: this.dataV.projectName,
        status: this.dataV.status,
        statusS: this.dataV.statusS,
        number: this.dataV.id,
        orderBy: this.dataV.orderBy,
        issueTo: this.dataV.issueTo,
        item: this.dataV.item,
        itemName: this.dataV.itemName,
        project: this.dataV.project,
        itemMaster: this.dataV.itemMaster,
      };
      await api
        .post(`api/issue_item/`, b)
        .then((res) => {
          console.table(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.data = [];
      this.getData();
      this.modalR = false;
    },
    async Accept() {
      let p = [];
      let j = this.mQ;
      let cm = '';
      await api
        .get(`api/prod_raw/${this.mID}/`)
        .then((res) => {
          cm = res.data.costingMethod;
          console.table(cm);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/prod_det/?prod=${this.mID}&project=${this.mP}`)
        .then((res) => {
          p = res.data;
          console.table(p);
        })
        .catch((error) => {
          console.log(error);
        });
      if (cm == 'fifo') {
        for (let i = 0; i < p.length; i++) {
          if (p[i].stock >= j) {
            p[i].stock = p[i].stock - j;
            break;
          } else {
            j = j - p[i].stock;
            p[i].stock = 0;
          }
        }
      } else if (cm == 'lifo') {
        for (let i = p.length - 1; i >= 0; i--) {
          if (p[i].stock >= j) {
            p[i].stock = p[i].stock - j;
            break;
          } else {
            j = j - p[i].stock;
            p[i].stock = 0;
          }
        }
      }
      console.table(p);
      // return;
      for (let i = 0; i < p.length; i++) {
        await api
          .put(`api/prod_det/${p[i].id}/`, p[i])
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      let b = {
        // id: this.idM,
        statusS: 'approved',
        amount: this.mQ,
        pendingItem: this.mI - this.mQ,
        ledger: true,
      };
      console.table(b);
      // return;
      await api.put(`api/issue_item/${this.idM}/`, b).catch((error) => {
        console.log(error);
      });
      this.data = [];
      this.getData();
      this.modal = false;
    },
    async Reject(a) {
      console.log(`api/issue_item/${a}/`);
      await api
        .put(`api/issue_item/${a}/`, {
          id: a,
          status: 105,
          statusS: 'rejected',
        })
        .catch((error) => {
          console.log(error);
        });
      this.getData();
    },
  },
};
</script>

<style>
.activeRed {
  background-color: #f8d766 !important;
  color: black !important;
}
.activeFilter {
  background-color: #f8d766 !important;
  color: white !important;
}
</style>