<template>
  <div class="">
    <div style="height: 8vh" class="bg-csNav flex justify-end items-center">
      <button @click="OrderHistory()" class="text-white bg-accentM hover:bg-accent2 px-3 rounded-lg text-lg mr-2" style="height: 40px">Order History</button>
    </div>
    <div style="height: 92vh" class="grid grid-cols-4">
      <div class="col-span-3 bg-scafold2 border-r border-gray-400">
        <p class="text-4xl text-left ml-4 mt-2 font-semibold">In kitchen</p>
        <div class="grid grid-cols-3">
          <div @click="OrderPlaced2(d)" class="border bg-white hover:shadow-sm hover:bg-scafold cursor-pointer p-4 m-2" v-for="d in dataOrder" :key="d">
            <div class="grid grid-cols-2">
              <div class="col-span-1 text-left text-2xl">Order Number</div>
              <div class="col-span-1 text-right text-2xl">{{ d.idd }}</div>

              <div class="col-span-2 border-b border-gray-400 mb-2"></div>

              <div class="col-span-1 text-left text-lg">Table Number</div>
              <div class="col-span-1 text-right text-lg">{{ d.table }}</div>

              <div class="col-span-2 mx-4 mt-1 rounded bg-gray-600 text-white">
                <div class="grid grid-cols-2">
                  <div class="text-lg">Item</div>
                  <div class="text-lg">Qty</div>
                  <div class="col-span-2 border border-white"></div>
                  <div class="col-span-2" v-for="p in d.order" :key="p">
                    <div class="grid grid-cols-2">
                      <div class=" ">{{ p.itemName }}</div>
                      <div class=" ">{{ p.quantity }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-1">
        <p class="text-4xl text-left ml-4 mt-2 font-semibold">Placed</p>
        <div class="grid grid-cols-1">
          <div @click="OrderPlaced(d.id)" class="border bg-gray-600 rounded text-white hover:shadow-sm hover:bg-gray-700 cursor-pointer p-4 m-2" v-for="d in dataOrderS" :key="d">
            <div class="grid grid-cols-2">
              <div class="col-span-1 text-left text-xl">Order Number</div>
              <div class="col-span-1 text-right text-xl">{{ d.idd }}</div>

              <div class="col-span-2 border-b border-white mb-2"></div>

              <div class="col-span-1 text-left text">Table Number</div>
              <div class="col-span-1 text-right text">{{ d.table }}</div>

              <div class="col-span-2 mx-4 mt-1 rounded bg-white text-gray-600">
                <div class="grid grid-cols-2">
                  <div class="text-">Item</div>
                  <div class="text">Qty</div>
                  <div class="col-span-2 border border-gray-600"></div>
                  <div class="col-span-2" v-for="p in d.order" :key="p">
                    <div class="grid grid-cols-2">
                      <div class="text-sm">{{ p.itemName }}</div>
                      <div class="text-sm">{{ p.quantity }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="orderModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="orderModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 900px; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div style="float: left; width: 850px; height: 90vh">
              <p class="text-3xl font-semibold underline mb-2">Order History</p>
              <div class="grid grid-cols-2">
                <div class="col-span-1 m-1 border bg-scafold2 rounded hover:bg-blue-200 cursor-pointer" v-for="d in dataOrderH" :key="d">
                  <div class="grid grid-cols-2 mx-4">
                    <div class="col-span-1 text-left text-xl">Order Number</div>
                    <div class="col-span-1 text-right text-xl">{{ d.idd }}</div>

                    <div class="col-span-1 text-left text-lg">Table number</div>
                    <div class="col-span-1 text-right text-lg">{{ d.table }}</div>

                    <div class="col-span-1 text-left text-lg">Amount</div>
                    <div class="col-span-1 text-right text-lg font-semibold">৳ {{ d.total }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 5px">
              <button class="button is-danger is-light" @click.prevent="orderModal = false" style="margin-right: 5px">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="OrderMove == true">
      <div class="modal is-active">
        <div class="modal-background" @click.prevent="OrderMove = false">
          <div class="modal-content" style="height: 30vh; background: white; margin-top: 25vh">
            <div style="margin-top: 15%">
              <p class="subtitle is-4">Accept the Order</p>
              <div style="margin-top: 25px">
                <button class="button" style="background-color: #26ade4; color: white" @click.prevent="ChangeStatus()">Accept</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="OrderMove2 == true">
      <div class="modal is-active">
        <div class="modal-background" @click.prevent="OrderMove2 = false">
          <div class="modal-content" style="height: 30vh; background: white; margin-top: 25vh">
            <div style="margin-top: 15%">
              <p class="subtitle is-4">Order Served</p>
              <div style="margin-top: 25px">
                <button class="button" style="background-color: #26ade4; color: white" @click.prevent="ChangeStatus2()">Served</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
// import { toast } from 'bulma-toast'\
export default {
  data() {
    return {
      dataOrder: [],
      dataOrderS: [],
      dataOrderH: [],
      orderModal: false,
      OrderMove: false,
      OrderMove2: false,
      orderVar: '',
    };
  },
  created() {
    this.getData();
    this.getData2();
    // setInterval(() => {
    //   console.log(true);
    //   this.getData2();
    // }, 6000000);
  },
  methods: {
    async getData() {
      let table;
      await api
        .get('api/table/')
        .then((response) => {
          table = response.data;
          console.log(table);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/food_pos/?status=In Kitchen`)
        .then((response) => {
          this.dataOrder = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataOrder.length; i++) {
        this.dataOrder[i].idd = this.dataOrder[i].id.toString().padStart(4, '0');
        let item = this.dataOrder[i].item.split('|');
        let itemName = this.dataOrder[i].itemName.split('|');
        let quantity = this.dataOrder[i].itemQuantity.split('|');
        this.dataOrder[i].order = [];
        for (let j = 0; j < table.length; j++) {
          if (table[j].id == this.dataOrder[i].table) {
            this.dataOrder[i].table = table[j].num;
            this.dataOrder[i].tableId = table[j].id;
            break;
          }
        }
        for (let j = 0; j < item.length; j++) {
          let q = {
            item: item[j],
            itemName: itemName[j],
            quantity: quantity[j],
          };
          this.dataOrder[i].order.push(q);
        }
      }
      console.table(this.dataOrder);
    },
    async getData2() {
      let table;
      await api
        .get('api/table/')
        .then((response) => {
          table = response.data;
          console.log(table);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/food_pos/?status=Placed`)
        .then((response) => {
          this.dataOrderS = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataOrderS.length; i++) {
        this.dataOrderS[i].idd = this.dataOrderS[i].id.toString().padStart(4, '0');
        let item = this.dataOrderS[i].item.split('|');
        let itemName = this.dataOrderS[i].itemName.split('|');
        let quantity = this.dataOrderS[i].itemQuantity.split('|');
        this.dataOrderS[i].order = [];
        for (let j = 0; j < table.length; j++) {
          if (table[j].id == this.dataOrderS[i].table) {
            this.dataOrderS[i].table = table[j].num;
            this.dataOrderS[i].tableId = table[j].id;
            break;
          }
        }
        for (let j = 0; j < item.length; j++) {
          let q = {
            item: item[j],
            itemName: itemName[j],
            quantity: quantity[j],
          };
          this.dataOrderS[i].order.push(q);
        }
      }
      console.table(this.dataOrderS);
    },
    async ChangeStatus() {
      await api
        .put(`api/food_pos/${this.orderVar}/`, { status: 'In Kitchen' })
        .then((res) => {
          console.log(res.data);
          this.getData();
          this.getData2();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async ChangeStatus2() {
      console.log(this.orderVar);
      await api
        .put(`api/food_pos/${this.orderVar.id}/`, { status: 'Served' })
        .then((res) => {
          console.log(res.data);
          api
            .put(`api/table/${this.orderVar.table}/`, { status: 'Occupied' })
            .then((res) => {
              {
                console.log(res);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          this.getData();
          this.getData2();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    OrderPlaced(i) {
      this.OrderMove = true;
      this.orderVar = i;
    },

    OrderPlaced2(i) {
      this.OrderMove2 = true;
      this.orderVar = i;
    },

    async OrderHistory() {
      let table = [];
      await api
        .get('api/table/')
        .then((response) => {
          table = response.data;
          console.log(table);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/food_pos/?status=Served`)
        .then((response) => {
          this.dataOrderH = response.data;
          console.table(this.dataOrderH);
          for (let i = 0; i < this.dataOrderH.length; i++) {
            this.dataOrderH[i].idd = this.dataOrderH[i].id.toString().padStart(4, '0');
            for (let j = 0; j < table.length; j++) {
              if (table[j].id == this.dataOrderH[i].table) {
                this.dataOrderH[i].table = table[j].num;
                break;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.orderModal = true;
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
.activeRed {
  background-color: #2e4150 !important;
  color: white !important;
}
</style>