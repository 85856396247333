<template>
  <div style="height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Room Accessories</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="$router.push('/settings')">Back</button></div>
        </div>
      </div>
    </div>

    <div class="columns">
      <!-- Tables -->
      <div class="column is-12">
        <div class="box" style="height: 88vh">
          <!-- Tabs -->
          <div class="tabs is-medium">
            <ul>
              <li v-bind:class="{ 'is-active': isActive == 'bedroom' }"><a v-on:click="isActive = 'bedroom'">Bedroom Items</a></li>
              <li v-bind:class="{ 'is-active': isActive == 'washroom' }"><a v-on:click="isActive = 'washroom'">Washroom Items</a></li>
              <li v-bind:class="{ 'is-active': isActive == 'mini' }"><a v-on:click="isActive = 'mini'">Mini Bar Items</a></li>
            </ul>
          </div>

          <div class="tab-a">
            <!-- TAB: Bedroom -->
            <div class="a" v-bind:class="{ 'is-active': isActive == 'bedroom' }">
              <!-- Input -->
              <div class="columns">
                <div class="column is-2"></div>
                <div class="column is-3">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">Name:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="item name" v-model="name" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-3">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">Quantity:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="quantity" v-model="defaultQuantity" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-1">
                  <div style="float: left">
                    <button class="button" style="background-color: #26ade4; color: white" @click="AddData('bedroom')">Add</button>
                  </div>
                </div>
                <div class="column is-1"></div>
              </div>

              <!-- Table -->
              <div class="columns">
                <div class="column is-2"></div>
                <div class="column is-8">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="d in dataItem" v-bind:key="d.name">
                        <template v-if="d.item_type == 'bedroom'">
                          <td>{{ d.name }}</td>
                          <td>{{ d.defaultQuantity }}</td>
                          <td>
                            <!-- <button class="button is-info is-small" @click="ItemEdit(d.name)"><i class="fa-solid fa-pen" style="font-size:10px;"></i></button> -->
                            <button class="button is-small" style="background-color: #e67c6c; color: white; margin-left: 5px" @click="DeleteItem(d.name)"><i class="fas fa-solid fa-xmark" style="font-size: 15px"></i></button>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="column is-2"></div>
              </div>
            </div>

            <!-- TAB: Washroom -->
            <div class="a" v-bind:class="{ 'is-active': isActive == 'washroom' }">
              <!-- Input -->
              <div class="columns">
                <div class="column is-2"></div>
                <div class="column is-3">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">Name:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="item name" v-model="name" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-3">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">Quantity:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="quantity" v-model="defaultQuantity" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-1">
                  <div style="float: left">
                    <button class="button" style="background-color: #26ade4; color: white" @click="AddData('washroom')">Add</button>
                  </div>
                </div>
                <div class="column is-1"></div>
              </div>

              <!-- Table -->
              <div class="columns">
                <div class="column is-2"></div>
                <div class="column is-8">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="d in dataItem" v-bind:key="d.name">
                        <template v-if="d.item_type == 'washroom'">
                          <td>{{ d.name }}</td>
                          <td>{{ d.defaultQuantity }}</td>
                          <td>
                            <!-- <button class="button is-info is-small" @click="ItemEdit(d.name)"><i class="fa-solid fa-pen" style="font-size:10px;"></i></button> -->
                            <button class="button is-small" style="background-color: #e67c6c; color: white; margin-left: 5px" @click="DeleteItem(d.name)"><i class="fas fa-solid fa-xmark" style="font-size: 15px"></i></button>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="column is-2"></div>
              </div>
            </div>

            <!-- TAB: Mini Bar -->
            <div class="a" v-bind:class="{ 'is-active': isActive == 'mini' }">
              <!-- Input -->
              <div class="columns">
                <div class="column is-1"></div>
                <div class="column is-3">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">Name:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="item name" v-model="name" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-2">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">Quantity:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 150px">
                          <input class="input" type="text" placeholder="quantity" v-model="defaultQuantity" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-2">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">Price:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 150px">
                          <input class="input" type="text" placeholder="price" v-model="price" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-1">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">Vat:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 50px">
                          <input class="input" type="text" placeholder="vat" v-model="vat" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-1">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; font-weight: normal">vat</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="margin-top: 10px">
                          <input type="checkbox" v-model="is_vat" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-1">
                  <div style="float: left">
                    <button class="button" style="background-color: #26ade4; color: white" @click="AddData('miniBar')">Add</button>
                  </div>
                </div>
                <div class="column is-1"></div>
              </div>

              <!-- Table -->
              <div class="columns">
                <div class="column is-1"></div>
                <div class="column is-10">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Vat</th>
                        <th>is Vat</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="d in dataItem" v-bind:key="d.name">
                        <template v-if="d.item_type == 'miniBar'">
                          <td>{{ d.name }}</td>
                          <td>{{ d.defaultQuantity }}</td>
                          <td>{{ d.price }}</td>
                          <td>{{ d.vat }}</td>
                          <td>{{ d.is_vat }}</td>
                          <td>
                            <!-- <button class="button is-info is-small" @click="ItemEdit(d.name)"><i class="fa-solid fa-pen" style="font-size:10px;"></i></button> -->
                            <button class="button is-small" style="background-color: #e67c6c; color: white; margin-left: 5px" @click="DeleteItem(d.name)"><i class="fas fa-solid fa-xmark" style="font-size: 15px"></i></button>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="column is-1"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- ModaL: Item edit -->
        <div v-if="itemEditM == true">
          <div class="modal is-active">
            <div class="modal-background" @click.prevent="itemEditM = false"></div>
            <div class="modal-content" style="height: 40vh; background: white">
              <div style="margin-top: 10%">
                <p style="font-size: 22px">Item: {{ dataItemSeleted.name }}</p>

                <div class="field is-horizontal" style="margin-left: 100px; margin-top: 30px">
                  <div class="field-label is-normal">
                    <label class="label" style="font-size: 18px; font-weight: normal">Quantity:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <p class="control" style="width: 300px">
                        <input class="input" type="text" placeholder="quantity" v-model="dataItemSeleted.defaultQuantity" />
                      </p>
                    </div>
                  </div>
                </div>

                <div class="field is-horizontal" style="margin-left: 100px">
                  <div class="field-label is-normal">
                    <label class="label" style="font-size: 18px; font-weight: normal">Price:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <p class="control" style="width: 300px">
                        <input class="input" type="text" placeholder="quantity" v-model="dataItemSeleted.price" />
                      </p>
                    </div>
                  </div>
                </div>

                <div style="margin-top: 30px">
                  <button class="button" style="background-color: #26ade4; color: white" @click="SubmitEdit()">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      isActive: 'bedroom',
      dataI: [],
      dataItem: [],
      dataItemSeleted: [],
      itemEditM: false,
      itemV: '',
      name: '',
      item_type: '',
      defaultQuantity: '',
      price: '',
      is_vat: false,
      vat: '',
    };
  },
  created() {
    this.getDataI();
    this.getDataItem();
  },
  methods: {
    async getDataI() {
      await api
        .get('api/inventory_view/')
        .then((response) => {
          this.dataI = response.data;
          console.log(this.dataI);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataItem() {
      await api
        .get('api/item/')
        .then((response) => {
          this.dataItem = response.data;
          console.log(this.dataItem);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataItemSeleted(t) {
      await api
        .get(`/api/item/${t}/`)
        .then((response) => {
          this.dataItemSeleted = response.data;
          console.log(this.dataItemSeleted);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async AddData(t) {
      if (t == 'washroom' || t == 'bedroom') {
        this.price = 0;
        this.vat = 0;
      }

      if (this.name == '' || this.defaultQuantity == '') {
        toast({
          message: 'Feilds can not be empty',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      const a = {
        name: this.name,
        item_type: t,
        defaultQuantity: this.defaultQuantity,
        price: this.price,
        is_vat: this.is_vat,
        vat: this.vat,
      };
      await api
        .post('/api/item/', a)
        .then(() => {
          toast({
            message: 'Item added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });

          (this.name = ''), (this.item_type = ''), (this.defaultQuantity = ''), (this.price = ''), (this.is_vat = false), (this.vat = ''), this.getDataItem();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async DeleteItem(t) {
      await api
        .delete(`/api/item/${t}/`)
        .then((response) => {
          toast({
            message: 'Item deleted',
            type: 'is-warning',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          });
          console.log(response);
          this.getDataItem();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    ItemEdit(t) {
      this.getDataItemSeleted(t);
      this.itemV = t;
      this.itemEditM = true;
    },

    async SubmitEdit() {
      const t = this.itemV;
      await api
        .patch(`/api/item/${t}/`, this.dataItemSeleted)
        .then((response) => {
          toast({
            message: 'Item edited',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(response);
          this.getDataItem();
          this.dataItemSeleted = [];
          this.itemEditM = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.tab-a .a {
  display: none;
}
.tab-a .a.is-active {
  display: block;
}
ul li {
  float: left;
}
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>