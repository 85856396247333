<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%">
          <div style="float: left"><p style="font-size: 24px">Room Service</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="FoodModal()">Add Order</button></div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-1"></div>
      <div class="column is-10">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Id</th>
              <th>Room No</th>
              <th>Card no</th>
              <th>Date</th>
              <th>Time</th>
              <th>Total Bill</th>
              <th>Special Request</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in dataFoodCost" v-bind:key="d.id">
              <td>{{ d.id }}</td>
              <td>{{ d.room_no }}</td>
              <td>{{ d.card_no }}</td>
              <td>{{ d.date }}</td>
              <td>{{ d.time }}</td>
              <td>{{ d.total_bill }}</td>
              <td>{{ d.special_request }}</td>
              <td>
                <span style="text-transform: uppercase">{{ d.status }}</span>
              </td>
              <td>
                <button class="button is-small" @click.prevent="Details(d.id)"><i class="fas fa-solid fa-info" style="color: #26ade4; font-size: 15px"></i></button>
                <button class="button is-small" @click.prevent="Remove(d.id)" style="margin-left: 5px"><i class="fas fa-solid fa-xmark" style="color: #e67c6c; font-size: 15px"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-1"></div>
    </div>

    <!-- Modal: Order add -->
    <div v-if="foodModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="foodModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 70vw; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div class="columns" style="height: 10vh">
              <div class="column is-12">
                <div class="box" style="height: 7vh">
                  <p style="font-size: 20px; float: left">Add Order</p>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column is-6">
                <div class="" style="height: 75vh">
                  <div style="margin-left: 5%">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Room No:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <select v-model="room_no" v-on:change="Search()" style="width: 100%; height: 40px">
                              <option value="" hidden>Select Room</option>
                              <option v-for="r in dataCard" :key="r.room_no">{{ r.room_no }}</option>
                            </select>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Card No:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="card no." v-model="cardNo" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Time:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 150px; display: flex">
                            <input class="input" type="date" v-model="date" />
                            <input class="input" type="time" style="margin-left: 22px" v-model="time" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="box" style="height: 20vh; margin-top: 10%; margin-left: 5%; margin-right: 5%; box-shadow: 2px 2px 2px 2px #26ade4">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 18px; float: left">Food:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 60%">
                            <select v-model="foodSelect" style="width: 100%; height: 40px">
                              <option value="" hidden>Select Food</option>
                              <option v-for="(r, index) in dataFood" :key="r.id" :value="index">{{ r.name }}</option>
                            </select>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 18px; float: left">Quantity:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 60%">
                            <input class="input" type="number" min="0" placeholder="quantity" v-model="quantity" />
                          </p>
                        </div>
                      </div>
                    </div>

                    <button class="button is-small" style="background-color: #26ade4; color: white" @click.prevent="Add()">Add</button>
                  </div>

                  <div class="box" style="height: 25vh; margin-top: 10%; margin-left: 5%; margin-right: 5%; box-shadow: 2px 2px 2px 2px #26ade4">
                    <div class="field">
                      <label class="label" style="float: left; font-size: 18px; margin-bottom: 10px">Special Request:</label>
                    </div>
                    <textarea class="textarea" placeholder="request" style="width: 100%; height: 150px; max-height: 150px" v-model="req"></textarea>
                  </div>
                </div>
              </div>

              <!-- Order Table -->
              <div class="column is-6">
                <div class="box" style="height: 75vh">
                  <div style="display: flex; margin: 5px 0px 10px 15px">
                    <p style="font-size: 22px">Total: {{ total }}</p>
                  </div>

                  <table class="table is-fullwidth is-striped">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Quantity</th>
                        <th>Cost</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(d, index) in orderA" v-bind:key="d.index">
                        <td>{{ d.name }}</td>
                        <td><input type="number" v-model="d.quantity" min="0" style="width: 15%" @change="(d.cost = d.costI * d.quantity), Total()" /></td>
                        <td>{{ d.cost }}</td>
                        <td>
                          <button class="button is-small" style="background-color: #e67c6c; color: white" @click="EditOrder(index)"><i class="fa-solid fa-xmark" style="font-size: 20px"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 20px">
              <button class="button" @click.prevent="foodModal = false" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
              <button class="button" @click.prevent="AddOrder()" style="background-color: #26ade4; color: white">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Order details -->
    <div v-if="orderModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="(orderModal = false), (orderD = [])"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div class="columns" style="height: 10vh">
              <div class="column is-12">
                <div class="box" style="height: 7vh">
                  <p style="font-size: 20px; float: left">Order No. {{ orderModalNumber }}</p>
                </div>
              </div>
            </div>

            <div class="columns" style="height: 5vh">
              <div class="column is-4">Room no: {{ roomM }}</div>
              <div class="column is-4">Request: {{ reqM }}</div>
              <div class="column is-4">Status: {{ statusM }}</div>
            </div>

            <div class="columns" style="height: 70vh">
              <div class="column is-1"></div>
              <div class="column is-10">
                <table class="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Food Name</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="d in orderD" v-bind:key="d.n">
                      <td>{{ d.n }}</td>
                      <td>{{ d.a }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="column is-1"></div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 20px">
              <button class="button" @click.prevent="(orderModal = false), (orderD = [])" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
              <button class="button" :disabled="statusM == 'delivered'" style="background-color: #26ade4; color: white" @click.prevent="orderDelivered()">Delivered</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      hotel: [],
      dataFood: [],
      foodPrice: [],
      foodName: [],
      dataFoodCost: [],
      dataCard: [],
      foodT: [],
      foodModal: false,
      orderModal: false,
      room_no: '',
      cardNo: '',
      foodSelect: '',
      req: '',
      quantity: 0,
      orderA: [],
      total: 0,
      date: '',
      time: '',
      orderD: [],
      orderModalNumber: '',
      roomM: '',
      reqM: '',
      statusM: '',
      res_var: '',
      pic: null,
    };
  },
  async created() {
    await this.getHotel();
    await this.getDataFood();
    await this.getDataFoodCost();
    await this.getCard();
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back03;
        })
        .catch((err) => console.log(err));
    },
    async getHotel() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.hotel = res.data;
          console.log('Hotel', this.hotel);
        })
        .catch((err) => console.log(err));
    },

    async getDataFood() {
      await api
        .get('api/food_type/')
        .then((response) => {
          this.dataFood = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataFood.length; i++) {
        this.foodPrice[this.dataFood[i].id] = this.dataFood[i].cost;
        this.foodName[this.dataFood[i].id] = this.dataFood[i].name;
      }
      console.table(this.foodPrice);
      console.table(this.foodName);
    },

    async getDataFoodCost() {
      await api
        .get('api/food/')
        .then((response) => {
          this.dataFoodCost = response.data;
          console.table(this.dataFoodCost);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getCard() {
      await api
        .get('api/card/')
        .then((response) => {
          this.dataCard = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      let varr = [];
      for (let i = 0; i < this.dataCard.length; i++) {
        if (this.dataCard[i].checked_in == true) {
          const a = {
            card_no: this.dataCard[i].card_no,
            room_no: parseInt(this.dataCard[i].room_no),
            rev_id: this.dataCard[i].rev_id,
          };
          varr.push(a);
          if (this.dataCard[i].secondary_room != '') {
            let b = this.dataCard[i].secondary_room.split(',');
            for (let j = 0; j < b.length; j++) {
              const c = {
                card_no: this.dataCard[i].card_no,
                room_no: parseInt(b[j]),
                rev_id: this.dataCard[i].rev_id,
              };
              varr.push(c);
            }
          }
        }
      }
      this.dataCard = varr;
      console.table(this.dataCard);
    },

    Search() {
      for (let i = 0; i < this.dataCard.length; i++) {
        if (this.dataCard[i].room_no == this.room_no) {
          this.cardNo = this.dataCard[i].card_no;
          this.res_var = this.dataCard[i].rev_id;
          break;
        }
      }
    },

    Add() {
      if (this.foodSelect === '') {
        toast({
          message: 'Select food',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      const a = {
        id: this.dataFood[this.foodSelect].id,
        name: this.dataFood[this.foodSelect].name,
        quantity: this.quantity,
        cost: this.quantity * this.dataFood[this.foodSelect].cost,
        costI: this.dataFood[this.foodSelect].cost,
      };
      this.total += a.cost;
      this.orderA.push(a);
      this.quantity = 0;
      this.foodSelect = '';
      console.table(this.orderA);
    },

    EditOrder(t) {
      this.total -= this.orderA[t].cost;
      this.orderA.splice(t, 1);
    },

    Total() {
      this.total = 0;
      for (let i = 0; i < this.orderA.length; i++) {
        this.total += this.orderA[i].cost;
      }
    },

    async AddOrder() {
      let str = '';
      let varr = 0;
      for (let i = 0; i < this.orderA.length; i++) {
        varr += this.orderA[i].quantity;
        str += this.orderA[i].name + ' ' + this.orderA[i].quantity + '|';
      }
      str = str.slice(0, -1);
      if (this.room_no == '' || this.cardNo == '') {
        toast({
          message: 'Room/Card no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.date == '' || this.time == '') {
        toast({
          message: 'Time no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (str == '') {
        toast({
          message: 'Order is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.req == '') {
        this.req = 'NA';
      }
      this.total = parseInt(this.total + this.total * (this.hotel.room_service_tax / 100) + this.total * (this.hotel.room_service_sc / 100));
      let customer = [];
      await api
        .get(`api/reservation/${this.res_var}/`)
        .then((response) => {
          customer = response.data;
          console.log(customer);
        })
        .catch((error) => {
          console.log(error);
        });
      customer.total_due = parseInt(this.total) + parseInt(customer.total_due);
      customer.total_amount = parseInt(this.total) + parseInt(customer.total_amount);
      await api.put(`api/reservation/${this.res_var}/`, customer).catch((error) => {
        console.log(error);
      });
      const c = {
        room_no: this.room_no,
        card_no: this.cardNo,
        date: this.date,
        time: this.time,
        special_request: this.req,
        total_bill: this.total,
        status: 'pending',
        details: str,
      };
      await api
        .post('/api/food/', c)
        .then(
          toast({
            message: 'Order Added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => {
          console.log(err);
        });
      await this.jeAdd();
      let x = [];
      await api
        .get(`api/last_food/`)
        .then((response) => {
          x = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      const q = {
        type_bill: 'room_sevice',
        cost: this.total,
        discount: 0,
        bill_item: 'room_sevice' + ' ' + x.id,
        item_quantity: varr,
        trx_no: '',
        trx_type: '',
        card_validity: null,
        card_no: this.cardNo,
        addl_discount: 0,
        vat_amount: this.hotel.room_service_tax,
        sc_amount: this.hotel.room_service_sc,
        bill_category: 'debit',
        special_discount: 0,
        special_discount_reason: '',
      };
      await api
        .post('/api/bills/', q)
        .then(() => {})
        .catch((err) => {
          toast({
            message: 'Bill not sent',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(err);
        });
      this.room_no = '';
      this.cardNo = '';
      this.req = '';
      this.orderA = [];
      this.total = 0;
      (this.foodT = []), this.getDataFoodCost();

      this.foodModal = false;
    },

    FoodModal() {
      this.date = new Date().toISOString().substr(0, 10);
      this.time = new Date().toISOString().substr(11, 5);
      this.foodModal = true;
    },

    async Remove(t) {
      await api
        .delete(`/api/food/${t}/`)
        .then((response) => {
          toast({
            message: 'Order deleted',
            type: 'is-warning',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          });
          console.log(response);
          this.getDataFoodCost();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    Details(t) {
      this.orderModalNumber = t;
      for (let j = 0; j < this.dataFoodCost.length; j++) {
        if (this.dataFoodCost[j].id == t) {
          this.roomM = this.dataFoodCost[j].room_no;
          this.reqM = this.dataFoodCost[j].special_request;
          this.statusM = this.dataFoodCost[j].status;
          let a = this.dataFoodCost[j].details;
          let b = a.split('|');
          for (let i = 0; i < b.length; i++) {
            let c = b[i].split(' ');
            const d = {
              n: c[0],
              a: c[1],
            };
            this.orderD.push(d);
          }
          console.log(this.orderD);
          break;
        }
      }
      this.orderModal = true;
    },

    async orderDelivered() {
      await api
        .put(`api/food/${this.orderModalNumber}/`, { status: 'delivered' })
        .then(() => {
          toast({
            message: 'Order Delivered',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.getDataFoodCost();
      this.orderD = [];
      this.orderModal = false;
    },
    async jeAdd() {
      let x = [];
      let dataTT = [];
      let dataCAO = [];
      // let user = [];
      // await api
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })

      await api
        .get(`api/last_food/`)
        .then((response) => {
          x = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'room_service' && dataCAO[i].type === 'Debit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Room Service',
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'demo',
        reference: this.cardNo,
        credit: 0,
        debit: this.total,
        memo: x.id,
        journal_id: c,
        auto_journal: true,
      };
      await api.post('/api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>