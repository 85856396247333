<template>
  <div class="min-h-screen h-full bg-gray-200">
    <div class="grid grid-cols-4 gap-x-2">
      <div class="h-[55px] col-span-4 border-2 bg-accentM">
        <ol class="list-reset flex mt-3 ml-5">
          <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li><span @click="this.$router.push('/purchase')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Purchase</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li class="text-white text-xl">Purchase Pre GRN</li>
        </ol>
      </div>

      <div class="col-span-4 mt-4 ml-8">
        <!-- <button @click="true" class="bg-green-300 hover:bg-green-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Draft</button> -->
        <button @click="SavePO()" class="bg-button shadow-lg hover:bg-button2 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Save</button>
        <button @click="this.$router.push('/purchase')" class="bg-red-400 shadow-lg hover:bg-red-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Back</button>
      </div>

      <div v-for="(d, i) in dataPrint" :key="i" class="col-span-4 my-1 m-8 rounded">
        <!-- bg-white -->
        <div class="grid grid-cols-10">
          <div class="col-span-2 bg-white rounded-xl my-2 shadow-lg">
            <div class="my-10">
              <p class="text-2xl my-2 underline">Supperlier Details</p>
              <p class="text-xl my-2">{{ d.s.name }}</p>
              <p class="text-lg my-2">Phone: {{ d.s.phone }}</p>
              <p class="text-lg my-2">Address: {{ d.s.address }}</p>
            </div>
          </div>
          <div class="col-span-8">
            <div class="grid grid-cols-2 my-2">
              <div class="col-span-2 relative overflow-x-auto sm:rounded-lg mx-5 min-h-4/5 shadow-lg">
                <table class="w-full text-sm text-left text-gray-500">
                  <thead class="text-xs uppercase bg-gray-600">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-white">Item name</th>
                      <th scope="col" class="px-6 py-3 text-white">Total Amount</th>
                      <th scope="col" class="px-6 py-3 text-white">Expected Quantity</th>
                      <th scope="col" class="px-6 py-3 text-white">Revieved Quantity</th>
                      <th scope="col" class="px-6 py-3 text-white">Open</th>
                      <th scope="col" class="px-6 py-3 text-white">Remarks</th>
                      <th scope="col" class="px-6 py-3 text-white">ACtion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(p, j) in d.i" :key="j" class="border-b bg-white">
                      <td class="px-6 py-4 md:pl-10 text-gray-700">{{ p.item }}</td>
                      <td class="px-6 py-4 md:pl-10 text-gray-700">{{ parseInt(p.supplierSelectedOther) + parseInt(p.supplierSelectedtax) + parseInt(p.supplierSelectedOther) }}</td>
                      <td class="px-6 py-4 md:pl-10 text-gray-700">{{ p.quantity }}</td>
                      <td class="px-6 py-4 md:pl-10 text-gray-700"><input v-model="p.quantityReceived" class="px-2 py-1 border border-black rounded" @keyup="Check(i, j)" type="number" placeholder="revieved quantity" /></td>
                      <td class="px-6 py-4 md:pl-10 text-gray-700">{{ p.quantityOpen }}</td>
                      <td class="px-6 py-4 md:pl-10 text-gray-700"><input v-model="p.remarksReceived" class="px-2 py-1 border border-black rounded" type="text" placeholder="remarks" /></td>
                      <td class="px-6 py-4 md:pl-10 text-gray-700"><button v-if="p.receivedBoolean == false" @click="Save(i, j)" class="py-1 px-2 bg-button text-black rounded-md hover:bg-button3 hover:text-white hover:scale-105 text-base">Add</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="opacity-90 fixed inset-0 z-40 bg-black"></div>
    <div v-if="loading">
      <div class="w-full fixed block top-0 right-0 z-50" style="margin-top: 42vh; height: 20vh">
        <!-- <div class="flex items-center h-2 bg-blue-500 mt-[7vh]" :style="{ width: progress + '%' }"></div> -->
        <div class="flex items-center justify-center">
          <div class="loader items-center"></div>
        </div>
        <p class="text-white text-4xl mt-2 animate-bounce">Loading</p>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';
// import html2pdf from 'html2pdf-jspdf2';
// import { notify } from 'notiwind';

export default {
  components: {},

  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      id: this.$route.params.id,

      dataE: [],
      dataS: [],
      dataStatus: [],
      dataStatus2: [],
      dataI: [],
      dataTable: [],
      dataPurchase: [],
      dataPurchaseItem: [],
      dataCost: [],
      dataCOA: [],
      dataLineType: [],
      dataProject: [],
      dataPrint: [],

      loading: false,
      progress: 0,
    };
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      // console.log(this.id);
      await api
        .get(`api/item/`)
        .then((res) => {
          this.dataI = res.data;
          console.table(this.dataI);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/cost_center/`)
        .then((res) => {
          this.dataCost = res.data;
          //   console.table(this.dataCost);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/coa/`)
        .then((res) => {
          this.dataCOA = res.data;
          //   console.table(this.dataCOA);
        })
        .catch((error) => {
          console.log(error);
        });
      await api.get(`api/line_type/`).then((res) => {
        this.dataLineType = res.data;
        // console.table(this.dataLineType);
      });
      await api
        .get(`api/project/`)
        .then((res) => {
          this.dataProject = res.data;
          //   console.table(this.dataProject);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/supplier/`)
        .then((res) => {
          this.dataS = res.data;
          //   console.table(this.dataS);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=po&statusCode=&statusName=approved`)
        .then((res) => {
          this.dataStatus = res.data;
          if (this.dataStatus == '') {
            alert('Status not Set');
          }
          // console.table(this.dataStatus);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=po&statusCode=&statusName=complete`)
        .then((res) => {
          this.dataStatus2 = res.data;
          if (this.dataStatus2 == '') {
            alert('Status not Set');
          }
          // console.table(this.dataStatus);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/pruchase/${this.id}/`)
        .then((res) => {
          this.dataPurchase = res.data;
          // console.log(this.dataPurchase);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/requested_item/?orderId=${this.id}&status=${this.dataStatus[0].id}`)
        .then((res) => {
          this.dataPurchaseItem = res.data;
          // console.table(this.dataPurchaseItem);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        this.dataPurchaseItem[i].paymentTerms = '';
      }
      // console.table(this.dataPurchaseItem);
      let a = [],
        b = [];
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        a.push(this.dataPurchaseItem[i].supplierSelected);
      }
      a.forEach((e) => {
        if (!b.includes(e)) {
          b.push(e);
        }
      });
      for (let i = 0; i < b.length; i++) {
        let c = { s: [], i: [] };
        for (let j = 0; j < this.dataS.length; j++) {
          if (this.dataS[j].id == b[i]) {
            c.s = this.dataS[j];
            break;
          }
        }
        for (let j = 0; j < this.dataPurchaseItem.length; j++) {
          if (this.dataPurchaseItem[j].supplierSelected == b[i]) {
            c.i.push(this.dataPurchaseItem[j]);
          }
        }
        this.dataPrint.push(c);
      }
      // console.log(this.dataPrint);
    },
    Check(i, j) {
      if (this.dataPrint[i].i[j].quantityReceived < 0) {
        this.dataPrint[i].i[j].quantityReceived = '';
        this.dataPrint[i].i[j].quantityOpen = this.dataPrint[i].i[j].quantity;
        this.Toast2();
        return;
      }
      if (this.dataPrint[i].i[j].quantityReceived <= this.dataPrint[i].i[j].quantity) {
        this.dataPrint[i].i[j].quantityOpen = this.dataPrint[i].i[j].quantity - this.dataPrint[i].i[j].quantityReceived;
      } else {
        this.dataPrint[i].i[j].quantityReceived = '';
        this.dataPrint[i].i[j].quantityOpen = this.dataPrint[i].i[j].quantity;
        return;
      }
    },
    async Save(ii, jj) {
      if (this.dataPrint[ii].i[jj].quantityOpen == 0) {
        this.dataPrint[ii].i[jj].receivedBoolean = true;
      }
      for (let j = 0; j < this.dataI.length; j++) {
        if (this.dataI[j].name == this.dataPrint[ii].i[jj].item) {
          await api
            .get(`api/prod_raw/?category=&item=${this.dataI[j].name}`)
            .then((res) => {
              let a = res.data;
              if (a == '') {
                alert('Not exsist in inventory');
                return;
              } else {
                console.log(a);
                let b = {
                  stockI: this.dataPrint[ii].i[jj].quantityReceived,
                  stock: this.dataPrint[ii].i[jj].quantityReceived,
                  price: (parseInt(this.dataPrint[ii].i[jj].supplierSelectedOther) + parseInt(this.dataPrint[ii].i[jj].supplierSelectedtax) + parseInt(this.dataPrint[ii].i[jj].supplierSelectedOther)) / this.dataPrint[ii].i[jj].quantity,
                  date: this.date,
                  op: this.dataPrint[ii].i[jj].orderId,
                  batch: '',
                  projectName: this.dataPrint[ii].i[jj].projectName,
                  prod: a[0].id,
                  project: this.dataPrint[ii].i[jj].project,
                };
                console.log(b);
                api
                  .post(`api/prod_det/`, b)
                  .then((res) => {
                    console.log('data', res.data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                api
                  .put(`api/requested_item/${this.dataPrint[ii].i[jj].id}/`, this.dataPrint[ii].i[jj])
                  .then((res) => {
                    console.log(res.data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          this.getData;
        }
      }
    },
    async SavePO() {
      this.LoadData();
      let dd = this.dataStatus2[0].id;
      let ee = this.dataStatus2[0].statusCode;
      let ff = this.dataStatus2[0].statusName;
      this.dataPurchase.status = dd;
      this.dataPurchase.statusC = ee;
      this.dataPurchase.statusName = ff;
      await api
        .put(`api/pruchase/${this.id}/`, this.dataPurchase)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        this.dataPurchaseItem[i].status = dd;
        this.dataPurchaseItem[i].statusC = ee;
        this.dataPurchaseItem[i].statusName = ff;
        this.dataPurchaseItem[i].quantityOpen = 'NA';
        await api
          .put(`api/requested_item/${this.dataPurchaseItem[i].id}/`, this.dataPurchaseItem[i])
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    LoadData() {
      this.loading = true;
      const intervalId = setInterval(() => {
        if (this.progress >= 100) {
          clearInterval(intervalId);
          this.loading = false;
          this.progress = 0;
        } else {
          this.progress += 10;
        }
      }, 200);
    },
  },
};
</script>
<style scoped>
/* .loader { */
/* border: 16px solid #f3f3f3; Light grey */
/* border-top: 16px solid #3498db; Blue */
/* border-radius: 50%; */
/* width: 120px; */
/* height: 120px; */
/* animation: spin 2s linear infinite; */
/* } */

/* @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
</style>