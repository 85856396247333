<template>
  <div style="height: 100%">
    <!-- Title -->
    <div class="columns" style="height: 80px">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%">
          <div style="float: left"><p style="font-size: 24px">Picture Setup</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="$router.push('/settings')">Back</button></div>
        </div>
      </div>
    </div>

    <div class="columns" style="margin-top: 25px; margin-left: 30px">
      <div class="column is-3">
        <p style="float: left; font-size: 24px; margin-left: 5px">Reservation</p>
        <img :src="data.back01" style="width: 100%; height: 70%" />
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 300px">
              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload1" />
            </p>
          </div>
        </div>
      </div>

      <div class="column is-1"></div>

      <div class="column is-3">
        <p style="float: left; font-size: 24px; margin-left: 5px">Laundry</p>
        <img :src="data.back02" style="width: 100%; height: 70%" />
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 300px">
              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload2" />
            </p>
          </div>
        </div>
      </div>

      <div class="column is-1"></div>

      <div class="column is-3">
        <p style="float: left; font-size: 24px; margin-left: 5px">Room Service</p>
        <img :src="data.back03" style="width: 100%; height: 70%" />
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 300px">
              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload3" />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" style="margin-left: 30px">
      <div class="column is-3">
        <p style="float: left; font-size: 24px; margin-left: 5px">Maintanance</p>
        <img :src="data.back04" style="width: 100%; height: 70%" />
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 300px">
              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload4" />
            </p>
          </div>
        </div>
      </div>

      <div class="column is-1"></div>

      <div class="column is-3">
        <p style="float: left; font-size: 24px; margin-left: 5px">Accounts</p>
        <img :src="data.back05" style="width: 100%; height: 70%" />
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 300px">
              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload5" />
            </p>
          </div>
        </div>
      </div>

      <div class="column is-1"></div>

      <div class="column is-3">
        <p style="float: left; font-size: 24px; margin-left: 5px">Concierge & Complain</p>
        <img :src="data.back06" style="width: 100%; height: 70%" />
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 300px">
              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload6" />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" style="margin-left: 30px">
      <div class="column is-3">
        <p style="float: left; font-size: 24px; margin-left: 5px">Bellboy & Luggage</p>
        <img :src="data.back08" style="width: 100%; height: 70%" />
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 300px">
              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload8" />
            </p>
          </div>
        </div>
      </div>

      <div class="column is-1"></div>

      <div class="column is-3">
        <p style="float: left; font-size: 24px; margin-left: 5px">Lost&Found</p>
        <img :src="data.back07" style="width: 100%; height: 70%" />
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 300px">
              <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload7" />
            </p>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <button class="button is-big" style="background-color: #26ade4; color: white; position: relative; top: 50%; left: 50%" @click="Submit()">Update</button>
      </div>
    </div>
  </div>
</template>


<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.data = res.data;
          console.log(this.data);
        })
        .catch((err) => console.log(err));
    },
    async Submit() {
      await this.uploadImageHandler();
      this.data = [];
      this.getData();
    },
    OnFileUpload1(event) {
      this.pot1 = event.target.files[0];
      console.log(this.pot1);
    },
    OnFileUpload2(event) {
      this.pot2 = event.target.files[0];
      console.log(this.pot2);
    },
    OnFileUpload3(event) {
      this.pot3 = event.target.files[0];
      console.log(this.pot3);
    },
    OnFileUpload4(event) {
      this.pot4 = event.target.files[0];
      console.log(this.pot4);
    },
    OnFileUpload5(event) {
      this.pot5 = event.target.files[0];
      console.log(this.pot5);
    },
    OnFileUpload6(event) {
      this.pot6 = event.target.files[0];
      console.log(this.pot6);
    },
    OnFileUpload7(event) {
      this.pot7 = event.target.files[0];
      console.log(this.pot7);
    },
    OnFileUpload8(event) {
      this.pot8 = event.target.files[0];
      console.log(this.pot8);
    },
    async uploadImageHandler() {
      const fd = new FormData();

      if (this.pot1 != undefined) {
        fd.append('back01', this.pot1);
      }

      if (this.pot2 != undefined) {
        fd.append('back02', this.pot2);
      }

      if (this.pot3 != undefined) {
        fd.append('back03', this.pot3);
      }
      if (this.pot4 != undefined) {
        fd.append('back04', this.pot4);
      }
      if (this.pot5 != undefined) {
        fd.append('back05', this.pot5);
      }
      if (this.pot6 != undefined) {
        fd.append('back06', this.pot6);
      }
      if (this.pot7 != undefined) {
        fd.append('back07', this.pot7);
      }
      if (this.pot8 != undefined) {
        fd.append('back08', this.pot8);
      }

      fd.append('hotel_id', this.data.id);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      await api.put(`auth/hotel_logo/1/`, fd, config).then((res) => {
        toast({
          message: 'Saved',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        console.log('pic upload: ', res.data);
      });
    },
  },
};
</script>

<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>