<template>
  <div class="home">
    <div class="columns" style="">
      <div class="column is-12">
        <div class="box" style="height: 70px; display: inline-block; width: 100%">
          <div style="float: left"><p style="font-size: 24px">Dashboard</p></div>
        </div>
      </div>
    </div>

    <div class="columns">
      <!-- Blank -->
      <div class="column is-12" style="height: 200px">
        <div class="box ml-3" style="height: 100%; width: 99%">
          <div class="columns">
            <div class="column is-3" style="height: 150px; width: 23%; margin: 5px 15px 5px 15px; background-color: #31cbd6">
              <div class="content is-vcentered has-text-centered">
                <p style="font-size: 28px"><b>Clean Room</b></p>
                <p style="font-size: 25px">
                  <b>{{ roomStatus[0] }}</b>
                </p>
              </div>
            </div>
            <div class="column is-3" style="height: 150px; width: 23%; margin: 5px 15px 5px 15px; background-color: #2ccc47">
              <div class="content is-vcentered has-text-centered">
                <p style="font-size: 28px"><b>Not Cleaned Room</b></p>
                <p style="font-size: 25px">
                  <b>{{ roomStatus[1] }}</b>
                </p>
              </div>
            </div>
            <div class="column is-3" style="height: 150px; width: 23%; margin: 5px 15px 5px 15px; background-color: #bcf069">
              <div class="content is-vcentered has-text-centered">
                <p style="font-size: 28px"><b>Under Maintenance</b></p>
                <p style="font-size: 25px">
                  <b>{{ roomStatus[2] }}</b>
                </p>
              </div>
            </div>
            <div class="column is-3" style="height: 150px; width: 23%; margin: 5px 15px 5px 15px; background-color: #f06969">
              <div class="content is-vcentered has-text-centered">
                <p style="font-size: 28px"><b>Urgent</b></p>
                <p style="font-size: 25px">
                  <b>{{ roomStatus[3] }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Room Status -->
      <!-- <div class="column is-5" style="height:45vh;">
        <div class="box" style="height:100%; margin-left:-10px; width:99%; border: 1px solid #cfd8e8;">
          <div class="container">
            <p class="box is-size-25 has-text-weight-bold">Room Status</p>
          </div>
          <table class="table  mt-4 md-4" style="width:99%; height:80%">
            <thead>
              <tr>
                <th>Room</th><th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Clean Room</td><td>{{roomStatus[0]}}</td>
              </tr>
              <tr>
                <td>Not Clean</td><td>{{roomStatus[1]}}</td>
              </tr>
              <tr>
                <td>Maintenance</td><td>{{roomStatus[2]}}</td>
              </tr>
              <tr>
                <td>Urgent</td><td>{{roomStatus[3]}}</td>
              </tr>
            </tbody>
          </table>
        </div>    
      </div> -->
    </div>

    <!-- Room Filter -->
    <div class="column is-12" style="height: 64vh; margin-top: 10px">
      <div class="columns">
        <div class="column is-6">
          <div class="columns">
            <div class="column is-6">
              <div class="select is-fullwidth" style="">
                <select v-model="filterSelection" @change="filterRooms()">
                  <option value="" hidden><b>Filter Selection:</b></option>
                  <option v-for="filter in filterTypes" :key="filter.id">{{ filter }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Room status abr -->
        <!-- <div class="column is-6">
          <div style=" width:100p%; height:100%; ">
            <div style="display: flex; flex-direction: row; justify-content: left;">
              <div style="display:flex" data-tooltip="Clean">
                <div style="width:25px; height: 25px; background-color:#31cbd6; padding-top:0px;"></div>
                <p style="font-size:20px; margin-left:10px;">CL</p>
              </div>
              <div style="display:flex" data-tooltip="Not Clean">
                <div style="width:25px; height: 25px; background-color:#2ccc47; padding-top:0px; margin-left:10px;"></div>
                <p style="font-size:20px; margin-left:10px;">NC</p>
              </div>
              <div style="display:flex" data-tooltip="Maintenence">
                <div style="width:25px; height: 25px; background-color:#bcf069; padding-top:0px; margin-left:10px;"></div>
                <p style="font-size:20px; margin-left:10px;">MT</p>
              </div>
              <div style="display:flex" data-tooltip="Urgent">
                <div style="width:25px; height: 25px; background-color:#f06969; padding-top:0px; margin-left:10px;"></div>
                <p style="font-size:20px; margin-left:10px;">UR</p>
              </div>
            </div>              
          </div>
        </div> -->
      </div>

      <!-- Roomstatus -->
      <div class="box" style="background-repeat: no-repeat; background-size: cover; height: 100%; border: 1px solid #cfd8e8; height: 100%; width: 100%; overflow-y: scroll; margin-top: -10px; border: 1px solid #cfd8e8">
        <div class="container" style="height: 100%; padding-top: 0px; margin-left: -10px">
          <ul style="margin-left: 8px" class="rooms" v-for="room in roomView" :key="room.id">
            <li>
              <div :class="roomColor(room.status_hk)" v-on:click="roomModal(room.room_no)" style="height: 45px; width: 85px; margin: 5px 0px 2px 10px; border: 1px solid; border-radius: 4px; text-align: left; box-shadow: 2px 2px 2px black">
                <div style="display: flex; margin-top: 5px">
                  <div style="margin-left: 10px">
                    <p style="font-size: 25px">{{ room.room_no }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- Modal: Room -->
      <div v-if="rejectModal == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="(rejectModal = false), (roomCleanStatus = false), (validated = false), (roomC = false)"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 85vw; margin-right: 0">
            <div class="box" style="height: 100vh; background: #f6f7fb">
              <!-- Title: Room number -->
              <div class="columns">
                <div class="column is-12">
                  <div class="box">
                    <p style="font-size: 30px; display: flex; justify-content: left">Room no: {{ roomModalNumber }}</p>
                  </div>
                </div>
              </div>

              <!-- Item Box -->
              <div style="float: center">
                <div class="columns">
                  <!-- Column: Washroom -->
                  <div v-if="roomC == false" class="column is-3"></div>
                  <div v-else class="column is-3">
                    <div class="box" style="height: 80vh">
                      <!-- Title -->
                      <div class="box" style="width: 100%; height: 60px; box-shadow: 2px 2px 2px 2px #26ade4">
                        <h1 style="font-weight: bold; font-size: 22px">Washroom</h1>
                      </div>

                      <!-- Loop -->
                      <div v-for="(a, i) in dataI" :key="a">
                        <div v-if="a.item_type == 'washroom'">
                          <!-- for loop data -->
                          <div class="field" style="margin: 5px">
                            <div class="field-label is-normal">
                              <label class="label" style="font-weight: bold; float: left; padding: 4px; color: black"
                                >{{ a.name.toUpperCase() }} <label style="color: #26ade4"> :{{ a.defaultQuantity }}</label></label
                              >
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <input type="number" class="input" min="0" v-model="items[i]" style="width: 70px; float: right; border: 2px solid; border-radius: 4px; text-align: right" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Column: Bedroom -->
                  <div v-if="roomC == false" class="column is-3">
                    <p style="font-size: 34px; margin-top: 30vh">Room is Vacant</p>
                  </div>
                  <div v-else class="column is-3">
                    <div class="box" style="height: 80vh">
                      <!-- Title -->
                      <div class="box" style="width: 100%; height: 60px; box-shadow: 2px 2px 2px 2px #26ade4">
                        <h1 style="font-weight: bold; font-size: 22px">Bedroom</h1>
                      </div>

                      <!-- Loop -->
                      <div v-for="(a, i) in dataI" :key="a">
                        <div v-if="a.item_type == 'bedroom'">
                          <!-- for loop data -->
                          <div class="field" style="margin: 5px">
                            <div class="field-label is-normal">
                              <label class="label" style="font-weight: bold; float: left; padding: 4px; color: black"
                                >{{ a.name.toUpperCase() }} <label style="color: #26ade4"> :{{ a.defaultQuantity }}</label></label
                              >
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <input type="number" class="input" min="0" v-model="items[i]" style="width: 70px; float: right; border: 2px solid; border-radius: 4px; text-align: right" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Column: Mini Bar -->
                  <div v-if="roomC == false" class="column is-3"></div>
                  <div v-else class="column is-3">
                    <div class="box" style="height: 80vh">
                      <!-- Title -->
                      <div class="box" style="width: 100%; height: 60px; box-shadow: 2px 2px 2px 2px #26ade4">
                        <h1 style="font-weight: bold; font-size: 22px">Mini Bar</h1>
                      </div>

                      <!-- Loop -->
                      <div v-for="(a, i) in dataI" :key="a">
                        <div v-if="a.item_type == 'miniBar'">
                          <!-- for loop data -->
                          <div class="field" style="margin: 5px">
                            <div class="field-label is-normal">
                              <label class="label" style="font-weight: bold; float: left; padding: 4px; color: black"
                                >{{ a.name.toUpperCase() }} <label style="color: #26ade4"> :{{ a.defaultQuantity }}</label></label
                              >
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <input type="number" class="input" min="0" v-model="items[i]" @change="costT(id, i)" @keyup="costT(id, i)" style="width: 70px; float: right; border: 2px solid; border-radius: 4px; text-align: right" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Side -->
                  <div class="column is-3">
                    <div style="height: 55vh">
                      <div class="field">
                        <label class="label" style="float: left; font-size: 20px">Damage:</label>
                      </div>
                      <textarea class="textarea" placeholder="damage" v-model="damage" style="width: 100%"></textarea>

                      <div style="margin-top: 25vh">
                        <div v-if="validated == false" style="margin-bottom: 5vh">
                          <button class="button" style="background-color: turquoise; border: none; border-radius: 5px; color: #333; /* Dark grey */ padding: 15px 32px" @click.prevent="roomCleanApi()" :disabled="roomCleanStatus == true">
                            <p style="font-size: 20px" v-if="roomCleanStatus == false">Press to Clean</p>
                            <p style="font-size: 20px" v-else>Cleaned</p>
                          </button>
                        </div>

                        <div v-if="roomC == false"></div>
                        <div v-else style="font-size: 20px">
                          <h2>Mini Bar Cost(s): {{ cost }}</h2>
                        </div>

                        <div style="margin-top: 20px">
                          <button class="button" style="margin-right: 5px; background-color: #26ade4; color: white; height: 50px; width: 150px; font-size: 22px" @click.prevent="submitI">Submit</button>
                          <button class="button" style="margin-left: 5px; background-color: #de0059; color: white; height: 50px; width: 150px; font-size: 22px" @click.prevent="(rejectModal = false), (roomCleanStatus = false), (validated = false), (roomC = false)">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  name: 'Home',
  data() {
    return {
      roomData: [],
      filterSelection: '',
      filterTypes: ['all', 'clean', 'notClean', 'maintenance', 'urgent'],
      filterRoomSelection: '',
      roomView: null,
      rejectModal: false,
      tempAdd: null,
      roomStatus: [0, 0, 0, 0],
      roomModalNumber: 0,
      dataI: [],
      items: [],
      dataR: [],
      miniBarC: [],
      cost: 0,
      costF: 0,
      roomCleanStatus: false,
      validated: false,
      damage: '',
      itemV: 0,
      hotel: [],
      roomC: false,
    };
  },
  created() {
    this.roomStatus = [0, 0, 0, 0];
    this.getRoomInfo();
  },

  mounted() {
    this.getDataR();
    this.getDataF();
    this.getHotel();
  },

  methods: {
    async getHotel() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.hotel = res.data;
          console.log(this.hotel);
        })
        .catch((err) => console.log(err));
    },
    async getDataF() {
      await api
        .get('api/card/')
        .then((response) => {
          this.dataR = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let varr = [];
      for (let i = 0; i < this.dataR.length; i++) {
        if (this.dataR[i].checked_in == true) {
          const a = {
            card_no: this.dataR[i].card_no,
            room_no: parseInt(this.dataR[i].room_no),
            rev_id: this.dataR[i].rev_id,
          };
          varr.push(a);

          if (this.dataR[i].secondary_room != '') {
            console.log(this.dataR[i].secondary_room);
            let b = this.dataR[i].secondary_room.split(',');
            for (let j = 0; j < b.length; j++) {
              const c = {
                card_no: this.dataR[i].card_no,
                room_no: parseInt(b[j]),
                rev_id: this.dataR[i].rev_id,
              };
              varr.push(c);
            }
          }
        }
      }
      this.dataR = varr;
      console.table(this.dataR);
    },

    async getDataR() {
      await api
        .get('api/item/')
        .then((response) => {
          this.dataI = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataI.length; i++) {
        this.items[i] = 0;
      }
    },

    async getRoomInfo() {
      await api.get('api/room/').then((res) => {
        this.roomData = res.data;
      });
      this.roomView = this.roomData;
      for (let i = 0; i < this.roomData.length; i++) {
        if (this.roomData[i].status_hk == 'clean') {
          this.roomStatus[0]++;
        }
        if (this.roomData[i].status_hk == 'notClean') {
          this.roomStatus[1]++;
        }
        if (this.roomData[i].status_hk == 'maintenance') {
          this.roomStatus[2]++;
        }
        if (this.roomData[i].status_hk == 'urgent') {
          this.roomStatus[3]++;
        }
      }
    },

    roomColor(status) {
      if (status == 'clean') {
        return { clean: 'red' };
      }
      if (status == 'notClean') {
        return { notClean: 'green' };
      }
      if (status == 'maintenance') {
        return { maintenance: 'blue' };
      }
      if (status == 'urgent') {
        return { urgent: 'cyan' };
      }
    },

    filterRooms() {
      console.log(this.filterRoomSelection);
      console.log(this.filterSelection);
      if (this.filterSelection != 'all' && this.filterRoomSelection != 'all') {
        if (this.filterRoomSelection != '' && this.filterSelection == '') {
          this.roomView = this.roomData.filter((room) => room.room_type == this.filterRoomSelection);
        } else if (this.filterSelection != '' && this.filterRoomSelection == '') {
          this.roomView = this.roomData.filter((room) => room.status_hk == this.filterSelection);
        } else {
          this.roomView = this.roomData.filter((room) => room.status_hk == this.filterSelection && room.room_type == this.filterRoomSelection);
        }
      } else {
        this.roomView = this.roomData;
        this.filterSelection = '';
        this.filterRoomSelection = '';
      }
    },
    roomModal(t) {
      for (let i = 0; i < this.roomData.length; i++) {
        if (t == this.roomData[i].room_no) {
          if (this.roomData[i].status_hk == 'clean') {
            this.roomCleanStatus = true;
          } else if (this.roomData[i].status_hk == 'maintenance') {
            this.validated = true;
          }
        }
      }
      for (let i = 0; i < this.dataR.length; i++) {
        if (t === this.dataR[i].room_no) {
          this.roomC = true;
        }
      }
      this.roomModalNumber = t;
      this.rejectModal = true;
    },

    async roomCleanApi() {
      // if (this.roomCleanStatus==true) {
      await api
        .put(`/api/room/${this.roomModalNumber}/`, { status_hk: 'clean', room_no: this.roomModalNumber })
        .then(
          toast({
            message: 'Status changed to Clean',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          })
        )
        .catch((err) => {
          console.log(err);
        });
      this.roomCleanStatus = true;
      // }
      // else{
      //   await api
      //         .put(`/api/room/${this.roomModalNumber}/`,{status_hk: "notClean", room_no:this.roomModalNumber})
      //         .then(
      //           toast({
      //               message: 'Status changed to Not Clean',
      //               type: 'is-warning',
      //               dismissible: true,
      //               pauseOnHover: true,
      //               duration: 1000,
      //               position: 'bottom-right',
      //           })
      //         )
      //         .catch(err => {
      //             console.log(err)
      //         })
      // }
      this.roomStatus = [0, 0, 0, 0];
      this.getRoomInfo();
    },

    async submitI() {
      let card = '';
      let res_var = '';
      let check = false;
      console.log(this.roomModalNumber);
      for (let i = 0; i < this.dataR.length; i++) {
        if (this.roomModalNumber == this.dataR[i].room_no) {
          card = this.dataR[i].card_no;
          res_var = this.dataR[i].rev_id;
          check = true;
          break;
        }
      }
      if (check == false) {
        toast({
          message: 'Room is empty',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }

      if (this.damage != '') {
        const aa = {
          room_no: this.roomModalNumber,
          damage_des: this.damage,
        };
        await api
          .post('/api/damage/', aa)
          .then(
            toast({
              message: 'Damage Sumiited',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          )
          .catch((err) => {
            console.log(err);
          });
      }

      if (this.cost > 0) {
        const q = {
          type_bill: 'room_service',
          cost: this.costF,
          discount: 0,
          bill_item: 'Mini Bar',
          item_quantity: this.itemV,
          trx_no: '',
          trx_type: '',
          card_validity: null,
          card_no: card,
          addl_discount: 0,
          vat_amount: 0,
          sc_amount: 0,
          bill_category: 'debit',
          special_discount: 0,
          special_discount_reason: '',
        };
        await api
          .post('/api/bills/', q)
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }

      let customer = [];
      await api
        .get(`api/reservation/${res_var}/`)
        .then((response) => {
          customer = response.data;
          console.log(customer);
        })
        .catch((error) => {
          console.log(error);
        });
      customer.total_due = parseInt(this.costF) + parseInt(customer.total_due);
      customer.total_amount = parseInt(this.costF) + parseInt(customer.total_amount);
      await api.put(`api/reservation/${res_var}/`, customer).catch((error) => {
        console.log(error);
      });

      //Jornal
      let dataTT = [];
      let dataCAO = [];
      // let user = [];
      // await api
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'mini_bar' && dataCAO[i].type === 'Debit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      this.costF = Math.ceil(this.costF);
      console.log('debit', this.costF);
      const a = {
        description: 'Mini Bar',
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'demo',
        reference: 'R' + card,
        credit: 0,
        debit: this.costF,
        memo: this.roomModalNumber,
        journal_id: c,
        auto_journal: true,
      };
      await api.post('/api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });

      for (let i = 0; i < this.dataI.length; i++) {
        const a = {
          room_no: this.roomModalNumber,
          item_stock_quantity: this.dataI[i].name,
          item_used: this.items[i],
          item_bill: this.dataI[i].price * this.items[i],
          discount: '0',
          date: new Date().toISOString().substr(0, 10),
        };
        await api.post('/api/stock/', a).catch((err) => {
          console.log(err);
        });
      }
      toast({
        message: 'Sumiited',
        type: 'is-success',
        dismissible: true,
        pauseOnHover: true,
        duration: 2000,
        position: 'bottom-right',
      });

      for (let i = 0; i < this.dataI.length; i++) {
        this.items[i] = 0;
      }
      this.roomCleanStatus = false;
      this.validated = false;
      this.roomStatus = [0, 0, 0, 0];
      this.damage = '';
      this.itemV = 0;
      this.cost = 0;
      this.costF = 0;
      this.roomC = false;
      this.getRoomInfo();
      this.rejectModal = false;
    },

    costT() {
      this.cost = 0;
      this.itemV = 0;
      this.costF = 0;
      for (let i = 0; i < this.dataI.length; i++) {
        if (this.dataI[i].item_type == 'miniBar') {
          this.cost += this.dataI[i].price * this.items[i];
          this.costF += this.dataI[i].price * this.items[i];
          this.itemV += this.items[i];
          if (this.dataI[i].is_vat == true) {
            this.costF += this.dataI[i].price * (this.dataI[i].vat / 100) * this.items[i];
          }
        }
      }
      console.log(this.cost);
      console.log(this.costF);
    },
  },
};
</script>

<style scoped>
.home {
  height: 100vh;
}

ul li {
  float: left;
}
.clean {
  background-color: #31cbd6;
}
.notClean {
  background-color: #2ccc47;
}
.maintenance {
  background-color: #bcf069;
}
.urgent {
  background-color: #f06969;
}

.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>