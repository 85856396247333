<template>
  <div class="home">
    <div class="columns" style="height: 8vh">
      <div class="column is-12">
        <div class="box" style="height: 8vh; display: inline-block; width: 100%">
          <div style="float: left"><p class="font-semibold" style="font-size: 24px">Dashboard</p></div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 mt-5 gap-2 mx-2">
      <div class="col-span-1 bg-white shadow rounded">
        <div><p class="font-semibold" style="font-size: 22px; margin-bottom: 10px">Rooms</p></div>
        <div style="display: inline-block; width: 100%; margin-left: 5px">
          <div class="grid grid-cols-3">
            <div class="col-span-2">
              <PieChart class="p-2" :chart-data="chartData" />
            </div>
            <div class="col-span-1" style="">
              <p style="font-size: 20px; text-align: left; margin-top: 120px; font-family: Arial"><span style="font-weight: bold">Clean: </span>{{ roomStatus[0] }}</p>
              <p style="font-size: 20px; text-align: left; margin-top: 010px; font-family: Arial"><span style="font-weight: bold">Not Clean: </span>{{ roomStatus[1] }}</p>
              <p style="font-size: 20px; text-align: left; margin-top: 010px; font-family: Arial"><span style="font-weight: bold">Maintenance: </span>{{ roomStatus[2] }}</p>
              <p style="font-size: 20px; text-align: left; margin-top: 010px; font-family: Arial"><span style="font-weight: bold">Urgent: </span>{{ roomStatus[3] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-1 bg-white shadow rounded">
        <div><p class="font-semibold" style="font-size: 22px; margin-bottom: 10px">Guest Info</p></div>
        <div class="columns">
          <div class="column is-1"></div>
          <div class="column is-3" style="">
            <p style="font-size: 20px; text-align: left; margin-left: 10px; margin-top: 140px; font-family: Arial"><span style="font-weight: bold">Checked In: </span>{{ status[0] }}</p>
            <p style="font-size: 20px; text-align: left; margin-left: 10px; margin-top: 010px; font-family: Arial"><span style="font-weight: bold">Resevation: </span>{{ status[1] }}</p>
            <p style="font-size: 20px; text-align: left; margin-left: 10px; margin-top: 010px; font-family: Arial"><span style="font-weight: bold">Checked Out: </span>{{ status[2] }}</p>
          </div>
          <div class="column is-7">
            <BarChart :chart-data="chartDataM" />
          </div>
        </div>
      </div>

      <div class="col-span-1 bg-white shadow rounded">
        <div><p class="font-semibold" style="font-size: 22px; margin-bottom: 10px">Recent Booking</p></div>
        <div><img class="w-full px-10" src="../assets/dashboard_varr.png" alt="" /></div>
      </div>
      <div class="col-span-1 bg-white shadow rounded">
        <div><img class="w-full px-10" src="../assets/dashboard_varrr.png" alt="" /></div>
      </div>
    </div>
  </div>
</template>


<script>
import api from '../boot/axios';
// import { toast } from 'bulma-toast'
import PieChart from '../components/pieChart.ts';
import BarChart from '../components/barChart.ts';
// import DoughnutChart from '../components/doughnutChart.ts'

export default {
  name: 'HomeD',
  components: {
    PieChart,
    BarChart,
    // DoughnutChart
  },
  data() {
    return {
      roomData: [],
      roomView: null,
      roomStatus: [0, 0, 0, 0],
      status: [0, 0, 0],
      dataR: [],
      hotel: [],
      chartData: {
        labels: ['Clean', 'Not Clean', 'Maintenance', 'Ungent'],
        datasets: [
          {
            backgroundColor: ['#03adfc', '#34eb83', '#391d9d', '#fc0303'],
            data: [0, 0, 0, 0],
          },
        ],
      },
      chartDataM: {
        labels: ['Checked In', 'Reserved', 'Checked Out'],
        datasets: [
          {
            label: '',
            backgroundColor: '#7C8CF8',
            data: [0, 0, 0],
          },
        ],
      },
    };
  },
  created() {
    this.getRoomInfo();
  },

  mounted() {
    this.getDataF();
    this.getHotel();
  },

  methods: {
    async getHotel() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.hotel = res.data;
        })
        .catch((err) => console.log(err));
    },
    async getDataF() {
      await api
        .get('api/res_cus_card/')
        .then((response) => {
          this.dataR = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataR.length; i++) {
        if (this.dataR[i].status == 'Checked In') {
          this.status[0]++;
        } else if (this.dataR[i].status == 'reserved') {
          this.status[1]++;
        } else if (this.dataR[i].status == 'Checked Out') {
          this.status[2]++;
        }
      }
      this.chartDataM.datasets[0].data = this.status;
    },

    async getRoomInfo() {
      await api.get('api/room/').then((res) => {
        this.roomData = res.data;
      });
      this.roomView = this.roomData;
      for (let i = 0; i < this.roomData.length; i++) {
        if (this.roomData[i].status_hk == 'clean') {
          this.roomStatus[0]++;
        }
        if (this.roomData[i].status_hk == 'notClean') {
          this.roomStatus[1]++;
        }
        if (this.roomData[i].status_hk == 'maintenance') {
          this.roomStatus[2]++;
        }
        if (this.roomData[i].status_hk == 'urgent') {
          this.roomStatus[3]++;
        }
      }
      this.chartData.datasets[0].data = this.roomStatus;
    },
  },
};
</script>

<style scoped>
.home {
  height: 100vh;
}

ul li {
  float: left;
}
.clean {
  background-color: #31cbd6;
}
.notClean {
  background-color: #2ccc47;
}
.maintenance {
  background-color: #bcf069;
}
.urgent {
  background-color: #f06969;
}

.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>