<template>
  <div style="height: 100%">
    <!-- Title -->
    <div class="columns" style="height: 80px">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%">
          <div style="float: left"><p style="font-size: 24px">Room Setup</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="$router.push('/settings')">Back</button></div>
        </div>
      </div>
    </div>

    <div class="columns" style="height: 90vh; margin-top: 15px">
      <div class="column is-6" style="height: 90vh">
        <div class="box" style="height: 100%; width: 98%; border: 1px; margin-left: 15px">
          <div class="columns" style="height: 30px">
            <div class="column is-10">
              <p style="float: left; font-size: 22px; margin-left: 25px">Room Types</p>
            </div>
            <div class="column is-2">
              <button class="button is-small" style="background-color: #26ade4; color: white; float: right" @click="roomTM = true"><i class="fas fa-solid fa-plus"></i></button>
            </div>
          </div>

          <hr />
          <div class="container" style="height: 100%; margin-left: -10px">
            <ul style="margin: 10px 10px 10px 10px; width: 100%" v-for="(r, index) in dataRT" :key="r.type_name">
              <li>
                <div v-on:click="roomModal(r.type_name)" :style="{ 'background-color': `${dataColor[index]}` }" style="height: 100px; width: 220px; margin: 5px 5px 2px 10px; border: 1px solid; text-align: left">
                  <div style="margin-top: 10px; margin-left: 10px">
                    <div style="font-size: 20px; margin-top: 15px">{{ r.type_name }}</div>
                    <div style="font-size: 18px; margin-top: 7px">Price: {{ r.cost }}</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="column is-6" style="height: 90vh">
        <div class="box" style="height: 100%; border: 1px; margin-right: 5px; overflow-y: scroll">
          <div class="columns" style="height: 30px">
            <div class="column is-10">
              <p style="float: left; font-size: 22px; margin-left: 25px">Rooms</p>
            </div>
            <div class="column is-2">
              <button class="button is-small" style="background-color: #26ade4; color: white; float: right" @click="roomAdd = true"><i class="fas fa-solid fa-plus"></i></button>
            </div>
          </div>

          <hr />
          <table class="table is-fullwidth is-striped">
            <thead>
              <tr>
                <th>Room No</th>
                <th>Room Type</th>
                <th>Adults</th>
                <th>Child</th>
                <th>View</th>
                <th>Extra Bed</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="d in dataR" v-bind:key="d.room_no">
                <td>{{ d.room_no }}</td>
                <td>{{ d.room_type }}</td>
                <td>{{ d.adults }}</td>
                <td>{{ d.child }}</td>
                <td>{{ d.view }}</td>
                <td>
                  <div v-if="d.extra_bed == true">Yes</div>
                  <div v-else>No</div>
                </td>
                <td>
                  <button class="button is-small" style="background-color: #26ade4; color: white" @click="roomEditModal(d.room_no)"><i class="fas fa-solid fa-pen" style="font-size: 10px"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="columns" style="margin: 10px 10px 30px 10px">
      <!-- Tables -->
      <div class="column is-12" style="width: 100%">
        <!-- Button Add -->
        <div class="columns" style="height: 70px">
          <div class="column is-12">
            <div class="box" style="display: inline-block; width: 100%">
              <div style="float: left"><p style="font-size: 22px">Price Change</p></div>
              <button class="button is-small" style="background-color: #26ade4; color: white; float: right" @click="flowAdd = true"><i class="fas fa-solid fa-plus"></i></button>
            </div>
          </div>
        </div>

        <div class="columns" style="margin-top: 25px">
          <div class="colunm is-2"></div>
          <div class="column is-8">
            <div class="box" style="height: 50vh; width: 60vw; overflow-y: scroll; margin-top: 10px; margin-left: 10vw; margin-right: 10vw; border: 1px solid #cfd8e8">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Room Type</th>
                    <th>Proposed Price</th>
                    <th>Proposed Discount</th>
                    <th>Effective Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="d in dataFlow" v-bind:key="d.id">
                    <td>{{ d.room_type }}</td>
                    <td>{{ d.proposedCost }}</td>
                    <td>{{ d.proposedDiscount }}</td>
                    <td>{{ d.activataionDate }}</td>
                    <td>
                      <p v-if="d.status == 'pending'">Pending</p>
                      <p v-else-if="d.status == 'rejected'">Rejected</p>
                      <p v-else>Approved</p>
                    </td>
                    <td>
                      <p v-if="d.status == 'rejected'">
                        <button class="button is-small" style="background-color: #26ade4; color: white" @click="showRejectReason(d)"><i class="fas fa-solid fa-pen" style="font-size: 10px"></i></button>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="colunm is-2"></div>
        </div>
      </div>
    </div>

    <div>
      <!-- Modal: flow room add -->
      <div v-if="flowAdd == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="flowAdd = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 800px; margin-right: 0">
            <div class="box" style="min-height: 100vh">
              <div class="columns">
                <div class="column is-12">
                  <div class="box" style="height: 70px">
                    <p style="font-size: 20px">Flow Room</p>
                  </div>
                </div>
              </div>

              <div class="columns" style="height: 75vh; margin-top: 15px">
                <div class="column is-12">
                  <div class="box" style="height: 75vh; min-height: 40vh">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Room Type:</label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                          <select v-model="room_type" style="width: 300px; height: 40px">
                            <option value="" hidden>Select Room</option>
                            <option v-for="r in dataRT" :key="r.type_name">{{ r.type_name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Price</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="cost" v-model="cost" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Discount</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="discount" v-model="discount" />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Effective Date</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input type="date" class="input" v-model="date" />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Choose Template</label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                          <select v-model="templateValue" style="width: 300px; height: 40px">
                            <option value="" hidden>Select Room</option>
                            <option v-for="r in templateData" :key="r.name" :value="r.name">{{ r.name }} || {{ r.role_concated }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Buttons -->
              <div style="margin-top: 25px">
                <button class="button" @click.prevent="flowAdd = false" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
                <button class="button" style="background-color: #26ade4; color: white" @click.prevent="AddFlow()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal: room add -->
      <div v-if="roomAdd == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="roomAdd = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 800px; margin-right: 0">
            <div class="box" style="min-height: 100vh">
              <div class="columns">
                <div class="column is-12">
                  <div class="box" style="height: 70px">
                    <p style="font-size: 20px">Add Room</p>
                  </div>
                </div>
              </div>

              <div class="columns" style="height: 75vh; margin-top: 15px">
                <div class="column is-12">
                  <div class="box" style="height: 75vh; min-height: 40vh">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Room No:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="room no." v-model="room_no" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Room Type:</label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                          <select v-model="room_type" style="width: 300px; height: 40px">
                            <option value="" hidden>Select Room</option>
                            <option v-for="r in dataRT" :key="r.type_name">{{ r.type_name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Adults</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="number" min="0" v-model="adults" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Child</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="number" min="0" v-model="child" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Floor</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="number" placeholder="floor" v-model="floor" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Features</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="features" v-model="features" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">View</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="view" v-model="view" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Extra Bed:</label>
                      </div>
                      <div class="field-body">
                        <input type="checkbox" v-model="extra_bed" style="margin-top: 15px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Buttons -->
              <div style="margin-top: 25px">
                <button class="button" @click.prevent="roomAdd = false" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
                <button class="button" style="background-color: #26ade4; color: white" @click.prevent="AddRoom()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal: room Edit -->
      <div v-if="roomAddE == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="roomAddE = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 800px; margin-right: 0">
            <div class="box" style="min-height: 100vh">
              <div class="columns">
                <div class="column is-12">
                  <div class="box" style="height: 70px">
                    <p style="font-size: 20px">Room no. {{ dataRE.room_no }}</p>
                  </div>
                </div>
              </div>

              <div class="columns" style="height: 75vh; margin-top: 15px">
                <div class="column is-12">
                  <div class="box" style="height: 75vh; min-height: 40vh">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Room No:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" v-model="dataRE.room_no" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Room Type:</label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                          <select v-model="dataRE.room_type" style="width: 300px; height: 40px">
                            <!-- <option value="" hidden>Select Room</option> -->
                            <option v-for="r in dataRT" :key="r.type_name">{{ r.type_name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Adults</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="number" min="0" v-model="dataRE.adults" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Child</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="number" min="0" v-model="dataRE.child" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Floor</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="number" v-model="dataRE.floor" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Features</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" v-model="dataRE.features" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">View</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" v-model="dataRE.view" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Extra Bed:</label>
                      </div>
                      <div class="field-body">
                        <input type="checkbox" v-model="dataRE.extra_bed" style="margin-top: 15px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Buttons -->
              <div style="margin-top: 25px">
                <button class="button" @click.prevent="DeleteRoom()" style="background-color: #e67c6c; color: white; margin-right: 5px">Delete</button>
                <button class="button" @click.prevent="roomAddE = false" style="background-color: #76dbab; color: white; margin-right: 5px">Cancel</button>
                <button class="button" style="background-color: #26ade4; color: white" @click.prevent="EditRoom()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal: room type add -->
      <div v-if="roomTM == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="roomTM = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 800px; margin-right: 0">
            <div class="box" style="min-height: 100vh">
              <div class="columns">
                <div class="column is-12">
                  <div class="box" style="height: 70px">
                    <p style="font-size: 20px">Add Room Type</p>
                  </div>
                </div>
              </div>

              <div class="columns" style="height: 75vh; margin-top: 15px">
                <div class="column is-12">
                  <div class="box" style="height: 75vh; min-height: 40vh">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Room Type:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="type name" v-model="type_name" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Color:</label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                          <!-- <select v-model="room_color" style="width:300px; height:40px">
                                                    <option value="" hidden>Select Color</option>
                                                    <option :style="{'background-color': `${r}`}" v-for="r in color" :key="r">{{r}}</option>
                                                </select> -->
                          <input class="input" type="color" placeholder="type name" v-model="room_color" style="width: 150px" />
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Cost:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="cost" v-model="cost" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Discount:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" v-model="discount" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Bed:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="number" min="0" v-model="bed" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Short Form:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="short" v-model="short" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <div style="margin-top: 25px">
                    <button class="button" @click.prevent="roomTM = false" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
                    <button class="button" style="background-color: #26ade4; color: white" @click.prevent="AddRoomType()">Submit</button>
                  </div>
                </div>
              </div>

              <!-- Buttons -->
            </div>
          </div>
        </div>
      </div>

      <!-- Modal: room type Edit -->
      <div v-if="roomTME == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="roomTME = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 800px; margin-right: 0">
            <div class="box" style="min-height: 100vh">
              <div class="columns" style="">
                <div class="column is-12">
                  <div class="box" style="height: 70px">
                    <p style="font-size: 20px">{{ roomTypeNameE }} Room</p>
                  </div>
                </div>
              </div>

              <div class="columns" style="height: 75vh; margin-top: 15px">
                <div class="column is-12">
                  <div class="box" style="height: 75vh; min-height: 40vh">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Color:</label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                          <!-- <select v-model="dataRTE.color" style="width:300px; height:40px">
                                                    <option value="" hidden>Select Color</option>
                                                    <option :style="{'background-color': `${r}`}" v-for="r in color" :key="r">{{r}}</option>
                                                </select> -->
                          <input class="input" type="color" placeholder="type name" v-model="dataRTE.color" style="width: 100px" />
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Cost:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="cost" v-model="dataRTE.cost" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Discount:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" v-model="dataRTE.discount" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Bed:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="number" min="0" v-model="dataRTE.bed" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label" style="font-size: 20px">Short Form:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 300px">
                            <input class="input" type="text" placeholder="short" v-model="dataRTE.short" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Buttons -->
              <div style="margin-top: 25px">
                <button class="button" @click.prevent="DeleteRoomType()" style="background-color: #e67c6c; color: white; margin-right: 5px">Delete</button>
                <button class="button" @click.prevent="roomTME = false" style="background-color: #76dbab; color: white; margin-right: 5px">Cancel</button>
                <button class="button" style="background-color: #26ade4; color: white" @click.prevent="EditRoomType()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="rejectModal == true">
        <div class="modal is-active">
          <div class="modal-background" @click.prevent="rejectModal = false"></div>
          <div class="modal-content" style="background: white; height: 80vh; width: 40vw; overflow-x: hidden">
            <div style="margin-left: 70px; margin-top: 5vh">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 20px">Template Chosen</label>
                </div>
                <div class="field-body">
                  <div class="control">
                    <select v-model="templateValue" style="width: 400px; height: 40px" disabled>
                      <option value="" hidden>Select Room</option>
                      <option v-for="r in templateData" :key="r.name" :value="r.name">{{ r.name }} || {{ r.role_concated }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal" style="">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 20px">Room Type:</label>
                </div>
                <div class="field-body">
                  <div class="control">
                    <select v-model="room_type" style="width: 300px; height: 40px" disabled>
                      <option value="" hidden>Select Room</option>
                      <option v-for="r in dataRT" :key="r.type_name">{{ r.type_name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal" style="">
                  <label class="label" style="font-size: 20px">Reject Reason:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control" style="width: 300px">
                      <textarea class="textarea" placeholder="Reason" v-model="rejectReason" style=""></textarea>
                    </p>
                  </div>
                </div>
              </div>

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 20px">Price</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control" style="width: 300px">
                      <input class="input" type="text" placeholder="cost" v-model="cost" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 20px">Discount</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control" style="width: 300px">
                      <input class="input" type="text" placeholder="discount" v-model="discount" />
                    </p>
                  </div>
                </div>
              </div>

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 20px">Effective Date</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control" style="width: 300px">
                      <input type="date" class="input" v-model="date" />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button class="button" style="background-color: #26ade4; color: white; margin-top: 20px" @click.prevent="rejectSubmit()">Submit</button>
          </div>
        </div>
      </div>

      <!-- ModaL: Approval -->
      <div v-if="statusB == true">
        <div class="modal is-active">
          <div class="modal-background" @click.prevent="statusB = false">
            <div class="modal-content" style="height: 30vh; background: white; margin-top: 25vh">
              <div style="margin-top: 15%">
                <p class="subtitle is-4">Pricing has approved?</p>
                <div style="margin-top: 25px">
                  <button class="button" style="background-color: #26ade4; color: white" @click.prevent="ChangeStatus()">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      isActive: 'type',
      id: '',
      idC: '',
      idD: '',
      idRoomType: '',
      dataFlow: [],
      dataColor: [],
      dataR: [],
      dataRE: [],
      dataRT: [],
      dataRTE: [],
      roomTypeArr: [],
      roomTM: false,
      roomTME: false,
      roomAdd: false,
      roomAddE: false,
      flowAdd: false,
      statusB: false,
      type_name: '',
      cost: '',
      discount: 0,
      short: '',
      bed: 0,
      roomTypeNameE: '',
      roomE: '',
      room_no: '',
      room_color: '',
      room_type: '',
      extra_bed: false,
      adults: 0,
      child: 0,
      floor: '',
      view: '',
      features: '',
      date: '',
      templateData: [],
      templateValue: '',
      templatelevelData: '',
      rejectModal: false,
      rejectReason: '',
      rejectTransferId: '',
    };
  },
  created() {
    this.getData();
    this.getDataR();
    this.getDataFlow();
    this.getTemplateData();
  },
  methods: {
    async showRejectReason(id) {
      this.rejectTransferId = id;
      this.rejectModal = true;
      this.templateValue = id.template;
      this.rejectReason = id.reject_reason;
      this.room_type = id.room_type;
      this.cost = id.proposedCost;
      this.discount = id.proposedDiscount;
      this.date = id.activataionDate;
    },
    async rejectSubmit() {
      console.log(this.rejectTransferId);
      await api
        .put(`api/room_type_flow/${this.rejectTransferId.id}/`, {
          room_type: this.rejectTransferId.room_type,
          proposedCost: this.cost,
          proposedDiscount: this.discount,
          activataionDate: this.date,
          status: 'pending',
          template: this.rejectTransferId.workflow_name,
          status_role: 'ongoing',
        })
        .catch((err) => console.log(err));

      await this.getDataFlow();
      this.date = '';
      this.discount = '';
      this.cost = '';
      this.room_type = '';
      this.rejectModal = false;
    },
    async getTemplateData() {
      await api
        .get('api/workflow_template/')
        .then((res) => {
          this.templateData = res.data;
        })
        .catch((err) => console.log(err));
      console.log('template data', this.templateData);
    },
    async getData() {
      await api
        .get('api/room_type/')
        .then((response) => {
          this.dataRT = response.data;
          console.table(this.dataRT);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataRT.length; i++) {
        this.roomTypeArr.push(this.dataRT[i].type_name);
        this.dataColor.push(this.dataRT[i].color);
      }
    },

    async getDataFlow() {
      await api
        .get('api/room_type_flow/')
        .then((response) => {
          this.dataFlow = response.data;
          console.table(this.dataFlow);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataR() {
      await api
        .get('api/room/')
        .then((response) => {
          this.dataR = response.data;
          console.table(this.dataR);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataE() {
      const q = this.roomTypeNameE;
      await api
        .get(`api/room_type/${q}/`)
        .then((response) => {
          this.dataRTE = response.data;
          console.log(this.dataRTE);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataEE() {
      const q = this.roomE;
      await api
        .get(`api/room/${q}/`)
        .then((response) => {
          this.dataRE = response.data;
          console.log(this.dataRE);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async AddRoom() {
      const a = {
        room_no: this.room_no,
        room_type: this.room_type,
        extra_bed: this.extra_bed,
        adults: this.adults,
        child: this.child,
        floor: this.floor,
        view: this.view,
        status_fd: 'available',
        status_hk: 'clean',
        features: this.features,
      };
      await api
        .post('/api/room/', a)
        .then(() => {
          toast({
            message: 'Room has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          this.room_no = '';
          this.room_type = '';
          this.extra_bed = false;
          this.adults = 0;
          this.child = 0;
          this.floor = '';
          this.view = '';
          (this.features = ''), this.getDataR();

          this.roomAdd = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async AddRoomType() {
      const a = {
        type_name: this.type_name,
        cost: this.cost,
        discount: this.discount,
        services: '',
        bed: this.bed,
        color: this.room_color,
        short: this.short,
      };
      await api
        .post('/api/room_type/', a)
        .then(() => {
          toast({
            message: 'Room type has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          this.type_name = '';
          this.cost = '';
          this.discount = 0;
          this.bed = 0;
          this.short = '';
          this.dataRT = [];
          this.dataColor = [];
          this.getData();
          this.getDataR();

          this.roomTM = false;
          // this.$router.push('/settings_room');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    roomModal(t) {
      this.roomTypeNameE = t;
      this.getDataE();
      this.roomTME = true;
    },

    roomEditModal(t) {
      this.roomE = t;
      this.getDataEE();
      this.roomAddE = true;
    },

    async EditRoomType() {
      const t = this.roomTypeNameE;
      await api
        .patch(`/api/room_type/${t}/`, this.dataRTE)
        .then((response) => {
          toast({
            message: 'Room type edited',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(response);
          this.dataColor = [];
          this.dataRT = [];
          this.getData();
          this.getDataR();
          this.roomTME = false;
          this.$router.push('/settings_room');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async DeleteRoomType() {
      const t = this.roomTypeNameE;
      await api
        .delete(`/api/room_type/${t}/`)
        .then((response) => {
          toast({
            message: 'Room type deleted',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(response);
          this.dataRTE;
          this.dataColor = [];
          this.dataRT = [];
          this.getData();
          this.roomTME = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async EditRoom() {
      const t = this.roomE;
      console.log('lol', this.dataRE);
      await api
        .patch(`/api/room/${t}/`, this.dataRE)
        .then((response) => {
          toast({
            message: 'Room edited',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(response);
          this.getDataR();
          this.roomAddE = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async DeleteRoom() {
      const t = this.roomE;
      await api
        .delete(`/api/room/${t}/`)
        .then((response) => {
          toast({
            message: 'Room deleted',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(response);
          this.getDataR();
          this.roomAddE = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    flowEditModal(t) {
      console.log(t);
    },

    async AddFlow() {
      await api
        .get(`api/workflow_template_levels/?workflow_name=${this.templateValue}`)
        .then((res) => {
          this.templatelevelData = res.data[0];
        })
        .catch((err) => console.log(err));
      console.log(this.templatelevelData);
      const a = {
        room_type: this.room_type,
        proposedCost: this.cost,
        proposedDiscount: this.discount,
        activataionDate: this.date,
        status: 'pending',
        template: this.templatelevelData.workflow_name,
        depends_id: this.templatelevelData.depend_id,
        depends_on: this.templatelevelData.depend_on,
        role_user_id: this.templatelevelData.depend_role,
        status_role: 'ongoing',
      };
      console.log(a);
      await api
        .post('/api/room_type_flow/', a)
        .then(() => {
          toast({
            message: 'Flow has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          this.type_name = '';
          this.cost = '';
          this.discount = 0;
          this.date = '';
          this.getDataFlow();

          this.flowAdd = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async ChangeStatus() {
      await api.put(`api/room_type_flow/${this.id}/`, { status: 'approved' }).catch((error) => {
        console.log(error);
      });
      this.getDataFlow();

      await api.put(`api/room_type/${this.idRoomType}/`, { type_name: this.idRoomType, cost: this.idC, discount: this.idD }).catch((error) => {
        console.log(error);
      });
      this.getData();

      this.statusB = false;
    },
  },
};
</script>

<style>
.tab-a .a {
  display: none;
}
.tab-a .a.is-active {
  display: block;
}
ul li {
  float: left;
}
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>