<template>
  <div style="height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Room Transer Report</p></div>
          <div style="float: right"><button style="background-color: #26ade4; color: white" class="button" @click.prevent="$router.push('/report')">Back</button></div>
        </div>
      </div>
    </div>
    <div class="columns" style="margin-top: 0px">
      <div class="column is-1"></div>
      <div class="column is-8" style="diplay: flex"></div>
      <div class="column is-2"><button class="button" @click="printPDF()">print</button></div>
    </div>
    <div class="columns" style="margin-top: 20px">
      <div class="column is-12">
        <div class="box" style="height: 60vh; min-height: 600px; overflow-y: scroll">
          <table class="table is-fullwidth" style="overflow-y: auto">
            <thead>
              <tr>
                <th>Card No</th>
                <th>Room To</th>
                <th>Room From</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in data" v-bind:key="d.id">
                <td>{{ d.card_no }}</td>
                <td>{{ d.room_no_to }}</td>
                <td>{{ d.room_no_from }}</td>
                <td>{{ d.updated_at.substring(1, 10) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-show="modalShow == true">
      <div id="pdfportion">
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <img :src="logo" style="max-width: 50px; left: 0" />
        </div>
        <div style="display: flex; justify-content: center; font-size: 36px; margin-top: 5px">{{ company.company_name }} Hotel</div>
        <div style="display: flex; justify-content: center; font-size: 18px; margin-top: 5px">
          {{ company.company_address }}
        </div>
        <div style="display: flex; justify-content: center; font-size: 15px; margin-top: 5px">Hotline: {{ company.contact_person_phone }}</div>
        <div style="display: flex; justify-content: right; font-size: 14px; margin-top: 5px">Date: {{ date }}</div>

        <table class="table is-fullwidth" style="font-size: 12px; margin-top: 20px">
          <thead>
            <tr>
              <th>Card No</th>
              <th>To</th>
              <th>From</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in data" v-bind:key="d.id">
              <td>{{ d.card_no }}</td>
              <td>{{ d.room_no_to }}</td>
              <td>{{ d.room_no_from }}</td>
              <td>{{ d.updated_at.substring(1, 10) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import html2pdf from 'html2pdf-jspdf2';

export default {
  data() {
    return {
      data: [],
      total: 0,
      modalShow: false,
      logo: null,
      company: [],
      date: '',
    };
  },
  created() {
    this.getData();
    this.getLogo();
    this.getCompany();
  },
  methods: {
    async getLogo() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },

    async getCompany() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.company = res.data;
          console.log(this.company);
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/room_t/')
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    printPDF() {
      this.modalShow = true;
      this.date = new Date().toISOString().substr(0, 10);

      var element = document.getElementById('pdfportion');

      var opt = {
        margin: 0.2,
        filename: 'Room_transfer_' + this.date + '.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();

      this.modalShow = false;
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>