<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Luggage Storage</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="addModal = true">Add</button></div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-1"></div>
      <div class="column is-10">
        <table class="table is-fullwidth">
          <thead style="">
            <tr>
              <th>Tag no.</th>
              <th>Bag(s)</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in data" v-bind:key="d.id">
              <td style="text-align: center">{{ d.tag_no }}</td>
              <td style="text-align: center">{{ d.bag_no }}</td>
              <td>{{ d.name }}</td>
              <td>{{ d.phone }}</td>
              <td>{{ d.date }}</td>
              <td>{{ d.time }}</td>
              <td>
                <button class="button is-small" style="background-color: #76dbab; color: white" @click.prevent="(statusB = true), (id = d.id)" :disabled="d.status == true">
                  <p v-if="d.status == true">Delivered</p>
                  <p v-else>Stored</p>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-1"></div>
    </div>

    <!-- Modal: room add -->
    <div v-if="addModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="addModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 800px; margin-right: 0">
          <div class="box" style="height: 100vh; height: 100vh">
            <div class="columns">
              <div class="column is-12">
                <div class="box" style="height: 70px">
                  <p style="font-size: 20px">Add Luggage</p>
                </div>
              </div>
            </div>

            <div class="columns" style="height: 73vh; min-height: 500px; margin-top: 15px">
              <div class="column is-12">
                <div class="box" style="height: 73vh; min-height: 500px">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Name:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="Name" v-model="name" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Phone no.</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="tel" max="10" placeholder="phone no." v-model="phone" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Tag No:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="tag" v-model="tag" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Bags:</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <p class="control" style="width: 300px">
                          <input class="input" type="number" min="0" v-model="bag" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Date:</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <p class="control" style="width: 300px">
                          <input class="input" type="date" v-model="date" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Time:</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <p class="control" style="width: 300px">
                          <input class="input" type="time" v-model="time" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 25px">
              <button class="button" style="background-color: #e67c6c; color: white; margin-right: 5px" @click.prevent="addModal = false">Cancel</button>
              <button class="button" style="background-color: #26ade4; color: white" @click.prevent="Add()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ModaL: Delivery status update -->
    <div v-if="statusB == true">
      <div class="modal is-active">
        <div class="modal-background" @click.prevent="statusB = false">
          <div class="modal-content" style="height: 30vh; background: white; margin-top: 25vh">
            <div style="margin-top: 15%">
              <p class="subtitle is-4">Luggage has been Delivered</p>
              <div style="margin-top: 25px">
                <button class="button" style="background-color: #76dbab; color: white" @click.prevent="changeStatus">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      data: [],
      addModal: false,
      statusB: false,
      name: '',
      phone: '',
      bag: 0,
      tag: '',
      id: '',
      date: new Date().toISOString().substr(0, 10),
      time: new Date().toISOString().substr(11, 5),
    };
  },
  mounted() {
    this.getData();
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back08;
        })
        .catch((err) => console.log(err));
    },
    async getData() {
      await api
        .get('api/luggage_store/')
        .then((response) => {
          this.data = response.data;
          console.table(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    isNumeric(num) {
      return !isNaN(num);
    },

    async Add() {
      if (this.name == '' || this.phone == '') {
        toast({
          message: 'Name/phone is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (!this.isNumeric(this.phone)) {
        toast({
          message: 'Phone cannot contain alphabet',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.tag == '') {
        toast({
          message: 'Tag no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.bag == '') {
        toast({
          message: 'Bag no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }

      const a = {
        tag_no: this.tag,
        bag_no: this.bag,
        status: false,
        name: this.name,
        phone: this.phone,
        date: this.date,
        time: this.time,
      };
      await api
        .post('/api/luggage_store/', a)
        .then(() => {
          toast({
            message: 'Luggage item added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          });

          (this.tag = ''), (this.bag = 0), (this.name = ''), (this.phone = ''), (this.date = new Date().toISOString().substr(0, 10)), (this.time = new Date().toISOString().substr(11, 5)), this.getData();

          this.addModal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async changeStatus() {
      await api.put(`api/luggage_store/${this.id}/`, { status: true, claimed_date: new Date().toISOString().substr(0, 10), claimed_time: new Date().toISOString().substr(11, 5) }).catch((error) => {
        console.log(error);
      });
      this.getData();

      this.statusB = false;
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>