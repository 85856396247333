<template>
  <div style="height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%">
          <div style="float: left"><p style="font-size: 24px">Lost & Found</p></div>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="container" style="height: 90%; width: 90vw">
      <div class="tabs is-centered is-medium">
        <ul>
          <li v-bind:class="{ 'is-active': isActive == 'lost' }"><a v-on:click="isActive = 'lost'">Lost Items</a></li>
          <li v-bind:class="{ 'is-active': isActive == 'claimed' }"><a v-on:click="isActive = 'claimed'">Claimed Items</a></li>
        </ul>
      </div>

      <!-- Tab Data -->
      <div class="tab-contents">
        <!-- Lost Data -->
        <div class="content" v-bind:class="{ 'is-active': isActive == 'lost' }">
          <div class="container">
            <!-- Table -->
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Room</th>
                  <th>Received Date</th>
                  <th>Time</th>
                  <th>Items</th>
                  <th>Found by</th>
                  <th>Description</th>
                  <th>Claim Status</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="d in dataA" v-bind:key="d.id">
                  <template v-if="d.claimed == false">
                    <td>{{ d.room_no }}</td>
                    <td>{{ d.date }}</td>
                    <td>{{ d.time }}</td>
                    <td>{{ d.item }}</td>
                    <td>{{ d.found_by }}</td>
                    <td>{{ d.description }}</td>
                    <td>
                      <button class="button is-small" style="background-color: #76dbab; color: white" @click.prevent="(claimedM = true), (id = d.id)">
                        <p>Not claimed</p>
                      </button>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Claim Data -->
        <div class="content" v-bind:class="{ 'is-active': isActive == 'claimed' }">
          <div class="container">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Room</th>
                  <th>Found Date</th>
                  <th>Items</th>
                  <th>Found by</th>
                  <th>Description</th>
                  <th>Claimer's Name</th>
                  <th>Claimer's Phone</th>
                  <th>Claimed Date</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="d in dataA" v-bind:key="d.id">
                  <template v-if="d.claimed == true">
                    <td>{{ d.room_no }}</td>
                    <td>{{ d.date }}</td>
                    <td>{{ d.item }}</td>
                    <td>{{ d.found_by }}</td>
                    <td>{{ d.description }}</td>
                    <td>{{ d.claimed_name }}</td>
                    <td>{{ d.phone }}</td>
                    <td>{{ d.claimed_date }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Modal: Claimer Info -->
      <div v-if="claimedM == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="claimedM = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 900px; margin-right: 0">
            <div class="box" style="height: 100vh">
              <div style="float: left; width: 850px; height: 80vh">
                <p style="font-size: 30px">Claimer's Info</p>
                <div class="section">
                  <form>
                    <div class="field">
                      <label class="label">Name</label>
                      <div class="control">
                        <input type="text" class="input" placeholder="name" v-model="claimed_name" />
                      </div>
                    </div>

                    <div class="field">
                      <label>Phone</label>
                      <div class="control">
                        <input type="text" class="input" placeholder="phone" v-model="phone" />
                      </div>
                    </div>

                    <div class="field">
                      <label>NID</label>
                      <div class="control">
                        <input type="text" class="input" placeholder="nid" v-model="nid" />
                      </div>
                    </div>

                    <div class="field">
                      <label>Passport</label>
                      <div class="control">
                        <input type="text" class="input" placeholder="passport" v-model="passport" />
                      </div>
                    </div>

                    <div class="field">
                      <label>Received Date</label>
                      <div class="control">
                        <input type="date" class="input" v-model="claimed_date" />
                      </div>
                    </div>

                    <div class="field">
                      <label>Found Time</label>
                      <div class="control">
                        <input type="time" class="input" v-model="claimed_time" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <!-- Buttons -->
              <div style="margin-top: 5px">
                <button class="button is-danger is-light" @click.prevent="claimedM = false" style="margin-right: 5px">Cancel</button>
                <button class="button is-success is-light" @click.prevent="submitClaimedInfo()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      isActive: 'lost',
      dataA: [],
      claimedM: false,
      id: '',
      claimed: true,
      claimed_name: '',
      claimed_date: new Date().toISOString().substr(0, 10),
      claimed_time: '',
      phone: '',
      nid: '',
      passport: '',
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await api
        .get('api/Lost_found/')
        .then((response) => {
          this.dataA = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async submitClaimedInfo() {
      if (this.claimed_name == '') {
        toast({
          message: 'Name is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.phone == '' || this.nid == '' || this.passport == '') {
        toast({
          message: 'Phone/NiD/Passport is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.claimed_date == '' || this.claimed_time == '') {
        toast({
          message: 'Date/Time is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }

      const a = {
        claimed: true,
        claimed_name: this.claimed_name,
        claimed_date: this.claimed_date,
        claimed_time: this.claimed_time,
        phone: this.phone,
        nid: this.nid,
        passport: this.passport,
      };
      await api.put(`api/Lost_found/${this.id}/`, a).catch((error) => {
        console.log(error);
      });
      this.getData();

      this.claimedM = false;
    },
  },
};
</script>

<style>
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}
</style>