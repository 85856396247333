<template>
  <div>
    <div class="h-screen bg-gray-200">
      <div class="grid grid-cols-3 gap-x-2">
        <div class="h-[55px] col-span-3 border-2 bg-accentM">
          <ol class="list-reset flex mt-3 ml-5">
            <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li class="text-white text-xl">Inventory</li>
          </ol>
        </div>

        <div class="col-span-2 mt-4 px-7 mb-1">
          <div class="inline-flex rounded-md shadow-sm float-left" role="group">
            <select v-model="itemType" @change="getStock()" class="py-1 px-4 w-50 text-base font-medium text-gray-900 bg-white rounded-r-sm border border-gray-400 hover:text-black hover:border-1 hover:border-black">
              <option value="" hidden>Select Item Type</option>
              <option value="">All</option>
              <option v-for="d in dataType" :key="d.type_name" :value="d.type_name">{{ d.type_name }}</option>
            </select>
            <select v-model="project" @change="getStock()" class="py-1 px-4 w-40 text-base font-medium text-gray-900 bg-white rounded-r-sm border border-gray-400 hover:text-black hover:border-1 hover:border-black">
              <option value="" hidden>Select Project</option>
              <option value="">All</option>
              <option v-for="d in dataProject" :key="d" :value="d.id">{{ d.name }}</option>
            </select>
          </div>
        </div>

        <div class="col-span-1 mr-8 mt-4">
          <button @click="InventoryModal = true" class="bg-button hover:bg-button2 text-black hover:text-white hover:scale-105 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 text-base py-1 px-4 mx-1 float-right rounded">Add Inventory</button>
        </div>

        <div class="col-span-3 mt-1" style="height: 78vh">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 min-h-4/5">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-white uppercase bg-accentM">
                <tr>
                  <!-- <th scope="col" class="md:pl-20 px-6 py-3">Prodcut id</th> -->
                  <th scope="col" class="px-6 py-3 text-white">Name</th>
                  <th scope="col" class="px-6 py-3 text-white">Commit Quantity</th>
                  <th scope="col" class="px-6 py-3 text-white">Received Quantity</th>
                  <th scope="col" class="px-6 py-3 text-white">Issue Quantity</th>
                  <th scope="col" class="px-6 py-3 text-white">On Hand Quantity</th>
                  <th scope="col" class="px-6 py-3 text-white">Stock</th>
                  <th scope="col" class="px-6 py-3 text-white">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in dataRaw" :key="index" class="border-b odd:bg-white even:bg-gray-50">
                  <!-- <td class="px-6 py-4 md:pl-20">
                    {{ p.id }}
                  </td> -->
                  <td class="px-6 py-4">
                    {{ p.name }}
                  </td>
                  <td class="px-6 py-4">{{ p.stockC }}</td>
                  <td class="px-6 py-4">{{ p.stock }}</td>
                  <td class="px-6 py-4">{{ p.stockI }}</td>
                  <td class="px-6 py-4">{{ p.stock - p.stockI }}</td>
                  <td class="px-6 py-4">{{ p.stock - p.stockI + p.stockC }}</td>
                  <td class="px-6 py-4">
                    <button class="button is-small text-red-500" @click="TagDetails2(p.id, p.name, p.item)"><i class="fas fa-solid fa-info"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="h-[6vh] col-span-3">
          <button class="bg-button hover:bg-button2 text-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 text-base py-2 px-4 mx-1 mt-6 rounded">Prev</button>
          <button class="bg-button hover:bg-button2 text-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 text-base py-2 px-4 mx-1 mt-6 rounded">Next</button>
        </div>
      </div>
    </div>

    <div v-if="InventoryModal" class="opacity-80 fixed inset-0 z-40 bg-black"></div>
    <div v-if="InventoryModal">
      <div class="w-2/6 h-full fixed block top-0 right-0 bg-white z-50">
        <div class="h-[6vh] bg-slate-200 rounded-lg">
          <p class="text-2xl font-bold pt-3 font-mono">Add Inventory</p>
        </div>

        <div class="h-[380px]">
          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/5">
              <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> Product Type: </label>
            </div>
            <div class="md:w-3/5">
              <select v-model="type" class="border-2 border-gray-400 rounded w-3/5 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" required>
                <option value="" hidden>Product Type</option>
                <option v-for="c in dataType" :key="c.type_name" :value="c.type_name">{{ c.type_name }}</option>
              </select>
            </div>
          </div>
          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/5">
              <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> Product: </label>
            </div>
            <div class="md:w-3/5">
              <select v-model="rawProd" class="border-2 border-gray-400 rounded w-3/5 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" required>
                <option value="" hidden>Product</option>
                <option v-for="c in dataRaw" :key="c" :value="c.id">{{ c.name }}</option>
              </select>
            </div>
          </div>
          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/5">
              <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> Price: </label>
            </div>
            <div class="md:w-3/5">
              <input v-model="priceRaw" class="md:flex appearance-none border-2 border-gray-400 rounded w-3/5 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-900" type="number" placeholder="price" />
            </div>
          </div>
          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/5">
              <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> Amount: </label>
            </div>
            <div class="md:w-3/5">
              <input v-model="amountRaw" class="md:flex appearance-none border-2 border-gray-400 rounded w-3/5 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-900" type="number" placeholder="amount" />
            </div>
          </div>
          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/5">
              <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> Product: </label>
            </div>
            <div class="md:w-3/5">
              <select v-model="project" class="border-2 border-gray-400 rounded w-3/5 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" required>
                <option value="" hidden>Select Project</option>
                <option v-for="c in dataProject" :key="c" :value="c.id">{{ c.name }}</option>
              </select>
            </div>
          </div>
          <div class="md:flex md:items-center my-6 auto-rows-1fr">
            <div class="md:w-2/5">
              <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> Date: </label>
            </div>
            <div class="md:w-3/5">
              <input v-model="date" class="md:flex appearance-none border-2 border-gray-400 rounded w-3/5 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-900" type="date" placeholder="" />
            </div>
          </div>
        </div>

        <div v-if="type != ''">
          <button class="bg-red-500 hover:bg-red-400 hover:text-black text-white font-bold text-base py-2 px-4 mx-1 mt-2 rounded" @click.prevent="InventoryModal = false">Close</button>
          <button class="bg-button2 hover:bg-button hover:text-black text-white font-bold text-base py-2 px-4 mx-1 mt-2 rounded" @click.prevent="SaveInventory()">Save</button>
        </div>
      </div>
    </div>

    <div v-if="TagModal2" class="opacity-80 fixed inset-0 z-40 bg-black"></div>
    <div v-if="TagModal2">
      <div class="w-2/3 h-full fixed block top-0 right-0 bg-white z-50">
        <div class="h-[6vh] bg-slate-200 rounded-lg">
          <p class="text-2xl font-bold pt-3 font-mono">Product Details for: {{ modalHead }}</p>
        </div>

        <div style="height: 80vh">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 min-h-4/5">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-white uppercase bg-accentM">
                <tr>
                  <th scope="col" class="px-6 py-3 text-white">Initial Stock</th>
                  <th scope="col" class="px-6 py-3 text-white">Present Stock</th>
                  <th scope="col" class="px-6 py-3 text-white">Date Added</th>
                  <th scope="col" class="px-6 py-3 text-white">Price</th>
                  <th scope="col" class="px-6 py-3 text-white">Project</th>
                  <th scope="col" class="px-6 py-3 text-white">OP</th>
                  <th scope="col" class="px-6 py-3 text-white">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in dataTag" :key="index" class="border-b odd:bg-white even:bg-gray-50">
                  <td class="px-6 py-4 md:pl-20">
                    {{ p.stockI }}
                  </td>
                  <td class="px-6 py-4">
                    <template v-if="index == varrR"><input v-model="p.stock" class="w-[100px]" type="number" /></template>
                    <template v-else>{{ p.stock }}</template>
                  </td>
                  <td class="px-6 py-4">
                    {{ p.date }}
                  </td>
                  <td class="px-6 py-4">
                    <template v-if="index == varrR"><input v-model="p.price" class="w-[100px]" type="number" /></template>
                    <template v-else>{{ p.price }}</template>
                  </td>
                  <td class="px-6 py-4">
                    {{ p.projectName }}
                  </td>
                  <td class="px-6 py-4">
                    {{ p.op }}
                  </td>
                  <td class="px-6 py-4">
                    <template v-if="varrR == index"><button @click="SaveRaw(index)" class="bg-green-500 hover:bg-green-400 text-white hover:scale-110 px-2 py-1 rounded-sm">Save</button></template>
                    <template v-else><button @click="varrR = index" class="bg-blue-500 hover:bg-blue-400 text-white hover:scale-110 px-2 py-1 rounded-sm">Adjust</button></template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-2xl font-bold pt-3 font-mono mt-4">Ledger</p>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 min-h-4/5">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-white uppercase bg-accentM">
                <tr>
                  <th scope="col" class="px-6 py-3 text-white">Doc Number</th>
                  <th scope="col" class="px-6 py-3 text-white">Order Type</th>
                  <th scope="col" class="px-6 py-3 text-white">Transaction Date</th>
                  <th scope="col" class="px-6 py-3 text-white">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in dataLedger" :key="index" class="border-b odd:bg-white even:bg-gray-50">
                  <td class="px-6 py-4 md:pl-20">
                    {{ p.docNumber }}
                  </td>
                  <td class="px-6 py-4">
                    {{ p.orderType }}
                  </td>
                  <td class="px-6 py-4">
                    {{ p.date }}
                  </td>
                  <td class="px-6 py-4">
                    {{ p.amount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <button
            class="bg-red-500 hover:bg-red-400 hover:text-black text-white font-bold text-base py-2 px-4 mx-1 mt-2 rounded"
            @click.prevent="
              TagModal2 = false;
              varrR = 'x';
            "
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';
//  import readXlsxFile from 'read-excel-file';

export default {
  components: {},

  data() {
    return {
      searchName: '',
      dataLive: [],
      dataRaw: [],
      dataOther: [],
      dataBatch: [],
      dataProd: [],
      dataTag: [],
      dataProject: [],
      itemType: '',
      dataLedger: [],

      dataType: [],

      varrR: 'x',
      varrL: 'x',

      type: '',

      liveProd: '',
      tag: '',
      priceLive: '',
      weightLive: '',
      date: '',
      project: '',

      rawProd: '',
      priceRaw: '',
      amountRaw: '',

      otherProd: '',

      BatchModal: false,
      BatchListModal: false,
      InventoryModal: false,
      TagModal: false,
      TagModal2: false,
      modalHead: '',

      livestock: true,
      raw: false,
      other: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await api
        .get(`api/inventory_view/`)
        .then((res) => {
          this.dataType = res.data;
          // console.table(this.dataType);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/project/`)
        .then((res) => {
          this.dataProject = res.data;
          // console.table(this.dataProject);
        })
        .catch((error) => {
          console.log(error);
        });
      this.getStock();
    },
    async SaveRaw(a) {
      console.table(this.dataTag[a]);
      await api
        .put(`api/prod_det/${this.dataTag[a].id}/`, this.dataTag[a])
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.varrR = 'x';
    },
    async getStock() {
      await api
        .get(`api/prod_raw/?item=&item_type=${this.itemType}`)
        .then((res) => {
          this.dataRaw = res.data;

          // console.table(this.dataRaw);
          for (let i = 0; i < this.dataRaw.length; i++) {
            let b = 0,
              d = 0;
            api
              .get(`api/issue_item_true/?itemMaster=${this.dataRaw[i].item}&project=${this.project}`)
              .then((res) => {
                let c = res.data;
                console.log(c);
                for (let j = 0; j < c.length; j++) {
                  d += parseInt(c[j].amount);
                }
                this.dataRaw[i].stockI = d;
              })
              .catch((error) => {
                console.log(error);
              });
            api
              .get(`api/requested_item/?orderId=&status=10&item=${this.dataRaw[i].item}&project=${this.project}`)
              .then((res) => {
                let a = res.data;
                for (let j = 0; j < a.length; j++) {
                  b += parseInt(a[j].quantity);
                }
                this.dataRaw[i].stockC = b;
              })
              .catch((error) => {
                console.log(error);
              });
            this.dataRaw[i].stock = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/prod_det/?project=${this.project}`)
        .then((res) => {
          let a = res.data;
          // console.table(a);
          for (let i = 0; i < this.dataRaw.length; i++) {
            this.dataRaw[i].stock = 0;
            for (let j = 0; j < a.length; j++) {
              if (a[j].prod == this.dataRaw[i].id) {
                this.dataRaw[i].stock += parseInt(a[j].stock);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async TagDetails(i, j) {
      this.modalHead = j;
      await api
        .get(`api/prod_tag/?liveId=${i}&project=${this.project}`)
        .then((res) => {
          this.dataTag = res.data;
          // console.table(this.dataTag);
        })
        .catch((error) => {
          console.log(error);
        });
      this.TagModal = true;
    },
    async TagDetails2(i, j, k) {
      this.modalHead = j;
      await api
        .get(`api/prod_det/?prod=${i}&project=${this.project}`)
        .then((res) => {
          this.dataTag = res.data;
          console.table(this.dataTag);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/issue_item_true/?itemMaster=${k}&project=${this.project}`)
        .then((res) => {
          this.dataLedger = res.data;
          console.table(this.dataLedger);
        })
        .catch((error) => {
          console.log(error);
        });
      this.TagModal2 = true;
    },
    async SaveInventory() {
      let pName = '';
      let a = [],
        b = [];
      for (let i = 0; i < this.dataProject.length; i++) {
        if (this.project == this.dataProject[i].id) {
          pName = this.dataProject[i].name;
        }
      }

      let itemName = '',
        itemId = '';
      for (let i = 0; i < this.dataRaw.length; i++) {
        if (this.rawProd == this.dataRaw[i].id) {
          itemName = this.dataRaw[i].name;
          itemId = this.dataRaw[i].item;
          break;
        }
      }
      a = {
        stockI: this.amountRaw,
        stock: this.amountRaw,
        price: this.priceRaw,
        date: this.date,
        op: 'IM',
        batch: '',
        projectName: pName,
        prod: this.rawProd,
        project: this.project,
      };
      b = {
        orderType: 'IM',
        docNumber: 'IM-RAW' + this.rawProd,
        date: this.date,
        amount: 1,
        ledger: true,
        projectName: pName,
        project: this.project,
        item: this.rawProd,
        itemName: itemName,
        itemMaster: itemId,
      };
      await api
        .post(`api/prod_det/`, a)
        .then((res) => {
          console.table(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .post(`api/issue_item/`, b)
        .then((res) => {
          console.table(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      this.InventoryModal = false;
    },
  },
};
</script>

<style>
</style>