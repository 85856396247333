<template>
  <div class="h-full bg-gray-200">
    <div class="col-span-3 w-[700px]" v-show="printDiv">
      <div id="pdfPackage" class="bg-white text-black">
        <div v-for="d in dataPrint" :key="d.id">
          <div class="grid grid-cols-9 pb-5">
            <div class="col-span-2"></div>
            <div class="col-span-5">
              <div class="grid grid-cols-2">
                <div class="col-span-2" style="display: flex; justify-content: center"></div>
                <div class="col-span-2 text-xl" style="display: flex; justify-content: center">
                  <p class="font-bold">RFQ</p>
                </div>
              </div>
            </div>
            <div class="col-span-2"></div>
            <div class="col-span-9 mx-10 p-1 border-b border-black font-bold text-lg">Company Details</div>

            <div class="col-span-4 mt-1">
              <div class="grid grid-cols-2">
                <div class="col-span-2 ml-2">
                  <p class="text-base text-left ml-10"><span class="font-semibold">Company Name:</span>TTZ</p>
                  <p class="text-base text-left ml-10"><span class="font-semibold">Company Address:</span>Bashundhara, Dhaka</p>
                  <p class="text-base text-left ml-10"><span class="font-semibold">Company Phone:</span>01721101100</p>
                </div>
              </div>
            </div>
            <div class="col-span-1 mt-1"></div>
            <div class="col-span-4 mt-1">
              <div class="grid grid-cols-2">
                <div class="col-span-2 mr-2">
                  <p class="text-base text-left"><span class="font-semibold">Date:</span>{{ date }}</p>
                </div>
              </div>
            </div>

            <div class="col-span-9 mx-10 border-b p-1 border-black font-bold text-lg">Quote For</div>

            <div class="col-span-4 mt-1">
              <div class="grid grid-cols-2">
                <div class="col-span-2 ml-2">
                  <p class="text-base text-left ml-10"><span class="font-semibold">Supplier Name:</span> {{ d.supplier.name }}</p>
                  <p class="text-base text-left ml-10"><span class="font-semibold">Supplier Address:</span> {{ d.supplier.address }}</p>
                  <p class="text-base text-left ml-10"><span class="font-semibold">Supplier Phone:</span> {{ d.supplier.phone }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-10">
            <table class="text-center w-full">
              <thead class="" style="border-bottom: thin solid black; border: 1px solid black">
                <tr>
                  <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Name</th>
                  <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Quantity</th>
                  <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Unit Price</th>
                  <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Price Limit</th>
                  <th scope="col" class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">Expected Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in d.item" :key="p.id">
                  <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black; border-left: 1px solid black">{{ p.item }}</td>
                  <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">{{ p.quantity }}</td>
                  <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">{{ p.unitPrice }}</td>
                  <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">{{ p.supplierLimit }}</td>
                  <td class="pb-2" style="border-right: thin solid black; border-bottom: thin solid black">{{ p.supplierdate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="html2pdf__page-break"></div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-x-2">
      <div class="h-[55px] col-span-4 border-2 bg-accentM">
        <ol class="list-reset flex mt-3 ml-5">
          <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li><span @click="this.$router.push('/purchase_table')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Purchase</span></li>
          <li><span class="text-white mx-2 text-xl">></span></li>
          <li class="text-white text-xl">Purchase Request Quotation</li>
        </ol>
      </div>

      <div class="col-span-4 mt-4 ml-8">
        <button @click="Save()" class="bg-button shadow-lg hover:bg-button2 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Genarete RFQ</button>
        <button @click="this.$router.push('/purchase')" class="bg-red-400 shadow-lg hover:bg-red-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Back</button>
      </div>

      <div class="col-span-2 my-4 ml-10 mr-1 rounded shadow-lg py-5 bg-bg">
        <p class="font-bold ml-4 text-2xl text-left">Order Details</p>
        <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">RFQ Creator:</p>
          </div>
          <div class="md:w-4/6">
            <select class="border-2 border-gray-400 rounded w-3/5 float-right mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="creator" required>
              <option value="" hidden>Created By</option>
              <option v-for="c in dataE" :key="c" :value="c.id">{{ c.name }}</option>
            </select>
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Order Number:</p>
          </div>
          <div class="md:w-4/6">
            <input v-model="dataPurchase.orderNumber" disabled class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="order number" />
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Order By:</p>
          </div>
          <div class="md:w-4/6">
            <input disabled v-model="dataPurchase.orderBy" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="order By" />
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Requested By:</p>
          </div>
          <div class="md:w-4/6">
            <select disabled class="border-2 border-gray-400 rounded w-3/5 float-right mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="dataPurchase.requestedBy" required>
              <option value="" hidden>Requested By</option>
              <option v-for="c in dataE" :key="c" :value="c.id">{{ c.name }}</option>
            </select>
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Requested Date:</p>
          </div>
          <div class="md:w-4/6">
            <input disabled v-model="dataPurchase.date_requested" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="date" />
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Expected Date:</p>
          </div>
          <div class="md:w-4/6">
            <input disabled v-model="dataPurchase.date_requested" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="date" />
          </div>
        </div>

        <div class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Ungency:</p>
          </div>
          <div class="md:w-4/6">
            <select disabled class="border-2 border-gray-400 rounded w-3/5 float-right mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5" v-model="dataPurchase.urgency" required>
              <option value="" hidden>Ungency</option>
              <option v-for="c in dataU" :key="c" :value="c">{{ c }}</option>
            </select>
          </div>
        </div>

        <div v-if="dataPurchase.urgency == 'Urgent'" class="md:flex md:items-center my-4 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Remarks:</p>
          </div>
          <div class="md:w-4/6">
            <input disabled v-model="dataPurchase.remarks" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="Add Remarks" />
          </div>
        </div>
      </div>

      <div class="col-span-2 my-4 mr-10 py-5 ml-1 rounded shadow-lg bg-bg">
        <p class="font-bold ml-4 text-2xl text-left">Address</p>
        <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>

        <div class="md:flex md:items-center my-6 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Delivery Address:</p>
          </div>
          <div class="md:w-4/6">
            <textarea disabled v-model="dataPurchase.address_delivery" class="float-right md:flex w-4/5 h-24 appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button disabled:bg-slate-200 disabled:border-gray-400" type="text" placeholder="address" />
          </div>
        </div>

        <div class="md:flex md:items-center my-6 auto-rows-1fr">
          <div class="md:w-2/6">
            <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Billing Address:</p>
          </div>
          <div class="md:w-4/6">
            <textarea disabled v-model="dataPurchase.address_billing" class="float-right md:flex w-4/5 h-24 appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button disabled:bg-slate-200 disabled:border-gray-400" type="text" placeholder="address" />
          </div>
        </div>
      </div>

      <div class="col-span-4 mx-10 py-3 rounded shadow-lg mb-5 bg-bg">
        <p class="font-bold ml-4 text-2xl text-left">Requested Items</p>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 mt-2 min-h-4/5">
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs uppercase bg-accent2">
              <tr>
                <th scope="col" class="px-6 py-3 text-white">Name</th>
                <th scope="col" class="px-4 py-3 text-white">Quantity</th>
                <th scope="col" class="px-4 py-3 text-white">Unit Price</th>
                <th scope="col" class="px-4 py-3 text-white">Remarks</th>
                <th scope="col" class="px-4 py-3 text-white">Status</th>
                <th scope="col" class="px-4 py-3 text-white">Supplier</th>
                <th scope="col" class="px-4 py-3 text-white">Date</th>
                <th scope="col" class="px-4 py-3 text-white">Threshold Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in dataPurchaseItem" :key="index" class="border-b odd:bg-white even:bg-gray-50">
                <td class="px-6 py-4 md:pl-10">{{ p.item }}</td>
                <td class="px-6 py-4 md:pl-10">{{ p.quantity }}</td>
                <td class="px-6 py-4 md:pl-10">{{ p.unitPrice }}</td>
                <td class="px-6 py-4 md:pl-10">{{ p.remarks }}</td>
                <td class="px-6 py-4 md:pl-10">
                  <p v-if="p.statusName == 'approved'" class="text-green-700 uppercase font-bold">{{ p.statusName }}</p>
                  <p v-else-if="p.statusName == 'rejected'" class="text-red-700 uppercase font-bold">{{ p.statusName }}</p>
                  <p v-else class="text-gray-500 uppercase font-bold">{{ p.statusName }}</p>
                </td>
                <td class="py-4 md:pl-5">
                  <div class="md:w-2/10 tooltip tooltip-accent" data-tip="Supplier">
                    <span v-if="nameL[index] == false" @click.prevent="Demo3(index)"><i class="fas fa-solid fa-plus"></i></span>
                    <select v-if="nameL[index] == true" v-model="varr2" @change="Demo4(index)" class="ml-4 border-2 border-gray-600 rounded text-gray-900 focus:ring-blue-500 focus:border-blue-500 block">
                      <option value="" hidden>Name:</option>
                      <option v-for="c in dataS" :key="c.id" :value="c">{{ c.name }}</option>
                    </select>
                    <div class="ml-4" v-for="q in nameArr[index]" :key="q">
                      <span class="hover:text-red-700 hover:cursor-pointer" @click="Delete2(index, q)">{{ q.name }}</span>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 md:pl-10 tooltip tooltip-accent" data-tip="Date">
                  <input type="date" class="border-black border rounded-md py-1 px-2 focus:border-button focus:border" v-model="p.supplierdate" name="" />
                </td>
                <td class="px-6 py-4 md:pl-10">
                  <div class="tooltip tooltip-accent" data-tip="Threshold Price">
                    <input type="number" class="border-black border rounded-md py-1 px-2 focus:border-button focus:border" v-model="p.supplierLimit" name="" placeholder="Threshold Price" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';
import html2pdf from 'html2pdf-jspdf2';

export default {
  components: {},

  data() {
    return {
      date: new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 8),
      id: this.$route.params.id,
      dataE: [],
      dataS: [],
      dataStatus: [],
      dataStatus2: [],
      dataI: [],
      dataU: ['Urgent', 'Low'],
      dataTable: [],
      dataPurchase: [],
      dataPurchaseItem: [],
      dataCost: [],
      dataCOA: [],
      dataLineType: [],
      dataProject: [],

      nameL: [],
      varr2: '',
      nameArr: [],

      creator: '',

      dataPrint: [],
      printDiv: false,
      printVarr: { supplier: '', item: '' },
    };
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      // console.log(this.id);
      await api
        .get(`api/employee/`)
        .then((res) => {
          this.dataE = res.data;
          // console.table(this.dataE);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/cost_center/`)
        .then((res) => {
          this.dataCost = res.data;
          //   console.table(this.dataCost);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/coa/`)
        .then((res) => {
          this.dataCOA = res.data;
          //   console.table(this.dataCOA);
        })
        .catch((error) => {
          console.log(error);
        });
      await api.get(`api/line_type/`).then((res) => {
        this.dataLineType = res.data;
        // console.table(this.dataLineType);
      });
      await api
        .get(`api/project/`)
        .then((res) => {
          this.dataProject = res.data;
          //   console.table(this.dataProject);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/supplier/`)
        .then((res) => {
          this.dataS = res.data;
          //   console.table(this.dataS);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=or&statusCode=&statusName=approved`)
        .then((res) => {
          this.dataStatus = res.data;
          // console.table(this.dataStatus);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=rfq&statusCode=&statusName=pending`)
        .then((res) => {
          this.dataStatus2 = res.data;
          if (this.dataStatus2 == '') {
            alert('Status not Set');
          }
          // console.table(this.dataStatus2);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/pruchase/${this.id}/`)
        .then((res) => {
          this.dataPurchase = res.data;
          // console.log(this.dataPurchase);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/requested_item/?orderId=${this.id}&status=${this.dataStatus[0].id}`) // ?order${this.id}=6&status=1
        .then((res) => {
          this.dataPurchaseItem = res.data;
          // console.table(this.dataPurchaseItem);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        this.dataPurchaseItem[i].supplier = '';
        this.dataPurchaseItem[i].supplierdata = '';
        this.dataPurchaseItem[i].supplierLimit = '';
      }
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        this.nameArr[i] = [];
        this.nameL[i] = false;
        // this.varr2[i] = '';
      }
    },
    Demo3(i) {
      this.nameL[i] = true;
    },
    Demo4(i) {
      // console.log(this.varr2);
      if (this.nameArr[i].includes(this.varr2)) {
        this.varr2 = '';
        this.nameL[i] = false;
        return;
      }
      this.nameArr[i].push(this.varr2);
      this.varr2 = '';
      this.nameL[i] = false;
    },
    Delete2(w, q) {
      for (let i = 0; i < this.nameArr[w].length; i++) {
        if (this.nameArr[w][i] == q) {
          this.nameArr[w].splice(i, 1);
        }
      }
    },
    Clear() {
      this.orderNumber = '';
      this.orderBy = 'admin';
      this.requested = '';
      this.supplier = '';
      this.date1 = '';
      this.date2 = '';
      this.urgent = '';
      this.address1 = '';
      this.address2 = '';
      this.item = '';
      this.quantity = '';
      this.price = '';
      this.dataTable = [];
    },
    async Save() {
      // console.table(this.nameArr);
      let a = [];
      for (let i = 0; i < this.nameArr.length; i++) {
        for (let j = 0; j < this.nameArr[i].length; j++) {
          a = a.concat(this.nameArr[i][j].id);
        }
      }
      // console.table(a);
      var b = [];
      a.forEach((e) => {
        if (!b.includes(e)) {
          b.push(e);
        }
      });
      // console.table(b);
      for (let i = 0; i < b.length; i++) {
        let c = {
          supplier: [],
          item: [],
        };
        for (let j = 0; j < this.dataS.length; j++) {
          if (b[i] == this.dataS[j].id) {
            c.supplier = this.dataS[j];
            break;
          }
        }
        for (let j = 0; j < this.nameArr.length; j++) {
          for (let k = 0; k < this.nameArr[j].length; k++) {
            if (this.nameArr[j][k].id == b[i]) {
              c.item.push(this.dataPurchaseItem[j]);
            }
          }
        }
        this.dataPrint.push(c);
        // console.table(c.item);
      }
      let d = this.dataStatus2[0].id;
      let e = this.dataStatus2[0].statusCode;
      let f = this.dataStatus2[0].statusName;
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        let s = '';
        for (let j = 0; j < this.nameArr[i].length; j++) {
          if (s == '') {
            s = this.nameArr[i][j].id + '';
          } else {
            s = s + '|' + this.nameArr[i][j].id;
          }
        }
        this.dataPurchaseItem[i].supplier = s;
        this.dataPurchaseItem[i].status = d;
        this.dataPurchaseItem[i].statusC = e;
        this.dataPurchaseItem[i].statusName = f;
      }
      console.table(this.dataPurchaseItem);
      this.dataPurchase.status = d;
      this.dataPurchase.statusC = e;
      this.dataPurchase.statusName = f;
      console.table(this.dataPurchase);
      await api
        .put(`api/pruchase/${this.id}/`, this.dataPurchase)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataPurchaseItem.length; i++) {
        await api
          .put(`api/requested_item/${this.dataPurchaseItem[i].id}/`, this.dataPurchaseItem[i])
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.printPackage();
    },
    printPackage() {
      this.printDiv = true;
      var element = document.getElementById('pdfPackage');

      var opt = {
        margin: 0.2,
        filename: 'rfq.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        // pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();
      this.printDiv = false;
    },
  },
};
</script>
<style>
input:hover {
  cursor: pointer;
}
select:hover {
  cursor: pointer;
}
textarea:hover {
  cursor: pointer;
}
</style>