<template>
  <div style="height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Daily Sales Report</p></div>
          <div style="float: right"><button style="background-color: #26ade4; color: white" class="button" @click.prevent="$router.push('/report')">Back</button></div>
        </div>
      </div>
    </div>
    <div class="columns" style="margin-top: 0px">
      <div class="column is-1"></div>
      <div class="column is-9" style="diplay: flex">
        <div style="float: left">
          <div class="field has-addons" style="margin-left: 0">
            <p class="control">
              <input class="input" style="width: 140px; border: 1px solid #0c9eda" type="date" @change="Calculate()" v-model="dateV" />
            </p>
            <p class="control">
              <button class="button" style="color: white; border: 1px solid #0c9eda; background-color: #0c9eda" @click.prevent="Calculate()">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div class="column is-2"><button class="button" @click.prevent="exportTableToExcel('printit', 'test')">Excel</button><button class="button" style="margin-left: 4px" @click="printPDF()">PDF</button></div>
    </div>
    <div class="columns" style="margin-top: 20px">
      <div class="column is-12">
        <div class="box" style="overflow-y: auto; min-height: 600px; height: 60vh">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Card No</th>
                <th>Room No</th>
                <th>Check IN</th>
                <th>Check Out</th>
                <th>Days</th>
                <th>Room Tariff</th>
                <th>Room Service</th>
                <th>Room Laundry</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in dataX" v-bind:key="d.id">
                <td>{{ d.card_no }}</td>
                <td>{{ d.room_no }}</td>
                <td>{{ d.checkIn }}</td>
                <td>{{ d.checkOut }}</td>
                <td>{{ d.days }}</td>
                <td>{{ d.roomTariff }}</td>
                <td>{{ d.roomService }}</td>
                <td>{{ d.lundry }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-show="modalShow == true" id="printit">
      <div id="pdfportion">
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <img :src="logo" style="max-width: 50px; left: 0" />
        </div>
        <div style="display: flex; justify-content: center; font-size: 36px; margin-top: 5px">{{ company.company_name }} Hotel</div>
        <div style="display: flex; justify-content: center; font-size: 18px; margin-top: 5px">
          {{ company.company_address }}
        </div>
        <div style="display: flex; justify-content: center; font-size: 15px; margin-top: 5px">Hotline: {{ company.contact_person_phone }}</div>
        <div style="display: flex; justify-content: right; font-size: 14px; margin-top: 5px">Date: {{ date }}</div>

        <table class="table is-fullwidth" style="font-size: 12px; margin-top: 20px">
          <thead>
            <tr>
              <th>Card No</th>
              <th>Room No</th>
              <th>Check IN</th>
              <th>Check Out</th>
              <th>Days</th>
              <th>Room Tariff</th>
              <th>Room Service</th>
              <th>Room Laundry</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in dataX" v-bind:key="d.id">
              <td>{{ d.card_no }}</td>
              <td>{{ d.room_no }}</td>
              <td>{{ d.checkIn }}</td>
              <td>{{ d.checkOut }}</td>
              <td>{{ d.days }}</td>
              <td>{{ d.roomTariff }}</td>
              <td>{{ d.roomService }}</td>
              <td>{{ d.lundry }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import html2pdf from 'html2pdf-jspdf2';

export default {
  data() {
    return {
      dataBill: [],
      dateCard: [],
      date: '',
      time: '',
      dateV: '',
      logo: null,
      company: [],
      dataX: [],
    };
  },
  created() {
    this.getData();
    this.getLogo();
    this.getCompany();
  },
  methods: {
    async getLogo() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },

    async getCompany() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.company = res.data;
          console.log(this.company);
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/bills/')
        .then((response) => {
          this.dataBill = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/res_cus_card/')
        .then((response) => {
          this.dateCard = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    exportTableToExcel(tableID, filename = '') {
      var downloadLink;
      var dataType = 'application/vnd.ms-excel';
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

      // Specify file name
      filename = filename ? filename + '.xls' : this.selected_rule.rule_name + '.xls';

      // Create download link element
      downloadLink = document.createElement('a');

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(['\ufeff', tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },

    printPDF() {
      this.modalShow = true;
      this.date = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 8);
      this.time = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(10, 11);

      var element = document.getElementById('pdfportion');

      var opt = {
        margin: 0.2,
        filename: 'Daily_Sales_Report_' + this.date + '|' + this.time + '.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();

      this.modalShow = false;
    },

    Calculate() {
      if (this.dateV == '') {
        this.dataX = [];
        let ln = 0;
        let room = 0;
        let rs = 0;
        for (let i = 0; i < this.dateCard.length; i++) {
          ln = 0;
          room = 0;
          rs = 0;
          for (let j = 0; j < this.dataBill.length; j++) {
            if (this.dataBill[j].card_no == this.dateCard[i].card_no) {
              if (this.dataBill[j].type_bill == 'room' || this.dataBill[j].type_bill == 'room_rent') {
                room = parseInt(this.dataBill[j].cost + room);
              }
              if (this.dataBill[j].type_bill == 'room_sevice') {
                rs = parseInt(this.dataBill[j].cost + rs);
              }
              if (this.dataBill[j].type_bill == 'laundry') {
                ln = parseInt(this.dataBill[j].cost + ln);
              }
            }
          }
          const a = {
            card_no: this.dateCard[i].card_no,
            room_no: this.dateCard[i].room_no,
            checkIn: this.dateCard[i].check_in_date,
            checkOut: this.dateCard[i].check_out_date,
            days: this.dateCard[i].No_days,
            roomTariff: room,
            roomService: rs,
            lundry: ln,
          };
          this.dataX.push(a);
        }
      } else {
        this.dataX = [];
        let ln = 0;
        let room = 0;
        let rs = 0;
        for (let i = 0; i < this.dateCard.length; i++) {
          ln = 0;
          room = 0;
          rs = 0;
          for (let j = 0; j < this.dataBill.length; j++) {
            if (this.dataBill[j].date == this.dateV) {
              if (this.dataBill[j].card_no == this.dateCard[i].card_no) {
                if (this.dataBill[j].type_bill == 'room' || this.dataBill[j].type_bill == 'room_rent') {
                  room = parseInt(this.dataBill[j].cost + room);
                }
                if (this.dataBill[j].type_bill == 'room_sevice') {
                  rs = parseInt(this.dataBill[j].cost + rs);
                }
                if (this.dataBill[j].type_bill == 'laundry') {
                  ln = parseInt(this.dataBill[j].cost + ln);
                }
              }
            }
          }
          const a = {
            card_no: this.dateCard[i].card_no,
            room_no: this.dateCard[i].room_no,
            checkIn: this.dateCard[i].check_in_date,
            checkOut: this.dateCard[i].check_out_date,
            days: this.dateCard[i].No_days,
            roomTariff: room,
            roomService: rs,
            lundry: ln,
          };
          this.dataX.push(a);
        }
      }
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>