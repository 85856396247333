<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%">
          <div style="float: left"><p style="font-size: 24px">Transaction Table</p></div>
          <div style="float: right">
            <button class="button" style="background-color: #26ade4; color: white" @click.prevent="Save()">Save</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="columns" style="min-height: 600px; min-width: 600px">
      <div class="column is-1"></div>
      <div class="column is-10">
        <div class="box" style="height: 100%; min-height: 600px; min-width: 1300px">
          <table class="table is-fullwidth is-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Journal Id</th>
                <th>Account ID</th>
                <th>Description</th>
                <th>Date</th>
                <th>Credit</th>
                <th>Debit</th>
                <th>Memo</th>
                <th>Reference</th>
                <th>By</th>
                <th>Auto Journal</th>
                <th>Add to VAT ledger</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(d, index) in dataTT" :key="index">
                <td>{{ d.id }}</td>
                <td>{{ d.journal_id }}</td>
                <td>{{ d.account_id }}</td>
                <td>{{ d.description }}</td>
                <td>{{ d.date }}</td>
                <td>{{ d.credit }}</td>
                <td>{{ d.debit }}</td>
                <td>{{ d.memo }}</td>
                <td>{{ d.reference }}</td>
                <td>{{ d.credited_by }}</td>
                <td>{{ d.auto_journal }}</td>
                <td><input v-model="checkB[d.id]" type="checkbox" @click="Select(d.id)" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-1"></div>
    </div>

    <div class="columns">
      <div class="column is-12">
        <span v-if="showPrevButton">
          <button class="button" @click.prevent="showPrev()" style="background-color: #26ade4; color: white">Prev</button>
        </span>
        <span v-if="showNextButton">
          <button class="button" @click.prevent="showNext()" style="background-color: #26ade4; color: white; margin-left: 10px">Next</button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';

import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      dataCOA: [],
      dataTT: [],
      dataTT2: [],
      dataCC: [],
      varArr: [],
      total: 0,
      credit: 0,
      creditT: 0,
      debit: 0,
      debitT: 0,
      caoModal: false,
      jeModal: false,
      coaId: '',
      cost_center: '',
      responsible_center: '',
      type: '',
      tax: false,
      description: '',
      statement: '',
      credited_by: '',
      reference: '',
      memo: '',
      date: new Date().toISOString().substr(0, 10),
      amount: 0,
      varChange: '',
      hotel: [],
      user: [],
      showNextButton: false,
      showPrevButton: false,
      pageNum: 1,
      pic: null,
      checkB: [],
      printData: [],
    };
  },
  created() {
    this.getData();
    this.getHotel();
    this.getPic();
  },
  methods: {
    Select(i) {
      if (typeof this.checkB[i] === 'undefined') {
        this.checkB[i] = true;
      } else {
        this.checkB[i] = !this.checkB[i];
      }
      if (this.checkB[i] == true) {
        this.printData.push(i);
      } else if (this.checkB[i] == false) {
        var index = this.printData.indexOf(i);
        if (index !== -1) {
          this.printData.splice(index, 1);
        }
      }
    },
    async Save() {
      for (let i = 0; i < this.printData.length; i++) {
        console.log(`/api/Transaction_table2/${this.printData[i]}/`);
        await api.put(`/api/Transaction_table2/${this.printData[i]}/`, { vat_ledger: true }).then(() => {});
      }
      toast({
        message: 'Submitted',
        type: 'is-success',
        dismissible: true,
        pauseOnHover: true,
        duration: 2000,
        position: 'bottom-right',
      });
      (this.pageNum = 1), this.getData();
    },
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back05;
        })
        .catch((err) => console.log(err));
    },

    async getHotel() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.hotel = res.data;
        })
        .catch((err) => console.log(err));
      // await api
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         this.user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
    },

    async getData() {
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          this.dataTT2 = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      await api
        .get(`api/Transaction_table/?page=${this.pageNum}`)
        .then((res) => {
          this.showNextButton = false;
          this.showPrevButton = false;

          if (res.data.next) {
            this.showNextButton = true;
          }

          if (res.data.previous) {
            this.showPrevButton = true;
          }
          this.dataTT = res.data.results;
          console.log(this.dataTT);
        })
        .catch((err) => console.log(err));

      await api
        .get('api/coa/')
        .then((response) => {
          this.dataCOA = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/cost_center/')
        .then((response) => {
          this.dataCC = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showNext() {
      this.pageNum += 1;
      this.getData();
    },
    showPrev() {
      this.pageNum -= 1;
      this.getData();
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>