<template>
  <div class="relative">
    <div class="h-48 w-48">
      <input type="date" class="block w-full py-2 pl-10 pr-3 leading-5 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
      <div class="absolute inset-y-0 left-0 flex items-center pl-3">
        <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18.75a8.75 8.75 0 1 1 0-17.5 8.75 8.75 0 0 1 0 17.5zm-.625-8.75h1.25v5h-1.25v-5z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCalendar: false,
    };
  },
};
</script>

<style>
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}
input[type='date']::-webkit-datetime-edit-fields-wrapper {
  display: none;
}
</style>
