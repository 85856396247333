<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Guest Report</p></div>
          <div style="float: right"><button style="background-color: #26ade4; color: white" class="button" @click.prevent="$router.push('/report')">Back</button></div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-10" style="border-bottom: 1px solid #e4e8ee; margin-left: 20px">
        <div style="float: left">
          <div class="field has-addons" style="margin-left: 0">
            <p class="control">
              <input class="input" style="width: 200px; border: 1px solid #0c9eda" type="text" placeholder="Room No or Phone Number" v-model="searchKey" />
            </p>
            <p class="control">
              <input class="input" style="width: 150px; border: 1px solid #0c9eda" type="date" v-model="date1" />
            </p>
            <p class="control">
              <input class="input" style="width: 150px; border: 1px solid #0c9eda" type="date" v-model="date2" />
            </p>
            <p class="control">
              <button class="button" style="color: white; border: 1px solid #0c9eda; background-color: #0c9eda" @click.prevent="Search()">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </p>
            <template v-if="iconCancel == true">
              <div class="cancelIcon" data-tooltip="Clear Search">
                <i class="fa-solid fa-xmark" style="font-size: 30px; margin-left: 20px; margin-top: 0.5vh" @click.prevent="CancelSearch()"></i>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="column is-2"><button class="button" @click="printPDF()">print</button></div>
    </div>

    <div class="columns" style="min-height: 600px; min-width: 600px">
      <div class="column is-12">
        <div class="box" style="height: 100%; min-height: 600px; min-width: 1300px">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Card No</th>
                <th>Name</th>
                <th>Room</th>
                <th>Secondary Room</th>
                <th>Checked In</th>
                <th>Checked Out</th>
                <th>Phone</th>
                <th>Days</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="d in dataVarr" v-bind:key="d.card_no">
                <td>{{ d.card_no }}</td>
                <td>{{ d.gender + ' ' + d.first_name + ' ' + d.last_name }}</td>
                <td>{{ d.room_no }}</td>
                <td>
                  <template v-if="d.secondary_room == ''"> NA </template>
                  <template v-else>
                    {{ d.secondary_room }}
                  </template>
                </td>
                <td>{{ d.check_in_date }}</td>
                <td>{{ d.check_out_date }}</td>
                <td>{{ d.phone }}</td>
                <td>{{ d.No_days }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div class="column is-12">
          <button class="button" @click.prevent="showPrev()" :disabled="showPrevButton == false" style="background-color: #26ade4; color: white">Prev</button>
          <span style="font-size: 20px">{{ noP1 }} - </span>
          <span style="font-size: 20px">{{ noP2 }}</span>
          <button class="button" @click.prevent="showNext()" :disabled="showNextButton == false" style="background-color: #26ade4; color: white">Next</button>
        </div>
      </div>
    </div>

    <div v-show="modalShow == true">
      <div id="pdfportion">
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <img :src="logo" style="max-width: 50px; left: 0" />
        </div>
        <div style="display: flex; justify-content: center; font-size: 36px; margin-top: 5px">{{ company.company_name }} Hotel</div>
        <div style="display: flex; justify-content: center; font-size: 18px; margin-top: 5px">
          {{ company.company_address }}
        </div>
        <div style="display: flex; justify-content: center; font-size: 15px; margin-top: 5px">Hotline: {{ company.contact_person_phone }}</div>
        <div class="columns">
          <div class="column is-12">
            <div style="float: left; margin-left: 10px; font-size: 18px">
              <p>Date: {{ date }}</p>
            </div>
            <div style="float: right; margin-right: 10px; font-size: 18px">
              <p>Time: {{ time }}</p>
            </div>
          </div>
        </div>

        <table class="table is-fullwidth is-striped" style="font-size: 12px; margin-top: 20px">
          <thead>
            <tr>
              <th>Card No</th>
              <th>Name</th>
              <th>Room</th>
              <th>Secondary Room</th>
              <th>Checked In</th>
              <th>Checked Out</th>
              <th>Phone</th>
              <th>Days</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in data" v-bind:key="d.card_no">
              <td>{{ d.card_no }}</td>
              <td>{{ d.gender + ' ' + d.first_name + ' ' + d.last_name }}</td>
              <td>{{ d.room_no }}</td>
              <td>
                <template v-if="d.secondary_room == ''"> NA </template>
                <template v-else>
                  {{ d.secondary_room }}
                </template>
              </td>
              <td>{{ d.check_in_date }}</td>
              <td>{{ d.check_out_date }}</td>
              <td>{{ d.phone }}</td>
              <td>{{ d.No_days }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import html2pdf from 'html2pdf-jspdf2';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      data: [],
      dataRaw: [],
      modalShow: false,
      logo: null,
      company: [],
      date: '',
      time: '',
      noP1: 0,
      noP2: 0,

      date1: '',
      date2: '',
      searchKey: '',
      iconCancel: false,

      dataVarr: [],
      page: '',
      showNextButton: false,
      showPrevButton: false,
      pageNum: 1,
      pageN: 10,
    };
  },
  created() {
    this.getData();
    this.getLogo();
    this.getCompany();
  },
  methods: {
    async getLogo() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },

    async getCompany() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.company = res.data;
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/res_cus_card_r1/')
        .then((response) => {
          this.dataRaw = response.data;
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.page = Math.ceil(this.data.length / this.pageN);
      this.Paggination();
    },

    Search() {
      if (this.date1 == '' && this.date2 != '') {
        this.data = this.dataRaw;
        this.pageNum = 1;
        this.page = Math.ceil(this.data.length / this.pageN);
        this.Paggination();
        toast({
          message: 'Valid Date Range Needed',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.date1 != '' && this.date2 == '') {
        this.data = this.dataRaw;
        this.pageNum = 1;
        this.page = Math.ceil(this.data.length / this.pageN);
        this.Paggination();
        toast({
          message: 'Valid Date Range Needed',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      console.log('search', this.searchKey);
      if (this.date1 == '' && this.date2 == '' && this.searchKey == '') {
        this.data = this.dataRaw;
        this.pageNum = 1;
        this.page = Math.ceil(this.data.length / this.pageN);
        this.Paggination();
        toast({
          message: 'No Search key',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      this.iconCancel = true;
      if (this.date1 == '' && this.date2 == '' && this.searchKey != '') {
        this.data = [];
        this.pageNum = 1;
        for (let i = 0; i < this.dataRaw.length; i++) {
          if (this.dataRaw[i].room_no.includes(this.searchKey) || this.dataRaw[i].phone.includes(this.searchKey)) {
            this.data.push(this.dataRaw[i]);
          }
        }
        this.page = Math.ceil(this.data.length / this.pageN);
        this.Paggination();
      } else if (this.date1 != '' && this.date2 != '' && this.searchKey == '') {
        this.data = [];
        this.pageNum = 1;
        for (let i = 0; i < this.dataRaw.length; i++) {
          if (this.dataRaw[i].check_in_date >= this.date1 && this.dataRaw[i].check_in_date <= this.date2) {
            this.data.push(this.dataRaw[i]);
          }
        }
        this.page = Math.ceil(this.data.length / this.pageN);
        this.Paggination();
      } else {
        this.data = [];
        this.pageNum = 1;
        for (let i = 0; i < this.dataRaw.length; i++) {
          if (this.dataRaw[i].check_in_date >= this.date1 && this.dataRaw[i].check_in_date <= this.date2) {
            if (this.dataRaw[i].room_no.includes(this.searchKey) || this.dataRaw[i].phone.includes(this.searchKey)) {
              this.data.push(this.dataRaw[i]);
            }
          }
        }
        this.page = Math.ceil(this.data.length / this.pageN);
        this.Paggination();
      }
    },
    CancelSearch() {
      this.searchKey = '';
      this.date1 = '';
      this.date2 = '';
      this.data = this.dataRaw;
      this.pageNum = 1;
      this.page = Math.ceil(this.data.length / this.pageN);
      this.Paggination();
      this.iconCancel = false;
    },

    Paggination() {
      console.log('paggination', this.pageNum, this.page, this.data.length);
      this.showNextButton = false;
      this.showPrevButton = false;
      if (this.page > 1) {
        if (this.pageNum == 1) {
          this.showNextButton = true;
          this.dataVarr = [];
          this.noP1 = 1;
          this.noP2 = this.pageN;

          for (let i = 0; i < this.pageN; i++) {
            this.dataVarr.push(this.data[i]);
          }
        } else if (this.pageNum == this.page) {
          this.showPrevButton = true;
          this.dataVarr = [];
          this.noP1 = this.pageN * (this.pageNum - 1) + 1;
          this.noP2 = this.data.length;

          for (let i = this.pageN * (this.pageNum - 1); i < this.data.length; i++) {
            this.dataVarr.push(this.data[i]);
          }
        } else {
          this.showNextButton = true;
          this.showPrevButton = true;
          this.dataVarr = [];
          this.noP1 = this.pageN * (this.pageNum - 1) + 1;
          this.noP2 = this.pageNum * this.pageN;

          for (let i = this.pageN * (this.pageNum - 1); i < this.pageNum * this.pageN; i++) {
            this.dataVarr.push(this.data[i]);
          }
        }
      } else {
        this.dataVarr = this.data;
        this.noP1 = 1;
        this.noP2 = this.dataVarr.length;
      }
    },
    showNext() {
      this.pageNum += 1;
      this.Paggination();
    },
    showPrev() {
      this.pageNum -= 1;
      this.Paggination();
    },

    printPDF() {
      this.modalShow = true;
      this.date = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 8);
      this.time = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(10, 11);

      var element = document.getElementById('pdfportion');

      var opt = {
        margin: 0.2,
        filename: 'Guest_Report_' + this.date + '_' + this.time + '.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();
      this.modalShow = false;
    },
  },
};
</script>

<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>