<template>
  <div class="" style="height: 100vh; background-color: #f6f7fb">
    <div style="height: 60px">
      <!-- Invisible Heading -->
    </div>

    <div class="card bg-light" style="width: 650px; height: 600px">
      <h1 class="" style="font-size: 20px; font-weight: 700"><p style="border-bottom: 1px solid #dbdbdb">Register User</p></h1>
      <br />
      <div class="field is-horizontal mt-2">
        <div class="field-label mt-1">
          <label class="label" style="font-size: 18px; width: 200px; font-weight: 500">User Name:</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 250px">
              <input class="input" type="text" placeholder="User Name" v-model="username" />
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal mb-3">
        <div class="field-label mt-1">
          <label class="label" style="font-size: 18px; width: 200px; font-weight: 500">Email:</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 250px">
              <input v-model="email" type="email" class="input" placeholder="Email" required />
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal mb-3">
        <div class="field-label mt-1">
          <label class="label" style="font-size: 18px; width: 200px; font-weight: 500">First Name:</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 250px">
              <input v-model="firstName" type="text" class="input" placeholder="First Name" required />
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal mb-3">
        <div class="field-label mt-1">
          <label class="label" style="font-size: 18px; width: 200px; font-weight: 500">Last Name:</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 250px">
              <input v-model="lastName" type="text" class="input" placeholder="Last Name" required />
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal mb-3">
        <div class="field-label mt-1">
          <label class="label" style="font-size: 18px; width: 200px; font-weight: 500">Password:</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 250px">
              <input v-model="password" type="password" class="input" style="" placeholder="Password" required />
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal mb-3">
        <div class="field-label mt-1">
          <label class="label" style="font-size: 18px; width: 200px; font-weight: 500">Confirm Password:</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control" style="width: 250px">
              <input v-model="password_confirm" type="password" class="input" style="width: 250px" placeholder="Confirm Password" required />
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal mb-3">
        <div class="field-label mt-1">
          <label class="label" style="font-size: 18px; width: 200px; font-weight: 500">Role:</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control" style="width: 250px">
              <div class="select is-fullwidth" style="width: 250px">
                <select v-model="role">
                  <option value="" hidden><b>Role</b></option>
                  <option v-for="filter in roleArray" :key="filter.id" :value="filter">{{ filter.role_name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <label class="checkbox mb-5" style="font-size: 18px; font-weight: 500">
        <input type="checkbox" v-model="isManager" @click="isManager != isManager" />
        Manager Access
      </label>
      <br />

      <button class="button btn-lg btn-primary mb-4" type="submit" @click="submit" style="background-color: #26ade4; color: white">Register</button>
    </div>
    <!-- <div class="card bg-light col-lg-4">
                    <h1 class="h3 mb-3 fw-normal">Sign up</h1>

                    
                    <span class="text-danger"></span>
                    <input v-model="username" type="username" class="form-control mb-3" placeholder="username" required>
                    <input v-model="email" type="email" class="form-control mb-3" placeholder="Email" required>

                    <input v-model="password" type="password" class="form-control mb-3" placeholder="Password" required>

                    <input v-model="password_confirm" type="password" class="form-control mb-3" placeholder="Confirm Password" required>

                  

                    <button class="btn btn-lg btn-primary mt-4 mb-4" type="submit" @click="submit">Sign up</button>
              </div> -->
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  name: 'Register',

  data() {
    return {
      email: '',
      password: '',
      password_confirm: '',
      username: '',
      firstName: '',
      lastName: '',
      isManager: false,
      role: '',
      roleArray: [],
    };
  },
  async mounted() {
    await api
      .get('/auth/role/')
      .then((res) => {
        this.roleArray = res.data;
      })
      .catch((err) => console.log(err));
    console.log('roles', this.roleArray);
  },
  methods: {
    async submit() {
      if (this.password == this.password_confirm) {
        var regForm = {
          email: this.email,
          password: this.password,
          password_confirm: this.password_confirm,
          username: this.username,
          first_name: this.firstName,
          last_name: this.lastName,
          role: this.role.id,
          is_manager: this.isManager,
          position: this.role.role_name,
        };
        await api
          .post('/auth/registers', regForm)
          .then((res) => {
            if (res.status == 200) {
              console.log('user success');
              toast({
                message: `User ${this.username} has been created`,
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              });
            } else {
              console.log('user failed');
              toast({
                message: `Username ${this.username} has been taken`,
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
        // this.$router.push('/login');

        this.email = '';
        this.password = '';
        this.password_confirm = '';
        this.username = '';
        this.firstName = '';
        this.lastName = '';
        this.role = '';
        this.isManager = false;
      } else {
        toast({
          message: `Passwords does not match`,
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        this.password = '';
        this.password_confirm = '';
      }
    },
  },
};
</script>

<style scoped>
.card {
  margin: 0px auto;
  float: none;

  padding: 40px;
}
</style>
