<template>
  <div style="height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Laundry Price</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="$router.push('/settings')">Back</button></div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" style="font-size: 18px; font-weight: normal; width: 100px">Name:</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control" style="width: 100%">
                <input class="input" type="text" placeholder="name" v-model="name" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" style="font-size: 18px; font-weight: normal; width: 100px">Gender:</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <select v-model="gender" style="height: 35px">
                  <option value="" hidden>Select</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" style="font-size: 18px; font-weight: normal; width: 100px">Wash Price:</label>
          </div>
          <div class="field-body" style="margin-left: 5px">
            <div class="field">
              <p class="control" style="width: 100">
                <input class="input" type="text" style="text-align: right" v-model="wash" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-2">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" style="font-size: 18px; font-weight: normal; width: 100px">Iron Price:</label>
          </div>
          <div class="field-body" style="margin-left: 5px">
            <div class="field">
              <p class="control" style="width: 100">
                <input class="input" type="text" style="text-align: right" v-model="iron" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" style="font-size: 18px; font-weight: normal; width: 100px">Dry Price:</label>
          </div>
          <div class="field-body" style="margin-left: 5px">
            <div class="field">
              <p class="control" style="width: 100">
                <input class="input" type="text" style="text-align: right" v-model="dry" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <button class="button" style="background-color: #26ade4; color: white" @click.prevent="Add()">Add</button>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2"></div>
      <div class="column is-8">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Gender</th>
              <th>Wash Price</th>
              <th>Iron Price</th>
              <th>Dry Price</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in data" v-bind:key="d.id">
              <td>{{ d.item_name }}</td>
              <td>{{ d.gender_type }}</td>
              <td>{{ d.wash_cost }}</td>
              <td>{{ d.iron_cost }}</td>
              <td>{{ d.dry_cost }}</td>
              <td>
                <!-- <button class="button is-info is-small" @click="ItemEdit(d.id)"><i class="fa-solid fa-pen" style="font-size:10px;"></i></button> -->
                <button class="button is-small" style="background-color: #e67c6c; color: white; margin-left: 5px" @click="DeleteItem(d.id)"><i class="fas fa-solid fa-xmark" style="font-size: 15px"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      data: [],
      name: '',
      gender: '',
      wash: 0,
      dry: 0,
      iron: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await api
        .get('api/laundry_cost/')
        .then((response) => {
          this.data = response.data;
          console.log(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async Add() {
      if (this.wash == '' || this.dry == '' || this.iron == '' || this.name == '' || this.gender == '') {
        toast({
          message: 'Feilds can not be empty',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      } else {
        const a = {
          item_name: this.name,
          gender_type: this.gender,
          wash_cost: this.wash,
          dry_cost: this.dry,
          iron_cost: this.iron,
        };

        await api
          .post('api/laundry_cost/', a)
          .then((response) => {
            toast({
              message: 'Entry Added',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 1000,
              position: 'bottom-right',
            });
            this.name = '';
            (this.gender = ''), (this.wash = 0);
            this.dry = 0;
            this.iron = 0;
            console.log(response);
            this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async DeleteItem(t) {
      await api
        .delete(`/api/laundry_cost/${t}/`)
        .then((response) => {
          toast({
            message: 'Entry deleted',
            type: 'is-warning',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          });
          console.log(response);
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>