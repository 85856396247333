<template>
  <div class="home" v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Laundry</p></div>
        </div>
      </div>
    </div>

    <div class="container" style="height: 90%; width: 90%">
      <!-- Tab -->
      <div class="tabs is-centered is-medium">
        <ul>
          <li v-bind:class="{ 'is-active': isActive == 'guest' }"><a v-on:click="isActive = 'guest'">Guest</a></li>
          <li v-bind:class="{ 'is-active': isActive == 'customer' }"><a v-on:click="isActive = 'customer'">Customer</a></li>
        </ul>
      </div>

      <!-- Tab data -->
      <div class="container" style="height: 80%; margin-top: -15px">
        <div class="tab-contents">
          <!-- Guest Tab -->
          <div class="content" v-bind:class="{ 'is-active': isActive == 'guest' }">
            <!-- Button -->
            <div class="container" style="float: right; margin: 10px 10px 10px 10px">
              <button class="button" style="background-color: #26ade4; color: white" @click.prevent="addGuest = true">Add</button>
            </div>

            <!-- Table -->
            <div class="column is-12 mt-10">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Room</th>
                    <th>Card No</th>
                    <th>Received Date</th>
                    <th>Ex. Delivery Date</th>
                    <th>Item Number</th>
                    <th>Cost</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="d in dataA" v-bind:key="d.id">
                    <template v-if="d.customer_name === 'NaN'">
                      <td>{{ d.id }}</td>
                      <td>{{ d.room_no }}</td>
                      <td>{{ d.card_no }}</td>
                      <td>{{ d.date_received }}</td>
                      <td>{{ d.ex_date_delivery }}</td>
                      <td>{{ d.total_item }}</td>
                      <td>{{ d.total_cost }}</td>
                      <td>
                        <button class="button is-small" style="background-color: #76dbab; color: white" @click.prevent="(statusB = true), (id = d.id)" :disabled="d.status == true">
                          <p v-if="d.status == true">Delivered</p>
                          <p v-else>Pending</p>
                        </button>
                      </td>
                      <td>
                        <button class="button is-small" style="background-color: #26ade4; color: white" @click.prevent="Details(d.id)"><i class="fa-solid fa-info" style="font-size: 15px"></i></button>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Customer Tab -->
          <div class="content" v-bind:class="{ 'is-active': isActive == 'customer' }">
            <!-- Button -->
            <div class="container" style="float: right; margin: 10px 10px 10px 10px">
              <button class="button" style="background-color: #26ade4; color: white" @click.prevent="addCustomer = true">Add</button>
            </div>

            <!-- Table -->
            <div class="column is-12">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Customer Name</th>
                    <th>Phone</th>
                    <th>Received Date</th>
                    <th>Delivery Date</th>
                    <th>Item Number</th>
                    <th>Cost</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="d in dataA" v-bind:key="d.id">
                    <template v-if="d.customer_name !== 'NaN'">
                      <th>{{ d.id }}</th>
                      <td>{{ d.customer_name }}</td>
                      <td>{{ d.customer_phone }}</td>
                      <td>{{ d.date_received }}</td>
                      <td>{{ d.ex_date_delivery }}</td>
                      <td>{{ d.total_item }}</td>
                      <td>{{ d.total_cost }}</td>
                      <td>
                        <button class="button is-small" style="background-color: #76dbab; color: white" @click.prevent="(statusB = true), (id = d.id)" :disabled="d.status == true">
                          <p v-if="d.status == true">Delivered</p>
                          <p v-else>Pending</p>
                        </button>
                      </td>
                      <td>
                        <button class="button is-small" style="background-color: #26ade4; color: white" @click.prevent="DetailsC(d.id)"><i class="fa-solid fa-info" style="font-size: 15px"></i></button>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- ModaL: Delivery status update -->
          <div v-if="statusB == true">
            <div class="modal is-active">
              <div class="modal-background" @click.prevent="statusB = false">
                <div class="modal-content" style="height: 30vh; background: white; margin-top: 25vh">
                  <div style="margin-top: 15%">
                    <p class="subtitle is-4">The product has been delivered?</p>
                    <div style="margin-top: 25px">
                      <button class="button" style="background-color: #76dbab; color: white" @click.prevent="changeStatus">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal: Add Geust -->
          <div v-if="addGuest == true">
            <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
              <div class="modal-background" @click.prevent="addGuest = false"></div>
              <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 60vw; margin-right: 0">
                <div class="box" style="height: 100vh">
                  <div class="columns" style="height: 70px">
                    <div class="column is-12">
                      <div class="column is-12">
                        <p style="font-size: 30px">Add Guest</p>
                      </div>
                    </div>
                  </div>

                  <div class="columns" style="height: 78vh; margin-top: 15px">
                    <div class="column is-12">
                      <div class="box" style="min-height: 30vh">
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 18px; width: 150px">Room No:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 50px">
                                <select v-model="room_no" v-on:change="submit()">
                                  <option value="" hidden>Select Room</option>
                                  <option v-for="r in dataR" :key="r.id">
                                    {{ r.room_no }}
                                  </option>
                                </select>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Card no:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 200px">
                                <input class="input" readonly type="text" placeholder="card no." v-model="res_id" />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Received Date:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 200px">
                                <input class="input" type="date" :min="dateT" v-model="date_received" />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Delivery Date:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 200px">
                                <input class="input" type="date" :min="date_received" v-model="ex_date_delivery" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="" style="min-height: 50vh; margin-top: 30px">
                        <div class="columns">
                          <div class="column is-8"></div>
                          <div class="column is-4">
                            <div>Total Cost: {{ total_cost }} Total Item(s): {{ total_item }}</div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-1"></div>
                          <div class="column is-2">
                            <div class="field is-horizontal">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 16px; font-weight: normal">Gender:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <select v-model="gender" style="height: 35px">
                                      <option value="" hidden>Gender:</option>
                                      <option>Male</option>
                                      <option>Female</option>
                                    </select>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <div class="field is-horizontal" style="margin-left: 10px">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 16px; font-weight: normal">Item:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <select v-model="item" style="height: 35px">
                                      <option value="" hidden>Item</option>
                                      <template v-if="gender == 'Male'">
                                        <option v-for="r in itemM" :key="r.id">{{ r.item_name }}</option>
                                      </template>
                                      <template v-else-if="gender == 'Female'">
                                        <option v-for="r in itemF" :key="r.id">{{ r.item_name }}</option>
                                      </template>
                                    </select>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <div class="field is-horizontal">
                              <div class="field-body">
                                <div class="field">
                                  <div class="control" style="margin-top: 5px">
                                    <label class="radio">
                                      <input type="radio" name="lType" value="Wash" v-model="lType" />
                                      Wash
                                    </label>
                                    <label class="radio">
                                      <input type="radio" name="lType" value="Dry" v-model="lType" />
                                      Dry
                                    </label>
                                    <label class="radio">
                                      <input type="radio" name="lType" value="Iron" v-model="lType" />
                                      Iron
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <div class="field is-horizontal">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 16px; font-weight: normal">Quantity:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <input class="input" type="number" style="width: 60px" min="0" v-model="quantity" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <button class="button is-small" style="height: 40px; background-color: #26ade4; color: white" @click.prevent="Add()">Add</button>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-12">
                            <div class="box" style="height: 30vh; width: 100%; overflow-y: scroll">
                              <table class="table is-fullwidth">
                                <thead>
                                  <tr>
                                    <th>Gender</th>
                                    <th>Item</th>
                                    <th>Wash</th>
                                    <th>Quantity</th>
                                    <th>Cost</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr v-for="d in varr" v-bind:key="d.index">
                                    <td>{{ d.gender }}</td>
                                    <td>{{ d.item }}</td>
                                    <td>{{ d.wash }}</td>
                                    <td>{{ d.quantity }}</td>
                                    <td>{{ d.cost }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Buttons -->
                  <div style="">
                    <button class="button" style="background-color: #e67c6c; color: white; margin-right: 5px" @click.prevent="addGuest = false">Cancel</button>
                    <button class="button" style="background-color: #26ade4; color: white" @click.prevent="submitGuestInfo()">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal: Add Customer -->
          <div v-if="addCustomer == true">
            <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
              <div class="modal-background" @click.prevent="addCustomer = false"></div>
              <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 60vw; margin-right: 0">
                <div class="box" style="height: 100vh">
                  <div class="columns" style="height: 10vh">
                    <div class="column is-12">
                      <div class="column is-12">
                        <p style="font-size: 30px">Add Customer</p>
                      </div>
                    </div>
                  </div>

                  <div class="columns" style="height: 78vh; margin-top: 15px">
                    <div class="column is-12">
                      <div class="box" style="height: 30vh">
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 18px; width: 150px">Name:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 200px">
                                <input class="input" type="text" placeholder="name" v-model="customer_name" />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Phone no:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 200px">
                                <input class="input" type="text" placeholder="phone no." v-model="customer_phone" />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Received Date:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 200px">
                                <input class="input" type="date" :min="dateT" v-model="date_received" />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Delivery Date:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 200px">
                                <input class="input" type="date" :min="date_received" v-model="ex_date_delivery" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="" style="height: 50vh; margin-top: 30px">
                        <div class="columns">
                          <div class="column is-8"></div>
                          <div class="column is-4">
                            <div>Total Cost: {{ total_cost }} Total Item(s): {{ total_item }}</div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-1"></div>
                          <div class="column is-2">
                            <div class="field is-horizontal">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 16px; font-weight: normal">Gender:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <select v-model="gender" style="height: 35px">
                                      <option value="" hidden>Gender:</option>
                                      <option>Male</option>
                                      <option>Female</option>
                                    </select>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <div class="field is-horizontal" style="margin-left: 10px">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 16px; font-weight: normal">Item:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <select v-model="item" style="height: 35px">
                                      <option value="" hidden>Item</option>
                                      <template v-if="gender == 'Male'">
                                        <option v-for="r in itemM" :key="r.id">{{ r.item_name }}</option>
                                      </template>
                                      <template v-else-if="gender == 'Female'">
                                        <option v-for="r in itemF" :key="r.id">{{ r.item_name }}</option>
                                      </template>
                                    </select>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <div class="field is-horizontal">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 16px; font-weight: normal">Wash:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <select v-model="lType" style="height: 35px">
                                      <option value="" hidden>Select</option>
                                      <option>Wash</option>
                                      <option>Dry</option>
                                      <option>Iron</option>
                                    </select>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <div class="field is-horizontal">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 16px; font-weight: normal">Quantity:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <input class="input" type="number" style="width: 60px" min="0" v-model="quantity" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <button class="button is-small" style="height: 40px; background-color: #26ade4; color: white" @click.prevent="Add()">Add</button>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-12">
                            <div class="box" style="height: 30vh; width: 100%; overflow-y: scroll">
                              <table class="table is-fullwidth">
                                <thead>
                                  <tr>
                                    <th>Gender</th>
                                    <th>Item</th>
                                    <th>Wash</th>
                                    <th>Quantity</th>
                                    <th>Cost</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr v-for="d in varr" v-bind:key="d.index">
                                    <td>{{ d.gender }}</td>
                                    <td>{{ d.item }}</td>
                                    <td>{{ d.wash }}</td>
                                    <td>{{ d.quantity }}</td>
                                    <td>{{ d.cost }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Button -->
                  <div style="margin-top: 5px">
                    <button class="button" @click.prevent="addCustomer = false" style="margin-right: 5px; background-color: #e67c6c; color: white">Cancel</button>
                    <button class="button" style="background-color: #26ade4; color: white" @click.prevent="submitCustomerInfo()">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal: Details Guest -->
          <div v-if="detailsModalCustomer == true">
            <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
              <div class="modal-background" @click.prevent="(detailsModalCustomer = false), (orderD = [])"></div>
              <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
                <div class="box" style="height: 100vh">
                  <div class="columns" style="height: 70px">
                    <div class="column is-12">
                      <div class="box" style="height: 70px">
                        <p style="font-size: 20px; float: left">Order No. {{ orderCMOdal }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="columns" style="height: 8vh">
                    <div class="column is-3" style="font-size: 18px">Room No. {{ roomM }}</div>
                    <div class="column is-3" style="font-size: 18px">Card No. {{ cardM }}</div>
                    <div class="column is-3" style="font-size: 18px">Total Item(s): {{ itemTM }}</div>
                    <div class="column is-3" style="font-size: 18px">Total Cost: {{ costM }}</div>
                  </div>

                  <div class="columns" style="height: 70vh; width: 100%">
                    <div class="column is-12">
                      <table class="table is-fullwidth">
                        <thead>
                          <tr>
                            <th>Gender</th>
                            <th>Item</th>
                            <th>Type</th>
                            <th>Quantity</th>
                            <th>Cost</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="d in orderD" v-bind:key="d.n">
                            <td>{{ d.q }}</td>
                            <td>{{ d.w }}</td>
                            <td>{{ d.e }}</td>
                            <td>{{ d.r }}</td>
                            <td>{{ d.t }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- Buttons -->
                  <div>
                    <button class="button" @click.prevent="(detailsModalCustomer = false), (orderD = [])" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal: Details Customer -->
          <div v-if="detailsModalCustomer2 == true">
            <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
              <div class="modal-background" @click.prevent="(detailsModalCustomer2 = false), (orderD = [])"></div>
              <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
                <div class="box" style="height: 100vh">
                  <div class="columns" style="height: 10vh">
                    <div class="column is-12">
                      <div class="box" style="height: 7vh">
                        <p style="font-size: 20px; float: left">Order No. {{ orderCMOdal }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="columns" style="height: 8vh">
                    <div class="column is-3" style="font-size: 18px">Name: {{ nameM }}</div>
                    <div class="column is-3" style="font-size: 18px">Phone no. {{ phoneM }}</div>
                    <div class="column is-3" style="font-size: 18px">Total Item(s): {{ itemTM }}</div>
                    <div class="column is-3" style="font-size: 18px">Total Cost: {{ costM }}</div>
                  </div>

                  <div class="columns" style="height: 70vh; width: 100%">
                    <div class="column is-12">
                      <table class="table is-fullwidth">
                        <thead>
                          <tr>
                            <th>Gender</th>
                            <th>Item</th>
                            <th>Type</th>
                            <th>Quantity</th>
                            <th>Cost</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="d in orderD" v-bind:key="d.n">
                            <td>{{ d.q }}</td>
                            <td>{{ d.w }}</td>
                            <td>{{ d.e }}</td>
                            <td>{{ d.r }}</td>
                            <td>{{ d.t }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- Buttons -->
                  <div>
                    <button class="button" @click.prevent="(detailsModalCustomer2 = false), (orderD = [])" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      hotel: [],
      dataA: [],
      dataCost: [],
      dataR: [],
      itemM: [],
      itemF: [],
      varr: [],
      orderD: [],
      statusB: false,
      roomE: false,
      detailsModalCustomer: false,
      detailsModalCustomer2: false,
      id: '',
      isD: false,
      isActive: 'guest',
      addGuest: false,
      addCustomer: false,
      room_no: '',
      res_id: '',
      res_var: '',
      date_received: new Date().toISOString().substr(0, 10),
      dateT: new Date().toISOString().substr(0, 10),
      ex_date_delivery: '',
      total_item: 0,
      total_cost: 0,
      customer_name: '',
      customer_phone: '',
      gender: '',
      item: '',
      lType: '',
      quantity: 0,
      cardM: '',
      nameM: '',
      phoneM: '',
      roomM: '',
      costM: 0,
      itemTM: 0,
      orderCMOdal: '',
      pic: null,
    };
  },
  mounted() {
    this.getData();
    this.getDataF();
    this.getHotel();
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back02;
        })
        .catch((err) => console.log(err));
    },
    async getHotel() {
      await api
        .get('auth/company/1/')
        .then((res) => {
          this.hotel = res.data;
          console.log(this.hotel);
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/laundry/')
        .then((response) => {
          this.dataA = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      await api
        .get('api/laundry_cost/')
        .then((response) => {
          this.dataCost = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.VarMethod();
    },

    VarMethod() {
      this.itemM = [];
      this.itemF = [];
      this.dataCost.forEach((r) => {
        if (r.gender_type == 'Male') {
          this.itemM.push(r);
        }
        if (r.gender_type == 'Female') {
          this.itemF.push(r);
        }
      });
    },

    async getDataF() {
      await api
        .get('api/card/')
        .then((response) => {
          this.dataR = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      let varr = [];
      console.table(this.dataR);
      for (let i = 0; i < this.dataR.length; i++) {
        if (this.dataR[i].checked_in == true) {
          const a = {
            card_no: this.dataR[i].card_no,
            room_no: parseInt(this.dataR[i].room_no),
            rev_id: this.dataR[i].rev_id,
          };
          varr.push(a);
          if (this.dataR[i].secondary_room != '') {
            let b = this.dataR[i].secondary_room.split(',');
            for (let j = 0; j < b.length; j++) {
              const c = {
                card_no: this.dataR[i].card_no,
                room_no: parseInt(b[j]),
                rev_id: this.dataR[i].rev_id,
              };
              varr.push(c);
            }
          }
        }
      }
      this.dataR = varr;
      console.table(this.dataR);
    },

    async changeStatus() {
      await api.put(`api/laundry/${this.id}/`, { status: true, claimed_date: new Date().toISOString().substr(0, 10), claimed_time: new Date().toISOString().substr(11, 5) }).catch((error) => {
        console.log(error);
      });
      this.getData();

      this.statusB = false;
    },

    async submitGuestInfo() {
      let b = '';
      for (let i = 0; i < this.varr.length; i++) {
        b += this.varr[i].gender + ',';
        b += this.varr[i].item + ',';
        b += this.varr[i].wash + ',';
        b += this.varr[i].quantity + ',';
        b += this.varr[i].cost + '|';
      }
      b = b.slice(0, -1);

      if (this.room_no == '' || this.res_id == '') {
        toast({
          message: 'Room/Card no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.date_received == '' || this.ex_date_delivery == '') {
        toast({
          message: 'Date blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (b == '') {
        toast({
          message: 'Order is Blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      this.total_cost = parseInt(this.total_cost + this.total_cost * (this.hotel.laundry_tax / 100) + this.total_cost * (this.hotel.laundry_sc / 100));
      let customer = [];
      await api
        .get(`api/reservation/${this.res_var}/`)
        .then((response) => {
          customer = response.data;
          console.log(customer);
        })
        .catch((error) => {
          console.log(error);
        });
      customer.total_due = parseInt(this.total_cost) + parseInt(customer.total_due);
      customer.total_amount = parseInt(this.total_cost) + parseInt(customer.total_amount);
      await api.put(`api/reservation/${this.res_var}/`, customer).catch((error) => {
        console.log(error);
      });
      const a = {
        room_no: this.room_no,
        card_no: this.res_id,
        date_received: this.date_received,
        ex_date_delivery: this.ex_date_delivery,
        total_item: this.total_item,
        total_cost: this.total_cost,
        status: false,
        customer_name: 'NaN',
        customer_phone: '000',
        details: b,
      };
      await api
        .post('/api/laundry/', a)
        .then((response) => {
          toast({
            message: 'The laundry has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(response);
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
      await this.jeAdd();
      let x = [];
      await api
        .get(`api/last_laundry/`)
        .then((response) => {
          x = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      const q = {
        type_bill: 'laundry',
        cost: this.total_cost,
        discount: 0,
        bill_item: 'laundry' + ' ' + x.id,
        item_quantity: this.total_item,
        trx_no: '',
        trx_type: '',
        card_validity: null,
        card_no: this.res_id,
        addl_discount: 0,
        vat_amount: this.hotel.laundry_tax,
        sc_amount: this.hotel.laundry_sc,
        bill_category: 'debit',
        special_discount: 0,
        special_discount_reason: '',
      };
      await api
        .post('/api/bills/', q)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
      (this.room_no = ''), (this.res_id = ''), (this.ex_date_delivery = ''), (this.total_item = 0), (this.total_cost = 0), (this.varr = []), (this.addGuest = false);
    },

    async submitCustomerInfo() {
      let b = '';
      for (let i = 0; i < this.varr.length; i++) {
        b += this.varr[i].gender + ',';
        b += this.varr[i].item + ',';
        b += this.varr[i].wash + ',';
        b += this.varr[i].quantity + ',';
        b += this.varr[i].cost + '|';
      }
      b = b.slice(0, -1);

      if (this.customer_name == '' || this.customer_phone == '') {
        toast({
          message: 'Name/Phone no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.date_received == '' || this.ex_date_delivery == '') {
        toast({
          message: 'Date blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (b == '') {
        toast({
          message: 'Order is Blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }

      const a = {
        customer_name: this.customer_name,
        customer_phone: this.customer_phone,
        date_received: this.date_received,
        ex_date_delivery: this.ex_date_delivery,
        total_item: this.total_item,
        total_cost: this.total_cost,
        status: false,
        room_no: '',
        res_id: '',
        details: b,
      };
      this.total_cost = parseInt(this.total_cost + this.total_cost * (this.hotel.laundry_tax / 100) + this.total_cost * (this.hotel.laundry_sc / 100));
      await api
        .post('/api/laundry/', a)
        .then((response) => {
          toast({
            message: 'The laundry has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      const q = {
        date: new Date().toISOString().substr(0, 10),
        time: new Date().toISOString().substr(11, 5),
        type_bill: 'laundry',
        item_quantity: this.total_item,
        cost: this.total_cost,
        discount: 0,
        vat_amount: this.hotel.laundry_tax,
        sc_amount: this.hotel.laundry_sc,
        trx_type: 'cash',
      };
      await api
        .post('/api/bills_extra/', q)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });

      this.getData();
      (this.customer_name = ''), (this.customer_phone = ''), (this.ex_date_delivery = ''), (this.total_item = 0), (this.total_cost = 0), (this.varr = []), (this.addCustomer = false);
    },

    async submit() {
      for (let i = 0; i < this.dataR.length; i++) {
        if (this.dataR[i].room_no == this.room_no) {
          this.res_id = this.dataR[i].card_no;
          this.res_var = this.dataR[i].rev_id;
          this.roomE = true;
          break;
        }
      }
      if (this.roomE == false) {
        toast({
          message: 'Room is Empty',
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 1000,
          position: 'bottom-right',
        });
        this.res_id = '';
      }
      this.roomE = false;
    },
    Add() {
      let b = '';
      let c = 0;
      this.total_item += this.quantity;
      for (let i = 0; i < this.dataCost.length; i++) {
        if (this.dataCost[i].item_name == this.item) {
          if (this.lType == 'Wash') {
            this.total_cost += this.dataCost[i].wash_cost * this.quantity;
            b = this.dataCost[i].wash_cost;
            c = this.dataCost[i].wash_cost * this.quantity;
          }
          if (this.lType == 'Dry') {
            this.total_cost += this.dataCost[i].dry_cost * this.quantity;
            b = this.dataCost[i].dry_cost;
            c = this.dataCost[i].dry_cost * this.quantity;
          }
          if (this.lType == 'Iron') {
            this.total_cost += this.dataCost[i].iron_cost * this.quantity;
            b = this.dataCost[i].iron_cost;
            c = this.dataCost[i].iron_cost * this.quantity;
          }
          break;
        }
      }
      if (this.gender == '' || this.item == '' || this.wash == '' || this.quantity == '') {
        toast({
          message: 'Fields cannot blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      const a = {
        gender: this.gender,
        item: this.item,
        wash: this.lType + '(' + b + ')',
        quantity: this.quantity,
        cost: c,
      };
      this.varr.push(a);
      this.gender = '';
      this.item = '';
      this.lType = '';
      this.quantity = 0;
    },
    Details(t) {
      for (let j = 0; j < this.dataA.length; j++) {
        this.orderCMOdal = t;
        if (this.dataA[j].id == t) {
          this.roomM = this.dataA[j].room_no;
          this.cardM = this.dataA[j].card_no;
          this.itemTM = this.dataA[j].total_item;
          this.costM = this.dataA[j].total_cost;
          let a = this.dataA[j].details;
          let b = a.split('|');
          for (let i = 0; i < b.length; i++) {
            let c = b[i].split(',');
            const d = {
              q: c[0],
              w: c[1],
              e: c[2],
              r: c[3],
              t: c[4],
            };
            this.orderD.push(d);
          }
          break;
        }
      }
      this.detailsModalCustomer = true;
    },

    DetailsC(t) {
      for (let j = 0; j < this.dataA.length; j++) {
        this.orderCMOdal = t;
        if (this.dataA[j].id == t) {
          this.nameM = this.dataA[j].customer_name;
          this.phoneM = this.dataA[j].customer_phone;
          this.itemTM = this.dataA[j].total_item;
          this.costM = this.dataA[j].total_cost;
          let a = this.dataA[j].details;
          let b = a.split('|');
          for (let i = 0; i < b.length; i++) {
            let c = b[i].split(',');
            const d = {
              q: c[0],
              w: c[1],
              e: c[2],
              r: c[3],
              t: c[4],
            };
            this.orderD.push(d);
          }
          break;
        }
      }
      this.detailsModalCustomer2 = true;
    },

    async jeAdd() {
      let x = [];
      let dataTT = [];
      let dataCAO = [];
      // let user = [];
      // await api
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get(`api/last_laundry/`)
        .then((response) => {
          x = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'laundry' && dataCAO[i].type === 'Debit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Laundry',
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'demo',
        reference: this.res_id,
        credit: 0,
        debit: this.total_cost,
        memo: x.id,
        journal_id: c,
        auto_journal: true,
      };
      await api.post('/api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style>
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>