<template>
  <div>
    <div class="min-h-screen h-full bg-gray-200">
      <div class="grid grid-cols-3 gap-x-2">
        <div class="h-14 col-span-3 border-2 border-gray-600 bg-accentM">
          <ol class="list-reset flex mt-3 ml-5">
            <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li><span @click="this.$router.push('/purchase')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Purchase</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li class="text-white text-xl">Purchase Apporval</li>
          </ol>
        </div>

        <div class="min-h-[5vh] col-span-2 mt-5">
          <div class="inline-flex rounded-md shadow-sm float-left ml-10" role="group">
            <button
              type="button"
              @click="
                pending = true;
                load = false;
                getData();
              "
              :class="{ activeRed: pending }"
              class="py-1 px-4 text-base font-medium text-gray-900 bg-white rounded-l-sm border border-gray-400 hover:bg-button2 hover:text-white"
            >
              Pending
            </button>
            <button
              type="button"
              @click="
                pending = false;
                load = true;
                getData();
              "
              :class="{ activeRed: load }"
              class="py-1 px-4 text-base font-medium text-gray-900 bg-white border border-gray-400 hover:bg-button2 hover:text-white"
            >
              RFQ
            </button>
          </div>
        </div>

        <div class="col-span-3 mt-1" style="height: 78vh">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 min-h-4/5">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs uppercase bg-accentM">
                <tr>
                  <th scope="col" class="md:pl-20 px-6 py-3 text-white">Order Id</th>
                  <th scope="col" class="px-6 py-3 text-white">Order By</th>
                  <th scope="col" class="px-6 py-3 text-white">Item Name</th>
                  <th scope="col" class="px-6 py-3 text-white">Quantity</th>
                  <th scope="col" class="px-6 py-3 text-white">Unit Price</th>
                  <th scope="col" class="px-6 py-3 text-white">Total Price</th>
                  <th scope="col" class="px-6 py-3 text-white">Budget</th>
                  <th scope="col" class="px-6 py-3 text-white">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in data" :key="index" class="border-b odd:bg-white even:bg-gray-50">
                  <td class="px-6 py-4 md:pl-20">{{ p.orderNumber }}</td>
                  <td class="px-6 py-4">{{ p.orderBy }}</td>
                  <td class="px-6 py-4">{{ p.itemName }}</td>
                  <td class="px-6 py-4">{{ p.quantity }}</td>
                  <td class="px-6 py-4">{{ p.unitPrice }}</td>
                  <td class="px-6 py-4">{{ p.quantity * p.unitPrice }}</td>
                  <td v-if="p.budgetBool" class="px-6 py-4 text-green-500 font-bold">{{ p.budget }}</td>
                  <td v-else class="px-6 py-4 text-red-500 font-bold">{{ p.budget }}</td>
                  <td v-if="p.statusName == 'approved'" class="px-6 py-4 font-bold text-green-700">
                    <router-link :to="{ name: 'RFQ', params: { id: p.idMain } }" class="bg-green-500 hover:bg-green-300 text-white hover:text-black text-sm py-1 px-2 mx-1 float-left rounded">Creat RFQ</router-link>
                    Approved
                  </td>
                  <td v-else-if="p.statusName == 'rejected'" class="px-6 py-4 font-bold text-red-700">
                    <router-link :to="{ name: 'RFQ', params: { id: p.idMain } }" class="bg-green-500 hover:bg-green-300 text-white hover:text-black text-sm py-1 px-2 mx-1 float-left rounded">Creat RFQ</router-link>
                    Rejected
                  </td>
                  <td v-else class="px-6 py-4">
                    <button @click="Accept(p.id)" class="bg-button2 hover:bg-button text-white hover:text-black text-sm py-1 px-2 mx-1 float-left rounded">Accept</button>
                    <button @click="Reject(p.id)" class="bg-red-600 hover:bg-red-400 text-white hover:text-black text-sm py-1 px-2 mx-1 float-left rounded">Reject</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="h-[6vh] col-span-3">
          <button class="bg-button hover:bg-button2 text-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 text-base py-2 px-4 mx-1 mt-6 rounded">Prev</button>
          <button class="bg-button hover:bg-button2 text-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 text-base py-2 px-4 mx-1 mt-6 rounded">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';

export default {
  components: {},

  data() {
    return {
      searchName: '',
      dataPurchase: [],
      dataItemReq: [],
      dataBudget: [],
      dataStatus: [],
      dataStatus2: [],
      data: [],

      pending: true,
      load: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.data = [];
      await api
        .get(`api/budget/`)
        .then((res) => {
          this.dataBudget = res.data;
          // console.table(this.dataBudget);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=or&statusCode=&statusName=pending`)
        .then((res) => {
          this.dataStatus = res.data;
          if (this.dataStatus == '') {
            alert('Status not Set');
          }
          console.table(this.dataStatus);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/status/?orderType=or&statusCode=&statusName=approved`)
        .then((res) => {
          this.dataStatus2 = res.data;
          if (this.dataStatus2 == '') {
            alert('Status not Set');
          }
          console.table(this.dataStatus2);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/pruchase/?status=${this.dataStatus[0].id}`)
        .then((res) => {
          this.dataPurchase = res.data;
          // console.table(this.dataPurchase);
        })
        .catch((error) => {
          console.log(error);
        });
      if (this.load) {
        await api
          .get(`api/requested_item/?orderId=&status=${this.dataStatus2[0].id}`)
          .then((res) => {
            this.dataItemReq = res.data;
            // console.table(this.dataItemReq);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.pending) {
        await api
          .get(`api/requested_item/?orderId=&status=${this.dataStatus[0].id}`)
          .then((res) => {
            this.dataItemReq = res.data;
            // console.table(this.dataItemReq);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      for (let i = 0; i < this.dataItemReq.length; i++) {
        for (let j = 0; j < this.dataPurchase.length; j++) {
          if (this.dataPurchase[j].id == this.dataItemReq[i].orderId) {
            this.dataItemReq[i].orderNumber = this.dataPurchase[j].orderNumber;
            this.dataItemReq[i].orderBy = this.dataPurchase[j].orderBy;
            this.dataItemReq[i].idMain = this.dataPurchase[j].id;
            break;
          }
        }
        for (let j = 0; j < this.dataBudget.length; j++) {
          if (this.dataBudget[j].item == this.dataItemReq[i].item) {
            this.dataItemReq[i].budget = this.dataBudget[j].budget;
            let a = this.dataItemReq[i].quantity * this.dataItemReq[i].unitPrice;
            if (a <= this.dataBudget[j].budget) {
              this.dataItemReq[i].budgetBool = true;
            } else {
              this.dataItemReq[i].budgetBool = false;
            }
            break;
          }
        }
        this.data.push(this.dataItemReq[i]);
      }
      console.table(this.data);
    },
    async Accept(a) {
      await api
        .put(`api/requested_item/${a}/`, {
          status: this.dataStatus2[0].id,
          statusCode: this.dataStatus2[0].statusCode,
          statusName: this.dataStatus2[0].statusName,
        })
        .catch((error) => {
          console.log(error);
        });
      this.dataPurchase = [];
      this.dataItemReq = [];
      this.dataBudget = [];
      this.data = [];
      this.getData();
    },
    async Reject(a) {
      await api
        .put(`api/requested_item/${a}/`, {
          id: a,
          status: -1,
          statusName: 'rejected',
        })
        .catch((error) => {
          console.log(error);
        });
      this.dataPurchase = [];
      this.dataItemReq = [];
      this.dataBudget = [];
      this.data = [];
      this.getData();
    },
  },
};
</script>

<style>
.activeRed {
  background-color: #f8d766 !important;
  color: black !important;
}
.activeFilter {
  background-color: #f8d766 !important;
  color: white !important;
}
</style>