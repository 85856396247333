<template>
  <div style="height: 100vh">
    <div class="columns" style="height: 100px">
      <div class="column is-12" style="height: 70px">
        <div class="box" style="font-size: 22px; font-weight: bold; height: 70px; width: 100%">
          <p style="float: left">Workflow</p>
          <template v-if="this.currentUserData.role == 3">
            <button class="button" style="float: right; background-color: #099dd9; color: white; margin-top: -5px" @click.prevent="newModal = true">Add New</button>
          </template>
          <template v-else>
            <template v-if="this.delegationDefine.length == 0">
              <button
                class="button"
                style="float: right; background-color: #099dd9; color: white; margin-top: -5px"
                @click.prevent="
                  delegateModal = true;
                  getDelegateInfo();
                "
              >
                Delegate
              </button>
            </template>
            <template v-else>
              <button class="button" style="float: right; background-color: #099dd9; color: white; margin-top: -5px" @click.prevent="cancelDelegate()">Revert Delegate</button>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <template v-if="this.delegationDefine.length != 0">
          <p>All New Assigned Workflows will be delegated</p>
        </template>
        <template v-else>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Room Type</th>
                <th>Proposed Price</th>
                <th>Proposed Discount</th>
                <th>Effective Date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="d in FlowFilterData" v-bind:key="d.id">
                <td>{{ d.room_type }}</td>
                <td>{{ d.proposedCost }}</td>
                <td>{{ d.proposedDiscount }}</td>
                <td>{{ d.activataionDate }}</td>
                <td>
                  <p v-if="d.status == 'pending'">Pending</p>
                  <p v-else-if="d.status == 'rejected'">Rejected</p>
                  <p v-else>Approved</p>
                </td>
                <td>
                  <template v-if="d.status != 'done'">
                    <button class="button is-small" style="margin-right: 10px; background-color: #40c4ff; color: white" @click="flowEditModal(d)" :disabled="d.role_user_id != this.currentUserData.id || d.status == 'rejected'">Approve</button>
                    <button
                      class="button is-small"
                      style="background-color: #ff6865; color: white"
                      @click="
                        rejectProposal(d);
                        rejectModal = true;
                      "
                      :disabled="d.role_user_id != this.currentUserData.id || d.status == 'rejected'"
                    >
                      Reject
                    </button>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <div v-if="rejectModal == true">
          <div class="modal is-active">
            <div class="modal-background" @click.prevent="rejectModal = false"></div>
            <div class="modal-content" style="background: white; height: 40vh; width: 40vw; overflow-x: hidden">
              <div class="field is-horizontal" style="margin-top: 5vh; margin-left: 2vw">
                <div class="field-label is-normal" style="">
                  <label class="label" style="font-size: 18px; text-align: left">Reject Reason:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control" style="width: 410px">
                      <textarea class="textarea" placeholder="Reason" v-model="rejectReason" style="width: 200px"></textarea>
                    </p>
                  </div>
                </div>
              </div>
              <button class="button" style="margin-top: 20px; background-color: #099dd9; color: white" @click.prevent="rejectSubmit()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div v-if="delegateModal == true">
          <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
            <div class="modal-background" @click.prevent="delegateModal = false"></div>
            <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 40vw; margin-right: 0; overflow-x: hidden">
              <div style="width: 40vw; background-color: #51627e; color: white; font-size: 20px; margin-bottom: 10px">Delegate</div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="font-size: 20px">Choose Delegate: </label>
                </div>
                <div class="field-body">
                  <div class="control">
                    <select v-model="delegateValue" style="width: 400px; height: 40px">
                      <option value="" hidden>Select Delegate</option>
                      <option v-for="r in userExemtedFinal" :key="r.name" :value="r">{{ r.username }} | {{ r.first_name }} {{ r.last_name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <p>Please complete your pending approval's before delegation</p>
              <br />
              <button class="button" @click.prevent="submitDelegate()" style="background-color: #099dd9; color: white">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div v-if="newModal == true">
          <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
            <div class="modal-background" @click.prevent="newModal = false"></div>
            <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 40vw; margin-right: 0; overflow-x: hidden">
              <div style="width: 40vw; background-color: #51627e; color: white; font-size: 20px; margin-bottom: 10px">Add New WorkFlow</div>
              <div class="field is-horizontal">
                <div class="field-label is-normal" style="margin-left: 0">
                  <label class="label" style="font-size: 20px; width: 180px">WorkFlow Name:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input class="input" type="text" placeholder="WorkFlow Name" v-model="workflowName" style="width: 350px; float: left" />
                    </p>
                  </div>
                </div>
              </div>
              <div style="border-bottom: 1px solid black; margin-top: 20px; font-size: 20px">WorkFlow Level</div>
              <div class="columns">
                <div class="column is-8">
                  <table class="table is-fullwidth" style="margin-left: 10px">
                    <thead class="has-text-centered">
                      <th>Level</th>
                      <th>User Name</th>

                      <!-- <th style="word-break:break;">Depends on:</th> -->
                    </thead>
                    <tbody>
                      <tr v-for="(element, index) in assignRole" :key="element.setId">
                        <th scope="row">{{ element.DependId }}</th>
                        <td>
                          <div class="select" style="width: 400px">
                            <select v-model="element.roleName" v-on:change="roleNameAssign(index, element.roleName)">
                              <option disabled selected label="Select Options"></option>
                              <option v-for="user in roleApi" :key="user.id" :value="user.id">{{ user.position }} | {{ user.first_name }} {{ user.last_name }} | limit: {{ user.limit_start }} BDT-{{ user.limit_end }} BDT</option>
                            </select>
                          </div>
                        </td>
                        <td v-if="index == assignRole.length - 1 && index != 0">
                          <button class="button" @click.prevent="deleteRole(index)" style="background-color: #ff6865; color: white"><i class="fa-solid fa-xmark"></i></button>
                        </td>

                        <!-- <td><div class="select"><select v-model="element.dependOn"><option v-for="elements in assignRole" :key=elements.setId ><template v-if="element.DependId===1">None</template><template v-else>{{elements.DependId}}</template></option></select></div></td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-if="workflowName != ''" style="margin-right: 10px">
                <div v-if="assignRole[0].roleName != null && role1button == true">
                  <button class="button is-pulled-right" @click.prevent="submitTemplate()" style="background-color: #099dd9; color: white">Submit</button>
                  <button
                    class="button is-pulled-right"
                    style="background-color: #40c4ff; color: white"
                    @click.prevent="
                      addRoleTask();
                      role1button = false;
                      role2button = true;
                    "
                  >
                    Add Task 1
                  </button>
                </div>
                <div v-if="assignRole[1] != null">
                  <div v-if="assignRole[1].roleName != null && role2button == true">
                    <button class="button is-pulled-right" @click.prevent="submitTemplate()" style="background-color: #099dd9; color: white">Submit</button>
                    <button
                      class="button is-pulled-right"
                      style="background-color: #40c4ff; color: white"
                      @click.prevent="
                        addRoleTask();
                        role1button = false;
                        role2button = false;
                        role3button = true;
                      "
                    >
                      Add Task 2
                    </button>
                  </div>
                </div>
                <div v-if="assignRole[2] != null">
                  <div v-if="assignRole[2].roleName != null && role3button == false">
                    <button class="button is-pulled-right" @click.prevent="submitTemplate()" style="background-color: #099dd9; color: white">Submit</button>
                    <button
                      class="button is-pulled-right"
                      style="background-color: #40c4ff; color: white"
                      @click.prevent="
                        addRoleTask();
                        role2button = false;
                        role3button = true;
                      "
                    >
                      Add Task 3
                    </button>
                  </div>
                  <div v-if="role3button == true">
                    <button class="button is- is-pulled-right" @click.prevent="submitTemplate()" style="background-color: #099dd9; color: white">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import axios from '../boot/axios';
import { toast } from 'bulma-toast';
export default {
  data() {
    return {
      newModal: false,
      workflowName: '',
      assignRole: [
        {
          roleName: null,
          DependId: 1,
          dependOn: 0,
        },
      ],
      taskDemoNum: 0,
      roleApi: [],
      userData: [],
      dependIdCount: 1,
      role1button: true,
      role2button: false,
      role3button: false,
      roleDetail: [],
      roleConcated: '',
      lastWorkflowName: '',
      dataFlow: [],
      templateLevelData: [],
      currentUserData: '',
      rejectModal: false,
      rejectId: '',
      rejectReason: '',
      dataFlowFilter: [],
      FlowFilterData: [],
      delegateModal: false,
      filterUserFlowData: [],
      filterUserExemted: [],
      userExemted: [],
      userExemtedFinal: [],
      delegateValue: '',
      valuesDelegate: [],
      delegationDefine: [],
    };
  },
  async mounted() {
    await axios
      .get('/auth/users/?is_manager=true')
      .then((res) => {
        this.userData = res.data;
        //console.log(this.userData)
      })
      .catch((err) => console.log(err));

    this.roleApi = this.userData;

    await this.getDataFlow();
  },
  methods: {
    async cancelDelegate() {
      await api
        .get(`api/workflow_template_levels/?workflow_name=&depend_role=${this.delegationDefine[0].delegate_to}`)
        .then((res) => {
          this.valuesDelegate = res.data;
        })
        .catch((err) => console.log(err));

      for (let i = 0; i < this.valuesDelegate.length; i++) {
        await api
          .put(`api/workflow_template_levels/${this.valuesDelegate[i].id}/`, {
            depend_role: this.currentUserData.id,
            workflow_name: this.valuesDelegate[i].workflow_name,
          })
          .catch((err) => console.log(err));
      }
      await api.delete(`api/user_profile/${this.delegationDefine[0].id}/`).catch((err) => console.log(err));

      await this.getDataFlow();
    },
    async submitDelegate() {
      await api
        .get(`api/workflow_template_levels/?workflow_name=&depend_role=${this.currentUserData.id}`)
        .then((res) => {
          this.valuesDelegate = res.data;
        })
        .catch((err) => console.log(err));

      await api
        .post(`api/user_profile/`, {
          user: this.currentUserData.id,
          delegate_to: this.delegateValue.id,
        })
        .catch((err) => console.log(err));
      for (let i = 0; i < this.valuesDelegate.length; i++) {
        await api
          .put(`api/workflow_template_levels/${this.valuesDelegate[i].id}/`, {
            depend_role: this.delegateValue.id,
            workflow_name: this.valuesDelegate[i].workflow_name,
          })
          .catch((err) => console.log(err));
      }
      this.delegateModal = false;
      await this.getDataFlow();
    },
    async getDelegateInfo() {
      for (let i = 0; i < this.FlowFilterData.length; i++) {
        if (this.FlowFilterData[i].status == 'pending') {
          this.filterUserFlowData.push(this.FlowFilterData[i]);
        }
      }

      for (let i = 0; i < this.filterUserFlowData.length; i++) {
        await api
          .get(`api/workflow_template_levels/?workflow_name=${this.filterUserFlowData[i].template}`)
          .then((res) => {
            this.filterUserExemted = res.data;
          })
          .catch((err) => console.log(err));
      }

      let tempExemted = [];

      for (let i = 0; i < this.filterUserExemted.length; i++) {
        for (let j = 0; j < this.userData.length; j++) {
          if (this.filterUserExemted[i].depend_role != this.userData[j].id) {
            tempExemted.push(this.userData[j]);
          } else {
            this.userExemted.push(this.userData[j]);
          }
        }
      }

      this.userExemtedFinal = this.userData.filter((val) => !this.userExemted.includes(val));
    },
    async rejectProposal(id) {
      this.rejectModal = true;

      this.rejectId = id;
    },
    async rejectSubmit() {
      await api
        .get(`api/workflow_template_levels/?workflow_name=${this.rejectId.template}`)
        .then((res) => {
          this.templateLevelData = res.data;
        })
        .catch((err) => console.log(err));

      await api
        .put(`api/room_type_flow/${this.rejectId.id}/`, {
          depends_id: this.templateLevelData[0].depend_id,
          depends_on: this.templateLevelData[0].depend_on,
          template: this.rejectId.template,
          status_role: 'rejected',
          status: 'rejected',
          room_type: this.rejectId.room_type,
          reject_reason: this.rejectReason,
        })
        .then(() => {
          toast({
            message: 'Proposal has been Rejected',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
        })
        .catch((err) => console.log(err));

      this.getDataFlow();
      this.rejectModal = false;
    },
    async flowEditModal(id) {
      await api
        .get(`api/workflow_template_levels/?workflow_name=${id.template}`)
        .then((res) => {
          this.templateLevelData = res.data;
        })
        .catch((err) => console.log(err));

      //this.dataFlow.role_user_id == this.currentUserData.id
      if (id.status_role == 'ongoing') {
        if (this.templateLevelData.length == 1) {
          await api
            .put(`api/room_type_flow/${id.id}/`, {
              template: id.template,
              status_role: 'done',
              status: 'done',
              room_type: id.room_type,
            })
            .catch((err) => console.log(err));
        }
        if (this.templateLevelData.length == 2) {
          if (id.depends_id == 1) {
            await api
              .put(`api/room_type_flow/${id.id}/`, {
                depends_id: this.templateLevelData[1].depend_id,
                depends_on: this.templateLevelData[1].depend_on,
                template: id.template,
                status_role: 'ongoing',
                status: 'pending',
                room_type: id.room_type,
                role_user_id: this.templateLevelData[1].depend_role,
              })
              .catch((err) => console.log(err));
          }
          if (id.depends_id == 2) {
            await api
              .put(`api/room_type_flow/${id.id}/`, {
                template: id.template,
                status_role: 'done',
                status: 'done',
                room_type: id.room_type,
              })
              .catch((err) => console.log(err));
          }
        }
        if (this.templateLevelData.length == 3) {
          if (id.depends_id == 1) {
            await api
              .put(`api/room_type_flow/${id.id}/`, {
                depends_id: this.templateLevelData[1].depend_id,
                depends_on: this.templateLevelData[1].depend_on,
                template: id.template,
                status_role: 'ongoing',
                status: 'pending',
                room_type: id.room_type,
                role_user_id: this.templateLevelData[1].depend_role,
              })
              .catch((err) => console.log(err));
          }
          if (id.depends_id == 2) {
            await api
              .put(`api/room_type_flow/${id.id}/`, {
                depends_id: this.templateLevelData[2].depend_id,
                depends_on: this.templateLevelData[2].depend_on,
                template: id.template,
                status_role: 'ongoing',
                status: 'pending',
                room_type: id.room_type,
                role_user_id: this.templateLevelData[2].depend_role,
              })
              .catch((err) => console.log(err));
          }
          if (id.depends_id == 3) {
            await api
              .put(`api/room_type_flow/${id.id}/`, {
                template: id.template,
                status_role: 'done',
                status: 'done',
                room_type: id.room_type,
              })
              .catch((err) => console.log(err));
          }
        }
      }
      this.getDataFlow();
    },
    async roleNameAssign(index, id) {
      for (let i = 0; i < this.userData.length; i++) {
        if (this.userData[i].id == id) {
          for (let j = 0; j < this.userData.length; j++) {
            if (this.roleDetail[j] != id) {
              this.roleDetail.push(this.userData[i].first_name + ' ' + this.userData[i].last_name);
              break;
            }
          }
        }
      }
    },
    async getDataFlow() {
      this.dataFlowFilter.length = 0;
      this.FlowFilterData.length = 0;

      await api
        .get('auth/currentuser/')
        .then((res) => {
          this.currentUserData = res.data;
        })
        .catch((err) => console.log(err));

      await api
        .get(`api/user_profile/?user=${this.currentUserData.id}`)
        .then((res) => {
          this.delegationDefine = res.data;
        })
        .catch((err) => console.log(err));

      await api
        .get('api/room_type_flow/')
        .then((response) => {
          this.dataFlow = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      await api
        .get(`api/workflow_template_levels/?depend_role=${this.currentUserData.id}`)
        .then((res) => {
          this.dataFlowFilter = res.data;
        })
        .catch((err) => console.log(err));

      if (this.dataFlowFilter.length != 0) {
        for (let i = 0; i < this.dataFlowFilter.length; i++) {
          for (let j = 0; j < this.dataFlow.length; j++) {
            if (this.dataFlowFilter[i].workflow_name == this.dataFlow[j].template) {
              this.FlowFilterData.push(this.dataFlow[j]);
            }
          }
        }
      }
    },
    async addRoleTask() {
      //console.log("role stage"+ stageNo)
      let dependTemp = this.dependIdCount;
      this.dependIdCount++;

      var roleTask = {
        roleName: this.assignRole.roleName,

        DependId: this.dependIdCount,

        dependOn: dependTemp,
      };

      this.assignRole.push(roleTask);

      //console.log(this.stage)

      const taskDemoNumVal = this.taskDemoNum++;

      console.log(taskDemoNumVal);
    },
    async submitTemplate() {
      for (let i = 0; i < this.roleDetail.length; i++) {
        this.roleConcated += this.roleDetail[i] + ' ' + '>';
      }

      await api
        .post('api/workflow_template/', {
          name: this.workflowName,
          role_concated: this.roleConcated.slice(0, -1),
        })
        .catch((err) => console.log(err));

      await api.get(`api/workflow_template/${this.workflowName}/`).then((res) => {
        this.lastWorkflowName = res.data.name;
      });

      for (let i = 0; i < this.assignRole.length; i++) {
        await api.post('api/workflow_template_levels/', {
          workflow_name: this.lastWorkflowName,
          depend_on: this.assignRole[i].dependOn,
          depend_id: this.assignRole[i].DependId,
          depend_role: this.assignRole[i].roleName,
          role_concated: this.roleConcated,
        });
      }

      //workhere
      this.newModal = false;
      this.workflowName = '';
      this.roleConcated = '';
    },
    deleteRole(index) {
      let roledeleted;
      roledeleted = this.assignRole.pop();
      console.log(roledeleted);

      if (index == 1) {
        this.role1button = true;
        this.role2button = false;
      }
      if (index == 2) {
        this.role2button = true;
        this.role3button = false;
      }
      this.dependIdCount--;
      this.roleDetail.pop();
    },
  },
};
</script>

<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>