<template>
  <div style="height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%">
          <div style="float: left"><p style="font-size: 24px">Food</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="$router.push('/settings')">Back</button></div>
        </div>
      </div>
    </div>

    <!-- Input -->
    <div class="columns">
      <div class="column is-2"></div>
      <div class="column is-3">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" style="font-size: 18px; font-weight: normal">Name:</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control" style="width: 300px">
                <input class="input" type="text" placeholder="item name" v-model="item" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-3">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" style="font-size: 18px; font-weight: normal">Cost:</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control" style="width: 300px">
                <input class="input" type="text" placeholder="cost" v-model="cost" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-2">
        <div style="float: left">
          <button class="button" style="background-color: #26ade4; color: white" @click="AddData()">Add</button>
        </div>
      </div>
      <div class="column is-2"></div>
    </div>

    <div class="columns">
      <div class="column is-2"></div>
      <div class="column is-7">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Cost</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in data" v-bind:key="d.id">
              <td>{{ d.name }}</td>
              <td>{{ d.cost }}</td>
              <td>
                <!-- <button class="button is-info is-small" @click="ItemEdit(d.id)"><i class="fa-solid fa-pen" style="font-size:10px;"></i></button> -->
                <button class="button is-small" style="background-color: #e67c6c; color: white; margin-left: 5px" @click="DeleteItem(d.id)"><i class="fas fa-solid fa-xmark" style="font-size: 15px"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-3"></div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      data: [],
      item: '',
      cost: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await api
        .get('api/food_type/')
        .then((response) => {
          this.data = response.data;
          console.log(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async AddData() {
      if (this.item == '' || this.cost == '') {
        toast({
          message: 'Feilds can not be empty',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      } else {
        const a = {
          name: this.item,
          cost: this.cost,
        };
        await api
          .post('/api/food_type/', a)
          .then(() => {
            toast({
              message: 'Food item added',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            });

            (this.item = ''), (this.cost = ''), this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async DeleteItem(t) {
      await api
        .delete(`/api/food_type/${t}/`)
        .then((response) => {
          toast({
            message: 'Food item deleted',
            type: 'is-warning',
            dismissible: true,
            pauseOnHover: true,
            duration: 1000,
            position: 'bottom-right',
          });
          console.log(response);
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>