<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Lost & Found</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="lfModal = true">Add</button></div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="columns">
      <div class="column is-1"></div>
      <div class="column is-10">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Room</th>
              <th>Received Date</th>
              <th>Time</th>
              <th>Items</th>
              <th>Found by</th>
              <th>Description</th>
              <th>Claim Status</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in dataA" v-bind:key="d.id">
              <td>{{ d.room_no }}</td>
              <td>{{ d.date }}</td>
              <td>{{ d.time }}</td>
              <td>{{ d.item }}</td>
              <td>{{ d.found_by }}</td>
              <td>{{ d.description }}</td>
              <td>
                <p v-if="d.claimed == false">Not Claimed</p>
                <p v-else>Claimed</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-1"></div>
    </div>

    <!-- Modal: Add Lost & Found -->
    <div v-if="lfModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="lfModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 900px; margin-right: 0">
          <div class="box" style="height: 100vh">
            <div style="float: left; width: 850px; height: 80vh">
              <p style="font-size: 30px">Add Lost & Found</p>
              <div class="section">
                <form>
                  <div class="field">
                    <label>Room No.</label>
                    <div class="control">
                      <select v-model="roomS" style="width: 100%">
                        <option value="" hidden>Select Room</option>
                        <option v-for="r in dataR" :key="r.id">{{ r.room_no }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="field">
                    <label>Found Date</label>
                    <div class="control">
                      <input type="date" class="input" v-model="date" />
                    </div>
                  </div>

                  <div class="field">
                    <label>Found Time</label>
                    <div class="control">
                      <input type="time" class="input" v-model="time" />
                    </div>
                  </div>

                  <div class="field">
                    <label>Item(s)</label>
                    <div class="control">
                      <input type="text" class="input" placeholder="number" v-model="item" />
                    </div>
                  </div>

                  <div class="field">
                    <label>Description</label>
                    <div class="control">
                      <input type="text" class="input" placeholder="description..." v-model="description" v-on:keyup.space="submit" />
                    </div>
                  </div>

                  <div class="field">
                    <label>Found By</label>
                    <div class="control">
                      <input type="text" class="input" placeholder="who found it" v-model="found_by" v-on:keyup.space="submit" />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <!-- Buttons -->
            <div style="margin-top: 5px">
              <button class="button" @click.prevent="lfModal = false" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
              <button class="button" @click.prevent="submitForm()" style="background-color: #26ade4; color: white">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      lfModal: false,
      dataA: [],
      dataR: [],
      room_no: '',
      date: new Date().toISOString().substr(0, 10),
      time: new Date().toISOString().substr(11, 5),
      item: 0,
      description: '',
      found_by: '',
      claimed: false,
      roomS: '',
      pic: null,
    };
  },
  mounted() {
    this.getData();
    this.getDataR();
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back07;
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/Lost_found/')
        .then((response) => {
          this.dataA = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataR() {
      await api
        .get('api/room/')
        .then((response) => {
          this.dataR = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async submitForm() {
      if (this.roomS == '') {
        toast({
          message: 'Room no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.date == '' || this.time == '') {
        toast({
          message: 'Date/Time no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.items == '' || this.description == '') {
        toast({
          message: 'Item descrption is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      const a = {
        room_no: this.roomS,
        date: this.date,
        time: this.time,
        item: this.item,
        description: this.description,
        found_by: this.found_by,
        claimed: this.claimed,
      };
      await api
        .post('/api/Lost_found/', a)
        .then(() => {
          toast({
            message: 'The Data has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          this.getData();
          this.lfModal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>