<template>
  <div v-bind:style="{ 'background-image': 'url(' + pic + ')' }" style="background-repeat: no-repeat; background-size: cover; height: 100vh">
    <!-- Title -->
    <div class="columns">
      <div class="column is-12">
        <div class="box" style="display: inline-block; width: 100%; height: 70px">
          <div style="float: left"><p style="font-size: 24px">Maintenance</p></div>
          <div style="float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="lfModal = true">Add</button></div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="container" style="">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Room</th>
            <th>Date</th>
            <th>Priority</th>
            <th>Maintenance Type</th>
            <th>Commnets</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="d in dataM" v-bind:key="d.id">
            <td>{{ d.room_no }}</td>
            <td>{{ d.date }}</td>
            <td>{{ d.priority }}</td>
            <td>{{ d.type_main }}</td>
            <td>
              <template v-if="d.comments == ''"> NA </template>
              <template v-else>
                {{ d.comments }}
              </template>
            </td>
            <td>
              <button class="button is-small" style="background-color: #76dbab; color: white" @click.prevent="(statusR = true), (id = d.id), (room = d.room_no)" :disabled="d.status == 'done'">
                <p v-if="d.status == 'pending'">Pending</p>
                <p v-else>Completed</p>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- ModaL: Maintenance status update -->
    <div v-if="statusR == true">
      <div class="modal is-active">
        <div class="modal-background" @click.prevent="statusR = false">
          <div class="modal-content" style="height: 30vh; background: white; margin-top: 25vh">
            <div style="margin-top: 15%">
              <p class="subtitle is-4">The Maintenance has been completed</p>
              <div style="margin-top: 25px">
                <button class="button" style="background-color: #26ade4; color: white" @click.prevent="changeStatus">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Add Maintenance -->
    <div v-if="lfModal == true">
      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
        <div class="modal-background" @click.prevent="lfModal = false"></div>
        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 900px; margin-right: 0">
          <div class="box" style="min-height: 100vh">
            <div class="columns">
              <div class="column is-12">
                <div class="box" style="height: 65px">
                  <p style="font-size: 20px">Add Maintenance</p>
                </div>
              </div>
            </div>
            <div class="columns" style="height: 75vh; margin-top: 15px">
              <div class="column is-12">
                <div class="box" style="height: 75vh; min-height: 40vh">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Room No.</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <select v-model="room_no" style="width: 100%">
                            <option value="" hidden>Select Room</option>
                            <option v-for="r in dataR" :key="r.id">{{ r.room_no }}</option>
                          </select>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Maintenance Type</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="Maintenance Type" v-model="type_main" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Priority</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <select v-model="priority" style="width: 100%">
                            <option value="" hidden>Select Room</option>
                            <option value="urgent">Urgent</option>
                            <option value="low">Low</option>
                          </select>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Comments</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 300px">
                          <input class="input" type="text" placeholder="Comments" v-model="comments" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Buttons -->
                <div style="margin-top: 5px">
                  <button class="button" @click.prevent="lfModal = false" style="background-color: #e67c6c; color: white; margin-right: 5px">Cancel</button>
                  <button class="button" @click.prevent="submitForm()" style="background-color: #26ade4; color: white">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';
import { toast } from 'bulma-toast';

export default {
  data() {
    return {
      lfModal: false,
      statusR: false,
      dataM: [],
      dataR: [],
      room_no: '',
      date: new Date().toISOString().substr(0, 10),
      type_main: '',
      priority: '',
      comments: '',
      status: '',
      id: '',
      room: '',
      pic: null,
    };
  },
  mounted() {
    this.getData();
    this.getDataR();
    this.getPic();
  },
  methods: {
    async getPic() {
      await api
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.pic = res.data.back04;
        })
        .catch((err) => console.log(err));
    },

    async getData() {
      await api
        .get('api/maintenance/')
        .then((response) => {
          this.dataM = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDataR() {
      await api
        .get('api/room/')
        .then((response) => {
          this.dataR = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let varr = [];
      for (let i = 0; i < this.dataR.length; i++) {
        if (this.dataR[i].status_hk != 'maintenance') {
          varr.push(this.dataR[i]);
        }
      }
      this.dataR = varr;
    },

    async submitForm() {
      if (this.room_no == '') {
        toast({
          message: 'Room no is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.type_main == '') {
        toast({
          message: 'Maintenance Type is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      if (this.priority == '') {
        toast({
          message: 'Priority is blank',
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        return;
      }
      const a = {
        room_no: this.room_no,
        date: new Date().toISOString().substr(0, 10),
        type_main: this.type_main,
        priority: this.priority,
        comments: this.comments,
        status: 'pending',
      };
      await api.put(`/api/room/${this.room_no}/`, { status_hk: 'maintenance', status_fd: 'maintenance', room_no: this.room_no }).catch((err) => {
        console.log(err);
      });
      await api
        .post('/api/maintenance/', a)
        .then(() => {
          toast({
            message: 'The Data has added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          this.getData();
          this.getDataR();
          this.lfModal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changeStatus() {
      await api.put(`api/maintenance/${this.id}/`, { status: 'done', end_date: new Date().toISOString().substr(0, 10), end_time: new Date().toISOString().substr(11, 5) }).catch((error) => {
        console.log(error);
      });
      this.getData();
      await api.put(`/api/room/${this.room}/`, { status_hk: 'clean', status_fd: 'available', room_no: this.room }).catch((err) => {
        console.log(err);
      });
      this.getData();
      this.getDataR();
      this.statusB = false;
    },
  },
};
</script>

<style>
.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>