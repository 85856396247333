<template>
  <div>
    <div class="h-screen bg-gray-200">
      <div class="grid grid-cols-4 gap-x-2">
        <div class="h-[55px] col-span-4 border-2 bg-accentM">
          <ol class="list-reset flex mt-3 ml-5">
            <li><span class="text-white hover:text-button cursor-pointer text-xl font-semibold">Home</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li><span @click="this.$router.push('/inventory')" class="text-white hover:text-button cursor-pointer text-xl font-semibold">Inventory</span></li>
            <li><span class="text-white mx-2 text-xl">></span></li>
            <li class="text-white text-xl">Inventory Issue</li>
          </ol>
        </div>

        <div class="col-span-4 mt-4 ml-8">
          <button @click="Save()" class="bg-button hover:bg-button2 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Submit</button>
          <button @click="Clear()" class="bg-red-400 hover:bg-red-600 text-black hover:text-white text-base py-1 px-4 mx-1 float-left rounded">Clear</button>
        </div>

        <div class="col-span-2 my-4 ml-10 mr-1 rounded py-5 bg-bg">
          <p class="font-bold ml-4 text-2xl text-left">Issue Details</p>
          <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Transfer By:</p>
            </div>
            <div class="md:w-4/6">
              <input disabled v-model="orderBy" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="issue to" />
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Issue To:</p>
            </div>
            <div class="md:w-4/6">
              <input v-model="issueTo" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="issue to" />
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Issue Number:</p>
            </div>
            <div class="md:w-4/6">
              <input v-model="issueNumber" disabled class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="order number" />
            </div>
          </div>
        </div>

        <div class="col-span-2 my-4 mr-10 py-5 ml-1 rounded bg-bg">
          <p class="font-bold ml-4 text-2xl text-left"></p>
          <!-- <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div> -->
          <br />

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Issue Date:</p>
            </div>
            <div class="md:w-4/6">
              <input v-model="date" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="date" />
            </div>
          </div>

          <div class="md:flex md:items-center my-4 auto-rows-1fr">
            <div class="md:w-2/6">
              <p class="block text-gray-900 font-bold pr-4 text-left ml-10" for="inline-full-name">Remarks:</p>
            </div>
            <div class="md:w-4/6">
              <textarea v-model="remark" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="remarks" />
            </div>
          </div>
        </div>

        <div class="col-span-4 mx-10 py-5 rounded bg-bg">
          <p class="font-bold ml-4 text-2xl text-left">Issue Items</p>
          <div class="flex-grow border-1 border-t border-gray-500 mx-3"></div>

          <div class="md:flex md:items-center mx-5 my-2 auto-rows-1fr">
            <div class="md:w-2/12 tooltip tooltip-accent" data-tip="Inventory Type">
              <select
                @change="
                  CheckQ();
                  quantity = '';
                "
                v-model="project"
                class="border-2 border-gray-400 rounded w-4/5 mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                required
              >
                <option value="" hidden>Select Project</option>
                <option value="">All</option>
                <option v-for="d in dataProject" :key="d" :value="d.id">{{ d.name }}</option>
              </select>
            </div>

            <div class="md:w-2/12 tooltip tooltip-accent" data-tip="Inventory Type">
              <select
                @change="
                  CheckQ();
                  quantity = '';
                "
                v-model="itemSelected"
                class="border-2 border-gray-400 rounded w-4/5 mr-10 py-2 px-4 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                required
              >
                <option value="" hidden>Item</option>
                <option v-for="d in dataItem" :key="d.id" :value="d.id">{{ d.name }}</option>
              </select>
            </div>

            <div class="md:w-2/12 tooltip tooltip-accent" data-tip="Quanity Avaialble">
              <input v-model="quantityA" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="number" placeholder="Quantity Avaialble" disabled />
            </div>

            <div class="md:w-2/12 tooltip tooltip-accent" data-tip="Quanity">
              <input v-model="quantity" :min="quantityA" @keyup="CheckQA()" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="number" placeholder="Quantity" />
            </div>

            <div class="md:w-2/12 tooltip tooltip-accent" data-tip="Reamrks">
              <input v-model="remarks" class="float-right md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 mx-10 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-button" type="text" placeholder="remarks" />
            </div>

            <div class="md:w-1/12">
              <button @click="Add()" class="bg-gray-600 hover:bg-gray-700 text-white rounded-lg py-1 px-3 hover:scale-110">Add</button>
            </div>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 mt-2 min-h-4/5">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-white uppercase bg-accent2">
                <tr>
                  <th scope="col" class="px-6 py-3 text-white">Name</th>
                  <th scope="col" class="px-4 py-3 text-white">Quantity Avaialble</th>
                  <th scope="col" class="px-4 py-3 text-white">Quantity Allocated</th>
                  <th scope="col" class="px-4 py-3 text-white">Project</th>
                  <th scope="col" class="px-4 py-3 text-white">Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in data" :key="p" class="border-b odd:bg-white even:bg-gray-50">
                  <td class="px-6 py-4 md:pl-20">{{ p.name }}</td>
                  <td class="px-6 py-4 md:pl-20">{{ p.qQ }}</td>
                  <td class="px-6 py-4 md:pl-20">{{ p.q }}</td>
                  <td class="px-6 py-4 md:pl-20">{{ p.pN }}</td>
                  <td class="px-6 py-4 md:pl-20">{{ p.remarks }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../boot/axios.js';

export default {
  components: {},

  data() {
    return {
      orderBy: localStorage.getItem('position'),
      date: new Date().toISOString().substr(0, 10),

      dataIssue: [],
      dataItem: [],
      data: [],
      dataProject: [],

      issueTo: '',
      issueNumber: '',
      remark: '',

      itemSelected: '',
      project: '',
      projectN: '',
      quantityA: '',
      quantity: '',
      remarks: '',
    };
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    async CheckQ() {
      if (this.project != '' && this.itemSelected != '') {
        console.log(`api/prod_det/?prod=${this.itemSelected}&project=${this.project}`);
        await api
          .get(`api/prod_det/?prod=${this.itemSelected}&project=${this.project}`)
          .then((res) => {
            let a = res.data;
            console.table(a);
            let b = 0;
            for (let i = 0; i < a.length; i++) {
              b += parseInt(a[i].stock);
            }
            this.quantityA = b;
          })
          .catch((error) => {
            console.log(error);
          });
        for (let i = 0; i < this.dataProject.length; i++) {
          if (this.dataProject[i].id == this.project) {
            this.projectN = this.dataProject[i].name;
            break;
          }
        }
      }
    },
    Clear() {
      this.issueTo = '';
      this.remark = '';
      this.issueNumber = '';
      this.issueDate = '';
      this.itemSelected = '';
      this.quantityA = '';
      this.quantity = '';
      this.remarks = '';
      this.project = '';
      this.data = [];
      this.getData();
    },
    async getData() {
      await api
        .get(`api/issue_item/`)
        .then((res) => {
          this.dataIssue = res.data;
          // console.table(this.dataIssue);
          if (this.dataIssue.length == 0) {
            this.issueNumber = '000001';
          } else {
            this.issueNumber = ('000000' + (this.dataIssue[this.dataIssue.length - 1].id + 1)).slice(-6);
          }
          console.log(this.issueNumber);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`/api/prod_raw/?category=raw`)
        .then((res) => {
          this.dataItem = res.data;
          // console.table(this.dataItem);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`/api/project/`)
        .then((res) => {
          this.dataProject = res.data;
          console.table(this.dataProject);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Add() {
      for (let i = 0; i < this.dataItem.length; i++) {
        if (this.itemSelected == this.dataItem[i].id) {
          const a = {
            id: this.dataItem[i].id,
            id2: this.dataItem[i].item,
            name: this.dataItem[i].name,
            qQ: this.quantityA,
            q: this.quantity,
            remarks: this.remarks,
            p: this.project,
            pN: this.projectN,
          };
          this.data.push(a);
          console.table(a);
          this.itemSelected = '';
          this.quantityA = '';
          this.quantity = '';
          this.remarks = '';
          this.project = '';
          return;
        }
      }
    },
    async Save() {
      const a = {
        orderType: 'II',
        docNumber: this.issueNumber,
        date: this.date,
        project: '',
        projectName: '',
        status: 101,
        statusS: 'pending',
        orderBy: this.orderBy,
        issueTo: this.issueTo,
        item: null,
        itemMaster: null,
        itemName: '',
        requestedItem: '',
        amount: 0,
        pendingItem: '',
        reamrk: this.remark,
      };
      for (let i = 0; i < this.data.length; i++) {
        a.item = this.data[i].id;
        a.itemMaster = this.data[i].id2;
        a.requestedItem = this.data[i].q;
        a.itemName = this.data[i].name;
        a.pendingItem = this.data[i].q;
        a.project = this.data[i].p;
        a.projectName = this.data[i].pN;
        await api
          .post(`/api/issue_item/`, a)
          .then((res) => {
            console.table(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.Clear();
    },
    CheckQA() {
      if (this.quantityA < this.quantity) {
        this.quantity = '';
      }
    },
  },
};
</script>